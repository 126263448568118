import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	copyLinkLabel: {
		id: 'copy-content-link-label',
		defaultMessage: 'Copy link',
		description: 'Label and tooltip for the button to copy content URL',
	},
	aiSummaryLabel: {
		id: 'ai-summary-label',
		defaultMessage: 'Summarize',
		description: 'Label for the AI summary',
	},
	moreActionsLabel: {
		id: 'more-actions-label',
		defaultMessage: 'More Actions',
		description: 'Label for the more actions label and tooltip',
	},
});
