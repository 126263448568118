import { DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI } from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { DropboxIcon } from './icon';
import { DropboxSearchResultIcon } from './search-result-icon';

export const dropboxConfig: ThirdPartyConfig = {
	displayName: 'Dropbox',
	id: 'dropbox',
	resourceType: 'dropbox-connector',
	icon: get3PLogo(DropboxIcon, 'Dropbox', 'medium'),
	searchResultIcon: get3PLogo(DropboxSearchResultIcon, 'Dropbox', 'large'),
	integrationAri: 'ari:cloud:platform::integration/dropbox',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Document,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Document],
			},
		],
		[
			TypeFilterValueKey.Spreadsheet,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Spreadsheet],
			},
		],
		[
			TypeFilterValueKey.Presentation,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Presentation],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
