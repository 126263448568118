import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const OutlookCalendarIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-76.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<path
				d="M17.1963 5.81042H9.67074C9.33358 5.81042 9.05615 6.08496 9.05615 6.4186V7.10989L13.2978 8.40948L17.8109 7.10989V6.4186C17.8109 6.08496 17.5335 5.81042 17.1963 5.81042Z"
				fill="#0364B8"
			/>
			<path
				d="M18.5332 12.4833C18.5973 12.2839 18.6485 12.0806 18.6864 11.8747C18.6864 11.7717 18.6308 11.6762 18.5406 11.6247L18.535 11.6217L18.5332 11.6209L13.7889 8.94645C13.7685 8.93335 13.7473 8.92131 13.7255 8.91047C13.5418 8.82037 13.3257 8.82037 13.142 8.91047C13.1202 8.92131 13.099 8.93328 13.0785 8.94645L8.33435 11.6209L8.33257 11.6217L8.32684 11.6247C8.23674 11.6762 8.18109 11.7717 8.18115 11.8747C8.21904 12.0806 8.27023 12.2839 8.33435 12.4833L13.3648 16.124L18.5332 12.4833Z"
				fill="#0A2767"
			/>
			<path
				d="M15.1842 7.10992H12.1201L11.2354 8.40952L12.1201 9.70898L15.1842 12.308H17.8106V9.70898L15.1842 7.10992Z"
				fill="#28A8EA"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.05615 7.10999H12.1203V9.70898H9.05615V7.10999Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1846 7.10999H17.811V9.70898H15.1846V7.10999Z"
				fill="#50D9FF"
			/>
			<path
				d="M15.1845 12.308L12.1203 9.70898H9.05615V12.308L12.1203 14.907L16.8619 15.6729L15.1845 12.308Z"
				fill="#0364B8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.1201 9.70898H15.1843V12.308H12.1201V9.70898Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.05615 12.3081H12.1203V14.9071H9.05615V12.3081Z"
				fill="#064A8C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1846 12.3081H17.811V14.9071H15.1846V12.3081Z"
				fill="#0078D4"
			/>
			<path
				d="M13.5172 15.8678L8.35449 12.1426L8.57073 11.7662C8.57073 11.7662 13.2742 14.4171 13.346 14.457C13.4054 14.4806 13.4723 14.4788 13.5303 14.4518C13.5972 14.4145 18.3156 11.7532 18.3156 11.7532L18.5327 12.1296L13.5172 15.8678Z"
				fill="#0A2767"
				fillOpacity="0.498039"
			/>
			<path
				d="M18.5407 12.1248L18.535 12.1282L18.5337 12.1291L13.7895 14.8035C13.5981 14.9257 13.3556 14.9406 13.1504 14.8429L14.8028 17.0352L18.4159 17.8136V17.8154C18.5863 17.6935 18.6874 17.4976 18.6873 17.2895V11.8749C18.6874 11.9778 18.6317 12.0734 18.5416 12.1248H18.5407Z"
				fill="#1490DF"
			/>
			<path
				d="M18.6864 17.2894V16.9698L14.3164 14.5059L13.7889 14.803C13.5976 14.9251 13.3551 14.9401 13.1499 14.8424L14.8023 17.0347L18.4154 17.8131V17.8149C18.5858 17.6929 18.6868 17.497 18.6868 17.289L18.6864 17.2894Z"
				fill="black"
				fillOpacity="0.047059"
			/>
			<path
				d="M18.6645 17.4555L13.8743 14.7551L13.7889 14.8032C13.5976 14.9253 13.3551 14.9403 13.1499 14.8426L14.8023 17.0349L18.4154 17.8133V17.815C18.538 17.7271 18.6262 17.5999 18.6649 17.4551L18.6645 17.4555Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M8.33388 12.1304V12.1261H8.32955L8.31637 12.1175C8.23142 12.0657 8.17985 11.9736 8.18067 11.8749V17.2904C8.18067 17.6463 8.4767 17.9393 8.83646 17.9393H18.0299C18.0845 17.9387 18.1389 17.9314 18.1918 17.9176C18.2192 17.9129 18.2458 17.9041 18.2706 17.8916C18.2799 17.8907 18.2888 17.8877 18.2968 17.8829C18.3326 17.8684 18.3665 17.8495 18.3975 17.8266L18.415 17.8136L8.33388 12.1304Z"
				fill="#28A8EA"
			/>
			<path
				d="M12.5581 15.6292V8.55423C12.5571 8.2378 12.2943 7.97775 11.9745 7.97681H9.06976V11.2065L8.33387 11.6215L8.33215 11.6223L8.32648 11.6254C8.23632 11.6769 8.18066 11.7724 8.18066 11.8753V16.2083V16.2065H11.9745C12.2943 16.2057 12.5571 15.9456 12.5581 15.6292Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M12.1204 16.0622V8.98728C12.1194 8.6709 11.8566 8.41086 11.5368 8.40985H9.06976V11.2064L8.33387 11.6214L8.33215 11.6223L8.32648 11.6253C8.23632 11.6768 8.18066 11.7723 8.18066 11.8752V16.6414V16.6397H11.5368C11.8566 16.6387 12.1194 16.3787 12.1204 16.0622ZM12.1204 15.1959V8.98728C12.1194 8.6709 11.8566 8.41086 11.5368 8.40985H9.06976V11.2064L8.33387 11.6214L8.33215 11.6223L8.32648 11.6253C8.23632 11.6768 8.18066 11.7723 8.18066 11.8752V15.775V15.7733H11.5368C11.8566 15.7724 12.1194 15.5123 12.1204 15.1959ZM11.6826 15.1959V8.98728C11.6816 8.6709 11.4189 8.41086 11.0991 8.40985H9.06976V11.2064L8.33387 11.6214L8.33215 11.6223L8.32648 11.6253C8.23632 11.6768 8.18066 11.7723 8.18066 11.8752V15.775V15.7733H11.0991C11.4189 15.7724 11.6816 15.5123 11.6826 15.1959Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M5.26223 8.40955H11.0985C11.4186 8.40955 11.6821 8.67016 11.6821 8.98691V14.7624C11.6821 15.0791 11.4186 15.3398 11.0985 15.3398H5.26223C4.94213 15.3398 4.67871 15.0791 4.67871 14.7624V8.98691C4.67871 8.67016 4.94213 8.40955 5.26223 8.40955Z"
				fill="#0078D4"
			/>
			<path
				d="M6.37132 10.7781C6.52637 10.4511 6.77674 10.1774 7.09008 9.99232C7.4373 9.7956 7.83266 9.69762 8.2326 9.70902C8.603 9.70096 8.96862 9.7939 9.28929 9.97757C9.59104 10.1554 9.83409 10.4163 9.98876 10.7283C10.1572 11.0721 10.2413 11.4504 10.2339 11.8324C10.2421 12.2317 10.1556 12.6274 9.98137 12.9877C9.82327 13.3108 9.5729 13.581 9.26127 13.7648C8.92812 13.9543 8.54887 14.0498 8.16472 14.0407C7.7863 14.0496 7.41259 13.9557 7.08441 13.7691C6.7803 13.591 6.53433 13.3299 6.37616 13.0171C6.20659 12.6783 6.12158 12.304 6.1284 11.926C6.12088 11.5301 6.20411 11.1377 6.37176 10.7781H6.37132ZM7.13733 12.6225C7.22004 12.8292 7.36032 13.0086 7.54185 13.1397C7.72658 13.2677 7.94804 13.3338 8.17345 13.3282C8.41363 13.3375 8.65044 13.2694 8.84802 13.1341C9.02733 13.003 9.16391 12.8227 9.2407 12.6156C9.32705 12.3847 9.36958 12.14 9.36633 11.8939C9.36901 11.6457 9.32902 11.3986 9.24815 11.1636C9.17684 10.952 9.04516 10.7653 8.86903 10.6261C8.67667 10.4839 8.4405 10.4117 8.20064 10.4216C7.97032 10.4157 7.7439 10.4821 7.55408 10.6113C7.36917 10.7428 7.22577 10.9237 7.14083 11.1329C6.95299 11.6117 6.95191 12.143 7.13777 12.6225H7.13733Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.4456 5.5H12.5525C11.9748 5.5 11.4995 5.97531 11.4995 6.55295V7.74978L18.7665 9.99978L26.4985 7.74978V6.55295C26.4985 5.97531 26.0232 5.5 25.4456 5.5Z"
				fill="#0364B8"
			/>
			<path
				d="M27.7358 17.053C27.8457 16.7078 27.9334 16.3558 27.9983 15.9993C27.9984 15.821 27.903 15.6557 27.7486 15.5666L27.7389 15.5613L27.7358 15.5599L19.6078 10.9297C19.5728 10.907 19.5364 10.8861 19.4991 10.8674C19.1844 10.7114 18.8141 10.7114 18.4994 10.8674C18.4621 10.8861 18.4258 10.9069 18.3906 10.9297L10.2627 15.5599L10.2597 15.5613L10.2498 15.5666C10.0955 15.6557 10.0001 15.821 10.0002 15.9993C10.0652 16.3558 10.1529 16.7078 10.2627 17.053L18.8811 23.3563L27.7358 17.053Z"
				fill="#0A2767"
			/>
			<path
				d="M21.9988 7.74982H16.7491L15.2334 9.99982L16.7491 12.2496L21.9988 16.7493H26.4985V12.2496L21.9988 7.74982Z"
				fill="#28A8EA"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.4996 7.74982H16.7493V12.2495H11.4996V7.74982Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.9989 7.74982H26.4986V12.2495H21.9989V7.74982Z"
				fill="#50D9FF"
			/>
			<path
				d="M21.999 16.7492L16.7493 12.2496H11.4996V16.7492L16.7493 21.249L24.8728 22.5749L21.999 16.7492Z"
				fill="#0364B8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.7493 12.2496H21.9989V16.7492H16.7493V12.2496Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.4996 16.7493H16.7493V21.2489H11.4996V16.7493Z"
				fill="#064A8C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.9989 16.7493H26.4986V21.2489H21.9989V16.7493Z"
				fill="#0078D4"
			/>
			<path
				d="M19.1423 22.9124L10.2974 16.4628L10.6678 15.8111C10.6678 15.8111 18.7261 20.4008 18.849 20.4698C18.9509 20.5107 19.0654 20.5075 19.1648 20.4608C19.2795 20.3963 27.3632 15.7886 27.3632 15.7886L27.7352 16.4403L19.1423 22.9124Z"
				fill="#0A2767"
				fillOpacity="0.498039"
			/>
			<path
				d="M27.7488 16.4321L27.7391 16.4381L27.7368 16.4396L19.6089 21.0699C19.2809 21.2813 18.8655 21.3072 18.5139 21.1381L21.3449 24.9336L27.5351 26.2813V26.2843C27.8269 26.0732 28.0001 25.7341 28 25.3739V15.9995C28.0001 16.1777 27.9047 16.3431 27.7503 16.4321H27.7488Z"
				fill="#1490DF"
			/>
			<path
				d="M27.9985 25.3738V24.8204L20.5117 20.5546L19.608 21.0691C19.2802 21.2805 18.8648 21.3065 18.5132 21.1373L21.3442 24.9329L27.5343 26.2805V26.2835C27.8262 26.0723 27.9993 25.7333 27.9993 25.3731L27.9985 25.3738Z"
				fill="black"
				fillOpacity="0.047059"
			/>
			<path
				d="M27.9611 25.661L19.7543 20.9858L19.608 21.0691C19.2802 21.2805 18.8648 21.3065 18.5132 21.1373L21.3442 24.9329L27.5343 26.2805V26.2835C27.7443 26.1312 27.8954 25.911 27.9618 25.6603L27.9611 25.661Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M10.2621 16.4419V16.4344H10.2547L10.2321 16.4195C10.0866 16.3299 9.99823 16.1703 9.99965 15.9995V25.3754C9.99965 25.9916 10.5068 26.4988 11.1232 26.4988H26.8737C26.9673 26.4978 27.0606 26.4853 27.1511 26.4613C27.1982 26.4531 27.2437 26.4379 27.2862 26.4163C27.3021 26.4147 27.3174 26.4096 27.3311 26.4013C27.3925 26.3762 27.4505 26.3435 27.5036 26.3037L27.5336 26.2813L10.2621 16.4419Z"
				fill="#28A8EA"
			/>
			<path
				d="M17.4992 22.4993V10.2503C17.4976 9.70247 17.0473 9.25225 16.4995 9.25061H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V23.5019V23.4989H16.4995C17.0473 23.4973 17.4976 23.047 17.4992 22.4993Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M16.7493 23.2491V11.0002C16.7477 10.4525 16.2973 10.0022 15.7496 10.0005H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V24.2519V24.2489H15.7496C16.2973 24.2472 16.7477 23.797 16.7493 23.2491ZM16.7493 21.7493V11.0002C16.7477 10.4525 16.2973 10.0022 15.7496 10.0005H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V22.7519V22.749H15.7496C16.2973 22.7473 16.7477 22.2971 16.7493 21.7493ZM15.9993 21.7493V11.0002C15.9977 10.4525 15.5475 10.0022 14.9997 10.0005H11.5229V14.8422L10.2621 15.5606L10.2592 15.5622L10.2495 15.5674C10.095 15.6566 9.99963 15.8219 9.99963 16.0001V22.7519V22.749H14.9997C15.5475 22.7473 15.9977 22.2971 15.9993 21.7493Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M4.99971 9.99988H14.9988C15.5472 9.99988 15.9985 10.4511 15.9985 10.9995V20.9986C15.9985 21.547 15.5472 21.9984 14.9988 21.9984H4.99971C4.45131 21.9984 4 21.547 4 20.9986V10.9995C4 10.4511 4.45131 9.99988 4.99971 9.99988Z"
				fill="#0078D4"
			/>
			<path
				d="M6.90014 14.1005C7.16577 13.5344 7.59472 13.0606 8.13155 12.7401C8.72643 12.3995 9.40377 12.2299 10.089 12.2497C10.7236 12.2357 11.35 12.3966 11.8993 12.7146C12.4163 13.0225 12.8327 13.4741 13.0977 14.0143C13.3864 14.6095 13.5304 15.2645 13.5177 15.9259C13.5318 16.6172 13.3835 17.3023 13.085 17.9261C12.8142 18.4854 12.3852 18.9533 11.8513 19.2715C11.2806 19.5995 10.6308 19.7648 9.97268 19.7492C9.32435 19.7646 8.6841 19.6019 8.12185 19.2789C7.60083 18.9705 7.17941 18.5185 6.90843 17.977C6.61792 17.3903 6.47228 16.7425 6.48395 16.0879C6.47108 15.4025 6.61366 14.7231 6.9009 14.1005H6.90014ZM8.2125 17.2938C8.35421 17.6517 8.59454 17.9623 8.90555 18.1892C9.22203 18.4109 9.60145 18.5252 9.98763 18.5155C10.3991 18.5318 10.8048 18.4138 11.1433 18.1795C11.4505 17.9526 11.6845 17.6404 11.8161 17.2818C11.964 16.8821 12.0369 16.4585 12.0313 16.0324C12.0359 15.6026 11.9674 15.1749 11.8289 14.768C11.7067 14.4017 11.4811 14.0783 11.1793 13.8374C10.8498 13.5913 10.4452 13.4662 10.0342 13.4834C9.63963 13.4731 9.2517 13.5881 8.9265 13.8118C8.6097 14.0395 8.36403 14.3527 8.2185 14.7148C7.89668 15.5438 7.89483 16.4636 8.21326 17.2938H8.2125Z"
				fill="white"
			/>
		</svg>
	);
};
