import React from 'react';

export const TrelloSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18718)">
			<g clipPath="url(#clip1_2230_18718)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M30.3575 6.41167H9.64252C7.85835 6.41167 6.41168 7.85833 6.41168 9.6425V30.3575C6.41168 32.1417 7.85835 33.5883 9.64252 33.5883H30.3575C32.1417 33.5883 33.5884 32.1417 33.5884 30.3575V9.6425C33.5884 7.85833 32.1417 6.41167 30.3575 6.41167ZM18.1184 25.9858C18.1184 26.5792 17.6375 27.0592 17.045 27.0592H12.5025C11.9092 27.0592 11.4292 26.5783 11.4292 25.9858V12.5025C11.4292 11.9092 11.91 11.4292 12.5025 11.4292H17.045C17.6383 11.4292 18.1184 11.91 18.1184 12.5025V25.9858ZM28.5709 19.7958C28.5709 20.3892 28.09 20.8692 27.4975 20.8692H22.955C22.3617 20.8692 21.8817 20.3883 21.8817 19.7958V12.5025C21.8817 11.9092 22.3625 11.4292 22.955 11.4292H27.4975C28.0909 11.4292 28.5709 11.91 28.5709 12.5025V19.7958Z"
					fill="#1868DB"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18718">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18718">
				<rect width="40" height="40" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
