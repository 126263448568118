const memoizedQuerySelector = (selectors: string) => {
	let element: Element | null = null;

	return () => {
		if (!element) {
			element = document.querySelector(selectors);
		}

		return element;
	};
};

export const getProductReleaseVersion = (product?: string) => {
	switch (product?.toLowerCase()) {
		case 'confluence':
			return (window as any).__buildInfo?.FRONTEND_VERSION || 'noversion';
		case 'jira':
			return (window as any).BUILD_KEY;
		case 'townsquare' || 'atlas':
			const versionMeta = memoizedQuerySelector('meta[name="release-version"]');
			if (versionMeta instanceof HTMLMetaElement) {
				return versionMeta.content;
			}
	}

	return 'noversion';
};
