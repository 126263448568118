import { type ReactNode } from 'react';

import type { SearchExperimentLayer } from './services/statsig/dynamic/types';

/**
 * Backend experiment types
 */

// BackendExperiment includes both single-layer and multi-layer experiments.
export type BackendExperiment =
	| {
			experimentId?: string; // TODO: remove after switching to dynamic experiments
			shadowExperimentId?: string; // TODO: remove after switching to dynamic experiments
			controlId?: string; // TODO: remove after switching to multi-layer experiments
			abTestId?: string; // TODO: remove after switching to multi-layer experiments
			experimentLayers?: SearchExperimentLayer[]; // TODO: make non-optional after switching to multi-layer experiments
	  }
	| undefined;

// TODO: QS-6283 remove this field once scalable statsig layers are rolled out
export type ExperimentLayer = {
	name: string;
	layerId: string | null;
	shadowId: string | null;
	controlId: string | null;
	abTestId: string | null;
	testId?: string | null;
	definitions?: [];
};

export type MultiLayerBackendExperiment = {
	experimentId: string;
	shadowExperimentId: string;
	experimentLayers: ExperimentLayer[];
};

export type BackendExperimentContextProps = {
	children: ReactNode;
};

/**
 * Frontend experiment types
 */

type FrontendExperiment =
	| {
			// Determine whether to serve the experiment or not
			serveExperiment: boolean;
			// The name of the statsig experiment
			abTestId: string;
			// The name of the group the user is enrolled in. Will either be the experimentId or the controlId
			cohort: string;
	  }
	| undefined;

export type FrontendExperiments =
	| {
			[key: string]: FrontendExperiment;
	  }
	| undefined;

export type FrontendExperimentProps = {
	// The name of the statsig experiment
	abTestId: string;
};

export type FrontendExperimentWithOverrides = {
	abTestId: string;
	overrides: {
		experiments?: ExperimentOverride[];
		gates?: GateOverride[];
	} | null;
};

/**
 * Mutual exclusive experiment types
 */

export type MutuallyExclusiveExperiment = {
	frontendExperiment: FrontendExperiment;
	backendExperiment: BackendExperiment;
};

export enum Cohort {
	Default = 'not-enrolled',
	Control = 'control',
	Test = 'test',
}

/**
 * Side by side experiment types
 */

type BaseExperiment = {
	location: 'frontend' | 'backend';
	name: string;
};
type ExperimentType = 'experiment' | 'experimentId' | 'gate' | 'layer';

export type LayerOverride = 'l1' | 'l2' | 'l3' | 'qr';

export type SideBySideExperiment = BaseExperiment & {
	type: ExperimentType;
	layer?: LayerOverride;
};

// Narrowed type where `type` is always 'experiment' or 'control'
export type ExperimentOverride = BaseExperiment & {
	type: 'experiment';
};

export type GateOverride = BaseExperiment & {
	type: 'gate';
};

export type ServedExperiment = {
	serveExperiment: boolean;
	abTestId: string;
	cohort: string;
};
