import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import GlobeIcon from '@atlaskit/icon/core/globe';

import { DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo, RovoLogoFromLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { messages } from './messages';
import { WebDomainSearchResultIcon } from './search-result-icon';

export const atlassianWebCrawlerConfig: ThirdPartyConfig = {
	displayName: <FormattedMessage {...messages['atlassian-web-crawler']} />,
	id: 'atlassian-web-crawler',
	resourceType: 'webcrawler-connector',
	icon: RovoLogoFromLogo(GlobeIcon, 'Atlassian Web Crawler'),
	searchResultIcon: get3PLogo(WebDomainSearchResultIcon, 'Atlassian Web Crawler', 'large'),
	integrationAri: 'ari:cloud:platform::integration/atlassian-web-crawler',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.WebPage,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: false,
	isEnabled: () => true,
};
