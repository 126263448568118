export class ResultsFetchError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'ResultsFetchError';
	}
}

export class EmptyEntitiesError extends ResultsFetchError {
	constructor() {
		super('No entities provided to fetch results');
		this.name = 'EmptyEntitiesError';
	}
}
