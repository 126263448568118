import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const DovetailIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-747.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#292A2E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.77446 13.8484C5.74119 13.8676 5.74119 13.9156 5.77446 13.9349L8.90847 15.7448C8.93934 15.7626 8.95836 15.7956 8.95836 15.8312V19.4501C8.95836 19.4885 8.99997 19.5125 9.03327 19.4933L12.1673 17.6834C12.1981 17.6656 12.2172 17.6326 12.2172 17.597V13.9492C12.2172 13.9136 12.1981 13.8806 12.1672 13.8628L8.95836 12.0106L5.77446 13.8484Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.77446 6.32257C5.74119 6.3418 5.74119 6.38983 5.77446 6.40903L8.90847 8.21896C8.93934 8.23681 8.95836 8.26975 8.95836 8.30542V12.0108L12.2172 10.1288V6.42346C12.2172 6.38779 12.1981 6.35482 12.1672 6.33697L9.00828 4.51363C8.97741 4.49578 8.93934 4.49578 8.90847 4.51363L5.77446 6.32257Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.2173 10.1281L15.4261 11.9813C15.457 11.9991 15.4761 12.0321 15.4761 12.0678V15.6866C15.4761 15.7251 15.5177 15.7491 15.5509 15.7299L18.6849 13.9199C18.7158 13.9021 18.7348 13.8691 18.7348 13.8335V10.1858C18.7348 10.1501 18.7158 10.1171 18.6849 10.0993L15.526 8.27594C15.4951 8.25812 15.457 8.25812 15.4261 8.27594L12.2173 10.1281Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.72 4H5.28C4.57308 4 4 4.57308 4 5.28V18.72C4 19.4269 4.57308 20 5.28 20H18.72C19.4269 20 20 19.4269 20 18.72V5.28C20 4.57308 19.4269 4 18.72 4Z"
				fill="#0C0020"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.8497 13.2323C7.82752 13.2451 7.82752 13.2771 7.8497 13.2899L9.93904 14.4965C9.95962 14.5084 9.9723 14.5304 9.9723 14.5542V16.9667C9.9723 16.9924 10 17.0084 10.0222 16.9955L12.1116 15.7889C12.1321 15.777 12.1448 15.7551 12.1448 15.7313V13.2995C12.1448 13.2757 12.1321 13.2537 12.1115 13.2419L9.9723 12.0071L7.8497 13.2323Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.8497 8.21489C7.82752 8.22771 7.82752 8.25973 7.8497 8.27253L9.93904 9.47915C9.95962 9.49104 9.9723 9.513 9.9723 9.53678V12.007L12.1448 10.7524V8.28214C12.1448 8.25836 12.1321 8.23638 12.1115 8.22449L10.0056 7.00893C9.985 6.99702 9.95962 6.99702 9.93904 7.00893L7.8497 8.21489Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.1448 10.7523L14.2841 11.9877C14.3047 11.9996 14.3174 12.0216 14.3174 12.0453V14.4579C14.3174 14.4835 14.3451 14.4995 14.3673 14.4867L16.4566 13.2801C16.4772 13.2682 16.4899 13.2463 16.4899 13.2225V10.7907C16.4899 10.7669 16.4772 10.7449 16.4566 10.733L14.3506 9.51745C14.33 9.50557 14.3047 9.50557 14.2841 9.51745L12.1448 10.7523Z"
				fill="white"
			/>
		</svg>
	);
};
