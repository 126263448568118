import type { EntityATI } from '@atlassian/search-client';

import type { ProductKeys } from '../products';

import type { BooleanFilterState, CreatedBooleanFilter } from './boolean-filter/types';
import type { CreatedSelectFilter, SelectFilterState } from './select-filter/types';
import type { NounSubtypeKey, TypeFilterValueKey } from './universal-type';

import type { FilterId } from './index';

export const CUSTOM_OPTION_VALUE = 'custom';
export const universalTypeFilterId = 'universalTypeFilter';

export type FilterControlDropdown = 'dropdown';
export type FitlerControlSort = 'sort';
export type FilterControlId = FilterControlDropdown | FitlerControlSort;
export type FilterOrControlId = FilterId | FilterControlId;

// TODO rename this so it makes more sense
export type AllFilterValues =
	| CreatedSelectFilter<'assignee'>
	| CreatedSelectFilter<'content'>
	| CreatedSelectFilter<'contributor'>
	| CreatedSelectFilter<'lastUpdated'>
	| CreatedSelectFilter<'project'>
	| CreatedSelectFilter<'space'>
	| CreatedBooleanFilter<'verified'>
	| CreatedBooleanFilter<'onlyTitleMatch'>
	| CreatedSelectFilter<'universalTypeFilter'>
	| CreatedSelectFilter<'focusAreaType'>
	| CreatedSelectFilter<'owner'>
	| CreatedSelectFilter<'label'>
	| CreatedSelectFilter<'pageStatus'>
	| CreatedBooleanFilter<'archived'>;

export type AllFilterStates =
	| SelectFilterState<'assignee'>
	| SelectFilterState<'content'>
	| SelectFilterState<'contributor'>
	| SelectFilterState<'lastUpdated'>
	| SelectFilterState<'project'>
	| SelectFilterState<'space'>
	| BooleanFilterState<'verified'>
	| BooleanFilterState<'onlyTitleMatch'>
	| SelectFilterState<'universalTypeFilter'>
	| SelectFilterState<'focusAreaType'>
	| SelectFilterState<'owner'>
	| SelectFilterState<'label'>
	| SelectFilterState<'pageStatus'>
	| BooleanFilterState<'archived'>;

// TODO: filterID opportunity
export type Filters = Record<string, AllFilterStates | undefined>;
export type AllFilterKeys = FilterId;

export type CreateFilters = SelectFilterState<FilterId> | BooleanFilterState<FilterId>;
export type SelectFilters = Record<FilterId, CreateFilters>;

export interface TypeFilterMapping {
	products: ProductKeys[];
	entities: Partial<Record<ProductKeys, EntityATI[]>>;
	subCategories: NounSubtypeKey[];
}

export type UniversalTypeFilterMapping = Record<TypeFilterValueKey, TypeFilterMapping>;
