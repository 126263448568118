import React, { createRef, Fragment, useRef } from 'react';

import { Box, Inline, xcss } from '@atlaskit/primitives';

import { ProductKeys, useProductConfigs } from '../../constants/products';
import { filterSpacer } from '../../utils/search-result-utils';
import { MetadataPopupButton } from '../metadata-popup-button';
import { Spacer } from '../metadata-spacer';
import { useOverflowObserver } from '../overflow-observer';

/** A metadata part to render */
export type SearchMetadataPart = string | null | [string, React.ReactNode];

const socialMetadataStyles = xcss({
	alignItems: 'center',
	paddingInlineStart: 'space.050',
	paddingInlineEnd: 'space.050',
});

const subheadingStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body',
});

const subheadingLeftAreaStyles = xcss({
	gridArea: 'subheading-left',
	display: 'flex',
	alignItems: 'center',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '16px',
	overflow: 'hidden',
});

const inlineStyles = xcss({
	flex: '1 0 auto',
	position: 'absolute',
	zIndex: 'card',
	alignItems: 'center',
});

const hiddenReferenceInlineStyles = xcss({
	flex: '1 0 auto',
	visibility: 'hidden',
	position: 'relative',
	zIndex: '1',
	alignItems: 'center',
});

const metadataItemStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
});

const renderParts = (part: SearchMetadataPart, spacer: boolean) => {
	const [key, value] = Array.isArray(part) ? part : [part, part];
	return (
		<Fragment key={key}>
			{value}
			{spacer && <Spacer />}
		</Fragment>
	);
};

const filterPart = (part: SearchMetadataPart): boolean => {
	if (Array.isArray(part)) {
		return !!part[1];
	}
	return !!part;
};

const renderSocialMetadata = (defaultSocialParts: SearchMetadataPart[]) => {
	const hasBreadcrumb =
		defaultSocialParts.filter((item) => Array.isArray(item) && item[1] === 'social-signal').length >
		0;
	const parts = hasBreadcrumb
		? defaultSocialParts.slice(0, -1).filter(filterPart)
		: defaultSocialParts.filter(filterPart);

	return (
		<>
			<Inline space="space.150" xcss={socialMetadataStyles}>
				<>{parts?.map((m) => renderParts(m, false))}</>
			</Inline>
			{hasBreadcrumb && defaultSocialParts[defaultSocialParts.length - 1]}
		</>
	);
};

const SearchMetadataWithOverflowObserver = ({
	metadataItems,
	searchResultId,
	containerRef,
}: {
	metadataItems: React.ReactNode[];
	searchResultId?: string;
	containerRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
	const headerMetadataWithIds = metadataItems.map((item, index) => {
		const prefix = searchResultId || 'default';
		return {
			id: `metadata-${prefix}-${index}`,
			content: item,
		};
	});

	const headerMetadataRefs = useRef<React.MutableRefObject<HTMLDivElement>[]>([]);

	headerMetadataRefs.current = headerMetadataWithIds.map(
		(_, i) => headerMetadataRefs.current[i] ?? createRef(),
	);

	const visibleItems = useOverflowObserver({
		containerRef,
		itemRefs: headerMetadataRefs,
		itemIds: headerMetadataWithIds.map((item) => item.id),
	});

	const hasHiddenItems = headerMetadataWithIds.length > visibleItems.length;

	const processVisibleItems = () => {
		const visibleMetadata = headerMetadataWithIds.filter((item) => visibleItems.includes(item.id));

		// If we have hidden items, remove spacer from last visible item
		if (hasHiddenItems && visibleMetadata.length > 0) {
			const lastIndex = visibleMetadata.length - 1;
			visibleMetadata[lastIndex] = {
				...visibleMetadata[lastIndex],
				content: filterSpacer(visibleMetadata[lastIndex].content),
			};
		}

		return visibleMetadata;
	};

	const processedVisibleItems = processVisibleItems();

	return (
		<Box xcss={[subheadingLeftAreaStyles, subheadingStyles]} testId="search-page-result-metadata">
			<Inline xcss={[hiddenReferenceInlineStyles]} testId="search-page-result-metadata-hidden">
				{headerMetadataWithIds.map((item, index) => {
					const elementId = `reference:${item.id}`;
					return (
						<Box
							key={elementId}
							id={elementId}
							ref={headerMetadataRefs.current[index]}
							xcss={metadataItemStyles}
						>
							{item.content}
						</Box>
					);
				})}
			</Inline>
			<Inline xcss={[inlineStyles]} testId="search-page-result-metadata-visible">
				{processedVisibleItems.map((item) => (
					<Box key={`visible-${item.id}`} xcss={metadataItemStyles}>
						{item.content}
					</Box>
				))}
				{hasHiddenItems && (
					<MetadataPopupButton
						metadata={headerMetadataWithIds
							.filter((item) => !visibleItems.includes(item.id))
							.map((item) => item.content)}
						isFirstElement={processedVisibleItems.length === 0}
					/>
				)}
			</Inline>
		</Box>
	);
};

export type SearchMetadataProps = {
	searchResultId?: string;
	// TODO: This was previously ProductType
	// We need to align our ProductKeys types with
	// import type { ProductType } from '@atlassian/search-client';
	// For now, lets drop to string and re-cast
	product?: string;
	productSelected?: boolean;
	/** Nullish/empty string parts are filtered out. */
	parts?: Array<SearchMetadataPart>;
	socialParts?: Array<SearchMetadataPart>;
	displaySpacer?: boolean;
	// Using the presence of containerRef to indicate whether to use overflow observer (mainly for search result header metadata)
	containerRef?: React.MutableRefObject<HTMLDivElement | null>;
};

export const SearchMetadata = ({
	searchResultId,
	product,
	productSelected,
	parts: defaultParts = [],
	socialParts: defaultSocialParts = [],
	displaySpacer = true,
	containerRef,
}: SearchMetadataProps) => {
	const products = useProductConfigs();
	// TODO: Shouldn't need to cast here, need to clean up the stack above this to not pass string around as product
	const productDisplayName =
		!product || productSelected ? '' : products[product as ProductKeys]?.displayName || '';
	const parts = defaultParts.filter(filterPart);
	if (defaultSocialParts.filter((item) => item !== null).length > 0) {
		parts.push(['social-metadata', renderSocialMetadata(defaultSocialParts)]);
	}

	const metadataItems: React.ReactNode[] = [];
	if (productDisplayName) {
		metadataItems.push(renderParts(['product-display-name', productDisplayName], true));
	}

	metadataItems.push(...parts.map((m, i) => renderParts(m, displaySpacer && i < parts.length - 1)));

	// If we don't have a containerRef, we don't want to use the overflow observer
	return containerRef ? (
		<SearchMetadataWithOverflowObserver
			metadataItems={metadataItems}
			searchResultId={searchResultId}
			containerRef={containerRef}
		/>
	) : (
		<>{metadataItems}</>
	);
};
