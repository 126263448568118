import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	copyLinkTooltipLabel: {
		id: 'copy-link-tooltip-label',
		defaultMessage: 'Copy link',
		description: 'Tooltip for the button to copy content URL',
	},
	copiedLinkTooltipLabel: {
		id: 'copied-link-tooltip-label',
		defaultMessage: 'Copied!',
		description: 'Tooltip for the button that shows content URL has been copied',
	},
	successIconLabel: {
		id: 'copy-link-success-icon-label',
		defaultMessage: 'Success icon',
		description: 'Label for the success icon used in the button to copy content URL',
	},
	linkIconLabel: {
		id: 'copy-link-link-icon-label',
		defaultMessage: 'Link icon',
		description: 'Label for the success icon used in the button to copy content URL',
	},
	copyLinkButtonLabel: {
		id: 'copy-link-button-label',
		defaultMessage: 'Copy Link Button',
		descsription: 'Label for the button that copies content URL',
	},
});
