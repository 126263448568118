import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ZeplinSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-550.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="white"
			/>
			<path
				d="M0.513685 8C0.513685 3.86542 3.86542 0.513685 8 0.513685H24C28.1346 0.513685 31.4863 3.86542 31.4863 8V24C31.4863 28.1346 28.1346 31.4863 24 31.4863H8C3.86542 31.4863 0.513685 28.1346 0.513685 24V8Z"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.02737"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.69901 19.4429L6.44342 19.886L6.37622 23.4935L10.7222 21.9604C9.2314 21.4767 8.14301 20.6255 7.69901 19.4429Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.35597 15.6271L4 17.164L6.44398 19.8859L7.69917 19.4428C7.25638 18.2636 7.52918 16.931 8.35597 15.6271Z"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.6872 10.4215C10.1396 12.3788 6.56324 16.4178 7.69923 19.443L27.7875 12.3549C26.6515 9.32966 21.2343 8.46421 15.6872 10.4215Z"
				fill="#FECF33"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.7998 21.3764C25.347 19.4187 28.9298 15.3978 27.7878 12.3549L7.69873 19.443C8.84112 22.486 14.2523 23.3337 19.7998 21.3764Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.7878 12.3549L7.69873 19.443C8.09193 20.4908 9.59232 21.007 11.6967 21.007C13.7487 21.007 16.3755 20.5167 19.1138 19.5504C24.661 17.5931 28.5638 14.4238 27.787 12.3549"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.7894 10.7908C21.7374 10.7908 19.1106 11.2811 16.3722 12.2474C10.8251 14.2047 6.92231 17.3736 7.6991 19.4429L27.7874 12.3548C27.3942 11.3069 25.8942 10.7908 23.7894 10.7908Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.6798 23.685C22.0886 23.685 21.217 23.1692 20.0878 22.1523C18.753 20.9492 17.2234 19.187 15.7811 17.1899C14.3391 15.1936 13.1539 13.1973 12.4443 11.5691C11.6715 9.79497 11.5611 8.70428 12.1167 8.3268C12.2387 8.24412 12.3855 8.20239 12.5535 8.20239C13.0055 8.20239 13.8863 8.52732 15.5147 10.0762L15.5507 10.1099L15.5455 10.1582C15.5343 10.2694 15.5243 10.3819 15.5151 10.492L15.4971 10.7106L15.3371 10.5553C13.5823 8.84762 12.8099 8.66332 12.5591 8.66332C12.4875 8.66332 12.4307 8.67723 12.3899 8.70505C12.1487 8.86848 12.1331 9.66979 12.8827 11.3895C13.5799 12.9886 14.7475 14.9544 16.1707 16.9253C17.5946 18.8965 19.1006 20.6328 20.4122 21.8143C21.8262 23.0885 22.4634 23.226 22.6722 23.226C22.7442 23.226 22.8022 23.2114 22.8458 23.1816C23.0998 23.0093 23.1002 22.1508 22.2642 20.2994L22.2258 20.2147L22.3098 20.1703C22.3938 20.1259 22.4754 20.0822 22.5514 20.0405L22.6522 19.9852L22.6986 20.0876C23.291 21.3993 23.845 23.0665 23.1186 23.5599C22.9962 23.6429 22.8486 23.685 22.6798 23.685Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.9342 21.3212L19.9262 21.4216C19.7858 23.098 19.4162 25.1129 18.4534 25.1948C18.4342 25.1964 18.4142 25.1971 18.3942 25.1971C17.7398 25.1971 17.121 24.2919 16.555 22.5069C16.019 20.8177 15.603 18.5506 15.3842 16.1215C15.1651 13.6936 15.1683 11.3901 15.3934 9.63644C15.6386 7.72511 16.1058 6.72714 16.7818 6.66957C16.8018 6.66803 16.8226 6.66687 16.843 6.66687C17.3174 6.66687 18.0326 7.18614 18.7646 9.60321L18.2866 9.69208C18.0422 8.88458 17.7758 8.22777 17.5114 7.77573C17.2666 7.35846 17.029 7.12857 16.8418 7.12857C16.8354 7.12857 16.8294 7.12857 16.823 7.12934C16.5302 7.15407 16.1034 7.84063 15.8658 9.69324C15.6446 11.4156 15.6418 13.6843 15.8578 16.0813C16.0746 18.4787 16.4834 20.7126 17.0098 22.3713C17.2454 23.1139 17.5038 23.7263 17.7574 24.1416C17.991 24.5249 18.217 24.7358 18.3938 24.7358C18.3998 24.7358 18.4058 24.7358 18.4122 24.735C18.6922 24.7115 19.2206 23.9971 19.443 21.4838L19.9342 21.3212Z"
				fill="#EE6723"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.62389 23.9703L8.0544 24.5242L7.9704 29.0335L13.4029 27.1172C11.5394 26.5125 10.1789 25.4486 9.62389 23.9703Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.445 19.2006L5 21.1217L8.05498 24.5241L9.62397 23.9702C9.07047 22.4962 9.41147 20.8305 10.445 19.2006Z"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.6089 12.6933C12.6745 15.1399 8.20398 20.1887 9.62397 23.9702L34.7343 15.11C33.3143 11.3285 26.5429 10.2467 19.6089 12.6933Z"
				fill="#FECF33"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.7499 26.387C31.6838 23.9398 36.1623 18.9138 34.7348 15.11L9.62347 23.9703C11.0515 27.774 17.8154 28.8336 24.7499 26.387Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.7348 15.11L9.62347 23.9703C10.115 25.28 11.9905 25.9253 14.6209 25.9253C17.1859 25.9253 20.4694 25.3124 23.8924 24.1045C30.8263 21.6579 35.7048 17.6962 34.7338 15.11"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.7368 13.155C27.1718 13.155 23.8883 13.7679 20.4654 14.9758C13.5314 17.4224 8.65295 21.3836 9.62394 23.9702L34.7343 15.11C34.2428 13.8003 32.3678 13.155 29.7368 13.155Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.3499 29.2725C27.6109 29.2725 26.5214 28.6278 25.1099 27.3567C23.4414 25.8528 21.5294 23.65 19.7264 21.1537C17.924 18.6583 16.4425 16.1628 15.5555 14.1277C14.5895 11.91 14.4515 10.5466 15.146 10.0748C15.2985 9.97141 15.482 9.91925 15.692 9.91925C16.257 9.91925 17.358 10.3254 19.3934 12.2616L19.4384 12.3036L19.4319 12.3639C19.4179 12.503 19.4054 12.6436 19.3939 12.7812L19.3714 13.0546L19.1714 12.8604C16.978 10.7258 16.0125 10.4954 15.699 10.4954C15.6095 10.4954 15.5385 10.5128 15.4875 10.5476C15.186 10.7519 15.1665 11.7535 16.1035 13.9031C16.975 15.902 18.4345 18.3593 20.2134 20.8228C21.9934 23.2869 23.8759 25.4572 25.5154 26.9341C27.2829 28.5269 28.0794 28.6988 28.3404 28.6988C28.4304 28.6988 28.5029 28.6805 28.5574 28.6433C28.8749 28.4279 28.8754 27.3548 27.8304 25.0404L27.7824 24.9347L27.8874 24.8791C27.9924 24.8236 28.0944 24.769 28.1894 24.7169L28.3154 24.6478L28.3734 24.7758C29.1139 26.4154 29.8064 28.4993 28.8984 29.1161C28.7454 29.2199 28.5609 29.2725 28.3499 29.2725Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.9179 26.3179L24.9079 26.4434C24.7324 28.5389 24.2704 31.0575 23.0669 31.1599C23.0429 31.1619 23.0179 31.1628 22.9929 31.1628C22.1749 31.1628 21.4014 30.0313 20.6939 27.8C20.0239 25.6886 19.5039 22.8546 19.2304 19.8183C18.9564 16.7834 18.9604 13.9041 19.2419 11.712C19.5484 9.3228 20.1324 8.07534 20.9774 8.00338C21.0024 8.00145 21.0284 8 21.0539 8C21.6469 8 22.5409 8.64909 23.4559 11.6704L22.8584 11.7815C22.5529 10.7721 22.2199 9.95112 21.8894 9.38607C21.5834 8.86448 21.2864 8.57713 21.0524 8.57713C21.0444 8.57713 21.0369 8.57713 21.0289 8.57809C20.6629 8.609 20.1294 9.46721 19.8324 11.783C19.5559 13.936 19.5524 16.7718 19.8224 19.7681C20.0934 22.7648 20.6044 25.5572 21.2624 27.6305C21.5569 28.5587 21.8799 29.3242 22.1969 29.8434C22.4889 30.3225 22.7714 30.5862 22.9924 30.5862C22.9999 30.5862 23.0074 30.5862 23.0154 30.5852C23.3654 30.5557 24.0259 29.6628 24.3039 26.5212L24.9179 26.3179Z"
				fill="#EE6723"
			/>
		</svg>
	);
};
