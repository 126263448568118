import React, { type ReactElement } from 'react';

import { Box } from '@atlaskit/primitives';

const HIGHLIGHT_START_TAG = '@@@hl@@@';
const HIGHLIGHT_END_TAG = '@@@endhl@@@';

const highlightRE = new RegExp(`${HIGHLIGHT_START_TAG}(.*?)${HIGHLIGHT_END_TAG}.*`);
const openingTagRE = new RegExp(`(.*?)${HIGHLIGHT_START_TAG}`);

export const enrichResultTitle = (title: string) => {
	const enrichedTitle: ReactElement[] = [];
	// counter added to maintain uniqueness of element key
	// during subsequent rerenders due to query changes
	let counter = 0;

	if (!title.match(highlightRE)) {
		// if there are no matches then return the title as is
		return (
			<Box as="span" key={title + counter}>
				{title}
			</Box>
		);
	}

	while (title) {
		const [preMatch, preText] = title.match(openingTagRE) || [];
		title = title.replace(preText, '');

		if (preText && preText !== '') {
			enrichedTitle.push(
				<Box as="span" key={preText + counter}>
					{preText}
				</Box>,
			);
		}

		const [boldMatch, highlightText] = title.match(highlightRE) || [];
		title = title.replace(`${HIGHLIGHT_START_TAG}${highlightText}${HIGHLIGHT_END_TAG}`, '');

		if (highlightText && highlightText !== '') {
			enrichedTitle.push(
				<Box as="strong" key={highlightText + counter}>
					{highlightText}
				</Box>,
			);
		}

		if (preMatch === undefined && boldMatch === undefined) {
			if (title !== '') {
				enrichedTitle.push(
					<Box as="span" key={title + counter}>
						{title}
					</Box>,
				);
			}
			break;
		}
		counter++;
	}

	return enrichedTitle;
};
