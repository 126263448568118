import React, { useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import {
	type ProductKeys,
	ProductKeys as ProductKeysType,
	useProductConfigs,
} from '../../constants/products';
import { type MessageContent, OAuthMessage } from '../oauth-message';

import { messages } from './messages';
import { increaseProductDismissCount } from './utils';

const oauthBannerContainerStyles = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

interface OAuthHandlersProps {
	product: ProductKeys;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	isOnSearchDialog?: boolean;
}
interface OauthHandlerFunctions {
	onAuthorize: () => void;
	onDismiss: () => void;
}

type useOAuthHandlers = (props: OAuthHandlersProps) => OauthHandlerFunctions;

type OAuthBannerProps = {
	product: ProductKeys;
	outboundAuthUrl: string;
	isSmartlink: boolean;
	isOnSearchDialog?: boolean;
	useOAuthHandlers: useOAuthHandlers;
};

export const OAuthBanner = ({
	product,
	outboundAuthUrl,
	isOnSearchDialog,
	isSmartlink,
	useOAuthHandlers,
}: OAuthBannerProps) => {
	const { formatMessage } = useIntl();
	const products = useProductConfigs();
	const [isDismissed, setIsDismissed] = useState(false);
	const productString =
		product === 'sharepoint' ? 'SharePoint' : products[product]?.displayName || '';
	const Icon = products[product].Icon;
	const showAlternateConnectMessage = product === ProductKeysType.Figma || isSmartlink;

	const { onAuthorize, onDismiss } = useOAuthHandlers({
		product,
		successFlagTitle: formatMessage(messages.authorizationSuccess, {
			product: productString,
		}),
		failFlagTitle: formatMessage(messages.authorizationFail, {
			product: productString,
		}),
		outboundAuthUrl,
		onCompletedAction: () => {
			setIsDismissed(true);
		},
		onDismissAction: () => {
			setIsDismissed(true);
			increaseProductDismissCount(product);
		},
		isOnSearchDialog,
	});

	const title = (
		<FormattedMessage
			{...(showAlternateConnectMessage
				? messages.connectThirdPartyProductTitleAlternate
				: messages.connectThirdPartyProductTitle)}
			values={{ product: productString }}
		/>
	);

	const description = <FormattedMessage {...messages.connectThirdPartyProductDescription} />;

	if (isDismissed) {
		return null;
	}

	const messageContent: MessageContent = {
		title,
		description,
		connectText: <FormattedMessage {...messages.connect} />,
		MessageIcon: Icon,
	};

	const messageProps = {
		content: messageContent,
		onAuthorize,
		onDismiss,
	};

	return isOnSearchDialog ? (
		<Box xcss={oauthBannerContainerStyles}>
			<OAuthMessage {...messageProps} isOnSearchDialog />
		</Box>
	) : (
		<OAuthMessage {...messageProps} />
	);
};
