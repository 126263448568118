export const JiraStatusCategoryColor = {
	MediumGray: 'MEDIUM_GRAY',
	Green: 'GREEN',
	Yellow: 'YELLOW',
	Brown: 'BROWN',
	Red: 'WARM_RED',
	BlueGray: 'BLUE_GRAY',
} as const;

export type JiraStatusCategoryColorType =
	(typeof JiraStatusCategoryColor)[keyof typeof JiraStatusCategoryColor];

export type HydratedJiraIssue = {
	id: string;
	statusField: {
		status: {
			name?: string;
			statusCategory?: {
				colorName?: JiraStatusCategoryColorType;
			};
		};
	};
};

export type JiraHydrationData = {
	jira: {
		issuesByKey: HydratedJiraIssue[];
	};
};
