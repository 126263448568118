import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import type {
	CreateSelectFilter,
	OptionsToProducts,
	SelectFilterDepParams,
	SelectFilterDeps,
	SelectFilterOption,
	SelectFilterOptionChange,
	SelectFilterState,
	SelectFilterType,
	SelectFilterViewState,
} from '../../../common/constants/filters/select-filter/types';
import { TypeFilterValueKey } from '../../../common/constants/filters/universal-type';
import { ProductKeys } from '../../../common/constants/products';
import { useSelectFilterStore } from '../../store/search';
import { typeLabels } from '../../store/search/filters/universal-type/messages';

export const useSelectFilter = ({
	filterId,
	optionValuesToExclude,
}: {
	filterId: string;
	optionValuesToExclude?: string[];
}) => {
	const [state, storeActions] = useSelectFilterStore({
		filterId,
	});

	const viewActions = {
		setOption: (
			option: SelectFilterOption | SelectFilterOption[],
			customValue?: Record<string, string>,
		) => {
			const options = Array.isArray(option) ? option : [option];
			const optionChange = options.map(
				(option) =>
					({
						value: option.value,
						isSelected: !option.isSelected,
					}) satisfies SelectFilterOptionChange,
			);

			return storeActions.setSelectFilterOption({ filterId, optionChange, customValue });
		},
		loadInitialOptions: () => storeActions.loadInitialSelectFilterOptions({ filterId }),
		setLookupInput: (query: string) => storeActions.setSelectFilterLookupInput({ filterId, query }),
		clearLookupInput: () => storeActions.setSelectFilterLookupInput({ filterId, query: '' }),
		clearOptions: () => storeActions.clearSelectFilterOptions({ filterId }),
	};

	if (!state) {
		return [, viewActions] satisfies [SelectFilterViewState | undefined, typeof viewActions];
	}

	const filteredOptions = state.options.filter((option) => {
		if (optionValuesToExclude) {
			return !optionValuesToExclude.includes(option.value);
		}
		return true;
	});

	const selectedOptionsCount = state.selectedOptions.length;

	const viewState = {
		defaultIsOpen: state.alwaysVisible === false,
		isLookupDefined: state.lookup.isDefined,
		isLoading:
			state.queryParams.isLoading || state.initialOptions.isLoading || state.lookup.isLoading,
		lookupInput: state.lookup.input,
		options: filteredOptions,
		suggestedOptions: filteredOptions.filter((option) => !option.isSelected && option.isSuggested),
		selectedOptions: state.selectedOptions,
		isLookupActive: state.lookup.isActive,
		alwaysVisible: state.alwaysVisible,
		customValue: state.customValue,
		badgeLabel: selectedOptionsCount > 1 ? `+${selectedOptionsCount - 1}` : undefined,
		firstSelectedOptionLabel: state.selectedOptions.at(0)?.label,
	} satisfies SelectFilterViewState;

	return [viewState, viewActions] satisfies [SelectFilterViewState, typeof viewActions];
};

// This is a select filter which has access to the selected product state
export const useUniversalSelectFilter = ({
	filterId,
	optionsToProducts,
}: {
	filterId: string;
	optionsToProducts: OptionsToProducts;
}) => {
	const { formatMessage } = useIntl();
	const [state, storeActions] = useSelectFilterStore({
		filterId,
	});

	const viewActions = {
		setOption: (option: SelectFilterOption | SelectFilterOption[]) => {
			const options = Array.isArray(option) ? option : [option];
			const optionChange = options.map(
				(option) =>
					({
						value: option.value,
						isSelected: !option.isSelected,
					}) satisfies SelectFilterOptionChange,
			);

			return storeActions.setSelectFilterOption({ filterId, optionChange });
		},
		loadInitialOptions: () => storeActions.loadInitialSelectFilterOptions({ filterId }),
		setLookupInput: (query: string) => storeActions.setSelectFilterLookupInput({ filterId, query }),
		clearLookupInput: () => storeActions.setSelectFilterLookupInput({ filterId, query: '' }),
		clearOptions: () => storeActions.clearSelectFilterOptions({ filterId }),
	};

	if (!state) {
		return [, viewActions] satisfies [SelectFilterViewState | undefined, typeof viewActions];
	}

	const selectedProductsAvailable = state.selectedProducts.length > 0;
	const products = selectedProductsAvailable ? state.selectedProducts : state.availableProducts;

	const filteredOptions = state.options
		.filter(
			(option) =>
				option.isDefault ||
				option.isSelected ||
				optionsToProducts[option.value]?.some((product) => products.includes(product)),
		)
		// This is a temporary hack to filter out Atlas specific types, unless Atlas is the selected product
		.filter(
			(option) =>
				!(
					state.selectedProducts[0] !== ProductKeys.Atlas &&
					(option.value === 'goal' || option.value === 'tag')
				),
		)
		// Filtering out all options that are tied exclusively to allFilterExcludedProducts for universal types
		.filter((option) =>
			!selectedProductsAvailable && optionsToProducts[option.value]?.length
				? optionsToProducts[option.value].some(
						(product) => !state.allFilterExcludedProducts.includes(product),
					)
				: true,
		);

	const selectedOptions = state.selectedOptions.map((option) => ({ ...option }));

	if (
		products.length === 1 &&
		products[0] === ProductKeys.ConfluenceDC &&
		fg('confluence_live_pages_open_beta_trait_opted_in')
	) {
		const isPageOption = (option: SelectFilterOption) => option.value === TypeFilterValueKey.Page;

		// Update both filtered and selected options that match Page type
		[filteredOptions, selectedOptions]
			.flatMap((options) => options.filter(isPageOption))
			.forEach((option) => {
				option.label = formatMessage(typeLabels.page);
			});
	}

	const viewState = {
		defaultIsOpen: state.alwaysVisible === false,
		isLookupDefined: state.lookup.isDefined,
		isLoading:
			state.queryParams.isLoading || state.initialOptions.isLoading || state.lookup.isLoading,
		lookupInput: state.lookup.input,
		options: filteredOptions,
		suggestedOptions: filteredOptions.filter((option) => !option.isSelected && option.isSuggested),
		selectedOptions,
		isLookupActive: state.lookup.isActive,
		alwaysVisible: state.alwaysVisible,
	} satisfies SelectFilterViewState;

	return [viewState, viewActions] satisfies [SelectFilterViewState, typeof viewActions];
};

export const SelectFilter: SelectFilterType = ({ initial, state, config }) => {
	const filterState = Object.freeze({
		...state,
	});

	return {
		id: initial.id,
		type: filterState.type,
		getState: getState({ initial, state: filterState, config }),
		queryParams: {
			key: initial.queryParams.key,
			get: getQueryParams({ initial, state: filterState, config }),
			set: setQueryParams({ initial, state: filterState, config }),
		},
		loadInitialOptions: loadInitialOptions({ initial, state: filterState, config }),
		setOption: setOption({ initial, state: filterState, config }),
		clearOptions: clearOptions({ initial, state: filterState, config }),
		setLookupInput: setLookupInput({ initial, state: filterState, config }),
		getOptions: getOptions({ initial, state: filterState, config }),
		getValue: getValue({ initial, state: filterState, config }),
		clear: deselectOptions({ initial, state: filterState, config }),
	};
};

export const getState = <ID extends string>({ initial, state, config }: SelectFilterDeps<ID>) => {
	return () => {
		const filter = SelectFilter({ initial, state, config });
		const allOptions = filter.getOptions({ isLookupActive: false });
		const newOptions = filter.getOptions({
			isLookupActive: state.lookup.isActive,
		});
		const selectedOptions = allOptions.filter((option) => option.isSelected);

		const newState = {
			...state,
			options: newOptions,
			selectedOptions,
			hasSelection: selectedOptions.length > 0,
			value: filter.getValue(),
		} satisfies SelectFilterState<ID>;
		return newState;
	};
};

// TODO: >>>> Write more tests here on edge cases
export const setQueryParams = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return async (
		queryParams: Record<string, string[] | undefined>,
		onStateChange?: (state: SelectFilterState<string>) => void,
	) => {
		const queryParamValues = queryParams[initial.queryParams.key];
		const filter = SelectFilter({ initial, state, config });

		if (!queryParamValues) {
			const clearedOptions = filter.clearOptions();
			onStateChange?.(clearedOptions.getState());
			return clearedOptions;
		}

		const allCurrentOptions = filter.getOptions({ isLookupActive: false });
		const allQueryParamValues = allCurrentOptions.map((option) => option.queryParamValue);
		const queryParamsToHydrate = queryParamValues.filter(
			(value) => !allQueryParamValues.includes(value),
		);

		if (queryParamsToHydrate.length === 0) {
			const newOptions = allCurrentOptions.map(
				(option) =>
					({
						isSelected: queryParamValues.includes(option.queryParamValue),
						value: option.value,
					}) satisfies SelectFilterOptionChange,
			);

			const filterWithNewOptions = filter.setOption(newOptions);
			onStateChange?.(filterWithNewOptions.getState());
			return filterWithNewOptions;
		}

		// At this point we need to hydrate the filters with new options from
		// the query params
		const newQueryParamOptions = [...state.queryParams.options];
		const fetcher = initial.queryParams.fetcher;
		if (fetcher) {
			onStateChange?.({
				...state,
				queryParams: {
					...state.queryParams,
					isLoading: true,
				},
			});

			const fetchedOptions = await fetcher(queryParamsToHydrate, config);
			fetchedOptions.forEach((option) => {
				const mappedOption = {
					isSelected: true,
					trackingKey: option.trackingKey,
					value: option.value,
					queryParamValue: option.queryParamValue,
					isSuggested: false,
					isDefault: option.isDefault ?? false,
					avatarUrl: option.avatarUrl,
					label: option.label,
				} satisfies SelectFilterOption;
				newQueryParamOptions.push(mappedOption);
			});
		} else {
			// The edge case here is where we have an unsupported query param
			// and we don't have a fetcher to hydrate the unsupported query param
			// TODO: >>>> Not sure what to do here
		}

		const selectedOptionsFromQueryParams = newQueryParamOptions.map((option) => ({
			...option,
			isSelected: queryParamValues.includes(option.queryParamValue),
		}));

		const selectedInitialOptionsFromQueryParams = [...state.initialOptions.options].map(
			(option) => ({
				...option,
				isSelected: queryParamValues.includes(option.queryParamValue),
			}),
		);

		const hydratedQueryParams = SelectFilter({
			initial,
			state: {
				...state,
				queryParams: {
					...state.queryParams,
					options: selectedOptionsFromQueryParams,
					isLoading: false,
				},
				initialOptions: {
					...state.initialOptions,
					options: selectedInitialOptionsFromQueryParams,
				},
			},
			config,
		});

		onStateChange?.(hydratedQueryParams.getState());
		return hydratedQueryParams;
	};
};

export const getQueryParams = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return () => {
		const queryParamValue = SelectFilter({ initial, state, config })
			.getOptions({ isLookupActive: false })
			.filter((option) => option.isSelected)
			.map((option) => option.queryParamValue);

		return { [initial.queryParams.key]: queryParamValue };
	};
};

export const loadInitialOptions = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return async (onStateChange?: (state: SelectFilterState<ID>) => void) => {
		if (state.didMount) {
			const newDropdownState = {
				...state,
			} satisfies SelectFilterState<ID>;

			onStateChange?.(newDropdownState);

			return SelectFilter({
				initial,
				state: newDropdownState,
				config,
			});
		}

		const newOptions = [...state.initialOptions.options];
		const fetcher = initial.initialOptions?.fetcher;

		if (fetcher) {
			const fetchingInitialOptionsState = {
				...state,
				initialOptions: {
					...state.initialOptions,
					isLoading: true,
				},
			} satisfies SelectFilterState<ID>;

			onStateChange?.(fetchingInitialOptionsState);

			try {
				const fetchedOptions = await fetcher(config);
				fetchedOptions.forEach((option) => {
					const mappedOption = {
						isSelected: false,
						trackingKey: option.trackingKey,
						value: option.value,
						queryParamValue: option.queryParamValue,
						isSuggested: true,
						isDefault: option.isDefault ?? false,
						avatarUrl: option.avatarUrl,
						label: option.label,
					} satisfies SelectFilterOption;
					newOptions.push(mappedOption);
				});
			} catch {
				const fetchFailedState = {
					...state,
					initialOptions: {
						...state.initialOptions,
						isLoading: false,
					},
				} satisfies SelectFilterState<ID>;
				onStateChange?.(fetchFailedState);
			}
		}

		const newInitialOptionsState = {
			...state,
			didMount: true,
			initialOptions: {
				...state.initialOptions,
				options: newOptions,
				isLoading: false,
			},
		} satisfies SelectFilterState<ID>;

		onStateChange?.(newInitialOptionsState);

		return SelectFilter({
			initial,
			state: newInitialOptionsState,
			config,
		});
	};
};

// TODO: >>>> Write more tests here on edge cases
export const getOptions = <ID extends string>({ initial, state }: SelectFilterDeps<ID>) => {
	return (options: { isLookupActive: boolean }) => {
		const allSelectedValues = new Set([
			...state.queryParams.options
				.filter((option) => option.isSelected)
				.map((option) => option.value),
			...state.initialOptions.options
				.filter((option) => option.isSelected)
				.map((option) => option.value),
			...state.lookup.options
				.filter((lookupOption) => lookupOption.isSelected)
				.map((option) => option.value),
		]);

		if (options.isLookupActive) {
			const lookupResults = state.lookup.lookupResultOptions.map(
				(option) =>
					({
						...option,
						isSelected: allSelectedValues.has(option.value),
					}) satisfies SelectFilterOption,
			);

			return lookupResults;
		}

		const duplicateOptions = new Set();

		const newOptions = [
			...state.queryParams.options, // Query Params first
			// 'interacted' lookup options are options that a user made a lookup for and has either
			// selected or deselected an option from a lookup query
			// The 'interacted' options are persisted in  state.lookup.options
			...state.lookup.options,
			...state.initialOptions.options,
		]
			.filter(
				(option) => !duplicateOptions.has(option.value) && duplicateOptions.add(option.value), // remove duplicates
			)
			.map((option) => ({
				...option,
				isSelected: allSelectedValues.has(option.value),
			}));

		if (initial.sortCompareFn) {
			return newOptions.sort(initial.sortCompareFn);
		}

		return newOptions;
	};
};

export const setOption = <ID extends string>({ initial, state, config }: SelectFilterDeps<ID>) => {
	return (option: SelectFilterOptionChange | SelectFilterOptionChange[]) => {
		const optionsToChange = Array.isArray(option) ? option : [option];
		const optionsToChangeRecord = Object.fromEntries(
			optionsToChange.map((option) => [option.value, option]),
		) as Record<string, SelectFilterOptionChange | undefined>;

		const queryParamOptions = state.queryParams.options.map((option) => {
			if (!optionsToChange.find((x) => x.value === option.value)) {
				return option;
			}
			return {
				...option,
				isSelected: optionsToChangeRecord[option.value]?.isSelected ?? false,
			};
		});

		const initialOptions = state.initialOptions.options.map((option) => {
			if (!optionsToChange.find((x) => x.value === option.value)) {
				return option;
			}

			return {
				...option,
				isSelected: optionsToChangeRecord[option.value]?.isSelected ?? false,
			};
		});

		const duplicateOptions = new Set();

		const lookupOptions = [
			...state.lookup.options,
			// 'interacted' lookup options are options that a user made a lookup for and has either
			// selected or deselected an option from a lookup query
			// The 'interacted' options are persisted in  state.lookup.options
			...state.lookup.lookupResultOptions.flatMap((option) => {
				if (!optionsToChange.find((x) => x.value === option.value)) {
					return [];
				}

				return [option];
			}),
		]
			.filter(
				(option) => !duplicateOptions.has(option.value) && duplicateOptions.add(option.value), // remove duplicates
			)
			.map((option) => ({
				...option,
				isSuggested: true,
				isSelected: optionsToChangeRecord[option.value]?.isSelected ?? option.isSelected,
			}));

		return SelectFilter({
			initial: initial satisfies CreateSelectFilter<ID>,
			state: {
				...state,
				initialOptions: {
					...state.initialOptions,
					options: initialOptions,
				},
				queryParams: {
					...state.queryParams,
					options: queryParamOptions,
				},
				lookup: {
					...state.lookup,
					options: lookupOptions,
				},
			},
			config,
		});
	};
};

export const clearOptions = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return () => {
		const queryParamOptions = state.queryParams.options.map((option) => ({
			...option,
			isSelected: false,
		}));

		const initialOptions = state.initialOptions.options.map((option) => ({
			...option,
			isSelected: false,
		}));

		const lookupOptions = state.lookup.options.map((option) => ({
			...option,
			isSelected: false,
		}));

		return SelectFilter({
			initial,
			state: {
				...state,
				initialOptions: {
					...state.initialOptions,
					options: initialOptions,
				},
				queryParams: {
					...state.queryParams,
					options: queryParamOptions,
				},
				lookup: {
					...state.lookup,
					options: lookupOptions,
				},
			},
			config,
		});
	};
};

export const setLookupInput = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return async (query: string, onStateChange?: (state: SelectFilterState<ID>) => void) => {
		if (!initial.lookup || query.length === 0 || state.lookup.input === query) {
			const noQueryState = {
				...state,
				lookup: {
					...state.lookup,
					input: query,
					isLoading: false,
					isActive: false,
				},
			} satisfies SelectFilterState<ID>;
			onStateChange?.(noQueryState);

			return SelectFilter({
				initial,
				state: noQueryState,
				config,
			});
		}

		const fetchingLookupOptionsState = {
			...state,
			lookup: {
				...state.lookup,
				input: query,
				isLoading: true,
				isActive: true,
			},
		} satisfies SelectFilterState<ID>;

		onStateChange?.(fetchingLookupOptionsState);

		try {
			const fetchedLookupOptions = await initial.lookup.fetcher(query, config);
			const newLookupOptions = fetchedLookupOptions.map((option) => {
				const mappedOption = {
					isSelected: false,
					trackingKey: option.trackingKey,
					value: option.value,
					queryParamValue: option.queryParamValue,
					isSuggested: false,
					isDefault: option.isDefault ?? false,
					avatarUrl: option.avatarUrl,
					label: option.label,
				} satisfies SelectFilterOption;
				return mappedOption;
			});

			const newLookupOptionsState = {
				...state,
				lookup: {
					...state.lookup,
					input: query,
					lookupResultOptions: newLookupOptions,
					isLoading: false,
					isActive: true,
				},
			} satisfies SelectFilterState<ID>;

			onStateChange?.(newLookupOptionsState);

			return SelectFilter({
				initial,
				state: newLookupOptionsState,
				config,
			});
		} catch {
			const failedLookupOptionsState = {
				...state,
				lookup: {
					...state.lookup,
					input: query,
					isLoading: false,
					isActive: true,
				},
			} satisfies SelectFilterState<ID>;

			onStateChange?.(failedLookupOptionsState);

			return SelectFilter({
				initial,
				state: failedLookupOptionsState,
				config,
			});
		}
	};
};

export const getValue = ({ initial, state, config }: SelectFilterDepParams) => {
	return () => {
		return SelectFilter({ initial, state, config })
			.getOptions({ isLookupActive: false })
			.filter((option) => option.isSelected)
			.map((option) => option.value);
	};
};

export const deselectOptions = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return () => {
		const filter = SelectFilter({ initial, state, config });

		const allOptions = [
			...filter.getOptions({
				isLookupActive: true,
			}),
			...filter.getOptions({
				isLookupActive: false,
			}),
		].map(
			(option) =>
				({
					value: option.value,
					isSelected: false,
				}) satisfies SelectFilterOptionChange,
		);

		return filter.setOption(allOptions);
	};
};
