import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GoogleCalendarIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-124.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<g clipPath="url(#clip0_719_13847)">
				<path
					d="M15.8208 8.17936L12.3822 7.7973L8.17949 8.17936L7.79736 12.0001L8.17942 15.8207L12.0001 16.2983L15.8208 15.8207L16.2029 11.9046L15.8208 8.17936Z"
					fill="white"
				/>
				<path
					d="M9.74654 14.1071C9.46096 13.9142 9.26322 13.6324 9.15527 13.2599L9.81819 12.9867C9.87837 13.216 9.98341 13.3936 10.1334 13.5197C10.2824 13.6458 10.4639 13.7079 10.6759 13.7079C10.8928 13.7079 11.079 13.642 11.2347 13.5101C11.3903 13.3783 11.4687 13.2102 11.4687 13.0068C11.4687 12.7986 11.3866 12.6285 11.2223 12.4967C11.058 12.365 10.8517 12.299 10.6052 12.299H10.2222V11.6428H10.566C10.7781 11.6428 10.9567 11.5856 11.1019 11.4709C11.2471 11.3563 11.3197 11.1997 11.3197 11C11.3197 10.8224 11.2547 10.681 11.1249 10.575C10.995 10.469 10.8306 10.4155 10.631 10.4155C10.4362 10.4155 10.2814 10.4671 10.1668 10.5712C10.0522 10.6753 9.96904 10.8033 9.91655 10.9542L9.26039 10.681C9.34728 10.4346 9.50684 10.2168 9.74081 10.0287C9.97484 9.8405 10.2738 9.74591 10.6367 9.74591C10.9051 9.74591 11.1468 9.79752 11.3608 9.90162C11.5747 10.0057 11.7428 10.15 11.8641 10.3333C11.9854 10.5176 12.0456 10.724 12.0456 10.9532C12.0456 11.1872 11.9893 11.3849 11.8766 11.5473C11.7638 11.7097 11.6253 11.8338 11.461 11.9208V11.9599C11.6779 12.0507 11.8546 12.1892 11.994 12.3754C12.1325 12.5617 12.2022 12.7843 12.2022 13.0441C12.2022 13.3039 12.1363 13.536 12.0045 13.7394C11.8726 13.9429 11.6902 14.1034 11.4591 14.2199C11.227 14.3364 10.9662 14.3956 10.6768 14.3956C10.3416 14.3966 10.0321 14.3001 9.74654 14.1071Z"
					fill="#1A73E8"
				/>
				<path
					d="M13.8145 10.8175L13.0905 11.3438L12.7266 10.7917L14.0323 9.84991H14.5328V14.2924H13.8145V10.8175Z"
					fill="#1A73E8"
				/>
				<path
					d="M15.8208 19.2592L19.2593 15.8206L17.54 15.0565L15.8208 15.8206L15.0566 17.5399L15.8208 19.2592Z"
					fill="#EA4335"
				/>
				<path
					d="M7.41504 17.54L8.17915 19.2593H15.8205V15.8207H8.17915L7.41504 17.54Z"
					fill="#34A853"
				/>
				<path
					d="M5.88689 4.74078C5.25366 4.74078 4.74072 5.25372 4.74072 5.88695V15.8207L6.46001 16.5848L8.17929 15.8207V8.17935H15.8206L16.5847 6.46007L15.8207 4.74078H5.88689Z"
					fill="#4285F4"
				/>
				<path
					d="M4.74072 15.8207V18.1131C4.74072 18.7464 5.25366 19.2593 5.88689 19.2593H8.17929V15.8207H4.74072Z"
					fill="#188038"
				/>
				<path
					d="M15.8203 8.17945V15.8208H19.2589V8.17945L17.5396 7.41534L15.8203 8.17945Z"
					fill="#FBBC04"
				/>
				<path
					d="M19.2589 8.17929V5.88689C19.2589 5.25359 18.7459 4.74072 18.1127 4.74072H15.8203V8.17929H19.2589Z"
					fill="#1967D2"
				/>
			</g>
			<defs>
				<clipPath id="clip0_719_13847">
					<rect width="14" height="14" fill="white" transform="translate(5 5)" />
				</clipPath>
			</defs>
		</svg>
	) : (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			aria-label="Google Calendar"
			viewBox="50 50 410 410 "
		>
			<path fill="#4285f4" d="M100 340h74V174h166v-74H137q-37 0-37 35" />
			<path fill="#1967d2" d="M338 100v76h74v-41q0-35-35-35" />
			<path fill="#fbbc04" d="M338 174h74v164h-74" />
			<path fill="#188038" d="M100 338v39q0 35 35 35h41v-74" />
			<path fill="#34a853" d="M174 338h164v74H174" />
			<path fill="#ea4335" d="M338 412v-74h74" />
			<path
				fill="none"
				stroke="#4285f4"
				strokeLinejoin="bevel"
				strokeWidth={15}
				d="M204 229a25 22 1 1 1 25 27h-9 9a25 22 1 1 1-25 27m66-52 27-19h4v-7 103"
			/>
		</svg>
	);
};
export default GoogleCalendarIcon;
