import { type ProductKeys } from '../../constants/products';

const OAUTH_BANNER_DISMISS_KEY = 'atlassian.unified-search.oauth-banner-dismiss-counts';

export type DismissCounts = Partial<Record<ProductKeys, number>>;

/**
 * The logic was defined in this [thread](https://atlassian.slack.com/archives/C06718QD2Q5/p1727378295868049?thread_ts=1726639342.227899)
 * @returns whether the OAuth banner for the product should be shown
 */
export const shouldShowOAuthBanner = (product: ProductKeys, isHello: boolean) => {
	const dismissCounts = getLocalStorageData();
	const hasUserDismissedThisProductBefore = (dismissCounts[product] ?? 0) > 0;
	if (isHello) {
		return !(hasUserDismissedThisProductBefore || hasUserDismissedEnough(dismissCounts));
	}
	return !hasUserDismissedThisProductBefore;
};

/**
 * The max number of times the user dismisses **across products**
 */
const MAX_DISMISSALS = 4;
/**
 * @returns whether the user has dismissed enough times across all products
 * so that we stop showing the OAuth banner for all products
 */
export const hasUserDismissedEnough = (dismissCounts: DismissCounts) => {
	const totalDismissalsAcrossProducts = Object.values(dismissCounts).reduce(
		(prev, curr) => prev + curr,
		0,
	);
	return totalDismissalsAcrossProducts >= MAX_DISMISSALS;
};

/**
 * Increases the value of the product's dismiss field by 1
 */
export const increaseProductDismissCount = (product: ProductKeys) => {
	const dismissCounts = getLocalStorageData();
	dismissCounts[product] = (dismissCounts[product] ?? 0) + 1;
	saveToLocalStorage(dismissCounts);
};

/**
 * @returns the local storage object that stores products and their dismiss counts
 */
const getLocalStorageData = (): DismissCounts => {
	const serializedDismissCounts = localStorage.getItem(OAUTH_BANNER_DISMISS_KEY);
	if (!serializedDismissCounts) {
		return {};
	}
	let localStorageObject;
	try {
		localStorageObject = JSON.parse(serializedDismissCounts);
	} catch (_) {
		return {};
	}
	return localStorageObject as DismissCounts;
};

/**
 * Saves the local storage object that stores products and their dismiss counts
 */
const saveToLocalStorage = (localStorageObject: any) => {
	localStorage.setItem(OAUTH_BANNER_DISMISS_KEY, JSON.stringify(localStorageObject));
};
