import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AsanaSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-263.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#F06A6A" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.2735 16.5076C19.0314 16.5076 17.2139 18.3252 17.2139 20.5674C17.2139 22.8095 19.0314 24.6271 21.2735 24.6271C23.5156 24.6271 25.3332 22.8095 25.3332 20.5674C25.3332 18.3252 23.5156 16.5076 21.2735 16.5076ZM10.7262 16.5079C8.4841 16.5079 6.6665 18.3252 6.6665 20.5674C6.6665 22.8095 8.4841 24.6271 10.7262 24.6271C12.9683 24.6271 14.786 22.8095 14.786 20.5674C14.786 18.3252 12.9683 16.5079 10.7262 16.5079ZM20.0594 11.4329C20.0594 13.6751 18.2419 15.4929 15.9999 15.4929C13.7577 15.4929 11.9402 13.6751 11.9402 11.4329C11.9402 9.19106 13.7577 7.37329 15.9999 7.37329C18.2419 7.37329 20.0594 9.19106 20.0594 11.4329Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.4756 20.9301C24.8722 20.9301 21.9512 23.8512 21.9512 27.4548C21.9512 31.0582 24.8722 33.9793 28.4756 33.9793C32.079 33.9793 35 31.0582 35 27.4548C35 23.8512 32.079 20.9301 28.4756 20.9301ZM11.5245 20.9306C7.92113 20.9306 5 23.8512 5 27.4548C5 31.0582 7.92113 33.9793 11.5245 33.9793C15.1279 33.9793 18.0492 31.0582 18.0492 27.4548C18.0492 23.8512 15.1279 20.9306 11.5245 20.9306ZM26.5243 12.7743C26.5243 16.3779 23.6033 19.2994 20.0001 19.2994C16.3965 19.2994 13.4756 16.3779 13.4756 12.7743C13.4756 9.17141 16.3965 6.25 20.0001 6.25C23.6033 6.25 26.5243 9.17141 26.5243 12.7743Z"
				fill="#F06A6A"
			/>
		</svg>
	);
};
