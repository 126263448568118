import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { WebexIcon } from './icon';
import { WebexSearchResultIcon } from './search-result-icon';

export const webexConfig: ThirdPartyConfig = {
	displayName: 'Webex',
	id: 'webex',
	resourceType: 'smartlinks-connector-webex',
	icon: get3PLogo(WebexIcon, 'Webex', 'medium'),
	searchResultIcon: get3PLogo(WebexSearchResultIcon, 'Webex', 'large'),
	integrationAri: 'ari:cloud:platform::integration/cisco',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Video,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
