import { type AggRequest, type AggResponse, fetchAgg } from '../../../../../common/utils/fetch-agg';
import { generateHashFromVariables } from '../../../../../utils/caching/common/key-generator';
import { inMemoryCache } from '../../../../../utils/caching/in-memory';

import {
	jiraFieldsQueryGenerator,
	type SearchPageJiraHydrationQueryVariables,
} from './query-generator';
import { type JiraHydrationData } from './types';

export type { JiraHydrationData, HydratedJiraIssue } from './types';

const cache = inMemoryCache<AggResponse<JiraHydrationData>>();

export const generateCacheKeyForJiraHydratedFields = (
	variables: SearchPageJiraHydrationQueryVariables,
) => {
	const keyObj = {
		issueKeys: variables.issueKeys,
	};

	return generateHashFromVariables(keyObj);
};

// Expire the cache if there are errors or results are empty
const expireWhen = (result: AggResponse<JiraHydrationData>) => {
	const { data, errors } = result;
	if (!data || (errors && errors.length > 0)) {
		return true;
	}
	return data.jira.issuesByKey.length === 0;
};

export type SearchPageJiraHydrationQueryProps = Pick<
	AggRequest<SearchPageJiraHydrationQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
>;

export const fetchJiraFieldsQuery = ({
	variables,
	aggAbsoluteUrl,
}: SearchPageJiraHydrationQueryProps) => {
	const graphQuery = jiraFieldsQueryGenerator();

	return cache.inMemoryDecorator(
		generateCacheKeyForJiraHydratedFields(variables),
		() =>
			fetchAgg<SearchPageJiraHydrationQueryVariables, JiraHydrationData>({
				variables,
				aggAbsoluteUrl,
				graphQuery,
				operationName: 'SearchPageJiraFieldHydration',
			}),
		expireWhen,
	);
};
