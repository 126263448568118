import React from 'react';

export const JiraSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18716)">
			<g clipPath="url(#clip1_2230_18716)">
				<g clipPath="url(#clip2_2230_18716)">
					<path
						d="M13.7923 27.2286H11.0183C6.83464 27.2286 3.83331 24.666 3.83331 20.9137H18.749C19.5221 20.9137 20.0223 21.4628 20.0223 22.2407V37.25C16.2934 37.25 13.7923 34.2299 13.7923 30.0199V27.2286ZM21.1592 19.7697H18.3852C14.2015 19.7697 11.2002 17.2529 11.2002 13.5006H26.1159C26.889 13.5006 27.4347 14.0039 27.4347 14.7819V29.7911C23.7058 29.7911 21.1592 26.771 21.1592 22.5611V19.7697ZM28.5715 12.3566H25.7976C21.6139 12.3566 18.6126 9.79401 18.6126 6.04169H33.5283C34.3014 6.04169 34.8016 6.59081 34.8016 7.32297V22.3323C31.0727 22.3323 28.5715 19.3121 28.5715 15.1022V12.3566Z"
						fill="#1868DB"
					/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18716">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18716">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip2_2230_18716">
				<rect
					width="30.9683"
					height="31.2083"
					fill="white"
					transform="translate(3.83331 6.04169)"
				/>
			</clipPath>
		</defs>
	</svg>
);
