import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { ClickUpIcon } from './icon';
import { ClickUpSearchResultIcon } from './search-result-icon';

export const clickUpConfig: ThirdPartyConfig = {
	displayName: 'ClickUp',
	id: 'clickup',
	resourceType: 'smartlinks-connector-clickup',
	icon: get3PLogo(ClickUpIcon, 'ClickUp', 'medium'),
	searchResultIcon: get3PLogo(ClickUpSearchResultIcon, 'ClickUp', 'large'),
	integrationAri: 'ari:cloud:platform::integration/clickup',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.WorkItem,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
