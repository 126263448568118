import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	initialBannerTitle: {
		id: 'search.bulk-connect-banner.initial.banner.title',
		defaultMessage: 'Your teammates leverage top apps to do their best work ever!',
		description: 'Title shown for the initial banner that a user sees',
	},
	initialBannerConnectText: {
		id: 'search.bulk-connect-banner.initial.banner.connect.text',
		defaultMessage: 'Let’s connect them',
		description: 'Text shown for the action button on the initial banner',
	},
	singleProductBannerTitle: {
		id: 'search.bulk-connect-banner.single.product.banner.title',
		defaultMessage: 'Your admin just added {product}!',
		description: 'Title shown to prompt users to connect third-party products',
	},
	singleProductBannerDescription: {
		id: 'search.bulk-connect-banner.single.product.banner.description',
		defaultMessage: 'Connect your account to get missing results',
		description: 'Description shown to prompt users to connect a single product',
	},
	singleProductBannerConnectText: {
		id: 'search.bulk-connect-banner.single.product.banner.connect.text',
		defaultMessage: 'Connect',
		description: 'Text shown for the action button on the single product banner',
	},
	multipleProductsBannerTitle: {
		id: 'search.bulk-connect-banner.multiple.products.banner.title',
		defaultMessage: 'Your admin approved {count} new apps!',
		description: 'Title shown to prompt users to connect multiple products',
	},
	multipleProductsBannerDescription: {
		id: 'search.bulk-connect-banner.multiple.products.banner.description',
		defaultMessage: 'Connect your accounts to get missing results',
		description: 'Description shown to prompt users to connect multiple products',
	},
	multipleProductsBannerConnectText: {
		id: 'search.bulk-connect-banner.multiple.products.banner.connect.text',
		defaultMessage: 'Let’s do this',
		description: 'Text shown for the action button on the multiple products banner',
	},
});
