export const RECENT_SEARCH_KEY = 'atlassian.unified-search.recent-searches';

export const MAX_RECENT_SEARCHES_STORED = 50;

type recentSearches = {
	[key: string]: { [key: string]: string[] };
};

export const addRecentSearch = (
	value: string,
	userId: string,
	product: string,
	recentSearchKey: string,
) => {
	const recentSearchesRaw = getLocalStorageItem(recentSearchKey);
	const recentSearches: recentSearches = recentSearchesRaw ? JSON.parse(recentSearchesRaw) : {};

	if (!recentSearches[userId]) {
		recentSearches[userId] = {};
	}
	let userSearches = recentSearches[userId];

	if (!userSearches[product]) {
		userSearches[product] = [];
	}
	let productSearches = userSearches[product];

	const trimmedValue = value.trim();

	// Pop the last value of the array if it is too long
	if (productSearches.length === MAX_RECENT_SEARCHES_STORED) {
		productSearches.pop();
	}

	// If the given value is a duplicate, remove it from the array
	if (productSearches.includes(trimmedValue)) {
		productSearches = productSearches.filter((v) => v !== trimmedValue);
	}

	productSearches.unshift(trimmedValue);

	recentSearches[userId][product] = productSearches;

	setLocalStorageItem(recentSearchKey, JSON.stringify(recentSearches));
};

export const getRecentSearches = (
	userId: string,
	product: string,
	recentSearchKey: string,
): string[] => {
	const recentSearchesRaw = getLocalStorageItem(recentSearchKey);
	const recentSearches = recentSearchesRaw ? (JSON.parse(recentSearchesRaw) as recentSearches) : {};
	const userSearches = recentSearches[userId] || {};
	return userSearches[product] || [];
};

export const removeRecentSearch = (
	value: string,
	userId: string,
	product: string,
	recentSearchKey: string,
) => {
	const recentSearchesRaw = getLocalStorageItem(recentSearchKey);
	const recentSearches: recentSearches = recentSearchesRaw ? JSON.parse(recentSearchesRaw) : {};

	if (!recentSearches[userId] || !recentSearches[userId][product]) {
		return [];
	}

	recentSearches[userId][product] = recentSearches[userId][product].filter((v) => v !== value);

	setLocalStorageItem(recentSearchKey, JSON.stringify(recentSearches));

	return recentSearches[userId][product];
};

const setLocalStorageItem = (key: string, value: string) => {
	window.localStorage.setItem(key, value);
};

const getLocalStorageItem = (key: string): string | null => {
	return window.localStorage.getItem(key);
};
