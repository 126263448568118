import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const NotionIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-683.5"
				y="-268.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.375"
				y="0.375"
				width="23.25"
				height="23.25"
				rx="5.625"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.75"
			/>
			<path
				d="M5.72382 5.14736L14.0236 4.53432C15.0431 4.44687 15.305 4.50582 15.9461 4.97186L18.5955 6.83828C19.0325 7.15927 19.178 7.24672 19.178 7.59621V17.8325C19.178 18.474 18.945 18.8535 18.1295 18.9114L8.49127 19.4952C7.87928 19.5241 7.58784 19.4367 7.26729 19.0282L5.31628 16.4912C4.96634 16.0243 4.82129 15.6748 4.82129 15.2662V6.16734C4.82129 5.6428 5.05423 5.20541 5.72382 5.14736Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.0236 4.53447L5.72382 5.14751C5.05423 5.20541 4.82129 5.64295 4.82129 6.16734V15.2662C4.82129 15.6746 4.96634 16.0241 5.31628 16.4912L7.26729 19.0281C7.58784 19.4365 7.87928 19.5241 8.49127 19.495L18.1296 18.9115C18.9446 18.8535 19.1781 18.474 19.1781 17.8326V7.59636C19.1781 7.26487 19.0472 7.16932 18.6617 6.88643L15.9461 4.97186C15.3052 4.50582 15.0431 4.44687 14.0236 4.53432V4.53447ZM8.70921 7.42882C7.92218 7.48177 7.74368 7.49377 7.29669 7.13032L6.16031 6.22644C6.04482 6.10944 6.10286 5.96349 6.39386 5.9344L14.3727 5.35136C15.0427 5.29286 15.3916 5.5264 15.6536 5.7304L17.0221 6.72188C17.0806 6.75143 17.2261 6.92588 17.051 6.92588L8.81121 7.42192L8.70921 7.42882ZM7.79168 17.7452V9.05539C7.79168 8.67589 7.90823 8.50085 8.25712 8.47145L17.721 7.91736C18.042 7.88841 18.1871 8.0924 18.1871 8.4713V17.1032C18.1871 17.4827 18.1286 17.8037 17.6046 17.8326L8.54827 18.3576C8.02433 18.3866 7.79183 18.2121 7.79183 17.7452H7.79168ZM16.7315 9.52128C16.7896 9.78377 16.7315 10.0463 16.469 10.0763L16.0325 10.1628V16.5786C15.6535 16.7826 15.3046 16.8992 15.013 16.8992C14.547 16.8992 14.4306 16.7532 14.0815 16.3161L11.2271 11.8252V16.1702L12.13 16.3746C12.13 16.3746 12.13 16.8996 11.4015 16.8996L9.39305 17.0162C9.33455 16.8992 9.39305 16.6077 9.5966 16.5497L10.1211 16.4042V10.6593L9.3932 10.6004C9.3347 10.3379 9.4802 9.95882 9.88819 9.92942L12.0432 9.78437L15.0131 14.3333V10.3089L14.2561 10.2219C14.1976 9.90047 14.4306 9.66692 14.7215 9.63842L16.7315 9.52128Z"
				fill="#292A2E"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.8801 5.63103L13.9736 5.03323C14.9678 4.94796 15.2232 5.00544 15.8483 5.4599L18.4319 7.27992C18.858 7.59294 18.9999 7.67822 18.9999 8.01902V18.0009C18.9999 18.6265 18.7727 18.9965 17.9774 19.053L8.57877 19.6223C7.98199 19.6505 7.69779 19.5652 7.38521 19.1669L5.48269 16.6929C5.14144 16.2376 5 15.8968 5 15.4983V6.62566C5 6.11416 5.22716 5.68764 5.8801 5.63103Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.9736 5.03338L5.8801 5.63118C5.22716 5.68764 5 6.11431 5 6.62566V15.4983C5 15.8966 5.14144 16.2374 5.48269 16.6929L7.38521 19.1668C7.69779 19.5651 7.98199 19.6505 8.57877 19.6221L17.9776 19.0531C18.7723 18.9965 19 18.6265 19 18.001V8.01917C19 7.69591 18.8723 7.60274 18.4964 7.32688L15.8483 5.4599C15.2233 5.00544 14.9678 4.94796 13.9736 5.03323V5.03338ZM8.7913 7.85579C8.02382 7.90742 7.84976 7.91912 7.41388 7.56471L6.30574 6.68329C6.19312 6.5692 6.24972 6.42688 6.53349 6.39851L14.314 5.82996C14.9674 5.77291 15.3076 6.00066 15.5631 6.19958L16.8975 7.16642C16.9546 7.19524 17.0965 7.36535 16.9258 7.36535L8.89076 7.84906L8.7913 7.85579ZM7.89657 17.9157V9.44193C7.89657 9.07187 8.01022 8.90117 8.35044 8.8725L17.5791 8.33218C17.8922 8.30395 18.0336 8.50288 18.0336 8.87236V17.2897C18.0336 17.6598 17.9766 17.9728 17.4656 18.001L8.63435 18.513C8.12343 18.5412 7.89671 18.3711 7.89671 17.9157H7.89657ZM16.6142 9.89624C16.6708 10.1522 16.6142 10.4082 16.3582 10.4374L15.9326 10.5218V16.7782C15.563 16.9771 15.2227 17.0908 14.9384 17.0908C14.4839 17.0908 14.3704 16.9485 14.0301 16.5222L11.2466 12.1429V16.3799L12.1271 16.5793C12.1271 16.5793 12.1271 17.0912 11.4167 17.0912L9.45814 17.2049C9.40109 17.0908 9.45814 16.8066 9.65662 16.75L10.1681 16.6081V11.006L9.45828 10.9485C9.40124 10.6925 9.54312 10.3229 9.94097 10.2942L12.0424 10.1528L14.9385 14.5887V10.6643L14.2003 10.5795C14.1433 10.266 14.3704 10.0383 14.6542 10.0105L16.6142 9.89624Z"
				fill="black"
			/>
		</svg>
	);
};
