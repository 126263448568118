import { fg } from '@atlaskit/platform-feature-flags';
import {
	confluenceSpaceKeysToIdsQuery,
	confluenceSpacesQuery,
	type EntityATI,
	searchEntitiesQuery,
	smartsRecommendedContainerQuery,
} from '@atlassian/search-client';

import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getBackendExperiment } from '../../../../../common/utils/bootstrap';
import { getProducts3PFromConfigForFilter, notEmpty } from '../../../../../common/utils/filters';
import { isHello } from '../../../../../common/utils/is-hello';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';

type Space = {
	/** Space ARI */
	ari: string;
	/** Space key, e.g. "MICROS" */
	key: string;
	name: string | null;
	avatarUrl: string;
};

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('space');

const LOOKUP_ENTITIES: EntityATI[] = ['ati:cloud:confluence:space'];

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createMultiSelectFilter({
	id: 'space',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'spaces',
		fetcher: async (queryParams, config) => {
			let spaceARIs: string[] = [];
			if (fg('search_page_simplify_space_filter_logic')) {
				const cloudId = config.cloudId;
				// Practically cloudId should always be present, but we need to satisfy the type checker
				if (!cloudId) {
					return [];
				}
				const response = await confluenceSpaceKeysToIdsQuery({ spaceKeys: queryParams, cloudId });
				const spaceIds =
					response.data?.spaces?.nodes?.map((node) => node?.id).filter(notEmpty) ?? [];
				spaceARIs = spaceIds.map((id) => `ari:cloud:confluence:${cloudId}:space/${id}`);
			} else {
				// use initial space ARIs from config as we can not directly use space value in queryParams
				spaceARIs = config.initialSpaceARIs || [];
			}

			const initialSpacesResponse = await confluenceSpacesQuery(spaceARIs);

			const allRecommendedSpaces: Space[] =
				initialSpacesResponse.data?.confluence.spaces.map((space) => ({
					key: space.key,
					name: space.name,
					ari: space.id,
					avatarUrl:
						space.links?.base && space.icon?.path ? `${space.links.base}${space.icon.path}` : '',
				})) || [];

			return allRecommendedSpaces.map((space) => ({
				trackingKey: space.ari,
				value: space.key,
				queryParamValue: space.key,
				avatarUrl: space.avatarUrl,
				label: space.name || '',
			}));
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			const recommendedSpacesResponse = await smartsRecommendedContainerQuery({
				aggAbsoluteUrl: config.aggAbsoluteUrl,
				variables: {
					userId: config.userId || '',
					tenantId: config.cloudId || '',
				},
			});

			const allRecommendedSpaces: Space[] =
				recommendedSpacesResponse.data?.smarts.results?.flatMap(({ container }) => {
					if (!(container && container.key)) {
						return [];
					}
					return [
						{
							key: container.key,
							name: container.name,
							ari: container.id,
							avatarUrl:
								container.links?.base && container.icon?.path
									? `${container.links.base}${container.icon.path}`
									: '',
						},
					];
				}) || [];

			const recommendedSpaceOptions = allRecommendedSpaces.map((space) => {
				return {
					trackingKey: space.ari,
					value: space.key,
					queryParamValue: space.key,
					avatarUrl: space.avatarUrl,
					label: space.name || '',
				};
			});

			return recommendedSpaceOptions;
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			const backendExperiment =
				config.experimentConfig &&
				getBackendExperiment({
					experimentConfig: config.experimentConfig,
					entities: LOOKUP_ENTITIES,
					productEdition: config.productEdition,
					isHello: isHello(config.cloudId),
					productDataRegions: config.productDataRegions,
					experience: 'search-page-space-filter',
				});

			const { experimentLayers, experimentId, shadowExperimentId } = backendExperiment || {};

			const response = await searchEntitiesQuery({
				aggAbsoluteUrl: config.aggAbsoluteUrl,
				variables: {
					query,
					first: 15,
					filters: {
						entities: ['ati:cloud:confluence:space'],
						locations: [`ari:cloud:confluence::site/${config.cloudId}`],
					},
					experience: 'full-page-search-space-filter',
					experimentId,
					shadowExperimentId,
					experimentLayers: experimentLayers?.map(({ name, layerId, shadowId, definitions }) => ({
						name,
						layerId,
						shadowId,
						definitions,
					})),
				},
				includeSpaceKey: true,
			});
			const { data } = response;
			if (!data) {
				return [];
			}

			return (
				data.search?.results.edges.map(({ node }) => ({
					trackingKey: node.id || '',
					value: node.key || '',
					queryParamValue: node.key ?? '',
					avatarUrl: node.iconUrl || undefined,
					label: node.title,
				})) ?? []
			);
		},
	},
});
