import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const DocusignIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-115.5"
				y="-166.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#0B1228" strokeOpacity="0.14" />
			<path
				d="M20.2568 20.2378V25.1619C20.2568 25.4206 20.0472 25.6295 19.7877 25.6295H6.80236C6.54285 25.6295 6.33325 25.4206 6.33325 25.1619V12.2299C6.33325 11.9712 6.54285 11.7623 6.80236 11.7623H11.733V19.7703C11.733 20.0289 11.9426 20.2378 12.2021 20.2378H20.2568Z"
				fill="#4C00FF"
			/>
			<path
				d="M25.6668 13.2943C25.6668 17.4325 23.2414 20.2278 20.257 20.2378V15.0252C20.257 14.876 20.1971 14.7267 20.0873 14.6273L17.3725 11.9215C17.2627 11.8121 17.123 11.7524 16.9732 11.7524H11.7432V6.83818C11.7432 6.57953 11.9528 6.37063 12.2123 6.37063H19.5284C23.0018 6.36068 25.6668 9.14605 25.6668 13.2943Z"
				fill="#FF5252"
			/>
			<path
				d="M20.0873 14.6171C20.1971 14.7266 20.257 14.8658 20.257 15.0151V20.2277H12.2123C11.9528 20.2277 11.7432 20.0188 11.7432 19.7601V11.7522H16.9732C17.123 11.7522 17.2727 11.8119 17.3725 11.9213L20.0873 14.6171Z"
				fill="black"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.0826 15.0417V18.6075C15.0826 18.7948 14.9308 18.946 14.7429 18.946H5.3397C5.15178 18.946 5 18.7948 5 18.6075V9.24286C5 9.05557 5.15178 8.9043 5.3397 8.9043H8.91017V14.7031C8.91017 14.8904 9.06195 15.0417 9.24987 15.0417H15.0826Z"
				fill="#4C00FF"
			/>
			<path
				d="M19 10.0137C19 13.0104 17.2437 15.0346 15.0826 15.0418V11.2671C15.0826 11.159 15.0393 11.051 14.9598 10.979L12.9938 9.0196C12.9143 8.94036 12.8131 8.89714 12.7047 8.89714H8.91742V5.33859C8.91742 5.15129 9.0692 5.00002 9.25712 5.00002H14.555C17.0702 4.99282 19 7.00981 19 10.0137Z"
				fill="#FF5252"
			/>
			<path
				d="M14.9598 10.9717C15.0393 11.051 15.0826 11.1518 15.0826 11.2599V15.0345H9.25712C9.0692 15.0345 8.91742 14.8832 8.91742 14.6959V8.89709H12.7047C12.8131 8.89709 12.9215 8.94032 12.9938 9.01955L14.9598 10.9717Z"
				fill="black"
			/>
		</svg>
	);
};
