import React from 'react';
import { token } from '@atlaskit/tokens';

export const RemoveIcon: React.FC<{}> = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.185 7.4 12 10.585 8.815 7.399a.507.507 0 0 0-.712.003l-.7.701a.496.496 0 0 0-.004.712l3.185 3.184L7.4 15.185a.507.507 0 0 0 .004.712l.7.7c.206.207.516.2.712.004l3.186-3.185 3.184 3.185a.507.507 0 0 0 .712-.004l.7-.7a.496.496 0 0 0 .004-.712l-3.186-3.186 3.186-3.184a.507.507 0 0 0-.003-.712l-.7-.7a.507.507 0 0 0-.36-.153.5.5 0 0 0-.353.15h-.001Z"
			fill={token('color.icon', '#44546F')}
		/>
	</svg>
);
