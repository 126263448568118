import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const OneDriveIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-28.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<path
				d="M10.0419 9.65028L10.042 9.64971L13.5059 11.7245L15.5699 10.8559C15.9893 10.6746 16.4415 10.5816 16.8985 10.5821C16.9746 10.5821 17.0499 10.5855 17.1247 10.5905C16.8766 9.62289 16.3527 8.74817 15.6168 8.07266C14.8809 7.39715 13.9646 6.94992 12.9794 6.78533C11.9941 6.62073 10.9822 6.74586 10.0667 7.1455C9.15118 7.54513 8.37146 8.20208 7.82227 9.0365C7.83991 9.03628 7.85729 9.03517 7.87497 9.03517C8.6405 9.03414 9.39106 9.24719 10.0419 9.65028Z"
				fill="#0364B8"
			/>
			<path
				d="M10.0421 9.64982L10.0419 9.65039C9.39112 9.2473 8.64056 9.03425 7.87503 9.03528C7.85734 9.03528 7.83994 9.03639 7.82232 9.03661C7.07306 9.04589 6.34049 9.25916 5.70334 9.65351C5.06618 10.0479 4.54853 10.6084 4.20601 11.2748C3.86349 11.9413 3.70904 12.6885 3.75928 13.4361C3.80951 14.1837 4.06252 14.9035 4.49112 15.5182L7.54571 14.2327L8.90358 13.6613L11.927 12.389L13.5059 11.7246L10.0421 9.64982Z"
				fill="#0078D4"
			/>
			<path
				d="M17.1247 10.5906C17.0499 10.5856 16.9746 10.5822 16.8985 10.5822C16.4416 10.5817 15.9894 10.675 15.57 10.8564L13.5059 11.7246L14.1044 12.0831L16.0663 13.2583L16.9223 13.771L19.8492 15.5242C20.1151 15.0305 20.253 14.4779 20.25 13.9171C20.2471 13.3564 20.1035 12.8053 19.8324 12.3144C19.5613 11.8235 19.1714 11.4084 18.6983 11.1073C18.2253 10.8061 17.6842 10.6285 17.1247 10.5906Z"
				fill="#1490DF"
			/>
			<path
				d="M16.9219 13.771L16.066 13.2582L14.104 12.0831L13.5055 11.7245L11.9266 12.389L8.90317 13.6613L7.54531 14.2327L4.49072 15.5181C4.87031 16.0639 5.37634 16.5096 5.9656 16.8174C6.55486 17.1251 7.20985 17.2856 7.87462 17.2853H16.8981C17.5031 17.2855 18.0969 17.1218 18.6164 16.8118C19.1359 16.5017 19.5618 16.0567 19.8488 15.5241L16.9219 13.771Z"
				fill="#28A8EA"
			/>
		</svg>
	) : (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 26 26">
			<g id="OneDrive">
				<rect fill="none" width="24" height="24" />
				<path d="M14.5,15l4.95-4.74A7.5,7.5,0,0,0,5.92,8C6,8,14.5,15,14.5,15Z" fill="#0364BA" />
				<path
					d="M9.15,8.89h0A6,6,0,0,0,6,8H5.92a6,6,0,0,0-4.84,9.43L8.5,16.5l5.69-4.59Z"
					fill="#0078D4"
				/>
				<path
					d="M19.45,10.26h-.32a4.84,4.84,0,0,0-1.94.4h0l-3,1.26L17.5,16l5.92,1.44a4.88,4.88,0,0,0-4-7.18Z"
					fill="#1490DF"
				/>
				<path
					d="M1.08,17.43A6,6,0,0,0,6,20H19.13a4.89,4.89,0,0,0,4.29-2.56l-9.23-5.53Z"
					fill="#28A8EA"
				/>
			</g>
		</svg>
	);
};
