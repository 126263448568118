/**
 * Derived from from https://github.com/uidotdev/usehooks/blob/main/index.js
 */

import { useCallback, useMemo } from 'react';

import { bind } from 'bind-event-listener';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

function dispatchStorageEvent(key: string, newValue: string | null) {
	window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

const setLocalStorageItem = (key: string, value: string | null) => {
	const stringifiedValue = JSON.stringify(value);
	window.localStorage.setItem(key, stringifiedValue);
	dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key: string) => {
	window.localStorage.removeItem(key);
	dispatchStorageEvent(key, null);
};

const getLocalStorageItem = (key: string) => {
	return window.localStorage.getItem(key);
};

const useLocalStorageSubscribe = (callback: () => void) => {
	const unbind = bind(window, { type: 'storage', listener: callback });
	return unbind;
};

export function useLocalStorage(key: string) {
	const getSnapshot = () => getLocalStorageItem(key);

	const store = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot);

	const setState = useCallback(
		(v: any) => {
			try {
				const nextState = typeof v === 'function' && store !== null ? v(JSON.parse(store)) : v;

				if (nextState === undefined || nextState === null) {
					removeLocalStorageItem(key);
				} else {
					setLocalStorageItem(key, nextState);
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.warn(e);
			}
		},
		[key, store],
	);

	return useMemo(() => {
		return [store ? JSON.parse(store) : store, setState];
	}, [setState, store]);
}

export function useLocalStorageSearchPagePersistedState() {
	return useLocalStorage('search-page-persisted-state');
}
