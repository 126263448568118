import { DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { FreshserviceIcon } from './icon';
import { FreshserviceSearchResultIcon } from './search-result-icon';

export const freshserviceConfig: ThirdPartyConfig = {
	displayName: 'Freshservice',
	id: 'freshservice',
	icon: get3PLogo(FreshserviceIcon, 'Freshservice', 'medium'),
	resourceType: 'freshservice-connector',
	searchResultIcon: get3PLogo(FreshserviceSearchResultIcon, 'Freshservice', 'large'),
	integrationAri: 'ari:cloud:platform::integration/freshservice', // this hasn't been implemented in the codebase yet, so will need to confirm that it is correct in the future,
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.WorkItem,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
