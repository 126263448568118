import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const TeamsIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-603.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="white"
			/>
			<path
				d="M0.385264 6C0.385264 2.89907 2.89907 0.385264 6 0.385264H18C21.1009 0.385264 23.6147 2.89907 23.6147 6V18C23.6147 21.1009 21.1009 23.6147 18 23.6147H6C2.89907 23.6147 0.385264 21.1009 0.385264 18V6Z"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.770528"
			/>
			<path
				d="M14.4626 10.3287H18.3371C18.7032 10.3287 18.9999 10.6309 18.9999 11.0037V14.5979C18.9999 15.968 17.9093 17.0787 16.564 17.0787H16.5525C15.2072 17.0789 14.1164 15.9684 14.1162 14.5983C14.1162 14.5981 14.1162 14.598 14.1162 14.5979V10.6815C14.1162 10.4867 14.2713 10.3287 14.4626 10.3287Z"
				fill="#5059C9"
			/>
			<path
				d="M17.0815 9.61849C17.9484 9.61849 18.6512 8.90274 18.6512 8.01982C18.6512 7.13689 17.9484 6.42114 17.0815 6.42114C16.2145 6.42114 15.5117 7.13689 15.5117 8.01982C15.5117 8.90274 16.2145 9.61849 17.0815 9.61849Z"
				fill="#5059C9"
			/>
			<path
				d="M12.1976 9.61827C13.4499 9.61827 14.465 8.58441 14.465 7.30907C14.465 6.03374 13.4499 4.99988 12.1976 4.99988C10.9453 4.99988 9.93018 6.03374 9.93018 7.30907C9.93018 8.58441 10.9453 9.61827 12.1976 9.61827Z"
				fill="#7B83EB"
			/>
			<path
				d="M15.2211 10.3287H8.82556C8.46387 10.3379 8.17775 10.6435 8.18614 11.0119V15.1113C8.13564 17.3218 9.85286 19.1562 12.0233 19.2103C14.1938 19.1562 15.911 17.3218 15.8605 15.1113V11.0119C15.8689 10.6435 15.5828 10.3379 15.2211 10.3287Z"
				fill="#7B83EB"
			/>
			<path
				opacity="0.1"
				d="M12.3718 10.3287V16.0733C12.37 16.3367 12.2133 16.5734 11.9741 16.6737C11.8979 16.7065 11.8161 16.7234 11.7334 16.7234H8.49271C8.44736 16.6062 8.4055 16.489 8.37061 16.3682C8.2485 15.9605 8.1862 15.5367 8.18573 15.1105V11.0108C8.17734 10.6431 8.46299 10.3379 8.82409 10.3287H12.3718Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M12.0229 10.3287V16.4286C12.0229 16.5128 12.0063 16.5961 11.9741 16.6737C11.8756 16.9173 11.6432 17.0769 11.3846 17.0787H8.65666C8.59736 16.9615 8.54154 16.8442 8.49271 16.7234C8.44387 16.6026 8.4055 16.489 8.37061 16.3682C8.2485 15.9605 8.1862 15.5367 8.18573 15.1105V11.0108C8.17734 10.6431 8.46299 10.3379 8.82409 10.3287H12.0229Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M12.0229 10.3287V15.718C12.0203 16.076 11.736 16.3655 11.3845 16.3682H8.37061C8.2485 15.9605 8.1862 15.5367 8.18573 15.1105V11.0108C8.17734 10.6431 8.46299 10.3379 8.82409 10.3287H12.0229Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M11.6741 10.3287V15.718C11.6714 16.076 11.3872 16.3655 11.0357 16.3682H8.37061C8.2485 15.9605 8.1862 15.5367 8.18573 15.1105V11.0108C8.17734 10.6431 8.46299 10.3379 8.82409 10.3287H11.6741Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M12.3722 8.49204V9.61111C12.3129 9.61466 12.2571 9.61822 12.1978 9.61822C12.1385 9.61822 12.0827 9.61467 12.0234 9.61111C11.9056 9.60315 11.7889 9.58413 11.6746 9.55427C10.9682 9.3839 10.3846 8.87937 10.1048 8.19718C10.0567 8.08261 10.0193 7.96366 9.99316 7.84192H11.7338C12.0859 7.84328 12.3709 8.13355 12.3722 8.49204Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M12.0231 8.84751V9.61132C11.9053 9.60336 11.7886 9.58434 11.6742 9.55448C10.9679 9.38411 10.3843 8.87958 10.1045 8.19739H11.3847C11.7367 8.19874 12.0217 8.48902 12.0231 8.84751Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M12.0231 8.84751V9.61132C11.9053 9.60336 11.7886 9.58434 11.6742 9.55448C10.9679 9.38411 10.3843 8.87958 10.1045 8.19739H11.3847C11.7367 8.19874 12.0217 8.48902 12.0231 8.84751Z"
				fill="black"
			/>
			<path
				opacity="0.2"
				d="M11.6742 8.84751V9.55448C10.9679 9.38411 10.3843 8.87958 10.1045 8.19739H11.0359C11.3879 8.19875 11.6729 8.48903 11.6742 8.84751Z"
				fill="black"
			/>
			<path
				d="M4.63941 8.19739H11.0349C11.3881 8.19739 11.6744 8.48894 11.6744 8.84858V15.3619C11.6744 15.7216 11.3881 16.0131 11.0349 16.0131H4.63941C4.28627 16.0131 4 15.7216 4 15.3619V8.84858C4 8.48894 4.28628 8.19739 4.63941 8.19739Z"
				fill="url(#paint0_linear_719_13847)"
			/>
			<path
				d="M9.51986 10.6762H8.24137V14.2217H7.42685V10.6762H6.1543V9.9884H9.51986V10.6762Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_719_13847"
					x1="5.33319"
					y1="7.68856"
					x2="10.4795"
					y2="16.4406"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#5A62C3" />
					<stop offset="0.5" stopColor="#4D55BD" />
					<stop offset="1" stopColor="#3940AB" />
				</linearGradient>
			</defs>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="1 1 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_3561_46629)">
				<path
					d="M15.0321 10H19.4879C19.9088 10 20.2501 10.3403 20.2501 10.76V14.8068C20.2501 16.3494 18.9959 17.6 17.4488 17.6H17.4355C15.8884 17.6002 14.634 16.3498 14.6338 14.8072C14.6338 14.8071 14.6338 14.8069 14.6338 14.8068V10.3972C14.6338 10.1778 14.8121 10 15.0321 10Z"
					fill="#5059C9"
				/>
				<path
					d="M18.0435 9.19961C19.0405 9.19961 19.8487 8.39372 19.8487 7.39961C19.8487 6.4055 19.0405 5.59961 18.0435 5.59961C17.0465 5.59961 16.2383 6.4055 16.2383 7.39961C16.2383 8.39372 17.0465 9.19961 18.0435 9.19961Z"
					fill="#5059C9"
				/>
				<path
					d="M12.4274 9.20001C13.8675 9.20001 15.0349 8.03594 15.0349 6.6C15.0349 5.16406 13.8675 4 12.4274 4C10.9873 4 9.81982 5.16406 9.81982 6.6C9.81982 8.03594 10.9873 9.20001 12.4274 9.20001Z"
					fill="#7B83EB"
				/>
				<path
					d="M15.904 10H8.54908C8.13313 10.0103 7.8041 10.3545 7.81375 10.7692V15.3848C7.75566 17.8737 9.73048 19.9391 12.2265 20C14.7226 19.9391 16.6974 17.8737 16.6393 15.3848V10.7692C16.649 10.3545 16.3199 10.0103 15.904 10Z"
					fill="#7B83EB"
				/>
				<path
					opacity="0.1"
					d="M12.6276 10V16.468C12.6256 16.7646 12.4454 17.0311 12.1703 17.144C12.0827 17.1809 11.9886 17.2 11.8935 17.2H8.16671C8.11456 17.068 8.06642 16.936 8.0263 16.8C7.88588 16.341 7.81423 15.8639 7.81368 15.384V10.768C7.80404 10.3539 8.13253 10.0103 8.54781 10H12.6276Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M12.2265 10V16.868C12.2265 16.9628 12.2074 17.0567 12.1703 17.144C12.0571 17.4183 11.7898 17.598 11.4924 17.6H8.35526C8.28706 17.468 8.22288 17.336 8.16671 17.2C8.11055 17.064 8.06642 16.936 8.0263 16.8C7.88588 16.341 7.81423 15.8639 7.81368 15.384V10.768C7.80404 10.3539 8.13253 10.0103 8.54781 10H12.2265Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M12.2265 10V16.068C12.2234 16.471 11.8965 16.797 11.4923 16.8H8.0263C7.88588 16.341 7.81423 15.8639 7.81368 15.384V10.768C7.80404 10.3539 8.13253 10.0103 8.54781 10H12.2265Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.8253 10V16.068C11.8223 16.471 11.4954 16.797 11.0912 16.8H8.0263C7.88588 16.341 7.81423 15.8639 7.81368 15.384V10.768C7.80404 10.3539 8.13253 10.0103 8.54781 10H11.8253Z"
					fill="black"
				/>
				<path
					opacity="0.1"
					d="M12.628 7.93121V9.19121C12.5598 9.19521 12.4956 9.19921 12.4274 9.19921C12.3592 9.19921 12.2951 9.19522 12.2269 9.19121C12.0914 9.18225 11.9572 9.16083 11.8257 9.12721C11.0133 8.93539 10.3422 8.36732 10.0205 7.59922C9.9651 7.47023 9.92213 7.33629 9.89209 7.19922H11.8939C12.2987 7.20075 12.6265 7.52757 12.628 7.93121Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M12.2269 8.3316V9.1916C12.0915 9.18264 11.9572 9.16122 11.8257 9.12761C11.0134 8.93578 10.3422 8.36772 10.0205 7.59961H11.4928C11.8976 7.60114 12.2254 7.92797 12.2269 8.3316Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M12.2269 8.3316V9.1916C12.0915 9.18264 11.9572 9.16122 11.8257 9.12761C11.0134 8.93578 10.3422 8.36772 10.0205 7.59961H11.4928C11.8976 7.60114 12.2254 7.92797 12.2269 8.3316Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M11.8257 8.33161V9.12761C11.0134 8.93578 10.3422 8.36772 10.0205 7.59961H11.0916C11.4964 7.60115 11.8242 7.92798 11.8257 8.33161Z"
					fill="black"
				/>
				<path
					d="M3.73533 7.59961H11.0902C11.4964 7.59961 11.8256 7.92788 11.8256 8.33281V15.6664C11.8256 16.0713 11.4963 16.3996 11.0902 16.3996H3.73533C3.32921 16.3996 3 16.0713 3 15.6664V8.33281C3 7.92788 3.32922 7.59961 3.73533 7.59961Z"
					fill="#5059C9"
				/>
				<path
					d="M9.34796 10.3916H7.87769V14.3836H6.94098V10.3916H5.47754V9.61719H9.34796V10.3916Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3561_46629">
					<rect width="17.25" height="16" fill="white" transform="translate(3 4)" />
				</clipPath>
			</defs>
		</svg>
	);
};
