import { DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI } from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { SharePointIcon } from './icon';
import { SharePointSearchResultIcon } from './search-result-icon';

export const sharepointConfig: ThirdPartyConfig = {
	displayName: 'Microsoft SharePoint',
	id: 'sharepoint',
	resourceType: 'sharepoint-connector',
	icon: get3PLogo(SharePointIcon, 'Sharepoint', 'medium'),
	searchResultIcon: get3PLogo(SharePointSearchResultIcon, 'Sharepoint', 'large'),
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Document,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Document],
			},
		],
		[
			TypeFilterValueKey.Spreadsheet,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Spreadsheet],
			},
		],
		[
			TypeFilterValueKey.Presentation,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Presentation],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
