import { useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import { ApplicationModes } from '../../../common/constants/application-modes';
import { ProductKeys, useProductConfigs } from '../../../common/constants/products';
import { getProductOrder } from '../../../common/utils/product';

import { inputPlaceholderMessages, skeletonPlaceholders } from './messages';
import {
	getGhostTextInputPlaceholder,
	ghostTextInputPlaceholders,
	useGhostTextSessionCount,
} from './utils';

export type UsePlaceholderTextProps = {
	applicationMode: ApplicationModes;
	isAdminHubAIEnabled: boolean;
	isNav4Enabled: boolean;
	quickFindOpen: boolean;
	availableProducts: ProductKeys[];
};

export const usePlaceholderText = ({
	applicationMode,
	isAdminHubAIEnabled,
	isNav4Enabled,
	quickFindOpen,
	availableProducts,
}: UsePlaceholderTextProps) => {
	const { formatMessage } = useIntl();
	const products = useProductConfigs();
	const [ghostTextSession, { incrementSession }] = useGhostTextSessionCount({
		initialValue: 0,
		maxCount: ghostTextInputPlaceholders.length,
	});

	const immutableGhostTextSession = useMemo(
		() => ghostTextSession,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const productDisplayNames = useMemo(() => {
		const productConfigs = availableProducts
			.sort(getProductOrder)
			.map((productKey) => products[productKey]);

		const firstPartyProducts = productConfigs
			.filter((product) => !product.is3P)
			.map((product) => product.displayName);

		const thirdPartyProducts = productConfigs
			.filter((product) => product.is3P)
			.map((product) => product.displayName);

		return [...thirdPartyProducts.slice(0, 2), firstPartyProducts[0]].join(', ');
	}, [availableProducts, products]);

	const staticPlaceholder = useMemo(() => {
		return formatMessage(skeletonPlaceholders.inputPlaceholder);
	}, [formatMessage]);

	const dynamicPlaceholder = useMemo(() => {
		const placeholders = [
			[
				!isNav4Enabled && !quickFindOpen,
				skeletonPlaceholders.inputPlaceholderNav3, // Search
			],
			[
				applicationMode === ApplicationModes.Unified,
				skeletonPlaceholders.inputPlaceholderDefault, // Search for anything
			],
			[
				applicationMode === ApplicationModes.Rovo,
				getGhostTextInputPlaceholder(immutableGhostTextSession, 0), // Search across {products}, and more  - Dynamic ghost text
			],
			[
				isAdminHubAIEnabled,
				inputPlaceholderMessages.inputPlaceholderWithAtlassianIntelligence, // Search or ask anything
			],
		] as const;

		const [, text] = placeholders.find(([predicate]) => predicate) ?? [
			true,
			skeletonPlaceholders.inputPlaceholderDefault, // Search for anything
		];

		return formatMessage(text, { products: productDisplayNames });
	}, [
		applicationMode,
		formatMessage,
		immutableGhostTextSession,
		isAdminHubAIEnabled,
		isNav4Enabled,
		productDisplayNames,
		quickFindOpen,
	]);

	const placeholder = fg('rovo_quick_find_static_placeholder_text')
		? staticPlaceholder
		: dynamicPlaceholder;

	return { placeholder, incrementSession, ghostTextSession };
};
