import React from 'react';

import Avatar from '@atlaskit/avatar';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import type { FormatOptionLabelMeta, OptionType } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';

export type OptionLabelProps = {
	option: OptionType;
	state: FormatOptionLabelMeta<OptionType>;
	children?: React.ReactNode;
};

const optionTextWrapperStyles = xcss({
	font: token('font.body'),
	marginLeft: 'space.100',
	color: 'color.text',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

export const OptionLabel = ({ option, state, children }: OptionLabelProps) => {
	if (state.context === 'menu' || state.context === 'value') {
		const spaces = {
			menu: 'space.050',
			value: undefined,
		} as const;
		return (
			<Stack space={spaces[state.context]}>
				<Flex alignItems="center">
					{children}
					<Box xcss={optionTextWrapperStyles}>{option.label}</Box>
				</Flex>
			</Stack>
		);
	}

	return <Box xcss={optionTextWrapperStyles}>{option.label}</Box>;
};

type OptionLabelComponent = (props: OptionLabelProps) => JSX.Element;

export const createOptionLabelFormatter =
	(Component: OptionLabelComponent) =>
	(option: OptionType, state: FormatOptionLabelMeta<OptionType>) => {
		const props = { option, state };
		return (
			<OptionLabel {...props}>
				<Component {...props} />
			</OptionLabel>
		);
	};

const AvatarOptionLabel = (props: OptionLabelProps) => (
	<Avatar src={props.option.avatarUrl} appearance="circle" size="small" borderColor="none" />
);

export const formatAvatarOptionLabel = createOptionLabelFormatter(AvatarOptionLabel);
