import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const WebexSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-358.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.513685"
				y="0.513685"
				width="30.9726"
				height="30.9726"
				rx="7.48631"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.02737"
			/>
			<mask
				id="mask0_719_13847"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				style={{ maskType: 'luminance' }}
				maskUnits="userSpaceOnUse"
				x="4"
				y="8"
				width="24"
				height="17"
			>
				<path d="M27.3332 8.23596H4.6665V24.7346H27.3332V8.23596Z" fill="white" />
			</mask>
			<g mask="url(#mask0_719_13847)">
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint0_linear_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint1_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint2_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint3_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint4_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint5_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint6_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint7_linear_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint8_radial_719_13847)"
				/>
				<path
					d="M20.2387 8.23596C18.5574 8.23596 16.6113 8.80589 15.3233 11.255C14.583 12.6628 14.2081 14.2476 13.8613 15.8204C13.7471 16.3379 13.6292 16.8617 13.5039 17.3584V24.4743C14.1401 24.2943 14.8236 23.948 15.4793 23.2816C17.9419 20.7786 18.3549 14.2296 19.5866 12.9778C19.7261 12.8359 19.877 12.7535 20.0398 12.7535C20.5354 12.7535 20.9187 13.3816 21.2856 14.1371C21.6936 14.9769 22.1783 15.935 23.6082 15.935C24.262 15.935 25.7703 15.4633 25.7703 13.614C25.7703 12.5073 23.9253 8.23596 20.2387 8.23596Z"
					fill="url(#paint9_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint10_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint11_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint12_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint13_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11665 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint14_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="#316AFF"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint15_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint16_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint17_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint18_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint19_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint20_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint21_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint22_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint23_radial_719_13847)"
				/>
				<path
					d="M25.0906 10.7562C24.9244 10.7562 24.7573 10.7719 24.5934 10.8042C25.3726 11.9279 25.7626 13.1052 25.7626 13.6138C25.7626 15.4632 24.2542 15.9349 23.6004 15.9349C23.0902 15.9349 22.7004 15.8129 22.3915 15.6178C22.3886 15.6294 22.3858 15.6409 22.3829 15.6525C22.291 16.0195 22.2185 16.4212 22.093 16.8122C21.8516 17.5638 21.526 18.4224 21.1784 19.0868C20.8036 19.8031 20.4326 20.2221 19.9904 20.2001C19.4874 20.1751 19.0797 19.5269 18.5729 17.6947C18.4095 17.1041 18.2581 16.4563 18.1179 15.8204C17.77 14.243 17.343 12.6567 16.6129 11.255C15.4203 8.96564 13.386 8.23596 11.7619 8.23596C10.2557 8.23596 9.13044 8.94905 8.24644 9.84756C8.00217 10.0959 7.73528 10.4304 7.46729 10.8235C8.23832 11.0092 8.96737 11.5896 9.08374 12.6895C9.10671 12.8992 9.26763 14.5649 9.80821 16.4951C9.9633 16.0112 10.1895 15.2945 10.7148 14.1371C10.9724 13.6072 11.2048 13.2576 11.4144 13.0446C11.6067 12.8491 11.7798 12.7684 11.9358 12.7684C12.1039 12.7684 12.678 12.9223 13.2369 14.8837C13.9301 17.3158 14.4459 21.1864 16.4113 23.2415C17.2781 24.1481 18.4912 24.7346 20.1724 24.7346C21.6476 24.7346 22.8775 24.0848 23.7544 23.1811C25.1962 21.6949 25.9862 19.2378 26.268 18.3286C27.0699 15.7419 27.2946 13.4024 27.3207 13.1549C27.4699 11.7405 26.2784 10.7562 25.0906 10.7562Z"
					fill="url(#paint24_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11672 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint25_linear_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11672 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint26_radial_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11672 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint27_linear_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11672 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint28_linear_719_13847)"
				/>
				<path
					d="M11.6995 24.6692C7.50242 24.6692 6.07389 19.3612 5.75381 18.3286C4.94999 15.7357 4.70563 13.4 4.67976 13.1549C4.5305 11.7405 5.66487 10.7562 6.90986 10.7562C7.85251 10.7562 8.94657 11.3205 9.09153 12.6896C9.11672 12.9199 9.30832 14.906 9.98629 17.07C10.6938 19.3285 11.4444 20.1971 11.9849 20.1971C12.8578 20.1971 13.3903 17.9552 13.8612 15.8204C14.2081 14.2476 14.583 12.6628 15.3233 11.255C16.6112 8.80589 18.5573 8.23596 20.2386 8.23596C23.9252 8.23596 25.7703 12.5073 25.7703 13.614C25.7703 15.4633 24.2619 15.935 23.6081 15.935C22.1783 15.935 21.6936 14.9769 21.2855 14.1371C20.9186 13.3816 20.5353 12.7535 20.0398 12.7535C19.877 12.7535 19.726 12.8359 19.5865 12.9778C18.3548 14.2296 17.9419 20.7786 15.4792 23.2816C14.114 24.6692 12.6285 24.6692 11.6995 24.6692Z"
					fill="url(#paint29_radial_719_13847)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_719_13847"
					x1="23.4738"
					y1="13.0575"
					x2="8.64713"
					y2="17.9199"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#5CEE64" />
					<stop offset="1" stopColor="#00BBFF" />
				</linearGradient>
				<radialGradient
					id="paint1_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(13.294 16.9995) rotate(-120.86) scale(9.71303 8.15396)"
				>
					<stop stopColor="#007383" />
					<stop offset="0.407867" stopColor="#00717E" stopOpacity="0.75" />
					<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint2_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(19.6758 15.1922) rotate(-126.734) scale(7.92135 5.20474)"
				>
					<stop stopColor="#218970" />
					<stop offset="0.459527" stopColor="#267E6A" />
					<stop offset="1" stopColor="#026C51" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint3_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.7347 17.4584) rotate(-107.468) scale(6.4515 3.36105)"
				>
					<stop stopColor="#178697" />
					<stop offset="0.407867" stopColor="#17838F" stopOpacity="0.79" />
					<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint4_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(15.603 17.424) rotate(-120.86) scale(16.3834 5.05372)"
				>
					<stop stopColor="#25342F" stopOpacity="0.3" />
					<stop offset="1" stopColor="#25342F" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint5_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(18.5255 15.3718) rotate(85.0979) scale(6.17047 3.4998)"
				>
					<stop stopColor="#056D4F" />
					<stop offset="0.232593" stopColor="#056C4E" stopOpacity="0.75" />
					<stop offset="0.598902" stopColor="#056C4E" stopOpacity="0.300824" />
					<stop offset="0.874482" stopColor="#056C4F" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint6_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(18.7984 5.39254) rotate(57.0774) scale(7.25351 3.80446)"
				>
					<stop stopColor="#22B67B" />
					<stop offset="0.407867" stopColor="#24BE82" />
					<stop offset="1" stopColor="#24BE82" stopOpacity="0.07" />
				</radialGradient>
				<linearGradient
					id="paint7_linear_719_13847"
					x1="-0.910474"
					y1="16.3092"
					x2="9.59679"
					y2="16.3956"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint8_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(1.34681 6.84522) rotate(49.6506) scale(9.47348 9.44982)"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint9_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8.93538 2.07559) rotate(48.0118) scale(19.2141 19.1838)"
				>
					<stop stopColor="#00B8FF" />
					<stop offset="0.745423" stopColor="#00B9FC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint10_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(9.95264 16.487) rotate(-107.468) scale(9.26141 6.41713)"
				>
					<stop stopColor="#00BCFF" stopOpacity="0.76" />
					<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint11_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(24.0325 12.9695) rotate(-129.551) scale(6.54912 4.58503)"
				>
					<stop stopColor="#5FEC50" />
					<stop offset="1" stopColor="#3CC08E" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint12_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(23.0951 17.1877) rotate(-79.0944) scale(1.98273 1.37134)"
				>
					<stop stopColor="#28855B" />
					<stop offset="1" stopColor="#14795C" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint13_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(22.9995 17.0418) rotate(-77.8798) scale(1.63453 2.28431)"
				>
					<stop stopColor="#28855B" />
					<stop offset="1" stopColor="#14795C" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint14_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(24.9467 14.0971) rotate(-132.79) scale(5.42213 3.80911)"
				>
					<stop stopColor="#5FF05B" />
					<stop offset="0.813285" stopColor="#5DE955" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint15_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(23.2511 15.6546) rotate(37.4559) scale(4.82207 6.70258)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.966119" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint16_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(22.0515 16.198) rotate(8.48611) scale(5.68959 8.49253)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint17_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(22.5131 16.9039) rotate(24.6631) scale(4.86013 8.66026)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.756788" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint18_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(21.9424 17.1918) rotate(95.8028) scale(8.73694 4.78794)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
					<stop offset="1" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint19_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(23.2511 13.3345) rotate(93.6671) scale(10.5147 3.75243)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint20_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(11.3351 15.5207) rotate(84.8296) scale(5.57953 2.95598)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint21_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(11.3311 15.6708) rotate(87.2504) scale(5.82312 4.80751)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint22_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.6559 21.1424) rotate(-23.1725) scale(6.49384 12.6932)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint23_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(10.3454 14.247) rotate(-159.526) scale(4.30226 7.36662)"
				>
					<stop stopColor="#1A3DA0" />
					<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint24_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(6.5583 15.7113) rotate(16.749) scale(8.20559 8.28352)"
				>
					<stop stopColor="#1E3882" />
					<stop offset="0.761404" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint25_linear_719_13847"
					x1="3.98411"
					y1="11.1727"
					x2="12.07"
					y2="16.3335"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.229329" stopColor="#1DC4FF" />
					<stop offset="1" stopColor="#1CC1FF" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint26_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(1.34633 6.84484) rotate(49.6506) scale(9.47348 9.44982)"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint27_linear_719_13847"
					x1="4.43106"
					y1="11.4363"
					x2="6.69912"
					y2="13.4925"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#26FBFF" />
					<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_719_13847"
					x1="3.82848"
					y1="13.0101"
					x2="6.96559"
					y2="13.2339"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#26FBFF" />
					<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint29_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(9.95236 16.4871) rotate(-107.468) scale(9.26141 6.41713)"
				>
					<stop stopColor="#00BCFF" stopOpacity="0.76" />
					<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_4156_8750" maskUnits="userSpaceOnUse" x="5" y="9" width="30" height="22">
				<path d="M35 9H5V30.8364H35V9Z" fill="white" />
			</mask>
			<g mask="url(#mask0_4156_8750)">
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint0_linear_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint1_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint2_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint3_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint4_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint5_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint6_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint7_linear_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint8_radial_4156_8750)"
				/>
				<path
					d="M25.6103 9C23.3851 9 20.8094 9.75432 19.1047 12.9958C18.1249 14.859 17.6287 16.9566 17.1697 19.0382C17.0185 19.7232 16.8625 20.4165 16.6967 21.0739V30.492C17.5387 30.2537 18.4433 29.7953 19.3112 28.9134C22.5705 25.6005 23.1171 16.9328 24.7473 15.276C24.9319 15.0882 25.1317 14.9791 25.3471 14.9791C26.0031 14.9791 26.5103 15.8105 26.996 16.8104C27.536 17.9219 28.1775 19.19 30.07 19.19C30.9353 19.19 32.9316 18.5656 32.9316 16.118C32.9316 14.6532 30.4897 9 25.6103 9Z"
					fill="url(#paint9_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint10_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint11_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint12_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint13_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.8899 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint14_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="#316AFF"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint15_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint16_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint17_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint18_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint19_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint20_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint21_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint22_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint23_radial_4156_8750)"
				/>
				<path
					d="M32.032 12.3356C31.812 12.3356 31.5908 12.3564 31.3738 12.3992C32.4052 13.8864 32.9213 15.4445 32.9213 16.1178C32.9213 18.5655 30.9249 19.1898 30.0596 19.1898C29.3844 19.1898 28.8685 19.0283 28.4596 18.7701C28.4557 18.7855 28.4521 18.8006 28.4482 18.816C28.3266 19.3017 28.2306 19.8334 28.0645 20.3509C27.7451 21.3456 27.3141 22.482 26.854 23.3614C26.358 24.3094 25.8669 24.864 25.2817 24.8349C24.616 24.8019 24.0763 23.9438 23.4056 21.519C23.1893 20.7372 22.989 19.8799 22.8034 19.0382C22.3429 16.9504 21.7777 14.851 20.8114 12.9958C19.233 9.96575 16.5406 9 14.391 9C12.3976 9 10.9082 9.94379 9.7382 11.133C9.41491 11.4616 9.06167 11.9044 8.70697 12.4247C9.72745 12.6705 10.6924 13.4387 10.8464 14.8944C10.8768 15.172 11.0898 17.3765 11.8053 19.9312C12.0105 19.2907 12.3098 18.3421 13.0052 16.8104C13.346 16.1089 13.6536 15.6463 13.9311 15.3644C14.1856 15.1056 14.4147 14.9988 14.6211 14.9988C14.8437 14.9988 15.6035 15.2025 16.3432 17.7984C17.2606 21.0174 17.9434 26.1403 20.5446 28.8603C21.6919 30.0601 23.2974 30.8364 25.5226 30.8364C27.475 30.8364 29.1028 29.9765 30.2634 28.7804C32.1717 26.8133 33.2173 23.5613 33.5903 22.358C34.6517 18.9343 34.9491 15.8379 34.9836 15.5103C35.1811 13.6383 33.6041 12.3356 32.032 12.3356Z"
					fill="url(#paint24_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.89 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint25_linear_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.89 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint26_radial_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.89 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint27_linear_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.89 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint28_linear_4156_8750)"
				/>
				<path
					d="M14.3084 30.7499C8.75341 30.7499 6.86272 23.7246 6.43908 22.358C5.3752 18.9261 5.05179 15.8347 5.01755 15.5103C4.81999 13.6383 6.32136 12.3356 7.96915 12.3356C9.21677 12.3356 10.6648 13.0825 10.8567 14.8946C10.89 15.1993 11.1436 17.828 12.0409 20.6922C12.9773 23.6813 13.9707 24.831 14.6861 24.831C15.8414 24.831 16.5462 21.8637 17.1694 19.0382C17.6285 16.9566 18.1248 14.859 19.1046 12.9958C20.8091 9.75432 23.3849 9 25.6101 9C30.4894 9 32.9315 14.6532 32.9315 16.118C32.9315 18.5656 30.9351 19.19 30.0698 19.19C28.1773 19.19 27.5358 17.9219 26.9958 16.8104C26.5101 15.8105 26.0028 14.9791 25.347 14.9791C25.1315 14.9791 24.9317 15.0882 24.747 15.276C23.1169 16.9328 22.5703 25.6005 19.3109 28.9134C17.504 30.7499 15.538 30.7499 14.3084 30.7499Z"
					fill="url(#paint29_radial_4156_8750)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_4156_8750"
					x1="29.892"
					y1="15.3814"
					x2="10.2685"
					y2="21.817"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#5CEE64" />
					<stop offset="1" stopColor="#00BBFF" />
				</linearGradient>
				<radialGradient
					id="paint1_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(16.4188 20.5988) rotate(-120.86) scale(12.8555 10.792)"
				>
					<stop stopColor="#007383" />
					<stop offset="0.407867" stopColor="#00717E" stopOpacity="0.75" />
					<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint2_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(24.8652 18.2068) rotate(-126.734) scale(10.4841 6.88862)"
				>
					<stop stopColor="#218970" />
					<stop offset="0.459527" stopColor="#267E6A" />
					<stop offset="1" stopColor="#026C51" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint3_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(15.6785 21.2061) rotate(-107.468) scale(8.53875 4.44845)"
				>
					<stop stopColor="#178697" />
					<stop offset="0.407867" stopColor="#17838F" stopOpacity="0.79" />
					<stop offset="0.874482" stopColor="#007281" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint4_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(19.4748 21.1606) rotate(-120.86) scale(21.6839 6.68874)"
				>
					<stop stopColor="#25342F" stopOpacity="0.3" />
					<stop offset="1" stopColor="#25342F" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint5_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(23.3427 18.4446) rotate(85.0979) scale(8.1668 4.6321)"
				>
					<stop stopColor="#056D4F" />
					<stop offset="0.232593" stopColor="#056C4E" stopOpacity="0.75" />
					<stop offset="0.598902" stopColor="#056C4E" stopOpacity="0.300824" />
					<stop offset="0.874482" stopColor="#056C4F" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint6_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(23.704 5.23664) rotate(57.0774) scale(9.60023 5.03531)"
				>
					<stop stopColor="#22B67B" />
					<stop offset="0.407867" stopColor="#24BE82" />
					<stop offset="1" stopColor="#24BE82" stopOpacity="0.07" />
				</radialGradient>
				<linearGradient
					id="paint7_linear_4156_8750"
					x1="-2.3813"
					y1="19.6852"
					x2="11.5254"
					y2="19.7995"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint8_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(0.606292 7.15932) rotate(49.6506) scale(12.5384 12.5071)"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint9_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(10.6501 0.846567) rotate(48.0118) scale(25.4304 25.3903)"
				>
					<stop stopColor="#00B8FF" />
					<stop offset="0.745423" stopColor="#00B9FC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint10_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(11.9964 19.9205) rotate(-107.468) scale(12.2578 8.49327)"
				>
					<stop stopColor="#00BCFF" stopOpacity="0.76" />
					<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint11_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(30.6315 15.265) rotate(-129.551) scale(8.66795 6.06842)"
				>
					<stop stopColor="#5FEC50" />
					<stop offset="1" stopColor="#3CC08E" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint12_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(29.3908 20.8479) rotate(-79.0944) scale(2.6242 1.81501)"
				>
					<stop stopColor="#28855B" />
					<stop offset="1" stopColor="#14795C" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint13_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(29.2643 20.6548) rotate(-77.8798) scale(2.16335 3.02336)"
				>
					<stop stopColor="#28855B" />
					<stop offset="1" stopColor="#14795C" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint14_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(31.8415 16.7574) rotate(-132.79) scale(7.17635 5.04146)"
				>
					<stop stopColor="#5FF05B" />
					<stop offset="0.813285" stopColor="#5DE955" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint15_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(29.5973 18.8187) rotate(37.4559) scale(6.38215 8.87106)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.966119" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint16_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(28.0096 19.5381) rotate(8.48611) scale(7.53034 11.2401)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint17_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(28.6206 20.4722) rotate(24.6631) scale(6.43252 11.4621)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.756788" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint18_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(27.8652 20.8533) rotate(95.8028) scale(11.5636 6.33698)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.592487" stopColor="#123AA8" stopOpacity="0" />
					<stop offset="1" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint19_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(29.5973 15.748) rotate(93.6671) scale(13.9165 4.96645)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint20_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(13.8261 18.6416) rotate(84.8296) scale(7.38468 3.91233)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint21_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(13.8208 18.8402) rotate(87.2504) scale(7.70707 6.36288)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.884611" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint22_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(15.5743 26.0821) rotate(-23.1725) scale(8.59478 16.7998)"
				>
					<stop stopColor="#123AA8" />
					<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint23_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.5162 16.9558) rotate(-159.526) scale(5.69417 9.74994)"
				>
					<stop stopColor="#1A3DA0" />
					<stop offset="0.708977" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint24_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.5039 18.8939) rotate(16.749) scale(10.8603 10.9635)"
				>
					<stop stopColor="#1E3882" />
					<stop offset="0.761404" stopColor="#123AA8" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint25_linear_4156_8750"
					x1="4.09683"
					y1="12.8869"
					x2="14.7987"
					y2="19.7173"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.229329" stopColor="#1DC4FF" />
					<stop offset="1" stopColor="#1CC1FF" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint26_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(0.60565 7.15881) rotate(49.6506) scale(12.5384 12.5071)"
				>
					<stop stopColor="#09F8FF" />
					<stop offset="1" stopColor="#0BF7F9" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint27_linear_4156_8750"
					x1="4.68838"
					y1="13.2358"
					x2="7.69022"
					y2="15.9571"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#26FBFF" />
					<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_4156_8750"
					x1="3.89084"
					y1="15.3187"
					x2="8.04291"
					y2="15.6149"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#26FBFF" />
					<stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint29_radial_4156_8750"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(11.996 19.9206) rotate(-107.468) scale(12.2578 8.49327)"
				>
					<stop stopColor="#00BCFF" stopOpacity="0.76" />
					<stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	);
};
