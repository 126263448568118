import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const PagerDutySearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-259.5"
				y="-646.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#04AB38"
			/>
			<path
				d="M9.78616 12.0784C9.78635 12.0782 9.78645 12.078 9.78645 12.0777C9.78645 10.145 9.78763 8.21234 9.78411 6.27966C9.78411 6.10613 9.78176 5.99826 10.0198 6.00002C12.4115 6.01409 14.8033 5.9965 17.195 6.02523C18.5486 6.04164 19.84 6.36349 21.006 7.07811C22.434 7.95219 23.2295 9.25421 23.4821 10.8916C23.753 12.6473 23.4681 14.2941 22.3889 15.7409C21.4708 16.9726 20.1917 17.6233 18.7045 17.9106C17.7795 18.0894 16.8433 18.1128 15.9077 18.1199C13.9556 18.1351 12.0029 18.1216 10.0502 18.1322C9.8187 18.1333 9.78059 18.0548 9.78176 17.8478C9.7888 15.9251 9.78587 14.0018 9.78587 12.0791C9.78587 12.0788 9.78597 12.0786 9.78616 12.0784Z"
				fill="#FEFEFE"
			/>
			<path
				d="M9.80268 23.3091C9.80268 22.5095 9.80678 21.7093 9.79916 20.9097C9.79741 20.7356 9.81909 20.6541 10.0295 20.6576C10.8584 20.6716 11.6879 20.6699 12.5168 20.6576C12.7091 20.6546 12.7367 20.7221 12.7361 20.8909C12.7302 22.5201 12.7291 24.1492 12.7373 25.7778C12.7384 25.9671 12.6775 26.0005 12.5057 25.9988C11.6768 25.9906 10.8473 25.9882 10.0184 25.9999C9.82613 26.0029 9.79741 25.936 9.79916 25.7666C9.80737 24.9471 9.80268 24.1281 9.80268 23.3085V23.3091Z"
				fill="#FEFEFE"
			/>
			<path
				d="M12.7242 11.9952C12.7242 10.9112 12.7265 9.82788 12.7212 8.74393C12.7206 8.58388 12.7353 8.50123 12.9358 8.5024C14.2735 8.51236 15.6113 8.48598 16.9484 8.51705C17.7111 8.53464 18.4544 8.70699 19.1285 9.09332C20.0137 9.60042 20.4393 10.3948 20.529 11.3873C20.5952 12.123 20.563 12.8493 20.247 13.5352C19.7429 14.6297 18.8049 15.1222 17.69 15.3614C17.1923 15.468 16.6858 15.4927 16.1775 15.4927C15.1229 15.4921 14.0684 15.4827 13.0143 15.4997C12.7576 15.5038 12.716 15.42 12.7189 15.1884C12.7312 14.1244 12.7242 13.0598 12.7242 11.9958V11.9952Z"
				fill="#04AB38"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M34.9953 20.0003C34.9953 24.9187 34.9941 29.8378 35.0006 34.7562C35.0006 34.9484 34.973 35.0006 34.765 35C24.927 34.993 15.0883 34.993 5.2502 35C5.0293 35.0006 5 34.9432 5 34.7416C5.00586 24.9141 5.00586 15.0865 5 5.25899C5 5.05801 5.02813 5 5.2502 5C15.0789 5.00762 24.907 5.00762 34.7357 5C34.9637 5 35.0012 5.05977 35.0012 5.27305C34.9947 10.1821 34.9959 15.0906 34.9959 19.9997L34.9953 20.0003Z"
				fill="#04AB38"
			/>
			<path
				d="M13.7745 16.0467C13.7745 14.1148 13.7757 12.183 13.7722 10.2511C13.7722 10.0776 13.7699 9.96982 14.0078 9.97158C16.3984 9.98564 18.7891 9.96806 21.1798 9.99677C22.5327 10.0132 23.8236 10.3349 24.989 11.0491C26.4164 11.9228 27.2115 13.2242 27.4641 14.8607C27.7348 16.6157 27.45 18.2616 26.3713 19.7077C25.4537 20.9388 24.1751 21.5892 22.6886 21.8763C21.764 22.055 20.8282 22.0785 19.893 22.0855C17.9418 22.1007 15.99 22.0872 14.0382 22.0978C13.8068 22.099 13.7687 22.0204 13.7699 21.8136C13.7769 19.8917 13.774 17.9692 13.774 16.0473L13.7745 16.0467Z"
				fill="#FEFEFE"
			/>
			<path
				d="M13.791 27.2719C13.791 26.4727 13.7951 25.6729 13.7874 24.8737C13.7857 24.6996 13.8074 24.6182 14.0177 24.6217C14.8463 24.6358 15.6754 24.634 16.5039 24.6217C16.6961 24.6188 16.7236 24.6861 16.723 24.8549C16.7172 26.4833 16.716 28.1116 16.7242 29.7394C16.7254 29.9286 16.6645 29.962 16.4928 29.9603C15.6642 29.9521 14.8351 29.9497 14.0066 29.9614C13.8144 29.9644 13.7857 29.8976 13.7874 29.7282C13.7956 28.9091 13.791 28.0905 13.791 27.2714V27.2719Z"
				fill="#FEFEFE"
			/>
			<path
				d="M16.7108 16.0473C16.7108 14.9639 16.7131 13.8811 16.7079 12.7976C16.7073 12.6377 16.7219 12.5551 16.9223 12.5562C18.2595 12.5662 19.5966 12.5398 20.9331 12.5709C21.6955 12.5885 22.4384 12.7607 23.1123 13.1469C23.9971 13.6537 24.4225 14.4477 24.5121 15.4397C24.5783 16.1751 24.5461 16.901 24.2303 17.5866C23.7264 18.6806 22.7888 19.1728 21.6744 19.4118C21.1769 19.5185 20.6706 19.5431 20.1626 19.5431C19.1085 19.5425 18.0544 19.5331 17.0008 19.5501C16.7442 19.5542 16.7026 19.4704 16.7055 19.239C16.7178 18.1755 16.7108 17.1114 16.7108 16.0479V16.0473Z"
				fill="#04AB38"
			/>
		</svg>
	);
};
