import React from 'react';

export const AWSS3Icon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<title>Icon-Architecture/64/Arch_Amazon-Simple-Storage-Service_64</title>
		<g
			id="Icon-Architecture/64/Arch_Amazon-Simple-Storage-Service_64"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			width="24"
			height="24"
		>
			<g id="Icon-Architecture-BG/64/Storage" fill="#7AA116">
				<rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
			</g>
			<g
				id="Icon-Service/64/Amazon-Simple-Storage-Service_64"
				transform="scale(0.3) translate(12.999900, 11.999600)"
				fill="#FFFFFF"
				width="24"
				height="24"
			>
				<path
					d="M47.836,30.893 L48.22,28.189 C51.761,30.31 51.807,31.186 51.8060132,31.21 C51.8,31.215 51.196,31.719 47.836,30.893 L47.836,30.893 Z M45.893,30.353 C39.773,28.501 31.25,24.591 27.801,22.961 C27.801,22.947 27.805,22.934 27.805,22.92 C27.805,21.595 26.727,20.517 25.401,20.517 C24.077,20.517 22.999,21.595 22.999,22.92 C22.999,24.245 24.077,25.323 25.401,25.323 C25.983,25.323 26.511,25.106 26.928,24.761 C30.986,26.682 39.443,30.535 45.608,32.355 L43.17,49.561 C43.163,49.608 43.16,49.655 43.16,49.702 C43.16,51.217 36.453,54 25.494,54 C14.419,54 7.641,51.217 7.641,49.702 C7.641,49.656 7.638,49.611 7.632,49.566 L2.538,12.359 C6.947,15.394 16.43,17 25.5,17 C34.556,17 44.023,15.4 48.441,12.374 L45.893,30.353 Z M2,8.478 C2.072,7.162 9.634,2 25.5,2 C41.364,2 48.927,7.161 49,8.478 L49,8.927 C48.13,11.878 38.33,15 25.5,15 C12.648,15 2.843,11.868 2,8.913 L2,8.478 Z M51,8.5 C51,5.035 41.066,0 25.5,0 C9.934,0 0,5.035 0,8.5 L0.094,9.254 L5.642,49.778 C5.775,54.31 17.861,56 25.494,56 C34.966,56 45.029,53.822 45.159,49.781 L47.555,32.884 C48.888,33.203 49.985,33.366 50.866,33.366 C52.049,33.366 52.849,33.077 53.334,32.499 C53.732,32.025 53.884,31.451 53.77,30.84 C53.511,29.456 51.868,27.964 48.522,26.055 L50.898,9.293 L51,8.5 Z"
					id="Amazon-Simple-Storage-Service-Icon_64_Squid"
				></path>
			</g>
		</g>
	</svg>
);
