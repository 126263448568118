import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AdobeXDSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-163.5"
				y="-550.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#470137"
			/>
			<path
				d="M16.7738 9.87271L13.0121 16.2385L17.0245 22.9902C17.0496 23.0416 17.0621 23.0931 17.0496 23.1445C17.0371 23.1959 16.9869 23.1574 16.9117 23.1702H14.0403C13.8397 23.1702 13.7018 23.1574 13.614 23.0288C13.3507 22.4886 13.0748 21.9614 12.8115 21.4212C12.5482 20.894 12.2598 20.3538 11.9589 19.8008C11.6579 19.2478 11.357 18.6948 11.0561 18.129H11.031C10.7677 18.682 10.4793 19.235 10.1909 19.788C9.90252 20.341 9.61413 20.894 9.33828 21.4341C9.04989 21.9742 8.76149 22.5272 8.4731 23.0545C8.42295 23.1831 8.32264 23.1959 8.18471 23.1959H5.42619C5.37603 23.1959 5.33842 23.2217 5.33842 23.1574C5.32588 23.1059 5.33842 23.0545 5.36349 23.0159L9.26304 16.4572L5.4638 9.85985C5.42619 9.80841 5.41365 9.75697 5.43872 9.73125C5.4638 9.69267 5.51396 9.67981 5.56411 9.67981H8.41041C8.4731 9.67981 8.5358 9.69267 8.58595 9.70553C8.63611 9.73125 8.67372 9.76983 8.71134 9.82127C8.94958 10.3743 9.22543 10.9273 9.51382 11.4802C9.81475 12.0332 10.1031 12.5734 10.4166 13.1135C10.7175 13.6536 10.9934 14.1938 11.2567 14.7468H11.2818C11.5451 14.1809 11.8209 13.6279 12.0968 13.0878C12.3727 12.5476 12.661 12.0075 12.9494 11.4674C13.2378 10.9273 13.5137 10.3743 13.7895 9.84699C13.8021 9.79555 13.8271 9.74411 13.8648 9.71839C13.9149 9.69267 13.9651 9.67981 14.0278 9.69267H16.6734C16.7361 9.67981 16.7988 9.71839 16.8114 9.78269C16.8239 9.79555 16.7988 9.84699 16.7738 9.87271Z"
				fill="#FF61F6"
			/>
			<path
				d="M22.5666 23.4404C21.6388 23.4533 20.7109 23.2604 19.8708 22.8617C19.0809 22.4887 18.4289 21.8714 17.9775 21.1127C17.5135 20.3282 17.2878 19.3508 17.2878 18.1806C17.2753 17.2289 17.5135 16.2901 17.9775 15.467C18.4539 14.6311 19.1436 13.9367 19.9711 13.4737C20.8488 12.9721 21.9021 12.7278 23.1434 12.7278C23.2061 12.7278 23.2939 12.7278 23.4067 12.7407C23.615 12.7644 23.7954 12.5864 23.7954 12.3768V8.70254C23.7954 8.61252 23.8331 8.56108 23.9208 8.56108H26.3882C26.4401 8.56108 26.4976 8.54735 26.5405 8.5766C26.5588 8.58915 26.5735 8.60725 26.5829 8.62694C26.5903 8.64235 26.5916 8.67682 26.5916 8.67682C26.5916 8.67682 26.5916 8.67682 26.5916 8.67682V20.9198C26.5916 21.1513 26.6041 21.4085 26.6167 21.6914C26.6417 21.9615 26.6543 22.2187 26.6668 22.4373C26.6668 22.5273 26.6292 22.6045 26.5414 22.6431C25.8894 22.926 25.1998 23.1318 24.4976 23.2604C23.8581 23.3761 23.2187 23.4404 22.5666 23.4404ZM23.3919 20.9354C23.6393 20.9437 23.7954 20.7021 23.7954 20.4546V15.5546C23.7954 15.3529 23.6573 15.1684 23.4569 15.1455C23.319 15.1327 23.181 15.1198 23.0431 15.1198C22.5541 15.1198 22.0651 15.2227 21.6262 15.4542C21.1999 15.6728 20.8363 15.9943 20.5604 16.4058C20.2846 16.8174 20.1467 17.3704 20.1467 18.0391C20.1341 18.4892 20.2094 18.9393 20.3598 19.3637C20.4852 19.7109 20.6733 20.0196 20.9241 20.2768C21.1623 20.5083 21.4507 20.6883 21.7767 20.7912C22.1152 20.9069 22.4663 20.9584 22.8174 20.9584C22.9881 20.9584 23.1485 20.9478 23.2985 20.9362C23.3288 20.9339 23.3616 20.9343 23.3919 20.9354Z"
				fill="#FF61F6"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.3125 5H29.6875C32.625 5 35 7.375 35 10.3125V28.9375C35 31.875 32.625 34.25 29.6875 34.25H10.3125C7.375 34.25 5 31.875 5 28.9375V10.3125C5 7.375 7.375 5 10.3125 5Z"
				fill="#470137"
			/>
			<path
				d="M20.775 12.6876L17.025 18.8751L21.025 25.4376C21.05 25.4876 21.0625 25.5376 21.05 25.5876C21.0375 25.6376 20.9875 25.6001 20.9125 25.6126H18.05C17.85 25.6126 17.7125 25.6001 17.625 25.4751C17.3625 24.9501 17.0875 24.4376 16.825 23.9126C16.5625 23.4001 16.275 22.8751 15.975 22.3376C15.675 21.8001 15.375 21.2626 15.075 20.7126H15.05C14.7875 21.2501 14.5 21.7876 14.2125 22.3251C13.925 22.8626 13.6375 23.4001 13.3625 23.9251C13.075 24.4501 12.7875 24.9876 12.5 25.5001C12.45 25.6251 12.35 25.6376 12.2125 25.6376H9.46246C9.41246 25.6376 9.37496 25.6626 9.37496 25.6001C9.36246 25.5501 9.37496 25.5001 9.39996 25.4626L13.2875 19.0876L9.49996 12.6751C9.46246 12.6251 9.44996 12.5751 9.47496 12.5501C9.49996 12.5126 9.54996 12.5001 9.59996 12.5001H12.4375C12.5 12.5001 12.5625 12.5126 12.6125 12.5251C12.6625 12.5501 12.7 12.5876 12.7375 12.6376C12.975 13.1751 13.25 13.7126 13.5375 14.2501C13.8375 14.7876 14.125 15.3126 14.4375 15.8376C14.7375 16.3626 15.0125 16.8876 15.275 17.4251H15.3C15.5625 16.8751 15.8375 16.3376 16.1125 15.8126C16.3875 15.2876 16.675 14.7626 16.9625 14.2376C17.25 13.7126 17.525 13.1751 17.8 12.6626C17.8125 12.6126 17.8375 12.5626 17.875 12.5376C17.925 12.5126 17.975 12.5001 18.0375 12.5126H20.675C20.7375 12.5001 20.8 12.5376 20.8125 12.6001C20.825 12.6126 20.8 12.6626 20.775 12.6876Z"
				fill="#FF61F6"
			/>
			<path
				d="M26.55 25.8753C25.625 25.8878 24.7 25.7003 23.8625 25.3128C23.075 24.9503 22.425 24.3503 21.975 23.6128C21.5125 22.8503 21.2875 21.9003 21.2875 20.7628C21.275 19.8378 21.5125 18.9253 21.975 18.1253C22.45 17.3128 23.1375 16.6378 23.9625 16.1878C24.8375 15.7003 25.8875 15.4628 27.125 15.4628C27.1875 15.4628 27.275 15.4628 27.3875 15.4753C27.5 15.4878 27.625 15.4878 27.775 15.5003V11.5503C27.775 11.4628 27.8125 11.4128 27.9 11.4128H30.4375C30.5 11.4003 30.55 11.4503 30.5625 11.5003C30.5625 11.5128 30.5625 11.5253 30.5625 11.5253V23.4253C30.5625 23.6503 30.575 23.9003 30.5875 24.1753C30.6125 24.4378 30.625 24.6878 30.6375 24.9003C30.6375 24.9878 30.6 25.0628 30.5125 25.1003C29.8625 25.3753 29.175 25.5753 28.475 25.7003C27.8375 25.8128 27.2 25.8753 26.55 25.8753ZM27.775 23.3753V17.8753C27.6625 17.8503 27.55 17.8253 27.4375 17.8128C27.3 17.8003 27.1625 17.7878 27.025 17.7878C26.5375 17.7878 26.05 17.8878 25.6125 18.1128C25.1875 18.3253 24.825 18.6378 24.55 19.0378C24.275 19.4378 24.1375 19.9753 24.1375 20.6253C24.125 21.0628 24.2 21.5003 24.35 21.9128C24.475 22.2503 24.6625 22.5503 24.9125 22.8003C25.15 23.0253 25.4375 23.2003 25.7625 23.3003C26.1 23.4128 26.45 23.4628 26.8 23.4628C26.9875 23.4628 27.1625 23.4503 27.325 23.4378C27.4875 23.4503 27.625 23.4253 27.775 23.3753Z"
				fill="#FF61F6"
			/>
		</svg>
	);
};
