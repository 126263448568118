import React from 'react';

export const AtlasSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18710)">
			<g clipPath="url(#clip1_2230_18710)">
				<g clipPath="url(#clip2_2230_18710)">
					<path
						d="M34.4312 30.4727L21.2001 4.01056C20.9397 3.43756 20.6792 3.33337 20.3667 3.33337C20.1062 3.33337 19.7937 3.43756 19.4811 3.95846C17.6058 6.92764 16.7724 10.3656 16.7724 13.9599C16.7724 18.9606 19.3248 23.6488 23.0754 31.202C23.4921 32.0354 23.8047 32.1917 24.5339 32.1917H33.7019C34.3791 32.1917 34.7437 31.9313 34.7437 31.4103C34.7437 31.1499 34.6916 30.9936 34.4312 30.4727ZM15.2097 17.9709C14.1679 16.4082 13.8553 16.304 13.5949 16.304C13.3344 16.304 13.1781 16.4082 12.7093 17.3458L6.09377 30.5769C5.8854 30.9936 5.83331 31.1499 5.83331 31.3583C5.83331 31.775 6.19795 32.1917 6.97931 32.1917H16.3036C16.9287 32.1917 17.3975 31.6708 17.6579 30.6811C17.9705 29.4309 18.0747 28.337 18.0747 27.0347C18.0747 23.3884 16.4598 19.8462 15.2097 17.9709Z"
						fill="#1868DB"
					/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18710">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18710">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip2_2230_18710">
				<rect
					width="28.9104"
					height="28.8583"
					fill="white"
					transform="translate(5.83331 3.33337)"
				/>
			</clipPath>
		</defs>
	</svg>
);
