import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const metadataSpacerStyles = xcss({
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

export const Spacer = () => (
	<Box as="span" xcss={metadataSpacerStyles}>
		•
	</Box>
);

Spacer.IS_METADATA_SPACER = true;
