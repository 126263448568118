import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const HubSpotFilterIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-587.5"
				y="-411.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#FF5C35" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.2949 7.36118V9.2655C18.0986 9.53156 19.5395 10.8388 19.9091 12.5445C20.2788 14.2502 19.4994 15.9955 17.9542 16.9226C16.409 17.8496 14.4229 17.7632 12.9725 16.7059L11.3506 18.2537C11.3927 18.3793 11.4149 18.5101 11.4169 18.6419C11.4169 19.1911 11.0701 19.6864 10.5383 19.8966C10.0065 20.1068 9.39443 19.9906 8.98742 19.6022C8.5804 19.2138 8.45864 18.6296 8.67892 18.1221C8.8992 17.6147 9.41812 17.2837 9.99372 17.2837C10.1318 17.2857 10.2689 17.307 10.4005 17.3469L12.0399 15.7824C11.0063 14.3791 10.9796 12.5146 11.9727 11.0849L6.58545 7.08265C6.32553 7.22716 6.03031 7.30382 5.72949 7.30488C4.77318 7.30379 3.99886 6.56308 4 5.65047C4.00115 4.73785 4.77732 3.99891 5.73363 4C6.68995 4.00109 7.46428 4.7418 7.46314 5.65442C7.4609 5.79367 7.44004 5.93209 7.40104 6.06629L12.8803 10.1347C13.4781 9.67598 14.1922 9.37614 14.9504 9.2655V7.36118C14.4121 7.12126 14.0671 6.60659 14.0644 6.03963V5.99518C14.0667 5.18711 14.7525 4.53257 15.5993 4.5304H15.6459C16.4927 4.53257 17.1785 5.18711 17.1808 5.99518V6.03963C17.1782 6.60659 16.8331 7.12126 16.2949 7.36118ZM13.3789 13.3881C13.3789 14.5707 14.3835 15.5295 15.6228 15.5295C16.8621 15.5295 17.8667 14.5707 17.8667 13.3881C17.8667 12.2055 16.8621 11.2467 15.6228 11.2467C14.3835 11.2467 13.3789 12.2055 13.3789 13.3881Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.2949 7.36118V9.2655C18.0986 9.53156 19.5395 10.8388 19.9091 12.5445C20.2788 14.2502 19.4994 15.9955 17.9542 16.9226C16.409 17.8496 14.4229 17.7632 12.9725 16.7059L11.3506 18.2537C11.3927 18.3793 11.4149 18.5101 11.4169 18.6419C11.4169 19.1911 11.0701 19.6864 10.5383 19.8966C10.0065 20.1068 9.39443 19.9906 8.98742 19.6022C8.5804 19.2138 8.45864 18.6296 8.67892 18.1221C8.8992 17.6147 9.41812 17.2837 9.99372 17.2837C10.1318 17.2857 10.2689 17.307 10.4005 17.3469L12.0399 15.7824C11.0063 14.3791 10.9796 12.5146 11.9727 11.0849L6.58545 7.08265C6.32553 7.22716 6.03031 7.30382 5.72949 7.30488C4.77318 7.30379 3.99886 6.56308 4 5.65047C4.00115 4.73785 4.77732 3.99891 5.73363 4C6.68995 4.00109 7.46428 4.7418 7.46314 5.65442C7.4609 5.79367 7.44004 5.93209 7.40104 6.06629L12.8803 10.1347C13.4781 9.67598 14.1922 9.37614 14.9504 9.2655V7.36118C14.4121 7.12126 14.0671 6.60659 14.0644 6.03963V5.99518C14.0667 5.18711 14.7525 4.53257 15.5993 4.5304H15.6459C16.4927 4.53257 17.1785 5.18711 17.1808 5.99518V6.03963C17.1782 6.60659 16.8331 7.12126 16.2949 7.36118ZM13.3789 13.3881C13.3789 14.5707 14.3835 15.5295 15.6228 15.5295C16.8621 15.5295 17.8667 14.5707 17.8667 13.3881C17.8667 12.2055 16.8621 11.2467 15.6228 11.2467C14.3835 11.2467 13.3789 12.2055 13.3789 13.3881Z"
				fill="#FF5C35"
			/>
		</svg>
	);
};
