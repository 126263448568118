export const ExternalBranchPartial = `
... on ExternalBranch {
    name
    container @optIn(to: "ExternalDataDepotQueryV2") {
        ... on ExternalRepository {
            name
        }
    }
}
`;
