import { useEffect, useState } from 'react';

type SubscribeFn = (listener: () => void) => () => void;
type GetSnapshotFn = () => any;

const useExternalStore = (subscribe: SubscribeFn, getSnapshot: GetSnapshotFn) => {
	const [state, setState] = useState(getSnapshot());
	useEffect(
		() =>
			subscribe(() => {
				const snapshot = getSnapshot();
				setState(snapshot);
			}),
		[subscribe, getSnapshot],
	);

	return state;
};

export default useExternalStore;
