import { fg } from '@atlaskit/platform-feature-flags';

import type { CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createSingleSelectFilter } from '../../../../filters/single-select-filter';

import { getAllPageStatusOptions } from './utils';

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('pageStatus');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

const loadQueryParams = (queryParams: string[], config: CloudConfig) => {
	const allOptions = getAllPageStatusOptions(config);
	return allOptions
		.filter((option) => !option.isDefault)
		.filter((option) => queryParams.includes(option.queryParamValue));
};

const getInitialOptions = (config: CloudConfig) => {
	const allOptions = getAllPageStatusOptions(config);
	return allOptions;
};

export const filter = createSingleSelectFilter({
	id: 'pageStatus',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'pageStatus',
		fetcher: async (queryParams, config) => {
			return loadQueryParams(queryParams, config);
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return getInitialOptions(config);
		},
	},
});
