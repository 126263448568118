import React, { type PropsWithChildren } from 'react';

import { type ApplicationModes } from '../../common/constants/application-modes';
import { useApplicationMode } from '../store';

interface EligibleOnlyProps {
	requiredMode: ApplicationModes;
}

/**
 * A component to control rendering of Rovo/Unified Search components.
 * To be used when a component/experience is only available in a specific application mode.
 *
 * Example usage:
 * <ShowIfEligible requiredMode={ApplicationModes.Rovo}>
 *   <SomeCoolRovoFeature />
 * </ShowIfEligible>
 */
const ShowIfEligible = ({ requiredMode, children }: PropsWithChildren<EligibleOnlyProps>) => {
	const currentMode = useApplicationMode();

	if (currentMode === requiredMode) {
		return <>{children}</>;
	}

	return null;
};

export default ShowIfEligible;
