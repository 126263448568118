import type {
	BooleanFilterDeps,
	BooleanFilterState,
	BooleanFilterType,
	BooleanFilterTypeFunc,
	BooleanFilterViewState,
	CreateBooleanFilter,
	CreatedBooleanFilter,
} from '../../../common/constants/filters/boolean-filter/types';
import { type CloudConfig } from '../../../common/constants/filters/cloud-config/types';
import { useBooleanFilterStore } from '../../store/search';

export const useBooleanFilter = ({ filterId }: { filterId: string }) => {
	const [state, storeActions] = useBooleanFilterStore({
		filterId,
	});

	const viewActions = {
		toggle: () => {
			if (!state) {
				return;
			}

			storeActions.setBooleanFilterValue({ filterId, value: !state.value });
		},
	};

	if (!state) {
		return [, viewActions] satisfies [BooleanFilterViewState | undefined, typeof viewActions];
	}

	const viewState = {
		value: state.value,
		autoFocus: state.alwaysVisible === false,
	} satisfies BooleanFilterViewState;

	return [viewState, viewActions] satisfies [BooleanFilterViewState, typeof viewActions];
};

export const createBooleanFilter = <ID extends string>(
	initial: CreateBooleanFilter<ID>,
): CreatedBooleanFilter<ID> => {
	const initialState = {
		...initial,
		type: 'boolean',
		value: false,
		hasSelection: false,
		universal: initial.universal,
	} satisfies BooleanFilterState<ID>;

	return {
		id: initial.id,
		type: initialState.type,
		products: initial.products,
		universal: initial.universal,
		queryParams: { key: initialState.queryParams.key },
		customValueFields: initial.customValueFields,
		withState: (
			state: unknown, // To support polymorphic function calls from the Store
			config: CloudConfig,
		) =>
			BooleanFilter({
				initial,
				state: state as BooleanFilterState<ID>, // This has to be cast back to the correct state
				config,
			}),
		getInitialState: (config: CloudConfig) =>
			BooleanFilter({ initial, state: initialState, config }),
		isCompatible: (id) => id === initial.id,
		isSupportCustomValue: false,
	};
};

export const BooleanFilter: BooleanFilterType = ({ initial, state, config }) => {
	const filterState = {
		...state,
		hasSelection: state.value,
	};

	return {
		...filterState,
		setValue: setValue({ initial, state: filterState, config }),
		getValue: () => filterState.value,
		getState: () => filterState,
		queryParams: {
			key: initial.queryParams.key,
			get: getQueryParams({ initial, state: filterState, config }),
			set: setQueryParams({ initial, state: filterState, config }),
		},
		clear: clear({ initial, state: filterState, config }),
	};
};

const setValue = <ID extends string>({ initial, state, config }: BooleanFilterDeps<ID>) => {
	return (value: boolean) => {
		const newState = {
			...state,
			value,
			hasSelection: value,
		} satisfies BooleanFilterState<ID>;

		return BooleanFilter({ initial, state: newState, config });
	};
};

const getQueryParams = <ID extends string>({ initial, state }: BooleanFilterDeps<ID>) => {
	return () => ({
		[initial.queryParams.key]: state.value === true ? ['true'] : undefined,
	});
};

const setQueryParams = <ID extends string>({ initial, state, config }: BooleanFilterDeps<ID>) => {
	return async (
		queryParams: Record<string, string[] | undefined>,
	): Promise<ReturnType<BooleanFilterTypeFunc<ID>>> => {
		const queryParamValue = queryParams[initial.queryParams.key];
		const booleanValue = queryParamValue?.at(0) === 'true' ? true : false;

		return BooleanFilter({
			initial,
			state: { ...state, value: booleanValue, hasSelection: booleanValue },
			config,
		});
	};
};

const clear = <ID extends string>({ initial, state, config }: BooleanFilterDeps<ID>) => {
	return () => {
		const filter = BooleanFilter({ initial, state, config });
		return filter.setValue(false);
	};
};
