import { fg } from '@atlaskit/platform-feature-flags';

import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createBooleanFilter } from '../../../../filters/boolean-filter';

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('verified');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createBooleanFilter({
	id: 'verified',
	queryParams: { key: 'verified' },
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: true,
	universal: false,
});
