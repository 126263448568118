import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const SmartsheetSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-166.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#292A2E" />
			<path
				d="M16.3609 17.2699C16.1039 16.7609 15.8582 16.2682 15.5681 15.7998C15.2003 15.2056 14.8011 14.6365 14.2557 14.1885C13.8461 13.8522 13.3923 13.6391 12.8449 13.7199C12.3726 13.7897 11.9917 14.0382 11.6488 14.3536C11.4678 14.5202 11.3025 14.7027 11.1507 14.9231C11.2328 14.9341 11.301 14.9405 11.3683 14.9527C11.856 15.0417 12.2311 15.3257 12.569 15.665C13.1371 16.2356 13.5597 16.9118 13.9383 17.6161C14.7871 19.1952 15.4097 20.8673 15.9423 22.5754C15.9476 22.592 15.9522 22.6086 15.9641 22.6478C18.1004 17.5379 20.5105 12.6048 23.831 8.1722L23.8693 8.18499C23.8693 8.29981 23.8693 8.41464 23.8693 8.52918C23.8693 12.2542 23.8734 15.9792 23.8667 19.7045C23.8647 20.8269 23.8476 21.9499 23.7643 23.0702C23.7367 23.4414 23.6987 23.8124 23.567 24.1656C23.4133 24.5778 23.1191 24.6836 22.7458 24.4507C22.429 24.2531 22.183 23.9766 21.9761 23.6719C21.5337 23.0205 21.1079 22.3583 20.8122 21.6222C20.7934 21.5751 20.7887 21.4679 20.6855 21.5682C20.0119 22.2231 19.2295 22.7295 18.4247 23.2004C16.1117 24.5536 13.6389 25.5089 11.0446 26.1583C10.1136 26.3911 9.17574 26.5888 8.23117 26.7563C8.13979 26.7725 8.11629 26.767 8.13573 26.6627C8.26192 25.981 8.31965 25.2929 8.31965 24.5987C8.31791 18.7132 8.31936 12.8277 8.31501 6.94225C8.31501 6.79487 8.35069 6.76318 8.49487 6.76318C13.5609 6.76725 18.6266 6.76667 23.6926 6.76667H23.8687C20.7609 9.84371 18.1366 13.2408 16.3618 17.2702L16.3609 17.2699Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21.0973 20.7601C20.7118 19.9966 20.3432 19.2575 19.9081 18.555C19.3563 17.6637 18.7575 16.8099 17.9394 16.138C17.325 15.6335 16.6444 15.3138 15.8233 15.4351C15.1149 15.5397 14.5435 15.9125 14.0292 16.3856C13.7576 16.6355 13.5096 16.9093 13.282 17.2399C13.4052 17.2564 13.5074 17.266 13.6084 17.2843C14.3399 17.4178 14.9025 17.8438 15.4095 18.3527C16.2615 19.2086 16.8955 20.2229 17.4634 21.2794C18.7366 23.648 19.6705 26.1562 20.4694 28.7184C20.4772 28.7432 20.4842 28.7681 20.502 28.827C23.7065 21.1621 27.3217 13.7624 32.3024 7.11352L32.3599 7.13271C32.3599 7.30495 32.3599 7.47719 32.3599 7.64899C32.3599 13.2365 32.366 18.824 32.356 24.412C32.3529 26.0956 32.3273 27.78 32.2024 29.4605C32.161 30.0174 32.104 30.5738 31.9065 31.1036C31.6758 31.7219 31.2346 31.8806 30.6745 31.5313C30.1994 31.2348 29.8304 30.8201 29.5201 30.3631C28.8565 29.386 28.2177 28.3926 27.7743 27.2886C27.746 27.2179 27.739 27.057 27.5841 27.2075C26.5737 28.1899 25.4001 28.9495 24.193 29.6559C20.7235 31.6857 17.0143 33.1185 13.1228 34.0927C11.7264 34.4419 10.3195 34.7385 8.90267 34.9896C8.76559 35.014 8.73035 35.0057 8.7595 34.8492C8.94879 33.8267 9.03539 32.7945 9.03539 31.7533C9.03278 22.925 9.03495 14.0968 9.02843 5.2686C9.02843 5.04753 9.08195 5 9.29822 5C16.8973 5.0061 24.4958 5.00523 32.0949 5.00523H32.359C27.6972 9.6208 23.7609 14.7165 21.0986 20.7605L21.0973 20.7601Z"
				fill="#143158"
			/>
		</svg>
	);
};
