import {
	type AggRequest,
	type AggResponse,
	fetchAgg,
	fetchAggDeferred,
} from '../../../common/utils/fetch-agg';
import { generateHashFromVariables } from '../../../utils/caching/common/key-generator';
import { inMemoryCache } from '../../../utils/caching/in-memory';

import {
	DEFERRED_SEARCH_PAGE_OPERATION_NAME,
	deferredQueryGenerator,
	jiraDcProjectFilterOptionsOperationName,
	jiraDcProjectFilterOptionsQueryGenerator,
	type JiraDcProjectFilterOptionsQueryVariables,
	queryGenerator,
	resultsCountOperationName,
	resultsCountQueryGenerator,
	type ResultsCountQueryVariables,
	SEARCH_DIALOG_OPERATION_NAME,
	SEARCH_PAGE_OPERATION_NAME,
	type SearchPageQueryVariables,
} from './query-generator';
import {
	type DeferredSearchPageQueryDeferIgnoredData,
	type DeferredSearchPageQueryFirstPartData,
	type DeferredSearchPageQuerySubsequentPartsData,
	type JiraDCFilterOptionsData,
	type SearchPageData,
} from './types';

export type DeferredSearchPageQueryProps = Pick<
	AggRequest<SearchPageQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
> & {
	originalQuery?: string;
};

type BaseQueryProps<T> = Pick<AggRequest<T>, 'aggAbsoluteUrl' | 'variables'> & {
	originalQuery?: string;
};

export type SearchPageQueryProps = BaseQueryProps<SearchPageQueryVariables>;
export type ResultsCountQueryProps = BaseQueryProps<ResultsCountQueryVariables>;
export type JiraDcProjectFilterOptionsQueryProps =
	BaseQueryProps<JiraDcProjectFilterOptionsQueryVariables>;

type SearchResultsQueryProps = SearchPageQueryProps & {
	useCache: boolean;
	operationName: string;
};

type DeferredSearchResultsQueryProps = SearchPageQueryProps & {
	useCache: boolean;
	operationName: string;
};

export const cache = inMemoryCache<AggResponse<SearchPageData>>();

export const generateCacheKey = (
	variables: SearchPageQueryVariables | ResultsCountQueryVariables,
	originalQuery?: string,
) => {
	/**
	 * Results once fetched will be used for upto 15 minutes from an in memory cache.
	 * 1. Generate key based on query, entities, filters
	 * 2. Serve from cache if present without resetting the expiration, evict if results are expired
	 * 3. If not present or expired then invoke the api
	 * 4. If API is invoked then store response in the cache with expiration and return back the response
	 */
	const keyObj = {
		query: variables.query,
		entities: variables.entities,
		commonFilters: variables.commonFilters,
		confluenceFilters: variables.confluenceFilters,
		jiraFilters: variables.jiraFilters,
		mercuryFilters: variables.mercuryFilters,
		thirdPartyFilters: variables.thirdPartyFilters,
		cloudIdARI: variables.cloudIdARI,
		sortField: variables.sort.at(0)?.field ?? '',
		sortOrder: variables.sort.at(0)?.order ?? '',
		after: variables.after,
		originalQuery,
	};

	return generateHashFromVariables(keyObj);
};

// Expire the cache if there are errors or edges are empty
const expireWhen = (result: AggResponse<SearchPageData>) => {
	const { data, errors } = result;
	if (!data || (errors && errors.length > 0)) {
		return true;
	}
	return data.search.results.edges.length === 0;
};

/**
 * Gets an initial set of results, with the rest of the results coming back later.
 */
const deferredSearchQuery = async ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
	useCache,
	operationName,
}: DeferredSearchResultsQueryProps) => {
	// Default experience is search-page
	variables.experience = variables.experience || 'search-page';

	const deferredGraphQuery = deferredQueryGenerator(operationName);
	return fetchAggDeferred<
		SearchPageQueryVariables,
		DeferredSearchPageQueryDeferIgnoredData,
		DeferredSearchPageQueryFirstPartData,
		DeferredSearchPageQuerySubsequentPartsData
	>({
		variables,
		aggAbsoluteUrl,
		graphQuery: deferredGraphQuery,
		operationName,
	});

	// TODO: QS-6145 solve caching situation
	// 	- hunches:
	// 			- (?) cache initial chunk, let search-page merge results as they come in to the cache
	// 			- (?) cache data in the same format as the non-deferred search page query? that way it's easier to merge data/other code around to work

	// if (useCache) {
	// 	return cache.inMemoryDecorator(
	// 		generateCacheKey(variables, originalQuery),
	// 		() =>
	// 			fetchAgg<SearchPageQueryVariables, SearchPageData>({
	// 				variables,
	// 				aggAbsoluteUrl,
	// 				graphQuery: deferredGraphQuery,
	// 				operationName,
	// 			}),
	// 		expireWhen,
	// 	);
	// } else {
	// 	return fetchAgg<SearchPageQueryVariables, SearchPageData>({
	// 		variables,
	// 		aggAbsoluteUrl,
	// 		graphQuery: deferredGraphQuery,
	// 		operationName,
	// 	});
	// }
};

const searchQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
	useCache,
	operationName,
}: SearchResultsQueryProps) => {
	// Default experience is search-page
	variables.experience = variables.experience || 'search-page';

	const graphQuery = queryGenerator(operationName);

	if (useCache) {
		return cache.inMemoryDecorator(
			generateCacheKey(variables, originalQuery),
			() =>
				fetchAgg<SearchPageQueryVariables, SearchPageData>({
					variables,
					aggAbsoluteUrl,
					graphQuery,
					operationName,
				}),
			expireWhen,
		);
	} else {
		return fetchAgg<SearchPageQueryVariables, SearchPageData>({
			variables,
			aggAbsoluteUrl,
			graphQuery,
			operationName,
		});
	}
};

export const deferredSearchPageQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: DeferredSearchPageQueryProps) => {
	return deferredSearchQuery({
		variables,
		aggAbsoluteUrl,
		originalQuery,
		useCache: true,
		operationName: DEFERRED_SEARCH_PAGE_OPERATION_NAME,
	});
};

export const searchPageQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: SearchPageQueryProps) => {
	return searchQuery({
		variables,
		aggAbsoluteUrl,
		originalQuery,
		useCache: true,
		operationName: SEARCH_PAGE_OPERATION_NAME,
	});
};

export const searchDialogQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: SearchPageQueryProps) => {
	return searchQuery({
		variables,
		aggAbsoluteUrl,
		originalQuery,
		useCache: false, // Bypassing cache to match previous Quick Find behavior
		operationName: SEARCH_DIALOG_OPERATION_NAME,
	});
};

export const resultsCountQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: ResultsCountQueryProps) => {
	const graphQuery = resultsCountQueryGenerator();

	return cache.inMemoryDecorator('count' + generateCacheKey(variables, originalQuery), () =>
		fetchAgg<ResultsCountQueryVariables, SearchPageData>({
			variables,
			aggAbsoluteUrl,
			graphQuery,
			operationName: resultsCountOperationName,
		}),
	);
};

export const jiraDcProjectFilterOptionsQuery = ({
	variables,
	aggAbsoluteUrl,
}: JiraDcProjectFilterOptionsQueryProps) => {
	const graphQuery = jiraDcProjectFilterOptionsQueryGenerator();

	return fetchAgg<JiraDcProjectFilterOptionsQueryVariables, JiraDCFilterOptionsData>({
		variables,
		aggAbsoluteUrl,
		graphQuery,
		operationName: jiraDcProjectFilterOptionsOperationName,
	});
};
