import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const HubSpotIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-115.5"
				y="-406.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#FF5C35" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.7267 9.81495V12.354C24.1316 12.7088 26.0529 14.4518 26.5457 16.726C27.0386 19.0003 25.9994 21.3274 23.9391 22.5635C21.8788 23.7995 19.2307 23.6843 17.2968 22.2746L15.1343 24.3383C15.1904 24.5057 15.2201 24.6801 15.2227 24.8558C15.2227 25.5882 14.7603 26.2486 14.0513 26.5288C13.3422 26.8091 12.5261 26.6542 11.9834 26.1363C11.4407 25.6184 11.2784 24.8395 11.5721 24.1629C11.8658 23.4863 12.5577 23.045 13.3251 23.045C13.5093 23.0476 13.692 23.076 13.8675 23.1293L16.0534 21.0432C14.6752 19.1722 14.6396 16.6862 15.9637 14.7799L8.78076 9.44358C8.4342 9.63626 8.04058 9.73846 7.63949 9.73989C6.3644 9.73843 5.33197 8.75082 5.3335 7.53399C5.33502 6.31718 6.36992 5.33192 7.64501 5.33338C8.9201 5.33483 9.95253 6.32244 9.95101 7.53927C9.94803 7.72494 9.92021 7.90949 9.86821 8.08843L17.1739 13.513C17.9709 12.9014 18.9231 12.5016 19.934 12.354V9.81495C19.2163 9.49505 18.7563 8.80883 18.7527 8.05287V7.99361C18.7557 6.91618 19.6702 6.04347 20.7993 6.04058H20.8613C21.9904 6.04347 22.9049 6.91618 22.9079 7.99361V8.05287C22.9044 8.80883 22.4444 9.49505 21.7267 9.81495ZM17.8387 17.8509C17.8387 19.4277 19.1782 20.706 20.8306 20.706C22.483 20.706 23.8225 19.4277 23.8225 17.8509C23.8225 16.274 22.483 14.9957 20.8306 14.9957C19.1782 14.9957 17.8387 16.274 17.8387 17.8509Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.0529 11.3022V14.8728C31.4349 15.3717 34.1366 17.8227 34.8296 21.0209C35.5228 24.2191 34.0614 27.4916 31.1641 29.2299C28.2669 30.968 24.543 30.806 21.8234 28.8236L18.7824 31.7256C18.8613 31.9611 18.903 32.2064 18.9066 32.4535C18.9066 33.4834 18.2565 34.412 17.2594 34.8061C16.2623 35.2002 15.1146 34.9824 14.3514 34.2541C13.5883 33.5259 13.36 32.4305 13.773 31.479C14.186 30.5275 15.159 29.907 16.2382 29.907C16.4972 29.9106 16.7541 29.9506 17.0009 30.0255L20.0749 27.092C18.1368 24.4609 18.0868 20.9649 19.9488 18.2841L9.84772 10.78C9.36036 11.0509 8.80684 11.1947 8.2428 11.1967C6.44971 11.1946 4.99786 9.80578 5 8.09462C5.00215 6.38347 6.45748 4.99796 8.25056 5C10.0437 5.00205 11.4955 6.39087 11.4934 8.10204C11.4892 8.36314 11.4501 8.62266 11.3769 8.8743L21.6506 16.5025C22.7714 15.6425 24.1104 15.0803 25.532 14.8728V11.3022C24.5226 10.8524 23.8758 9.88736 23.8708 8.8243V8.74096C23.875 7.22582 25.161 5.99857 26.7488 5.9945H26.836C28.4238 5.99857 29.7098 7.22582 29.714 8.74096V8.8243C29.7091 9.88736 29.0621 10.8524 28.0529 11.3022ZM22.5855 22.6027C22.5855 24.8201 24.4691 26.6177 26.7928 26.6177C29.1165 26.6177 31.0001 24.8201 31.0001 22.6027C31.0001 20.3852 29.1165 18.5876 26.7928 18.5876C24.4691 18.5876 22.5855 20.3852 22.5855 22.6027Z"
				fill="#FF5C35"
			/>
		</svg>
	);
};
