import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	webPage: {
		id: 'search-page.products.custom-website',
		defaultMessage: 'Web page',
		description: 'display name for Web page',
	},
	webPages: {
		id: 'search-page.products.custom-websites',
		defaultMessage: 'Web pages',
		description: 'display name for Web pages',
	},
	project: {
		id: 'search-page.nouns.project',
		defaultMessage: 'Project',
		description: 'display name for Project',
	},
	projects: {
		id: 'search-page.nouns.projects',
		defaultMessage: 'Projects',
		description: 'display name for Projects',
	},
	goal: {
		id: 'search-page.nouns.goal',
		defaultMessage: 'Goal',
		description: 'display name for Goal',
	},
	goals: {
		id: 'search-page.nouns.goals',
		defaultMessage: 'Goals',
		description: 'display name for Goals',
	},
	tag: {
		id: 'search-page.nouns.tag',
		defaultMessage: 'Tag',
		description: 'display name for Tag',
	},
	tags: {
		id: 'search-page.nouns.tags',
		defaultMessage: 'Tags',
		description: 'display name for Tags',
	},
});
