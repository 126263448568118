import {
	searchQueryParamsBaseSchema,
	searchQueryParamsValidators,
	type SearchQueryStringParamKeysWithValues,
	type SearchQueryStringParams,
	type SearchQueryStringParamsRaw,
} from '../../constants/schemas/query-params';

export const paramsToObject = (urlSearchParams: URLSearchParams) => {
	const queryParamsObject = Array.from(urlSearchParams.entries()).reduce(
		(paramsObject, [paramKey, value]) => {
			return { ...paramsObject, [paramKey]: value };
		},
		{} as Record<string, string>,
	);
	return queryParamsObject;
};

// NOTE: Any unsupported query parameters that fail the parse check are ignored
export const parseQueryParams = (
	queryParams: Record<string, unknown>,
	options?: { commaSeparatedValues?: boolean },
): SearchQueryStringParams => {
	const filteredParsedQueryParamEntries = Object.entries(searchQueryParamsBaseSchema.shape)
		.map(([key, schema]) => {
			const queryParamValue = queryParams[key];

			const parsedRawValue = schema.safeParse(queryParamValue);
			if (parsedRawValue.success || !options?.commaSeparatedValues) {
				return [key, parsedRawValue.data];
			}

			const commaSeparatedString =
				Array.isArray(queryParamValue) && queryParamValue.length
					? queryParamValue.join(',')
					: undefined;
			const parsedString = schema.safeParse(commaSeparatedString);

			return [key, parsedString.success ? parsedString.data : null];
		})
		.filter(([_, value]) => !(value === null || value === undefined));

	const parsedQueryParams = Object.fromEntries(filteredParsedQueryParamEntries);
	const validatedQueryParams = searchQueryParamsValidators.reduce(
		(queryParams, validator) => validator(queryParams),
		parsedQueryParams,
	);

	return validatedQueryParams;
};

export const mergeSearchQueryParams = (
	oldQueryParams: SearchQueryStringParams,
	newQueryParams: SearchQueryStringParams,
): SearchQueryStringParams => {
	const oldQueryParamEntries = Object.entries(oldQueryParams).filter(
		([_, value]) => !Array.isArray(value),
	);
	const newQueryParamEntries = Object.entries(newQueryParams).filter(
		([_, value]) => !Array.isArray(value),
	);

	const oldQueryParamArrayEntries = Object.entries(oldQueryParams).filter(([_, value]) =>
		Array.isArray(value),
	);
	const newQueryParamArrayEntries = Object.entries(newQueryParams).filter(([_, value]) =>
		Array.isArray(value),
	);

	const mergedQueryStringParamKeys = [
		...new Set(
			[...oldQueryParamArrayEntries, ...newQueryParamArrayEntries].map(
				([key]) => key as SearchQueryStringParamKeysWithValues,
			),
		),
	];

	const mergedSearchQueryArrays = mergedQueryStringParamKeys.map((key) => {
		return [
			key,
			[
				...new Set([
					...(oldQueryParams[key] ?? []),
					...(newQueryParams[key] ?? []), // New query param array values take precedence
				]),
			],
		];
	});

	return {
		...Object.fromEntries(oldQueryParamEntries),
		...Object.fromEntries(newQueryParamEntries),
		...Object.fromEntries(mergedSearchQueryArrays),
	};
};

export const commaSeparatedArrayEntries = (queryParams: SearchQueryStringParamsRaw) => {
	return Object.entries(queryParams).flatMap((queryParam) => {
		const [key, value] = queryParam;
		if (Array.isArray(value)) {
			return [[key, value.join(',')]];
		}
		return [[key, value]];
	});
};

export const toQueryStringParamsRaw = (
	queryParams: SearchQueryStringParams,
): SearchQueryStringParamsRaw => {
	const queryParamRawEntries = Object.entries(queryParams)
		.flatMap((queryParam): [string, string | undefined][] => {
			const [paramKey, value] = queryParam;

			if (value instanceof Date) {
				const dateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
				const dateFormatter = new Intl.DateTimeFormat('en-US', dateFormatOptions);
				const { month, day, year } = Object.fromEntries(
					dateFormatter.formatToParts(value).map((part) => [part.type, part.value]),
				);

				return [[paramKey, `${year}-${month}-${day}`]];
			}

			if (value === false || value === true) {
				return [[paramKey, String(value)]];
			}

			if (Array.isArray(value)) {
				return [[paramKey, value.join(',')]];
			}

			return [[paramKey, value]];
		})
		.sort(([firstKey], [secondKey]) => (firstKey < secondKey ? 1 : -1));

	return Object.fromEntries(queryParamRawEntries);
};

export const toQueryString = (queryParams: SearchQueryStringParams) => {
	const sanitizedQueryParams = Object.fromEntries(
		Object.entries(queryParams).filter(([_, value]) => value !== undefined),
	);
	return new URLSearchParams(
		commaSeparatedArrayEntries(toQueryStringParamsRaw(sanitizedQueryParams)),
	).toString();
};

export const toQueryParamsRaw = (
	queryParams: SearchQueryStringParams,
): SearchQueryStringParamsRaw => {
	return Object.fromEntries(commaSeparatedArrayEntries(toQueryStringParamsRaw(queryParams)));
};

export const areAllEqualQueryParams = (...queryParamArray: SearchQueryStringParams[]): boolean => {
	const queryParamStrings = queryParamArray.map((queryParams) => toQueryString(queryParams));
	const allQueryParamsAreEqual = new Set(queryParamStrings).size === 1;
	return allQueryParamsAreEqual;
};
