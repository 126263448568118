import React, { useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import SearchIcon from '@atlaskit/icon/core/search';
import Image from '@atlaskit/image';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@atlaskit/modal-dialog';
import { Box, Flex, Grid, Inline, media, Stack, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';

import { ProductKeys, ProductKeys3P } from '../../constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../constants/schemas/3p-config';
import { ProductTile } from '../product-tile';

import NoResultsDark from './assets/no-results-dark.svg';
import NoResultsLight from './assets/no-results-light.svg';
import { messages } from './messages';

const titleStyles = xcss({
	color: 'color.text',
	font: 'font.heading.medium',
	fontFamily: 'font.family.brand.heading',
	fontWeight: 'font.weight.medium',
	paddingBottom: 'space.200',
	width: '100%',
});

const subheadingStyles = xcss({
	color: 'color.text.subtle',
	font: 'font.body',
	fontFamily: 'font.family.brand.body',
	fontWeight: 'font.weight.regular',
	paddingBottom: 'space.200',
	width: '100%',
});

const inputStyles = xcss({
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.100',
	color: 'color.text.subtlest',
});

const iconStyles = xcss({
	paddingLeft: 'space.025',
	paddingRight: 'space.025',
});

const searchBarStyles = xcss({
	width: '100%',
	borderRadius: '5px',
	border: '1px solid',
	borderColor: 'color.border.input',
	background: token('color.background.input'),
});

//makes product tiles grid display responsive
const productTileGridStyles = xcss({
	gridTemplateColumns: 'repeat(5, 1fr)', // 5 in full size / default
	[media.above.xxs]: {
		gridTemplateColumns: 'repeat(2, 1fr)',
	},
	[media.above.xs]: {
		gridTemplateColumns: 'repeat(3, 1fr)',
	},
	[media.above.sm]: {
		gridTemplateColumns: 'repeat(4, 1fr)',
	},
	[media.above.md]: {
		gridTemplateColumns: 'repeat(5, 1fr)',
	},
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

const noResultsContainerStyles = xcss({
	alignContent: 'center',
	alignItems: 'center',
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

const noResultsImageStyles = xcss({
	height: '104px',
	width: '104px',
});

const noResultsTextStyles = xcss({
	color: 'color.text.subtle',
	fontFamily: 'font.family.brand.body',
	fontWeight: 'font.weight.medium',
});
interface OAuthHandlersProps {
	product: ProductKeys;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	onFailAction?: () => void;
	isOnSearchDialog?: boolean;
}
interface OauthHandlerFunctions {
	onAuthorize: () => void;
	onDismiss: () => void;
	onFail: () => void;
}

type useOAuthHandlers = (props: OAuthHandlersProps) => OauthHandlerFunctions;

type BulkConnectDialogProps = {
	availableProducts: ProductKeys3P[];
	newlyConnected: ProductKeys3P[];
	onClose: (productWasConnected: boolean, closedByButton: boolean) => void;
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	useOAuthHandlers: useOAuthHandlers;
	useInitializeBulkConnect: (
		availableProducts: ProductKeys3P[],
		recentlyConnectedProducts: ProductKeys3P[],
	) => void;
	useSetProductStatus: (productKey: ProductKeys3P, status: boolean) => void;
	useSetProductConnectedInSession: (status: boolean) => void;
	useCurrentProductStatuses: () => Map<ProductKeys3P, boolean>;
	useCurrentProductOrder: () => ProductKeys3P[];
	useProductConnectedInSessionStatus: () => boolean;
};

export const BulkConnectDialog = ({
	availableProducts,
	newlyConnected,
	onClose,
	thirdPartyConfigs,
	useOAuthHandlers,
	useInitializeBulkConnect,
	useSetProductStatus,
	useSetProductConnectedInSession,
	useCurrentProductStatuses,
	useCurrentProductOrder,
	useProductConnectedInSessionStatus,
}: BulkConnectDialogProps) => {
	const { formatMessage } = useIntl();
	useInitializeBulkConnect(availableProducts, newlyConnected);
	const productOrder = useCurrentProductOrder();
	const [productsToRender, setProductsToRender] = useState(productOrder);
	const productConnectedInSession = useProductConnectedInSessionStatus();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.currentTarget.value.toLowerCase().trim();

		const filteredProductsToRender = input
			? productOrder.filter((productKey) => {
					const displayName = thirdPartyConfigs[productKey]?.displayName;
					return (
						typeof displayName === 'string' && displayName.toLowerCase().trim().includes(input)
					);
				})
			: productOrder;

		setProductsToRender(filteredProductsToRender);
	};

	const renderProductTiles = useMemo(() => {
		if (productsToRender.length === 0) {
			return (
				<Box xcss={noResultsContainerStyles}>
					<Stack space="space.150" alignBlock="center" alignInline="center">
						<Box xcss={noResultsImageStyles}>
							<Image src={NoResultsLight} srcDark={NoResultsDark} role="presentation" />
						</Box>
						<Box xcss={noResultsTextStyles}>
							<FormattedMessage {...messages.noResults} />
						</Box>
					</Stack>
				</Box>
			);
		}

		return (
			<Grid xcss={productTileGridStyles} gap="space.150">
				{productsToRender.map((productKey) => {
					let config = thirdPartyConfigs[productKey];
					if (config) {
						return (
							<ProductTile
								icon={config.icon}
								productName={config?.displayName}
								productKey={productKey}
								url={config?.oAuthOutboundUrl ?? ''}
								newlyConnected={newlyConnected.includes(productKey)}
								useOAuthHandlers={useOAuthHandlers}
								useCurrentProductStatuses={useCurrentProductStatuses}
								setProductStatus={useSetProductStatus}
								setProductConnectedInSession={useSetProductConnectedInSession}
							/>
						);
					}
				})}
			</Grid>
		);
	}, [
		productsToRender,
		thirdPartyConfigs,
		newlyConnected,
		useOAuthHandlers,
		useCurrentProductStatuses,
		useSetProductStatus,
		useSetProductConnectedInSession,
	]);

	return (
		<Modal onClose={(e) => onClose(productConnectedInSession, false)} width={'x-large'}>
			<ModalHeader>
				<Stack grow="fill">
					<Box xcss={titleStyles}>
						<FormattedMessage {...messages.modalTitle} />
					</Box>
					<Box xcss={subheadingStyles}>
						<FormattedMessage {...messages.modalSubheading} />
					</Box>
					<Box xcss={searchBarStyles}>
						<Inline space="space.025" xcss={inputStyles}>
							<Box xcss={iconStyles}>
								<SearchIcon label={formatMessage(messages.searchIconLabel)} />
							</Box>
							<Textfield
								placeholder={formatMessage(messages.searchPlaceholder)}
								appearance="none"
								onChange={onChange}
							/>
						</Inline>
					</Box>
				</Stack>
			</ModalHeader>
			<ModalBody>{renderProductTiles}</ModalBody>
			<ModalFooter>
				<Flex gap="space.100">
					<Button onClick={(e) => onClose(productConnectedInSession, true)} appearance="subtle">
						<FormattedMessage {...messages.cancel} />
					</Button>
					<Button onClick={(e) => onClose(productConnectedInSession, true)} appearance="primary">
						<FormattedMessage {...messages.done} />
					</Button>
				</Flex>
			</ModalFooter>
		</Modal>
	);
};
