import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ServiceNowIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-411.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#292A2E" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0473 4.00178C10.4382 3.99842 8.86528 4.4785 7.53234 5.37978C6.19941 6.28105 5.16796 7.56197 4.57171 9.05646C3.97546 10.5509 3.84191 12.1901 4.18837 13.7614C4.53483 15.3327 5.34532 16.7637 6.51478 17.8688C6.78908 18.13 7.14782 18.2844 7.52608 18.3041C7.90434 18.3237 8.27713 18.2073 8.57701 17.9759C9.56641 17.2396 10.7668 16.842 12.0001 16.842C13.2334 16.842 14.4339 17.2396 15.4233 17.9759C15.726 18.2078 16.1021 18.3232 16.4828 18.301C16.8635 18.2788 17.2236 18.1205 17.4973 17.8549C18.6576 16.7539 19.4626 15.3314 19.8092 13.7698C20.1558 12.2082 20.0282 10.5787 19.4426 9.09017C18.8571 7.60161 17.8404 6.32184 16.5228 5.41488C15.2052 4.50793 13.6467 4.01512 12.0473 3.99963M12.0001 16.0379C11.4713 16.0522 10.9451 15.9586 10.4536 15.7628C9.96216 15.567 9.51573 15.2731 9.1416 14.8991C8.76746 14.525 8.47347 14.0787 8.27754 13.5873C8.08161 13.0959 7.98787 12.5697 8.00202 12.0408C8.00202 10.9805 8.42326 9.96353 9.17305 9.21374C9.92284 8.46395 10.9398 8.04272 12.0001 8.04272C13.0605 8.04272 14.0774 8.46395 14.8272 9.21374C15.577 9.96353 15.9983 10.9805 15.9983 12.0408C16.0124 12.5697 15.9187 13.0959 15.7227 13.5873C15.5268 14.0787 15.2328 14.525 14.8587 14.8991C14.4846 15.2731 14.0381 15.567 13.5467 15.7628C13.0552 15.9586 12.529 16.0522 12.0001 16.0379Z"
				fill="#62D84E"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0473 5.00214C10.4382 4.99878 8.86528 5.47886 7.53234 6.38014C6.19941 7.28142 5.16796 8.56233 4.57171 10.0568C3.97546 11.5513 3.84191 13.1905 4.18837 14.7618C4.53483 16.333 5.34532 17.764 6.51478 18.8692C6.78908 19.1304 7.14782 19.2848 7.52608 19.3044C7.90434 19.3241 8.27713 19.2077 8.57701 18.9763C9.56641 18.24 10.7668 17.8423 12.0001 17.8423C13.2334 17.8423 14.4339 18.24 15.4233 18.9763C15.726 19.2082 16.1021 19.3236 16.4828 19.3013C16.8635 19.2791 17.2236 19.1208 17.4973 18.8553C18.6576 17.7542 19.4626 16.3317 19.8092 14.7702C20.1558 13.2086 20.0282 11.5791 19.4426 10.0905C18.8571 8.60198 17.8404 7.3222 16.5228 6.41525C15.2052 5.50829 13.6467 5.01549 12.0473 5M12.0001 17.0382C11.4713 17.0525 10.9451 16.9589 10.4536 16.7631C9.96216 16.5674 9.51573 16.2735 9.1416 15.8994C8.76746 15.5254 8.47347 15.0791 8.27754 14.5876C8.08161 14.0962 7.98787 13.57 8.00202 13.0412C8.00202 11.9808 8.42326 10.9639 9.17305 10.2141C9.92284 9.46432 10.9398 9.04309 12.0001 9.04309C13.0605 9.04309 14.0774 9.46432 14.8272 10.2141C15.577 10.9639 15.9983 11.9808 15.9983 13.0412C16.0124 13.57 15.9187 14.0962 15.7227 14.5876C15.5268 15.0791 15.2328 15.5254 14.8587 15.8994C14.4846 16.2735 14.0381 16.5674 13.5467 16.7631C13.0552 16.9589 12.529 17.0525 12.0001 17.0382Z"
				fill="#62D84E"
			/>
		</svg>
	);
};
