import { type ProductKeys, PRODUCTS_ORDER } from '../constants/products';

export const getProductOrder = (a: ProductKeys, b: ProductKeys) => {
	const indexA = PRODUCTS_ORDER.indexOf(a);
	const indexB = PRODUCTS_ORDER.indexOf(b);

	if (indexA === -1 || indexB === -1) {
		return 0;
	} else {
		return indexA - indexB;
	}
};
