import { type CloudConfig } from '../../../common/constants/filters/cloud-config/types';
import type {
	CreatedSelectFilter,
	CreateSelectFilter,
	SelectFilterState,
	SelectFilterType,
} from '../../../common/constants/filters/select-filter/types';
import {
	clearOptions,
	deselectOptions,
	getOptions,
	getQueryParams,
	getState,
	getValue,
	loadInitialOptions,
	setLookupInput,
	setOption,
	setQueryParams,
} from '../base-select-filter';

export const createMultiSelectFilter = <ID extends string = string>(
	initial: CreateSelectFilter<ID>,
): CreatedSelectFilter<ID> => {
	const initialState = Object.freeze({
		id: initial.id,
		type: 'multi-select',
		didMount: false,
		hasSelection: false,
		alwaysVisible: initial.alwaysVisible,
		universal: initial.universal,
		queryParams: {
			isLoading: false,
			options: [],
		},
		lookup: {
			isLoading: false,
			isActive: false,
			isDefined: !!initial.lookup,
			options: [],
			lookupResultOptions: [],
			input: '',
		},
		initialOptions: {
			options: [],
			isLoading: false,
		},
		options: [],
		selectedOptions: [],
		value: [],
		products: initial.products,
	} satisfies SelectFilterState<ID>);

	return {
		id: initial.id,
		type: initialState.type,
		queryParams: { key: initial.queryParams.key },
		products: initial.products,
		universal: initial.universal,
		customValueFields: initial.customValueFields,
		withState: (state, config) =>
			MultiSelectFilter({
				initial,
				state: state as SelectFilterState<ID>, // This has to be cast back to the correct state
				config,
			}),
		getInitialState: (config: CloudConfig) =>
			MultiSelectFilter({ initial, state: initialState, config }),
		isCompatible: (id) => id === initial.id,
		isSupportCustomValue: false,
	};
};

export const MultiSelectFilter: SelectFilterType = ({ initial, state, config }) => {
	const filterState = Object.freeze({
		...state,
	});

	return {
		id: initial.id,
		type: filterState.type,
		getState: getState({ initial, state: filterState, config }),
		queryParams: {
			key: initial.queryParams.key,
			get: getQueryParams({ initial, state: filterState, config }),
			set: setQueryParams({ initial, state: filterState, config }),
		},
		loadInitialOptions: loadInitialOptions({ initial, state: filterState, config }),
		setOption: setOption({ initial, state: filterState, config }),
		clearOptions: clearOptions({ initial, state: filterState, config }),
		setLookupInput: setLookupInput({ initial, state: filterState, config }),
		getOptions: getOptions({ initial, state: filterState, config }),
		getValue: getValue({ initial, state: filterState, config }),
		clear: deselectOptions({ initial, state: filterState, config }),
	};
};
