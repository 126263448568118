import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const iconContainerStyles = {
	small: xcss({
		width: '16px',
		height: '16px',
	}),
	medium: xcss({
		width: '24px',
		height: '24px',
	}),
	large: xcss({
		width: '40px',
		height: '40px',
	}),
	xlarge: xcss({
		width: '48px',
		height: '48px',
	}),
} as const;

type Size = 'small' | 'medium' | 'large' | 'xlarge';

export interface SVGIconProps {
	glyph: () => JSX.Element;
	label?: string;
	size: Size;
}

const iconStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

export const SVGIcon = ({ glyph: SVG, label, size }: SVGIconProps) => {
	return (
		<Box xcss={[iconContainerStyles[size], iconStyles]} aria-label={label}>
			<SVG />
		</Box>
	);
};
