import React, { createContext, forwardRef, type PropsWithChildren } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { Anchor, type AnchorProps } from '@atlaskit/primitives';
import { type ServedExperiment } from '@atlassian/search-experiment';

import { type QueryParams } from '../../../common/types';
import { extend } from '../../../common/utils/object-utils';
import { defaultGenerateSearchUrl, defaultOnNavigate } from '../../../common/utils/url';
import { Link } from '../link-component';

export type LinkComponentProps = AnchorProps;

type NavigateCallback = (url: string, action: 'push' | 'replace') => void;

interface AppContextData {
	/**
	 * DEPRECATED. The internal link component will now use the provided onNavigate
	 * function to navigate.
	 *
	 * Optional component that is used to render links instead of a generic anchor tag.
	 */
	linkComponent: React.ComponentType<LinkComponentProps>;

	/**
	 * A function that maps a user's ID to the host application's profile page URL.
	 * When this returns null, the package knows that routing information is not
	 * available to reach a user's profile. UI may choose not to render links
	 * to user profiles then.
	 */
	mapUserIdToProfileUrl: (userId: string) => string | null;

	/**
	 * Retrieve feature gate state from stasig with Rovo vs Rovo overrides
	 * - See https://hello.atlassian.net/wiki/x/AZH-AwE
	 * - `@atlaskit/search-experiment` for implementation
	 *
	 * This function replaces
	 * - import { fg } from '@atlaskit/platform-feature-flags'
	 * - import { checkGate } from '@atlaskit/feature-gate-js-client';
	 *
	 * It will make use of overrides from Rovo vs Rovo mode if needed, calling to statsig if not (and if there are any errors)
	 *
	 */
	getFeatureGate: (name: string) => boolean;

	/**
	 * Retrieve frontend experiment state from statsig with Rovo vs Rovo overrides
	 * - See https://hello.atlassian.net/wiki/x/AZH-AwE
	 * - `@atlaskit/search-experiment` for implementation
	 *
	 */
	getFrontendExperiment: ({ abTestId }: { abTestId: string }) => ServedExperiment;

	/**
	 * A function that generates a search URL with the given query parameters.
	 */
	generateSearchUrl: (queryParams: QueryParams, isSecondaryLink?: boolean) => string;

	/**
	 * A function that generates a user URL with the given userId. This url will be used
	 * for search results that return users.
	 */
	generateUserUrl?: (userId: string) => string;

	/**
	 * A function that generates a team URL with the given teamId. This url will be used
	 * for search results that return teams.
	 */
	generateTeamUrl?: (teamId: string) => string;

	/**
	 * The query parameters that are currently in use.
	 */
	queryParams: QueryParams;

	/**
	 * Allows customisation of how the application navigates to a new URL, e.g. using router functions
	 */
	onNavigate:
		| NavigateCallback
		| {
				/**
				 * Function that is used when changing the browser's URL. If not provided,
				 * the default will be an assignment to window.lcoation.href.
				 */
				callback: NavigateCallback;

				options: {
					/**
					 * The default behavior for link clicks is to use the onNavigate function
					 * only when the link is clicked without a modifier (ctrl, cmd, shift)
					 * and when the href is on the same origin.
					 *
					 * If this option is enabled, the onNavigate function will always be used
					 * when a link is clicked.
					 */
					alwaysUseOnLinkClick: boolean;
				};
		  };

	/**
	 * When enabled, there will only be one input to search with (located in the navigation bar).
	 * When disabled, there will be two inputs to search with: 1 in the navigation bar and another on the search page.
	 */
	isNav4Enabled: boolean;

	/**
	 * Used to enable Atlassian Intelligence features.
	 */
	isAdminHubAIEnabled: boolean;

	/**
	 * In the SCAS package, where Rovo Free is offered (introduced in Team '25), this field is used to decide if the
	 * LLM powered features should be enabled.
	 * The condtion behind this value is: isRovoLLMEnabled = isRovoLegacyEnabled || (isRovoFreeEnabled && isAtlassianIntelligenceEnabled)
	 * where Rovo Legacy is Rovo 1.0 (the initial version of Rovo), which should be decomissioned at one point.
	 *
	 * This prop is used only if "rovo_search_updated_entitlement_checks_for_team_25" feature gate is enabled.
	 */
	isRovoLLMEnabled: boolean;
}

export type AppContextProviderProps = Partial<AppContextData>;

const DefaultLinkComponent = forwardRef<HTMLAnchorElement, AnchorProps>(
	(props: AnchorProps, ref) => {
		return fg('search_page_internal_link_component') ? (
			<Link ref={ref} {...props} />
		) : (
			<Anchor ref={ref} {...props} />
		);
	},
);

export const defaultAppContextData: AppContextData = {
	linkComponent: DefaultLinkComponent as React.ComponentType<LinkComponentProps>,
	mapUserIdToProfileUrl: () => null,
	generateSearchUrl: defaultGenerateSearchUrl,
	getFeatureGate: () => false,
	getFrontendExperiment: () => ({
		abTestId: '',
		cohort: '',
		serveExperiment: false,
	}),
	queryParams: {},
	onNavigate: defaultOnNavigate,
	isNav4Enabled: false,
	isAdminHubAIEnabled: false,
	isRovoLLMEnabled: false,
};

/**
 * Provides information about the host application that can't be stored in a state store,
 * e.g. a function.
 */
const AppContext = createContext<AppContextData>(defaultAppContextData);

export const useAppContext = () => React.useContext(AppContext);

export const AppContextProvider = ({
	children,
	...props
}: PropsWithChildren<AppContextProviderProps>) => (
	<AppContext.Provider value={extend(defaultAppContextData, props)}>{children}</AppContext.Provider>
);
