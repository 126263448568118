import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AdobeSignIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-166.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#584CCC" />
			<path
				d="M25.7066 18.7523C25.0666 18.0399 23.8399 17.7112 22.0799 17.7112C21.1199 17.7112 20.1066 17.8208 19.1466 17.9852C18.5066 17.3824 17.9732 16.6701 17.4399 15.9578C17.0666 15.4099 16.6932 14.8619 16.3732 14.2592C16.9066 12.725 17.2266 11.0812 17.3332 9.43743C17.3332 7.95802 16.7466 6.36902 15.1466 6.36902C14.6132 6.36902 14.0799 6.69778 13.8132 7.19091C13.1199 8.39636 13.3866 10.8621 14.5066 13.4373C14.1332 14.588 13.7066 15.7386 13.2266 16.9989C12.7999 18.0947 12.3199 19.1358 11.7332 20.1221C10.0799 20.7796 6.61323 22.4234 6.29323 24.2316C6.18656 24.7795 6.34656 25.3274 6.77323 25.6562C7.14656 25.9849 7.6799 26.2041 8.21323 26.1493C10.2932 26.1493 12.3732 23.1905 13.8132 20.6152C14.6132 20.3413 15.4132 20.0673 16.2666 19.8481C17.1732 19.6289 18.0266 19.4098 18.8266 19.2454C21.0666 21.2179 23.0399 21.5467 23.9999 21.5467C25.3332 21.5467 25.8666 20.944 26.0266 20.4508C26.2399 19.8481 26.1332 19.1906 25.7066 18.7523ZM24.2666 19.7933C24.2132 20.1769 23.7866 20.4508 23.2532 20.4508C23.0932 20.4508 22.9332 20.4508 22.7732 20.396C21.7066 20.1221 20.6932 19.5742 19.8399 18.8618C20.5332 18.7523 21.2799 18.6975 21.9732 18.6975C22.4532 18.6975 22.9332 18.7523 23.4132 18.807C23.8932 18.9166 24.4266 19.1358 24.2666 19.7933ZM14.7199 7.68405C14.8266 7.51967 14.9866 7.41009 15.1999 7.35529C15.7332 7.35529 15.8399 7.95802 15.8399 8.50595C15.7866 9.76619 15.5732 10.9716 15.1999 12.1771C14.2932 9.82098 14.3999 8.23198 14.7199 7.68405ZM17.7066 18.2043C17.1199 18.3139 16.5332 18.4783 15.9466 18.6427C15.5199 18.7523 15.0932 18.9166 14.6132 19.0262C14.8266 18.5331 15.0399 18.0399 15.2532 17.6016C15.5199 16.9989 15.7332 16.3962 15.9466 15.7934C16.1599 16.1222 16.3199 16.3962 16.5332 16.6701C16.9066 17.218 17.3332 17.7112 17.7066 18.2043ZM11.2532 21.3823C9.9199 23.574 8.58656 24.9987 7.8399 24.9987C7.73323 24.9987 7.57323 24.9439 7.5199 24.8891C7.3599 24.7795 7.30656 24.5603 7.3599 24.3959C7.46656 23.574 9.01323 22.4234 11.2532 21.3823Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.82667 4H17.1733C18.72 4 20 5.28 20 6.82667V16.7467C20 18.32 18.72 19.5733 17.1733 19.5733H6.82667C5.28 19.6 4 18.32 4 16.7733V6.82667C4 5.28 5.28 4 6.82667 4Z"
				fill="#584CCC"
			/>
			<path
				d="M16.8533 13.0401C16.5333 12.6934 15.92 12.5334 15.04 12.5334C14.56 12.5334 14.0533 12.5868 13.5733 12.6668C13.2533 12.3734 12.9867 12.0268 12.72 11.6801C12.5333 11.4134 12.3467 11.1468 12.1867 10.8534C12.4533 10.1068 12.6133 9.30676 12.6667 8.50676C12.6667 7.78676 12.3733 7.01343 11.5733 7.01343C11.3067 7.01343 11.04 7.17343 10.9067 7.41343C10.56 8.00009 10.6933 9.20009 11.2533 10.4534C11.0667 11.0134 10.8533 11.5734 10.6133 12.1868C10.4 12.7201 10.16 13.2268 9.86668 13.7068C9.04001 14.0268 7.30668 14.8268 7.14668 15.7068C7.09334 15.9734 7.17334 16.2401 7.38668 16.4001C7.57334 16.5601 7.84001 16.6668 8.10668 16.6401C9.14668 16.6401 10.1867 15.2001 10.9067 13.9468C11.3067 13.8134 11.7067 13.6801 12.1333 13.5734C12.5867 13.4668 13.0133 13.3601 13.4133 13.2801C14.5333 14.2401 15.52 14.4001 16 14.4001C16.6667 14.4001 16.9333 14.1068 17.0133 13.8668C17.12 13.5734 17.0667 13.2534 16.8533 13.0401ZM16.1333 13.5468C16.1067 13.7334 15.8933 13.8668 15.6267 13.8668C15.5467 13.8668 15.4667 13.8668 15.3867 13.8401C14.8533 13.7068 14.3467 13.4401 13.92 13.0934C14.2667 13.0401 14.64 13.0134 14.9867 13.0134C15.2267 13.0134 15.4667 13.0401 15.7067 13.0668C15.9467 13.1201 16.2133 13.2268 16.1333 13.5468ZM11.36 7.65343C11.4133 7.57343 11.4933 7.52009 11.6 7.49343C11.8667 7.49343 11.92 7.78676 11.92 8.05343C11.8933 8.66676 11.7867 9.25343 11.6 9.84009C11.1467 8.69343 11.2 7.92009 11.36 7.65343ZM12.8533 12.7734C12.56 12.8268 12.2667 12.9068 11.9733 12.9868C11.76 13.0401 11.5467 13.1201 11.3067 13.1734C11.4133 12.9334 11.52 12.6934 11.6267 12.4801C11.76 12.1868 11.8667 11.8934 11.9733 11.6001C12.08 11.7601 12.16 11.8934 12.2667 12.0268C12.4533 12.2934 12.6667 12.5334 12.8533 12.7734ZM9.62668 14.3201C8.96001 15.3868 8.29334 16.0801 7.92001 16.0801C7.86668 16.0801 7.78668 16.0534 7.76001 16.0268C7.68001 15.9734 7.65334 15.8668 7.68001 15.7868C7.73334 15.3868 8.50668 14.8268 9.62668 14.3201Z"
				fill="white"
			/>
		</svg>
	);
};
