import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

export const projectsCache = inMemoryCache<AggResponse<JiraProjectsResponse>>();

const GQL_JIRA_PROJECTS_OPERATION_NAME = 'SearchPageJiraProjectQuery';
const jiraProjectsQueryString = `query ${GQL_JIRA_PROJECTS_OPERATION_NAME}($projectIds: [ID!]!) {
  jira {
    jiraProjects(ids: $projectIds) {
			projectId
      name
      avatar {
        small
      }
    }
  }
}`;

export type JiraProject = {
	/**
	 * The project id of the project. e.g. 10000. If you need an ARI, query id instead.
	 * NOTE: this field is nullable on the API but search-client will filter projects without it.
	 **/
	projectId: string;
	/** The name of the project. */
	name: string;
	/** The avatar of the project. */
	avatar?: {
		/** A small avatar (24x24 pixels). */
		small?: string;
	};
};

export type JiraProjectsResponse = {
	jira: {
		jiraProjects?: JiraProject[];
	};
};

export type JiraProjectsQueryVariables = { projectIds: string[] };

export const jiraProjectsQuery = (projectIds: string[]) => {
	return projectsCache.inMemoryDecorator(projectIds.join(','), async () => {
		if (projectIds.length === 0) {
			return {
				data: {
					jira: {
						jiraProjects: [],
					},
				},
			};
		}

		const response = await fetchAgg<JiraProjectsQueryVariables, JiraProjectsResponse>({
			graphQuery: jiraProjectsQueryString,
			variables: { projectIds },
			operationName: GQL_JIRA_PROJECTS_OPERATION_NAME,
		});

		const hasErrors = response.errors?.some((error) => error);

		if (hasErrors) {
			return Promise.reject(response);
		}
		return filterProjectsWithoutId(response);
	});
};

const filterProjectsWithoutId = (
	response: AggResponse<JiraProjectsResponse>,
): AggResponse<JiraProjectsResponse> => {
	const filteredProjects = response.data?.jira.jiraProjects?.filter((project) => project.projectId);
	return {
		...response,
		data: { jira: { jiraProjects: filteredProjects } },
	};
};
