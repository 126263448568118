import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const DropboxIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-28.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#0061FF" />
			<path
				d="M12.0098 8.48964L8.24219 10.8931L12.0098 13.2965L8.24219 15.7L4.47461 13.2836L8.24219 10.8801L4.47461 8.48964L8.24219 6.08618L12.0098 8.48964ZM8.2227 16.473L11.9903 14.0695L15.7579 16.473L11.9903 18.8765L8.2227 16.473ZM12.0098 13.2836L15.7773 10.8801L12.0098 8.48964L15.7579 6.08618L19.5254 8.48964L15.7579 10.8931L19.5254 13.2965L15.7579 15.7L12.0098 13.2836Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg
			width="24px"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 43 40"
			version="1.1"
			height="24px"
		>
			<path
				d="m12.5 0l-12.5 8.1 8.7 7 12.5-7.8-8.7-7.3zm-12.5 21.9l12.5 8.2 8.7-7.3-12.5-7.7-8.7 6.8zm21.2 0.9l8.8 7.3 12.4-8.1-8.6-6.9-12.6 7.7zm21.2-14.7l-12.4-8.1-8.8 7.3 12.6 7.8 8.6-7zm-21.1 16.3l-8.8 7.3-3.7-2.5v2.8l12.5 7.5 12.5-7.5v-2.8l-3.8 2.5-8.7-7.3z"
				fill="#007EE5"
			/>
		</svg>
	);
};
