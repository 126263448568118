import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const PagerDutyIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-731.5"
				y="-652.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#04AB38"
			/>
			<path
				d="M7.33974 9.0588C7.33988 9.05866 7.33996 9.05847 7.33996 9.05827C7.33996 7.60876 7.34084 6.15925 7.3382 4.70974C7.3382 4.5796 7.33644 4.4987 7.51495 4.50002C9.30876 4.51057 11.1026 4.49738 12.8964 4.51892C13.9116 4.53123 14.8801 4.77262 15.7546 5.30858C16.8256 5.96414 17.4222 6.94066 17.6117 8.16868C17.8148 9.48551 17.6012 10.7206 16.7918 11.8057C16.1033 12.7294 15.1439 13.2175 14.0285 13.4329C13.3347 13.567 12.6326 13.5846 11.9309 13.5899C10.4668 13.6013 9.00231 13.5912 7.53781 13.5991C7.36414 13.6 7.33557 13.5411 7.33645 13.3859C7.34172 11.9438 7.33952 10.5014 7.33952 9.05933C7.33952 9.05913 7.3396 9.05894 7.33974 9.0588Z"
				fill="#FEFEFE"
			/>
			<path
				d="M7.35232 17.4818C7.35232 16.8821 7.35539 16.2819 7.34968 15.6822C7.34836 15.5516 7.36463 15.4905 7.52246 15.4931C8.14414 15.5037 8.76626 15.5024 9.38794 15.4931C9.53215 15.4909 9.55281 15.5415 9.55237 15.6681C9.54798 16.89 9.5471 18.1118 9.55325 19.3333C9.55413 19.4753 9.50841 19.5003 9.37959 19.499C8.75791 19.4929 8.13579 19.4911 7.51411 19.4999C7.3699 19.5021 7.34836 19.452 7.34968 19.3249C7.35583 18.7102 7.35232 18.096 7.35232 17.4813V17.4818Z"
				fill="#FEFEFE"
			/>
			<path
				d="M9.54312 8.99636C9.54312 8.1834 9.54488 7.37088 9.54093 6.55791C9.54049 6.43788 9.55148 6.37589 9.70184 6.37677C10.7051 6.38424 11.7084 6.36446 12.7113 6.38776C13.2833 6.40095 13.8408 6.53021 14.3464 6.81996C15.0103 7.20028 15.3295 7.79604 15.3967 8.54042C15.4464 9.09221 15.4223 9.63697 15.1853 10.1514C14.8072 10.9723 14.1037 11.3416 13.2675 11.521C12.8942 11.601 12.5143 11.6195 12.1332 11.6195C11.3422 11.619 10.5513 11.612 9.76075 11.6247C9.56818 11.6278 9.53697 11.565 9.53917 11.3913C9.5484 10.5933 9.54312 9.79481 9.54312 8.9968V8.99636Z"
				fill="#04AB38"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.9969 11.9995C19.9969 14.6225 19.9963 17.2458 19.9997 19.8688C19.9997 19.9713 19.985 19.9991 19.8741 19.9987C14.6273 19.995 9.38021 19.995 4.13343 19.9987C4.01562 19.9991 4 19.9684 4 19.8609C4.00312 14.62 4.00312 9.37906 4 4.13812C4 4.03094 4.015 4 4.13343 4C9.37521 4.00406 14.6167 4.00406 19.8584 4C19.98 4 20 4.03187 20 4.14562C19.9966 6.76359 19.9972 9.38125 19.9972 11.9992L19.9969 11.9995Z"
				fill="#04AB38"
			/>
			<path
				d="M8.67927 9.89217C8.67927 8.86191 8.6799 7.83166 8.67802 6.80141C8.67802 6.70891 8.67677 6.65142 8.80364 6.65236C10.0786 6.65985 11.3536 6.65048 12.6286 6.66579C13.3501 6.67454 14.0385 6.84609 14.6601 7.22701C15.4213 7.69292 15.8454 8.38694 15.9801 9.2597C16.1244 10.1956 15.9726 11.0733 15.3973 11.8446C14.9079 12.5011 14.226 12.8479 13.4332 13.001C12.9401 13.0964 12.4411 13.1089 11.9423 13.1126C10.9017 13.1207 9.86081 13.1135 8.81989 13.1192C8.69646 13.1198 8.67615 13.0779 8.67677 12.9676C8.68052 11.9427 8.67896 10.9174 8.67896 9.89248L8.67927 9.89217Z"
				fill="#FEFEFE"
			/>
			<path
				d="M8.68845 15.8778C8.68845 15.4515 8.69063 15.025 8.68657 14.5988C8.68563 14.506 8.6972 14.4625 8.80938 14.4644C9.25125 14.4719 9.69343 14.471 10.1353 14.4644C10.2378 14.4629 10.2525 14.4988 10.2522 14.5888C10.249 15.4572 10.2484 16.3256 10.2528 17.1936C10.2534 17.2946 10.2209 17.3124 10.1294 17.3114C9.68749 17.3071 9.24531 17.3058 8.80345 17.3121C8.70095 17.3136 8.68563 17.278 8.68657 17.1877C8.69095 16.7508 8.68845 16.3143 8.68845 15.8775V15.8778Z"
				fill="#FEFEFE"
			/>
			<path
				d="M10.2451 9.89333C10.2451 9.31556 10.2463 8.73809 10.2435 8.16031C10.2432 8.075 10.251 8.03094 10.3579 8.03157C11.071 8.03688 11.7841 8.02282 12.4969 8.03938C12.9035 8.04876 13.2997 8.14062 13.6591 8.34655C14.1309 8.61685 14.3578 9.04026 14.4056 9.56929C14.4409 9.96146 14.4237 10.3486 14.2553 10.7142C13.9866 11.2976 13.4866 11.5601 12.8922 11.6876C12.6269 11.7445 12.3569 11.7576 12.086 11.7576C11.5238 11.7573 10.9616 11.7523 10.3998 11.7613C10.2629 11.7635 10.2407 11.7189 10.2423 11.5954C10.2488 11.0283 10.2451 10.4608 10.2451 9.89365V9.89333Z"
				fill="#04AB38"
			/>
		</svg>
	);
};
