import { universalTypeFilterId } from '../../../../common/constants/filters';

import { filter as archivedFilter } from './archived';
import { filter as assigneeFilter } from './assignee';
import { filter as contentFilter } from './content';
import { filter as contributorFilter } from './contributor';
import { filter as focusAreaTypeFilter } from './focus-area-type';
import { filter as labelFilter } from './label';
import { filter as lastUpdatedFilter } from './last-updated';
import { filter as ownerFilter } from './owner';
import { filter as pageStatusFilter } from './page-status';
import { filter as projectFilter } from './project';
import { filter as spaceFilter } from './space';
import { filter as titleMatchFilter } from './title-match-only';
import { filter as universalTypeFilter } from './universal-type';
import { filter as verifiedFilter } from './verified';

export const allFilters = Object.freeze({
	[lastUpdatedFilter.id]: lastUpdatedFilter,
	[contributorFilter.id]: contributorFilter,
	[spaceFilter.id]: spaceFilter,
	[verifiedFilter.id]: verifiedFilter,
	[assigneeFilter.id]: assigneeFilter,
	[projectFilter.id]: projectFilter,
	[universalTypeFilterId]: universalTypeFilter,
	[contentFilter.id]: contentFilter,
	[titleMatchFilter.id]: titleMatchFilter,
	[ownerFilter.id]: ownerFilter,
	[labelFilter.id]: labelFilter,
	[focusAreaTypeFilter.id]: focusAreaTypeFilter,
	[pageStatusFilter.id]: pageStatusFilter,
	[archivedFilter.id]: archivedFilter,
});

export {
	archivedFilter,
	assigneeFilter,
	contentFilter,
	contributorFilter,
	lastUpdatedFilter,
	pageStatusFilter,
	projectFilter,
	spaceFilter,
	verifiedFilter,
	titleMatchFilter,
	universalTypeFilter,
	ownerFilter,
	labelFilter,
	focusAreaTypeFilter,
};
