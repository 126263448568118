import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	lastActive: {
		id: 'search-page.results.product-results.third-party.last-active',
		defaultMessage: 'Last active {relativeDate}',
		description: 'Label for the last active time',
	},
	threadTypeLabel: {
		id: 'search-page.results.product-results.third-party.thread-type-label',
		defaultMessage: 'Thread',
		description: 'Thread metadata label',
	},
	messageTypeLabel: {
		id: 'search-page.results.product-results.third-party.message-type-label',
		defaultMessage: 'Message',
		description: 'Message metadata label',
	},
	channelTypeLabel: {
		id: 'search-page.results.product-results.third-party.channel-type-label',
		defaultMessage: 'Channel',
		description: 'Channel metadata label',
	},
	channelMemberCount: {
		id: 'search-page.results.product-results.third-party.channel-member-count',
		defaultMessage: '{memberCount, plural, one {# member} other {# members}}',
		description: 'Label for the number of members in a channel',
	},
	privateConversationText: {
		id: 'search-page.results.product-results.third-party.private-conversation-text',
		defaultMessage: '{users} in a private conversation',
		description: 'Label for a private conversation',
	},
	noContributorsText: {
		id: 'search-page.results.product-results.third-party.no-contributors-label',
		defaultMessage: 'External people in #{channelName}',
		description: 'Text for no contributors in the search result title',
	},
	externalPeopleText: {
		id: 'search-page.results.product-results.third-party.external-people-label',
		defaultMessage: 'External people',
		description: 'Text for external people in the search result title',
	},
	externalPersonText: {
		id: 'search-page.results.product-results.third-party.external-person-label',
		defaultMessage: 'External person',
		description: 'Text for external person in the search result title',
	},
	titleWithRemainingContributors: {
		id: 'search-page.results.product-results.third-party.title-with-remaining-contributors',
		defaultMessage:
			'{title} and {remainingContributorsCount} {remainingContributorsCount, plural, one {other} other {others}}',
		description: 'Label for the title with remaining contributors',
	},
	titleAndChannelName: {
		id: 'search-page.results.product-results.third-party.title-and-channel-name',
		defaultMessage: '{title} in #{channelName}',
		description: 'Label for the title and channel name',
	},
});
