const MAX_WORDS = 20;
export const MAX_CHARACTERS_IN_EXCERPT = 250;

// This function finds the first occurrence of the query within the text
// and truncates the text surrounding the query.
export const truncateTextWithQuery = (text: string | undefined, query: string) => {
	if (!text) {
		return '';
	}

	const lowerCaseQuery = query.toLowerCase();
	const words = text.split(' ');
	const indexOfQuery = words.findIndex((value) => lowerCaseQuery === value.toLowerCase());

	if (indexOfQuery > MAX_WORDS) {
		return ['...']
			.concat(words.slice(indexOfQuery - MAX_WORDS, indexOfQuery + MAX_WORDS))
			.concat('...')
			.join(' ');
	}

	return (
		words
			.slice(0, 2 * MAX_WORDS)
			.join(' ')
			.substring(0, MAX_CHARACTERS_IN_EXCERPT) +
		(text.length > MAX_CHARACTERS_IN_EXCERPT ? '...' : '')
	);
};
