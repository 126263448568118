import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const FreshserviceSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-211.5"
				y="-646.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#09C6FA"
			/>
			<path
				d="M19.5937 5.66673C20.9365 5.66673 22.2798 5.67113 23.6242 5.66673C24.3026 5.66154 24.9068 5.84322 25.42 6.29401C25.9835 6.78752 26.3103 7.40801 26.3131 8.15706C26.3247 10.8806 26.3558 13.6049 26.3051 16.3272C26.2592 18.7784 25.3817 20.9421 23.7647 22.788C22.0746 24.7185 19.9473 25.8685 17.406 26.2278C15.4367 26.5073 13.5375 26.2314 11.731 25.3997C9.20853 24.2386 7.42672 22.3508 6.38956 19.781C5.76583 18.2346 5.54914 16.6171 5.72592 14.9517C5.95817 12.7665 6.7954 10.8461 8.23761 9.19041C9.63432 7.59607 11.3635 6.53557 13.4106 5.99614C14.2837 5.77043 15.1826 5.66009 16.0843 5.66793C17.2533 5.66873 18.4231 5.66833 19.5937 5.66673ZM17.596 14.8642C16.8082 14.8642 16.0205 14.8618 15.2328 14.8666C15.0959 14.8666 15.0528 14.8431 15.1031 14.6961C15.297 14.1259 15.4798 13.5522 15.6669 12.9792C15.8598 12.3899 16.0524 11.8005 16.2448 11.2112C16.2947 11.0583 16.2448 10.9345 16.1119 10.8594C15.979 10.7843 15.8449 10.8119 15.7447 10.9369C15.6889 11.0067 15.6386 11.081 15.5851 11.1537C14.6667 12.4335 13.7489 13.7129 12.8316 14.992C12.4663 15.5009 12.0999 16.0088 11.7322 16.5156C11.6866 16.5715 11.6581 16.6394 11.6503 16.7111C11.6424 16.7828 11.6555 16.8553 11.6879 16.9197C11.7561 17.0667 11.8818 17.1226 12.0355 17.1285C12.1009 17.1309 12.1668 17.1305 12.2322 17.1305H16.6722C16.9403 17.1305 16.9451 17.1305 16.8597 17.3941C16.499 18.4996 16.1366 19.6042 15.7727 20.7081C15.7068 20.9078 15.738 21.0315 15.8816 21.1114C16.0253 21.1913 16.1502 21.1569 16.2807 20.9916C16.2994 20.9685 16.3166 20.9441 16.3342 20.9197C17.6378 19.1158 18.9406 17.3118 20.2426 15.5079C20.3403 15.3725 20.389 15.2316 20.3064 15.075C20.2238 14.9185 20.0833 14.8614 19.905 14.8622C19.1356 14.867 18.3658 14.8642 17.596 14.8642Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.2169 5.00016C27.1662 5.00016 29.116 5.00653 31.0676 5.00016C32.0524 4.99262 32.9294 5.25634 33.6744 5.91071C34.4923 6.62711 34.9668 7.52781 34.9708 8.61515C34.9876 12.5686 35.0328 16.5233 34.9592 20.475C34.8926 24.0332 33.6188 27.1741 31.2715 29.8536C28.8183 32.656 25.7301 34.3252 22.0413 34.8469C19.1825 35.2526 16.4257 34.8521 13.8033 33.6448C10.1416 31.9593 7.55515 29.2189 6.0496 25.4886C5.14418 23.2438 4.82963 20.8958 5.08625 18.4783C5.42339 15.3063 6.63873 12.5186 8.73225 10.1152C10.7597 7.8008 13.2698 6.26138 16.2415 5.47833C17.5089 5.15069 18.8137 4.99051 20.1227 5.0019C21.8196 5.00305 23.5177 5.00248 25.2169 5.00016ZM22.317 18.3513C21.1735 18.3513 20.03 18.3479 18.8865 18.3548C18.6878 18.3548 18.6252 18.3206 18.6982 18.1073C18.9798 17.2797 19.2451 16.4468 19.5167 15.615C19.7967 14.7595 20.0763 13.904 20.3555 13.0485C20.428 12.8266 20.3555 12.6469 20.1626 12.5379C19.9697 12.4289 19.7751 12.4689 19.6297 12.6504C19.5486 12.7518 19.4756 12.8596 19.398 12.9651C18.0649 14.8229 16.7325 16.6801 15.4009 18.5368C14.8707 19.2756 14.3387 20.0129 13.805 20.7486C13.7388 20.8297 13.6975 20.9282 13.6861 21.0323C13.6747 21.1364 13.6937 21.2416 13.7407 21.3351C13.8398 21.5484 14.0223 21.6296 14.2453 21.6383C14.3403 21.6418 14.4359 21.6412 14.5309 21.6412H20.976C21.3652 21.6412 21.3722 21.6412 21.2482 22.0237C20.7245 23.6284 20.1986 25.232 19.6703 26.8344C19.5747 27.1242 19.6199 27.3039 19.8284 27.4198C20.0369 27.5357 20.2183 27.4859 20.4077 27.2459C20.4349 27.2123 20.4598 27.177 20.4853 27.1416C22.3776 24.523 24.2688 21.9043 26.1588 19.2857C26.3007 19.0892 26.3714 18.8846 26.2515 18.6574C26.1316 18.4302 25.9277 18.3473 25.6687 18.3484C24.5519 18.3554 23.4344 18.3513 22.317 18.3513Z"
				fill="#09C6FA"
			/>
		</svg>
	);
};
