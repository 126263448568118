import React from 'react';

export const CompassSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18698)">
			<g clipPath="url(#clip1_2230_18698)">
				<path
					d="M19.5833 19.3159V26.7434H12.1417V19.3159H19.5833V11.8934H6.00833C5.28333 11.8984 4.7 12.4801 4.705 13.1934C4.705 13.1993 4.705 13.2051 4.705 13.2109V32.8876C4.69 33.6009 5.26583 34.1901 5.99 34.2051C5.99583 34.2051 6.0025 34.2051 6.00833 34.2051H25.7033C26.4283 34.2001 27.0117 33.6184 27.0067 32.9051C27.0067 32.8993 27.0067 32.8934 27.0067 32.8876V19.3159H19.5833Z"
					fill="#1868DB"
				/>
				<path
					d="M19.5833 4.46666V11.8933H27.0425V19.3158H34.4616V5.76666C34.4766 5.05332 33.9008 4.46416 33.1766 4.44916C33.1708 4.44916 33.1641 4.44916 33.1583 4.44916L19.5833 4.46666Z"
					fill="#1868DB"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18698">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18698">
				<rect width="40" height="40" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
