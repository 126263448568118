import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const FreshserviceIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-683.5"
				y="-652.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#09C6FA"
			/>
			<path
				d="M14.6954 4.25008C15.7025 4.25008 16.7099 4.25338 17.7183 4.25008C18.2271 4.24619 18.6802 4.38244 19.0651 4.72054C19.4877 5.09067 19.7328 5.55604 19.7349 6.11783C19.7436 8.16046 19.767 10.2037 19.7289 12.2454C19.6945 14.0838 19.0364 15.7066 17.8236 17.091C16.5561 18.5389 14.9606 19.4014 13.0546 19.6709C11.5776 19.8805 10.1533 19.6736 8.79837 19.0498C6.90652 18.179 5.57016 16.7631 4.79229 14.8358C4.32449 13.676 4.16198 12.4628 4.29456 11.2138C4.46875 9.57492 5.09668 8.1346 6.17833 6.89283C7.22587 5.69708 8.52271 4.90171 10.0581 4.49714C10.7129 4.32786 11.3871 4.2451 12.0634 4.25098C12.9401 4.25158 13.8175 4.25128 14.6954 4.25008ZM13.1971 11.1482C12.6063 11.1482 12.0155 11.1464 11.4247 11.15C11.322 11.15 11.2897 11.1323 11.3274 11.0221C11.4729 10.5945 11.6099 10.1642 11.7503 9.73443C11.895 9.29243 12.0394 8.85042 12.1837 8.40841C12.2211 8.29372 12.1837 8.20089 12.084 8.14459C11.9844 8.08829 11.8838 8.10895 11.8087 8.20268C11.7668 8.25509 11.7291 8.31079 11.689 8.36529C11.0002 9.32517 10.3118 10.2847 9.62382 11.244C9.34987 11.6257 9.07501 12.0067 8.79926 12.3868C8.76505 12.4287 8.74371 12.4796 8.73781 12.5334C8.73192 12.5872 8.74172 12.6415 8.76604 12.6898C8.81722 12.8 8.9115 12.842 9.02673 12.8464C9.07581 12.8482 9.1252 12.8479 9.17428 12.8479H12.5042C12.7054 12.8479 12.709 12.8479 12.6449 13.0456C12.3744 13.8747 12.1026 14.7032 11.8296 15.5311C11.7802 15.6809 11.8036 15.7737 11.9113 15.8336C12.0191 15.8935 12.1128 15.8677 12.2106 15.7437C12.2247 15.7264 12.2376 15.7081 12.2507 15.6898C13.2284 14.3369 14.2055 12.9839 15.182 11.6309C15.2554 11.5294 15.2919 11.4237 15.2299 11.3063C15.168 11.1889 15.0626 11.1461 14.9288 11.1467C14.3518 11.1503 13.7745 11.1482 13.1971 11.1482Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.7823 4.00008C15.822 4.00008 16.8619 4.00348 17.9027 4.00008C18.428 3.99606 18.8957 4.13672 19.293 4.48571C19.7293 4.86779 19.9823 5.34817 19.9844 5.92808C19.9934 8.0366 20.0175 10.1457 19.9783 12.2533C19.9427 14.151 19.2634 15.8262 18.0115 17.2552C16.7031 18.7499 15.0561 19.6401 13.0887 19.9183C11.564 20.1347 10.0937 19.9211 8.69509 19.2772C6.74221 18.3783 5.36275 16.9168 4.55979 14.9272C4.0769 13.73 3.90914 12.4778 4.046 11.1884C4.22581 9.49669 4.87399 8.00991 5.99053 6.72809C7.07186 5.49376 8.41054 4.67273 9.99546 4.25511C10.6714 4.08037 11.3673 3.99494 12.0654 4.00101C12.9704 4.00163 13.8761 4.00132 14.7823 4.00008ZM13.2357 11.1207C12.6259 11.1207 12.016 11.1189 11.4061 11.1226C11.3002 11.1226 11.2668 11.1043 11.3057 10.9906C11.4559 10.5491 11.5974 10.1049 11.7423 9.66135C11.8916 9.20508 12.0407 8.74882 12.1896 8.29256C12.2282 8.17416 12.1896 8.07833 12.0867 8.02022C11.9839 7.9621 11.8801 7.98343 11.8025 8.08019C11.7593 8.13428 11.7203 8.19178 11.6789 8.24804C10.9679 9.23888 10.2573 10.2294 9.54717 11.2196C9.26438 11.6137 8.98066 12.0069 8.69601 12.3992C8.6607 12.4425 8.63867 12.4951 8.63258 12.5506C8.6265 12.6061 8.63662 12.6622 8.66172 12.7121C8.71455 12.8258 8.81187 12.8691 8.93082 12.8737C8.98148 12.8756 9.03246 12.8753 9.08313 12.8753H12.5205C12.7281 12.8753 12.7318 12.8753 12.6657 13.0793C12.3864 13.9352 12.1059 14.7904 11.8241 15.645C11.7732 15.7996 11.7973 15.8954 11.9085 15.9572C12.0197 16.0191 12.1164 15.9925 12.2174 15.8645C12.232 15.8466 12.2452 15.8277 12.2588 15.8089C13.2681 14.4122 14.2767 13.0156 15.2847 11.619C15.3604 11.5142 15.3981 11.4051 15.3341 11.2839C15.2702 11.1628 15.1614 11.1186 15.0233 11.1192C14.4277 11.1229 13.8317 11.1207 13.2357 11.1207Z"
				fill="#09C6FA"
			/>
		</svg>
	);
};
