// This function is used to override the values of an object with the values of another object.
// Unless the value is null or undefined, the value from the source object will be used.
export function extend<T extends Record<string, any>>(
	target: T,
	source: Partial<{ [K in keyof T]: T[K] | null | undefined }>,
): T {
	const result = { ...target };
	for (const key in source) {
		if (source[key] !== null && source[key] !== undefined) {
			result[key] = source[key] as T[Extract<keyof T, string>];
		}
	}
	return result;
}
