import {
	type Maybe,
	type SearchConfluenceSpaceType,
	type SearchContainerStatus,
} from '../../../../../common/types';
import { type AggRequest, type AggResponse, fetchAgg } from '../../../../../common/utils/fetch-agg';
import { generateHashFromVariables } from '../../../../../utils/caching/common/key-generator';
import { inMemoryCache } from '../../../../../utils/caching/in-memory';

const operationName = 'SmartsRecommendedContainerQuery';
const SmartsRecommendedContainerQuery = `
query ${operationName}($userId: String!, $tenantId: String!) {
  smarts {
    results: recommendedContainer(
      recommendationsQuery: {
        context: {
          userId: $userId,
          tenantId: $tenantId,
          product: "confluence"
        },
        modelRequestParams: {
          caller: "full-page-search",
          experience: "CgContainerNearbyUser"
        },
        requestingUserId: $userId
      }
    ) {
      id
      score
      container {
        createdDate
        id
        key
        name
        spaceId
        status
        type
        links {
          base
        }
        icon {
          path
        }
      }
    }
  }
}
`;

export type SmartsRecommendedContainer = {
	id: string;
	score: Maybe<number>;
	container: Maybe<{
		id: string;
		key: Maybe<string>;
		spaceId: string;
		createdDate: Maybe<string>;
		name: Maybe<string>;
		links: Maybe<{
			base: Maybe<string>;
		}>;
		icon: Maybe<{
			path: Maybe<string>;
		}>;
		type: Maybe<SearchConfluenceSpaceType>;
		status: Maybe<SearchContainerStatus>;
	}>;
};

export type SmartsRecommendedContainerQueryVariables = {
	userId: string;
	tenantId: string;
};

export type SmartsRecommendedContainerQueryData = {
	smarts: {
		results: Maybe<Array<SmartsRecommendedContainer>>;
	};
};

export const cache = inMemoryCache<AggResponse<SmartsRecommendedContainerQueryData>>();

// Expire the cache if there are errors or edges are empty
const expireWhen = (result: AggResponse<SmartsRecommendedContainerQueryData>) => {
	const { data, errors } = result;
	if (!data || (errors && errors.length > 0)) {
		return true;
	}
	const { results } = data.smarts;
	return !results || results.length === 0;
};

export type SmartsRecommendedContainerQueryProps = Pick<
	AggRequest<SmartsRecommendedContainerQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
>;

export const smartsRecommendedContainerQuery = ({
	variables,
	aggAbsoluteUrl,
}: SmartsRecommendedContainerQueryProps) =>
	cache.inMemoryDecorator(
		generateHashFromVariables(variables),
		() =>
			fetchAgg<SmartsRecommendedContainerQueryVariables, SmartsRecommendedContainerQueryData>({
				variables,
				aggAbsoluteUrl,
				graphQuery: SmartsRecommendedContainerQuery,
				operationName,
			}),
		expireWhen,
	);
