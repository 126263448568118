import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const StripeSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-646.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#635BFF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.752 12.4395C14.752 11.6875 15.368 11.3915 16.392 11.3915C17.864 11.3915 19.72 11.8395 21.192 12.6315V8.08746C19.584 7.44746 18 7.19946 16.4 7.19946C12.472 7.19946 9.86401 9.24746 9.86401 12.6715C9.86401 18.0075 17.216 17.1595 17.216 19.4635C17.216 20.3515 16.44 20.6395 15.36 20.6395C13.752 20.6395 11.704 19.9835 10.08 19.0955V23.6955C11.88 24.4715 13.696 24.7995 15.36 24.7995C19.384 24.7995 22.152 22.8075 22.152 19.3435C22.12 13.5835 14.752 14.6075 14.752 12.4395Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M5 6H35V36H5V6Z" fill="#635BFF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.8301 17.662C18.8301 16.957 19.4076 16.6795 20.3676 16.6795C21.7476 16.6795 23.4876 17.0995 24.8676 17.842V13.582C23.3601 12.982 21.8751 12.7495 20.3751 12.7495C16.6926 12.7495 14.2476 14.6695 14.2476 17.8795C14.2476 22.882 21.1401 22.087 21.1401 24.247C21.1401 25.0795 20.4126 25.3495 19.4001 25.3495C17.8926 25.3495 15.9726 24.7345 14.4501 23.902V28.2145C16.1376 28.942 17.8401 29.2495 19.4001 29.2495C23.1726 29.2495 25.7676 27.382 25.7676 24.1345C25.7376 18.7345 18.8301 19.6945 18.8301 17.662Z"
				fill="white"
			/>
		</svg>
	);
};
