import { fg } from '@atlaskit/platform-feature-flags';

import { ThirdPartyConfigs } from '../../../../../common/constants/3p-product-configs';
import {
	ProductKeys,
	type ProductKeys1P,
	ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createBooleanFilter } from '../../../../filters/boolean-filter';

const productKeys1P: ProductKeys1P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('onlyTitleMatch');

const thirdPartyProductsToExclude: ProductKeys3P[] = [ProductKeys3P.Slack, ProductKeys3P.Teams];
const allThirdPartyProducts: ProductKeys3P[] = [...ThirdPartyConfigs.keys()].filter(
	(val: ProductKeys3P) => !thirdPartyProductsToExclude.includes(val),
);

const getProducts1P = () => {
	return fg('add_confluence_title_match_filter') ? [ProductKeys.Confluence] : productKeys1P;
};

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config')
		? products3PFromConfig
		: allThirdPartyProducts;
};
export const filter = createBooleanFilter({
	id: 'onlyTitleMatch',
	queryParams: { key: 'titleMatch' },
	products: [...getProducts1P(), ...getProducts3P()],
	alwaysVisible: true,
	universal: false,
});
