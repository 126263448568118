import { fg } from '@atlaskit/platform-feature-flags';
import { type EntityATI } from '@atlassian/search-client';

import type { CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import type { FetchedSelectFilterOption } from '../../../../../common/constants/filters/select-filter/types';
import {
	allTypeFilterValueKeys,
	TypeFilterValueKey,
} from '../../../../../common/constants/filters/universal-type';
import {
	is3pProductKey,
	type ProductKeys,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { type ThirdPartyConfigsBootstrap } from '../../../../../common/constants/schemas/3p-config';
import { ContentType } from '../../../../../common/constants/schemas/query-params';
import {
	featureGateNotRequestedATIsInAllTypesFilter,
	getTypeMapping,
} from '../../../../../common/utils/filters/constants';

import { typeLabels } from './messages';

type FilterOption = FetchedSelectFilterOption & { products: ProductKeys[] };

export const isProductEntitiesIncluded = (
	availableProducts: ProductKeys[],
	product: ProductKeys,
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
) => {
	if (is3pProductKey(product)) {
		return (
			availableProducts.includes(product) &&
			!thirdPartyConfigs[product as ProductKeys3P]?.userNeedsOAuth
		);
	} else {
		return availableProducts.includes(product);
	}
};

/**
 * Given the user's search input within the Type filter,
 * return the matching types to the user.
 */
export const lookupOptions = (query: string, config: CloudConfig): FetchedSelectFilterOption[] => {
	const allOptions = getAllTypeOptions(config);
	const matchingOptions = allOptions
		.filter((option) => lookupMatches(query, option.label))
		.map((option) => ({
			label: option.label,
			trackingKey: option.value,
			value: option.value,
			queryParamValue: option.queryParamValue,
			isDefault: option.isDefault,
		}));
	return matchingOptions;
};

export const isEntityEnabled = (
	ati: EntityATI,
	selectedTypes: TypeFilterValueKey[],
	dropAttachmentsComments: boolean,
): boolean => {
	if (selectedTypes.length === 0) {
		return !featureGateNotRequestedATIsInAllTypesFilter(dropAttachmentsComments).includes(ati);
	}
	return true;
};

/**
 * This function is used for populating the entities for the search request
 */
export const getEntitiesForQuery = (
	products: ProductKeys[],
	selectedTypes: TypeFilterValueKey[],
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
	availableProducts: ProductKeys[],
	dropAttachmentsComments: boolean,
): {
	entitiesForQuery: EntityATI[];
	entitiesForResultsCountQuery: EntityATI[];
} => {
	if (fg('confluence_performance_improve_search_query')) {
		return getEntitiesForQueryPerformanceImproved({
			products,
			selectedTypes,
			thirdPartyConfigs,
			availableProducts,
			dropAttachmentsComments,
		});
	}
	const selectedEntities = new Set<EntityATI>();
	const unselectedEntities = new Set<EntityATI>();
	const types = selectedTypes.length === 0 ? allTypeFilterValueKeys : selectedTypes;
	const typeMapping = getTypeMapping();

	const otherProducts = availableProducts.filter((product) => !products.includes(product));
	types.forEach((type) => {
		const productToEntityMap = typeMapping[type].entities;
		products.forEach((product) => {
			if (isProductEntitiesIncluded(products, product, thirdPartyConfigs)) {
				productToEntityMap[product]?.forEach((ati) => {
					if (isEntityEnabled(ati, selectedTypes, dropAttachmentsComments)) {
						selectedEntities.add(ati);
					}
				});
			}
		});
		// collect unselected entities
		otherProducts.forEach((product) => {
			if (isProductEntitiesIncluded(otherProducts, product, thirdPartyConfigs)) {
				productToEntityMap[product]?.forEach((ati) => {
					if (!selectedEntities.has(ati)) {
						unselectedEntities.add(ati);
					}
				});
			}
		});
	});

	/// result count wants all entities for selected type
	const entitiesForResultsCountQuery = Array.from([
		...selectedEntities,
		...unselectedEntities,
	]).sort();

	// Do not show Atlas goal and tag results when no product is selected
	if (types.includes('project') && products.length > 1) {
		selectedEntities.delete('ati:cloud:townsquare:goal');
		selectedEntities.delete('ati:cloud:townsquare:tag');
	}

	// Sorting helps a ton in testing
	const entitiesForQuery = Array.from(selectedEntities).sort();

	return {
		entitiesForQuery,
		entitiesForResultsCountQuery,
	};
};

const isProductEntitiesIncludedSimplified = (
	availableProducts: ProductKeys[],
	product: ProductKeys,
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
) => {
	// Main change from the original code
	if (is3pProductKey(product)) {
		return (
			availableProducts.includes(product) &&
			!thirdPartyConfigs[product as ProductKeys3P]?.userNeedsOAuth
		);
	}

	return availableProducts.includes(product);
};
const getProductsWithEntities = (
	products: ProductKeys[],
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
): ProductKeys[] => {
	return products.reduce<ProductKeys[]>((acc, product) => {
		if (isProductEntitiesIncludedSimplified(products, product, thirdPartyConfigs)) {
			acc.push(product);
		}

		return acc;
	}, []);
};

export const getEntitiesForQueryPerformanceImproved = ({
	products,
	selectedTypes,
	thirdPartyConfigs,
	availableProducts,
	dropAttachmentsComments,
}: {
	products: ProductKeys[];
	selectedTypes: TypeFilterValueKey[];
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	availableProducts: ProductKeys[];
	dropAttachmentsComments: boolean;
}): {
	entitiesForQuery: EntityATI[];
	entitiesForResultsCountQuery: EntityATI[];
} => {
	const otherProducts = availableProducts.filter((product) => !products.includes(product));
	const productsWithEntities = getProductsWithEntities(products, thirdPartyConfigs);

	const otherProductsWithEntities = otherProducts.reduce<ProductKeys[]>((acc, otherProduct) => {
		if (isProductEntitiesIncluded(otherProducts, otherProduct, thirdPartyConfigs)) {
			acc.push(otherProduct);
		}

		return acc;
	}, []);

	// Stuff that we can't be cached that easy
	const selectedEntities = new Set<EntityATI>();
	const unselectedEntities = new Set<EntityATI>();
	const types = selectedTypes.length === 0 ? allTypeFilterValueKeys : selectedTypes;
	const typeMapping = getTypeMapping();

	// Using normal-loop to reduce overhead and improve performance
	for (let i = 0; i < types.length; i++) {
		const type = types[i];

		const productToEntityMap = typeMapping[type].entities;

		for (let h = 0; h < productsWithEntities.length; h++) {
			const product = productsWithEntities[h];
			const arr = productToEntityMap[product];

			if (!arr || !Array.isArray(arr)) {
				continue;
			}

			for (let w = 0; w < arr.length; w++) {
				const ati = arr[w];

				if (isEntityEnabled(ati, selectedTypes, dropAttachmentsComments)) {
					selectedEntities.add(ati);
				}
			}
		}

		for (let j = 0; j < otherProductsWithEntities.length; j++) {
			const product = productsWithEntities[j];

			const arr = productToEntityMap[product];

			if (!arr || !Array.isArray(arr)) {
				continue;
			}

			for (let w = 0; w < arr.length; w++) {
				const ati = arr[w];
				if (!selectedEntities.has(ati)) {
					unselectedEntities.add(ati);
				}
			}
		}
	}

	/// result count wants all entities for selected type
	const entitiesForResultsCountQuery = Array.from([
		...selectedEntities,
		...unselectedEntities,
	]).sort();

	// Do not show Atlas goal and tag results when no product is selected
	if (types.includes('project') && products.length > 1) {
		selectedEntities.delete('ati:cloud:townsquare:goal');
		selectedEntities.delete('ati:cloud:townsquare:tag');
	}

	// Sorting helps a ton in testing
	const entitiesForQuery = Array.from(selectedEntities).sort();

	return {
		entitiesForQuery,
		entitiesForResultsCountQuery,
	};
};

export const lookupMatches = (query: string, label: string): boolean => {
	return label.toLowerCase().includes(query.toLowerCase());
};

export const getAllTypeOptions = (config: CloudConfig): FilterOption[] => {
	const typeMapping = getTypeMapping();

	const allOption: FilterOption = {
		products: [],
		label: config.intl?.formatMessage(typeLabels['all']) || '',
		value: 'all',
		trackingKey: 'all',
		queryParamValue: ContentType['All'],
		isDefault: true,
	};

	const typeOptions: FilterOption[] = allTypeFilterValueKeys.map((type) => {
		const messageKey =
			type === 'page' && fg('confluence_live_pages_open_beta_trait_opted_in')
				? 'pageAndLiveDoc'
				: type;
		return {
			products: typeMapping[type].products,
			value: type,
			trackingKey: type,
			queryParamValue: type,
			isDefault: false,
			label: config.intl?.formatMessage(typeLabels[messageKey]) || '',
		};
	});

	return [allOption, ...typeOptions];
};
