import { AnyAri } from '@atlassian/ari';

import type { Maybe, SearchResult } from '../../../../common/types';

import { FederatedEmailPartial } from './FederatedEmailPartial';

const SearchResultFederatedPartial = `
... on SearchResultFederated {
	lastModifiedDate
	entity {
		${FederatedEmailPartial}
	}
}
`;

export type SearchFederatedEntity = SearchFederatedEmailEntity;

export type SearchFederatedEmailPartial = SearchResultFederated<SearchFederatedEmailEntity>;

export type ThirdPartyRestrictionLevel = 'restricted' | 'public' | 'private';

export interface SearchResultFederated<EntityType extends SearchFederatedEntity>
	extends SearchResult {
	lastModifiedDate?: string;
	entity?: EntityType;
}

enum ResourceType {
	EMAIL = 'email',
}

export interface SearchFederatedEmailEntity {
	id?: Maybe<string>;
	sender?: Maybe<SearchFederatedEmailUser>;
}

export interface SearchFederatedEmailUser {
	email?: Maybe<string>;
	name?: Maybe<string>;
}

export const getFederatedPartials = () => {
	return SearchResultFederatedPartial;
};

export const isSearchResultFederated = (
	partial: SearchResult,
): partial is SearchResultFederated<SearchFederatedEntity> =>
	partial.__typename === 'SearchResultFederated';

export const isSearchFederatedEmailEntity = (partial: SearchResult) => {
	const { resourceType } = AnyAri.parse(partial.id);
	return resourceType === ResourceType.EMAIL;
};
