import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';

import { messages } from './messages';

const feedbackButtonStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	color: 'color.link',
	padding: 'space.0',
	':hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
});

type FeedbackButtonProps = {
	onClick: (e: React.KeyboardEvent | React.MouseEvent) => void;
};

export const FeedbackButton = ({ onClick }: FeedbackButtonProps) => (
	<Pressable xcss={feedbackButtonStyles} onClick={onClick}>
		<FormattedMessage {...messages.feedbackButton} />
	</Pressable>
);
