import React from 'react';

export const JiraServiceManagementSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18708)">
			<g clipPath="url(#clip1_2230_18708)">
				<g clipPath="url(#clip2_2230_18708)">
					<path
						d="M23.4886 16.3121H32.6441C34.0191 16.3121 34.4886 17.62 33.6502 18.6596L19.3301 36.3334C14.702 32.6444 15.138 26.809 18.5252 22.5499L23.4886 16.3121ZM16.4124 23.3547H7.2569C5.8819 23.3547 5.41238 22.0468 6.2508 21.0072L20.5709 3.3334C25.199 7.02243 24.6959 12.7907 21.3423 17.0834L16.4124 23.3547Z"
						fill="#0C66E4"
					/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18708">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18708">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip2_2230_18708">
				<rect width="28.2341" height="33" fill="white" transform="translate(5.83331 3.33334)" />
			</clipPath>
		</defs>
	</svg>
);
