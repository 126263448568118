import { useCallback, useMemo } from 'react';

import { is3pProductKey, type ProductKeys } from '../../constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../constants/schemas/3p-config';

import { BannerType } from './types';

export const DEFAULT_BULK_CONNECT_BANNER_STATE: BulkConnectBannerState = {
	initialBannerDismissed: false,
	secondaryBannerDismissed: false,
};

interface BulkConnectBannerState {
	initialBannerDismissed: boolean;
	secondaryBannerDismissed: boolean;
}

interface UseBulkConnectBannerProps {
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	availableProducts: ProductKeys[];
	newlyConnectedProducts: ProductKeys[];
	useFeatureFlaggedLocalStorageCallback: () => () => any[];
}

export const useBulkConnectBanner = ({
	thirdPartyConfigs,
	availableProducts,
	newlyConnectedProducts,
	useFeatureFlaggedLocalStorageCallback,
}: UseBulkConnectBannerProps) => {
	const useLocalStorage = useFeatureFlaggedLocalStorageCallback();
	const [storedState, setState] = useLocalStorage();

	const hasProductsNeedingOAuth = availableProducts.some((product) => {
		if (is3pProductKey(product) && thirdPartyConfigs[product]?.userNeedsOAuth) {
			return true;
		}
		return false;
	});

	const bannerState: BulkConnectBannerState = useMemo(() => {
		if (!storedState || !storedState.bulkConnectBanner) {
			return DEFAULT_BULK_CONNECT_BANNER_STATE;
		}
		return storedState.bulkConnectBanner;
	}, [storedState]);

	const bannerType: BannerType = useMemo(() => {
		if (!bannerState.initialBannerDismissed && hasProductsNeedingOAuth) {
			return BannerType.INITIAL;
		}

		if (!bannerState.secondaryBannerDismissed && newlyConnectedProducts.length > 0) {
			return BannerType.SECONDARY;
		}

		return BannerType.NONE;
	}, [bannerState, hasProductsNeedingOAuth, newlyConnectedProducts]);

	const dismissBanner = useCallback(() => {
		if (bannerType === BannerType.NONE) {
			return;
		}
		setState({
			...storedState,
			bulkConnectBanner: {
				initialBannerDismissed: true,
				secondaryBannerDismissed: true,
			},
		});
	}, [bannerType, storedState, setState]);

	return {
		bannerType,
		dismissBanner,
	};
};
