/**
 * Breakdown of the filtering & ordering logic :
 *
 * 0th pass: if the string has any highlight tags, remove them and continue
 * An alternate could be to exit early, but then we don't have the scoring information from the later passes
 *
 * 1st Pass - Complete match of query broken by words - This checks that all the words of the query must occur somewhere in the Title.
 *    - This is case insensitive.
 *    - This is unordered.
 *    For eg -
 *    Query - "mad max" will NOT let "Nomad" or "Climax" pass through,
 *    but  "Nomad Climax", "Madmax", "max Mad" will pass through
 *
 * 2nd Pass Scoring - Score calculation based on the kind of matches.
 *    There are 3 types - Exact match, StartsWith match & Partial match
 *    For eg -
 *    Exact Match (Score = 100) - Query: "Mad" will score "mad", "mad max" with 100.
 *            Note: Special characters stripped -  "Mad" will score "mad-max", "max-mad" with 100.
 *    StartsWith Match (Score = 10) - Query: "ark" will score "ARKK" and "Arkose Meeting" as 10
 *    Partial Match (Score = 1) - Query: "mad max" will score "Nomad Climax" as 2
 *
 *    Mix of multiple matching scenarios is possible.
 *    For example - "mad max" query will score "Mad Climax" as 100 (Exact) + 1 (Partial) = 101
 *
 * 3rd Pass - Sorting & Highlighting
 *    Sorting happens in the descending order of the score.
 *    Highlighting will match the entered query and inject necessary tags.
 *    Note: if both StartsWith and Partial matching exists in the same word then only StartsWith is highlighted
 *
 *    Two new properties are populated:
 *    1. highlightedText - highlights the query
 *    2. negatedHighlightedText - highights everything except the part that matches the query
 */

const HIGHLIGHT_START_TAG = '@@@hl@@@';
const HIGHLIGHT_END_TAG = '@@@endhl@@@';

const SPECIAL_CHARACTER_REGEX = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g;
const CONSECUTIVE_START_REGEX = new RegExp(`${HIGHLIGHT_START_TAG}${HIGHLIGHT_START_TAG}`, 'g');
const CONSECUTIVE_END_REGEX = new RegExp(`${HIGHLIGHT_END_TAG}${HIGHLIGHT_END_TAG}`, 'g');
const CONSECUTIVE_START_END_REGEX = new RegExp(`${HIGHLIGHT_START_TAG}${HIGHLIGHT_END_TAG}`, 'g');
const ESCAPE_SPECIAL_CHARACTERS = /[.*+?^${}()|[\]\\]/g;

const cleanStartRegex = new RegExp(HIGHLIGHT_START_TAG, 'g');
const cleanEndRegex = new RegExp(HIGHLIGHT_END_TAG, 'g');

export const highlightMatchedText = (source: string, matchWords: Array<string>) => {
	// Remove any previous highlighting and regenerate
	// Supports the use case where we perform this on the frontend, and later the backend starts doing it for us first
	const cleanedSource = source.replace(cleanStartRegex, '').replace(cleanEndRegex, '');

	const lowerCaseMatchWords = matchWords
		.filter((word) => word !== '')
		.map((word) => word.toLowerCase());

	const wordsDetails = cleanedSource.split(' ').map((word) => {
		const lowerCaseWord = word.toLowerCase();
		const alphabetOnlyWord = lowerCaseWord.replace(SPECIAL_CHARACTER_REGEX, ' ').split(' ');

		let partialCount = 0;
		let startsWithCount = 0;
		let exactMatchCount = 0;

		let exactMatch = '';
		let partialMatch = '';
		let startsWithMatch = '';

		for (let i = 0; i < lowerCaseMatchWords.length; ++i) {
			if (
				lowerCaseWord === lowerCaseMatchWords[i] ||
				alphabetOnlyWord.includes(lowerCaseMatchWords[i])
			) {
				exactMatchCount++;
				exactMatch = lowerCaseMatchWords[i];
				break;
			}

			if (lowerCaseWord.startsWith(lowerCaseMatchWords[i])) {
				startsWithCount++;
				startsWithMatch = lowerCaseMatchWords[i];
				continue;
			}

			if (lowerCaseWord.includes(lowerCaseMatchWords[i])) {
				partialCount++;
				partialMatch = lowerCaseMatchWords[i];
			}
		}

		const score = 100 * exactMatchCount + 10 * startsWithCount + partialCount;

		if (exactMatchCount > 0 || startsWithCount > 0 || partialCount > 0) {
			const pattern =
				exactMatchCount > 0 ? exactMatch : startsWithCount > 0 ? startsWithMatch : partialMatch;
			const regExp = new RegExp(`(${pattern.replace(ESCAPE_SPECIAL_CHARACTERS, '\\$&')})`, 'ig');

			const openingClosingRegExp = new RegExp(
				`(${HIGHLIGHT_START_TAG}|${HIGHLIGHT_END_TAG})`,
				'ig',
			);

			const highlighted = word
				.replace(regExp, `${HIGHLIGHT_START_TAG}$1${HIGHLIGHT_END_TAG}`)
				.replace(CONSECUTIVE_START_END_REGEX, ``);

			const mirror = {
				[`${HIGHLIGHT_START_TAG}`]: `${HIGHLIGHT_END_TAG}`,
				[`${HIGHLIGHT_END_TAG}`]: `${HIGHLIGHT_START_TAG}`,
			};

			const mirroredHighlighted = highlighted.replace(
				openingClosingRegExp,
				(ch) => mirror[ch as keyof typeof mirror],
			);

			const negatedHighlight = `${HIGHLIGHT_START_TAG}${mirroredHighlighted}${HIGHLIGHT_END_TAG}`
				.replace(CONSECUTIVE_START_REGEX, `${HIGHLIGHT_START_TAG}`)
				.replace(CONSECUTIVE_END_REGEX, `${HIGHLIGHT_END_TAG}`)
				.replace(CONSECUTIVE_START_END_REGEX, '');

			return {
				highlighted,
				negatedHighlight,
				score,
			};
		}

		// no match scenario
		return {
			negatedHighlight: `${HIGHLIGHT_START_TAG}${word}${HIGHLIGHT_END_TAG}`,
			highlighted: word,
			score,
		};
	});

	const highlightedTitle = wordsDetails.map((wordObj) => wordObj.highlighted).join(' ');
	const negatedHighlightedTitle = wordsDetails.map((wordObj) => wordObj.negatedHighlight).join(' ');

	const finalScore = wordsDetails.reduce((acc, word) => acc + word.score, 0);

	if (highlightedTitle !== '' && highlightedTitle !== cleanedSource) {
		return { highlightedTitle, negatedHighlightedTitle, score: finalScore };
	}

	return {};
};
