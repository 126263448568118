import React from 'react';

import { useIntl } from 'react-intl-next';

import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { N0, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { i18n } from './messages';

const clearFilterOptionsButtonStyles = xcss({
	background: token('color.background.input', N0),
	color: 'color.text.subtle',
	border: 'none',
	paddingLeft: 'space.250',
	font: 'inherit',
	cursor: 'pointer',
	outline: 'inherit',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	height: '36px',
	width: '100%',

	':hover': {
		backgroundColor: 'color.background.input.hovered',
	},

	':disabled': {
		color: 'color.text.accent.gray',
		cursor: 'default',
	},
});

type ClearFilterOptionsButtonProps = {
	onClick: () => void;
	children: string;
};

const ClearFilterOptionsButton = ({ onClick, children }: ClearFilterOptionsButtonProps) => {
	return (
		<Pressable onClick={onClick} xcss={clearFilterOptionsButtonStyles}>
			{children}
		</Pressable>
	);
};

const menuFooterStyles = xcss({
	borderTop: `${token('color.border', N40)} solid 1px`,
});

const MenuListBase = (props: any) => {
	const { children, className, cx, getStyles, innerProps, innerRef, isMulti } = props;
	return (
		<div
			// eslint-disable-next-line
			style={getStyles('menuList', props)}
			// eslint-disable-next-line
			className={cx(
				{
					'menu-list': true,
					'menu-list--is-multi': isMulti,
				},
				className,
			)}
			ref={innerRef}
			{...innerProps}
		>
			{children}
		</div>
	);
};

export const MenuList = (props: any) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<MenuListBase {...props} />
			<Box xcss={menuFooterStyles}>
				{props.selectProps.canClearFilter && (
					<ClearFilterOptionsButton onClick={() => props.selectProps.onClearOptions()}>
						{formatMessage(i18n.searchClearFilterLabel)}
					</ClearFilterOptionsButton>
				)}
			</Box>
		</>
	);
};
