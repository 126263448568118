import { JiraIcon as oldJiraIcon } from '@atlaskit/logo';
import { JiraIcon } from '@atlaskit/temp-nav-app-icons/jira';

import {
	DEFAULT_CLOUD_GRAPH_COMMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_PROJECT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo, getLogoVersion, RovoLogoFromLogo } from '../../icons';
import { JiraSearchResultIcon } from '../../icons/jira-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const jiraDCConfig: ThirdPartyConfig = {
	displayName: 'Jira Data Center',
	id: 'jira-dc',
	resourceType: 'jira-dc-connector',
	icon: RovoLogoFromLogo(getLogoVersion(JiraIcon, oldJiraIcon), 'Jira Data Center'),
	searchResultIcon: get3PLogo(JiraSearchResultIcon, 'Jira Data Center', 'large'),
	integrationAri: 'ari:cloud:platform::integration/jira-dc',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Project,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_SPACE_ENTITY_ATI,
				subTypes3P: [NounSubtypeKey.Project],
			},
		],
		[
			TypeFilterValueKey.Issue,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_PROJECT_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Issue],
			},
		],
		[
			TypeFilterValueKey.Comment,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_COMMENT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	filters: ['lastUpdated', 'project', 'assignee'],
	requiresOauth: true,
	isEnabled: () => true,
};
