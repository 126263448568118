import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const MuralIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-459.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.385264"
				y="0.385264"
				width="23.2295"
				height="23.2295"
				rx="5.61474"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.770528"
			/>
			<path
				d="M12.6754 16.1763C12.6757 14.0134 12.6843 11.8504 12.6739 9.68758C12.6654 7.94483 13.8929 6.44038 15.5576 6.08234C15.8132 6.02736 16.0709 5.99181 16.3335 6.00416C16.3754 6.06652 16.3699 6.13777 16.3701 6.20736C16.3708 6.83713 16.371 7.46704 16.3698 8.09681C16.3696 8.17062 16.3803 8.24819 16.3276 8.31236C15.8848 8.32742 15.5243 8.50576 15.2565 8.86034C15.089 9.08221 14.9992 9.3339 14.9928 9.61332C14.9914 9.67342 14.9908 9.73352 14.9908 9.79377C14.9908 11.8638 14.9908 13.9339 14.9905 16.0039C14.9905 16.0639 14.9863 16.1238 14.9842 16.1839C14.9391 16.2353 14.8769 16.2339 14.8176 16.2342C14.1625 16.2368 13.5073 16.2368 12.8522 16.2342C12.7888 16.2339 12.7197 16.2401 12.6755 16.1763H12.6754Z"
				fill="#00843F"
			/>
			<path
				d="M19.9958 9.66997C19.997 9.7251 19.9991 9.78023 19.9991 9.83536C19.9992 12.6608 19.9994 15.4861 19.9988 18.3115C19.9988 18.5251 20.0262 18.4962 19.8174 18.4965C19.1651 18.4977 18.5127 18.497 17.8602 18.497C17.8251 18.497 17.7899 18.4955 17.7548 18.497C17.7016 18.4992 17.684 18.4709 17.6862 18.4226C17.6871 18.4025 17.6862 18.3825 17.6862 18.3623C17.6865 15.4716 17.6867 12.5811 17.687 9.69046C17.687 9.68548 17.6874 9.68036 17.6876 9.67539C17.7236 9.6043 17.7918 9.60324 17.8563 9.60294C18.5111 9.59948 19.1658 9.59963 19.8206 9.60249C19.8869 9.60279 19.9509 9.61213 19.9956 9.66982L19.9958 9.66997Z"
				fill="black"
			/>
			<path
				d="M6.31589 9.65845C6.31815 9.71343 6.32222 9.76841 6.32222 9.82339C6.32252 12.6568 6.32267 15.4902 6.32222 18.3237C6.32222 18.5291 6.34511 18.5007 6.1493 18.501C5.48729 18.502 4.82529 18.502 4.16344 18.5002C4.11208 18.5002 4.05183 18.5251 4.01025 18.4692C4.02758 18.4151 4.01538 18.3598 4.01538 18.3053C4.01628 15.4761 4.01613 12.6469 4.01613 9.81781C4.01613 9.61688 4.02682 9.60212 4.22535 9.60121C4.85738 9.5985 5.4894 9.59941 6.12143 9.60106C6.19072 9.60121 6.26407 9.59579 6.31604 9.65845H6.31589Z"
				fill="black"
			/>
			<path
				d="M11.3296 18.485C11.282 18.508 11.2309 18.499 11.1813 18.4991C10.5242 18.5006 9.86683 18.5014 9.20965 18.5021C9.1845 18.5021 9.15949 18.5029 9.13434 18.502C9.06545 18.4995 9.02985 18.4615 9.02754 18.3881C9.02709 18.373 9.02754 18.358 9.02754 18.3429C9.02754 15.4932 9.02754 12.6434 9.02769 9.79368C9.02769 9.74864 9.03101 9.70361 9.03282 9.65842C9.08463 9.59651 9.15738 9.60103 9.22667 9.60073C9.86141 9.59907 10.4961 9.59907 11.1309 9.60073C11.2003 9.60088 11.2728 9.59711 11.3247 9.65857C11.3267 9.72364 11.3305 9.78871 11.3305 9.85378C11.3308 11.899 11.3306 13.9442 11.3311 15.9892C11.3311 16.0542 11.3234 16.1197 11.3409 16.1837C11.3704 16.2444 11.3702 16.3096 11.3702 16.3748C11.3704 17.0109 11.371 17.647 11.3696 18.283C11.3696 18.3521 11.3782 18.4246 11.3296 18.4847V18.485Z"
				fill="#5887FF"
			/>
			<path
				d="M11.3247 9.65883C10.5607 9.65883 9.79675 9.65883 9.03263 9.65868C9.00822 9.44901 8.97343 9.24296 8.87477 9.05166C8.60861 8.53577 8.0406 8.24174 7.47124 8.32896C6.89584 8.41707 6.43884 8.87166 6.34515 9.45007C6.33401 9.5192 6.32557 9.58879 6.31593 9.65823C5.60302 9.65823 4.89011 9.65793 4.17719 9.65778C4.15716 9.65778 4.13682 9.65944 4.11694 9.65763C4.04795 9.65145 4.01226 9.67751 4.01753 9.75132C4.02039 9.79123 4.01783 9.8316 4.01783 9.87182C4.01783 12.6882 4.01783 15.5048 4.01753 18.3212C4.01753 18.3709 4.01286 18.4206 4.01015 18.4697C3.99267 18.4307 4.00307 18.3902 4.00307 18.351C4.00231 15.501 4.00201 12.6509 4.00277 9.80087C4.00277 9.28513 4.06874 8.77888 4.27389 8.30139C4.8172 7.03749 5.76675 6.26101 7.12404 6.04561C9.03022 5.74301 10.8088 6.97166 11.247 8.85389C11.3084 9.11794 11.3462 9.38605 11.3246 9.65883H11.3247Z"
				fill="#FF4B4B"
			/>
			<path
				d="M19.9958 9.67086C19.816 9.6689 19.6361 9.66543 19.4563 9.66513C18.9213 9.66438 18.3864 9.66468 17.8514 9.66513C17.7967 9.66513 17.7413 9.65941 17.6878 9.67658C17.6785 9.52701 17.6637 9.37954 17.6171 9.23479C17.4522 8.72221 16.9529 8.33104 16.4019 8.3214C16.377 8.32094 16.3523 8.31612 16.3276 8.31326C16.3303 8.28344 16.3353 8.25361 16.3353 8.22379C16.335 7.48406 16.3343 6.74449 16.3337 6.00492C18.3515 5.97976 20.0257 7.66918 19.9958 9.67101V9.67086Z"
				fill="#FC83FF"
			/>
			<path
				d="M11.3295 18.4854C11.3295 17.7731 11.3295 17.0608 11.3299 16.3486C11.3299 16.2938 11.3216 16.2382 11.3408 16.1844C11.7857 16.1819 12.2307 16.1793 12.6756 16.1768C12.7253 16.1795 12.7752 16.1844 12.8249 16.1844C13.5447 16.1849 14.2644 16.1846 14.9843 16.1844C14.9865 16.2343 14.9909 16.2843 14.9909 16.3342C14.9913 16.9954 14.9912 17.6568 14.9912 18.3181C14.9912 18.3532 14.99 18.3883 14.9909 18.4232C14.9921 18.4723 14.9705 18.4981 14.9201 18.4975C14.885 18.4972 14.8499 18.4982 14.8149 18.4982C13.7077 18.4982 12.6006 18.4982 11.4933 18.4981C11.4385 18.4981 11.3829 18.5051 11.3296 18.4853L11.3295 18.4854Z"
				fill="#FFAA00"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.6756 16.1765C12.6759 14.0137 12.6845 11.8507 12.6741 9.68782C12.6657 7.94507 13.8931 6.44062 15.5579 6.08258C15.8135 6.0276 16.0712 5.99206 16.3337 6.00441C16.3756 6.06677 16.3702 6.13801 16.3703 6.2076C16.3711 6.83737 16.3712 7.46729 16.37 8.09706C16.3699 8.17086 16.3806 8.24844 16.3279 8.3126C15.885 8.32766 15.5246 8.50601 15.2568 8.86058C15.0893 9.08245 14.9995 9.33415 14.993 9.61356C14.9916 9.67366 14.991 9.73376 14.991 9.79401C14.991 11.8641 14.991 13.9341 14.9907 16.0042C14.9907 16.0641 14.9865 16.1241 14.9844 16.1842C14.9394 16.2355 14.8772 16.2342 14.8178 16.2345C14.1628 16.237 13.5075 16.237 12.8525 16.2345C12.789 16.2342 12.7199 16.2404 12.6758 16.1765H12.6756Z"
				fill="#00843F"
			/>
			<path
				d="M19.9958 9.67021C19.997 9.72534 19.9991 9.78047 19.9991 9.8356C19.9992 12.661 19.9994 15.4863 19.9988 18.3118C19.9988 18.5254 20.0262 18.4965 19.8174 18.4968C19.1651 18.498 18.5127 18.4972 17.8602 18.4972C17.8251 18.4972 17.7899 18.4957 17.7548 18.4972C17.7016 18.4995 17.684 18.4712 17.6862 18.4228C17.6871 18.4028 17.6862 18.3827 17.6862 18.3625C17.6865 15.4719 17.6867 12.5814 17.687 9.6907C17.687 9.68573 17.6874 9.68061 17.6876 9.67564C17.7236 9.60454 17.7918 9.60349 17.8563 9.60319C18.5111 9.59972 19.1658 9.59987 19.8206 9.60273C19.8869 9.60303 19.9509 9.61237 19.9956 9.67006L19.9958 9.67021Z"
				fill="black"
			/>
			<path
				d="M6.31589 9.65857C6.31815 9.71355 6.32222 9.76853 6.32222 9.82351C6.32252 12.6569 6.32267 15.4904 6.32222 18.3238C6.32222 18.5292 6.34511 18.5008 6.1493 18.5011C5.48729 18.5021 4.82529 18.5021 4.16344 18.5003C4.11208 18.5003 4.05183 18.5252 4.01025 18.4693C4.02758 18.4152 4.01538 18.3599 4.01538 18.3054C4.01628 15.4762 4.01613 12.647 4.01613 9.81794C4.01613 9.617 4.02682 9.60224 4.22535 9.60133C4.85738 9.59862 5.4894 9.59953 6.12143 9.60118C6.19072 9.60133 6.26407 9.59591 6.31604 9.65857H6.31589Z"
				fill="black"
			/>
			<path
				d="M11.3293 18.4851C11.2817 18.5081 11.2307 18.4991 11.1811 18.4992C10.5239 18.5008 9.86659 18.5015 9.20941 18.5023C9.18425 18.5023 9.15925 18.503 9.13409 18.5021C9.06521 18.4996 9.02961 18.4616 9.0273 18.3882C9.02685 18.3732 9.0273 18.3581 9.0273 18.343C9.0273 15.4933 9.0273 12.6435 9.02745 9.7938C9.02745 9.74877 9.03076 9.70373 9.03257 9.65854C9.08439 9.59663 9.15714 9.60115 9.22643 9.60085C9.86117 9.5992 10.4959 9.5992 11.1306 9.60085C11.2001 9.601 11.2725 9.59724 11.3245 9.65869C11.3265 9.72376 11.3302 9.78883 11.3302 9.8539C11.3305 11.8991 11.3304 13.9443 11.3308 15.9894C11.3308 16.0543 11.3231 16.1198 11.3406 16.1838C11.3701 16.2445 11.37 16.3097 11.37 16.375C11.3701 17.0111 11.3707 17.6472 11.3694 18.2831C11.3694 18.3522 11.378 18.4247 11.3293 18.4848V18.4851Z"
				fill="#5887FF"
			/>
			<path
				d="M11.3247 9.65896C10.5607 9.65896 9.79675 9.65896 9.03263 9.65881C9.00822 9.44913 8.97343 9.24308 8.87477 9.05178C8.60861 8.53589 8.0406 8.24187 7.47124 8.32908C6.89584 8.41719 6.43884 8.87178 6.34515 9.45019C6.33401 9.51933 6.32557 9.58891 6.31593 9.65835C5.60302 9.65835 4.89011 9.65805 4.17719 9.6579C4.15716 9.6579 4.13682 9.65956 4.11694 9.65775C4.04795 9.65158 4.01226 9.67763 4.01753 9.75144C4.02039 9.79136 4.01783 9.83172 4.01783 9.87194C4.01783 12.6883 4.01783 15.5049 4.01753 18.3213C4.01753 18.371 4.01286 18.4207 4.01015 18.4698C3.99267 18.4308 4.00307 18.3903 4.00307 18.3511C4.00231 15.5011 4.00201 12.651 4.00277 9.801C4.00277 9.28525 4.06874 8.779 4.27389 8.30151C4.8172 7.03761 5.76675 6.26113 7.12404 6.04574C9.03022 5.74313 10.8088 6.97179 11.247 8.85401C11.3084 9.11806 11.3462 9.38617 11.3246 9.65896H11.3247Z"
				fill="#FF4B4B"
			/>
			<path
				d="M19.9958 9.67086C19.816 9.6689 19.6361 9.66543 19.4563 9.66513C18.9213 9.66438 18.3864 9.66468 17.8514 9.66513C17.7967 9.66513 17.7413 9.65941 17.6878 9.67658C17.6785 9.52701 17.6637 9.37954 17.6171 9.23479C17.4522 8.72221 16.9529 8.33104 16.4019 8.3214C16.377 8.32094 16.3523 8.31612 16.3276 8.31326C16.3303 8.28344 16.3353 8.25361 16.3353 8.22379C16.335 7.48406 16.3343 6.74449 16.3337 6.00492C18.3515 5.97976 20.0257 7.66918 19.9958 9.67101V9.67086Z"
				fill="#FC83FF"
			/>
			<path
				d="M11.3293 18.4856C11.3293 17.7733 11.3293 17.061 11.3298 16.3489C11.3298 16.294 11.3215 16.2385 11.3406 16.1847C11.7856 16.1821 12.2305 16.1796 12.6755 16.177C12.7252 16.1797 12.775 16.1847 12.8248 16.1847C13.5446 16.1851 14.2643 16.1848 14.9841 16.1847C14.9864 16.2345 14.9908 16.2845 14.9908 16.3344C14.9912 16.9957 14.9911 17.6571 14.9911 18.3183C14.9911 18.3534 14.9899 18.3885 14.9908 18.4234C14.992 18.4725 14.9704 18.4983 14.92 18.4977C14.8849 18.4974 14.8498 18.4985 14.8148 18.4985C13.7076 18.4985 12.6005 18.4985 11.4932 18.4983C11.4384 18.4983 11.3828 18.5054 11.3295 18.4855L11.3293 18.4856Z"
				fill="#FFAA00"
			/>
		</svg>
	);
};
