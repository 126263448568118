import React, { useMemo } from 'react';

import { FormattedMessage } from 'react-intl-next';

import GlobeIcon from '@atlaskit/icon/core/globe';
import {
	AtlassianIcon,
	BitbucketIcon as oldBitbucketIcon,
	CompassIcon as oldCompassIcon,
	ConfluenceIcon as oldConfluenceIcon,
	FocusIcon as oldFocusIcon,
	JiraIcon as oldJiraIcon,
	JiraProductDiscoveryIcon as oldJiraProductDiscoveryIcon,
	JiraServiceManagementIcon as oldJiraServiceManagementIcon,
	TrelloIcon as oldTrelloIcon,
} from '@atlaskit/logo';
import { fg } from '@atlaskit/platform-feature-flags';
import { BitbucketIcon } from '@atlaskit/temp-nav-app-icons/bitbucket';
//Imported these individually because they're imported as default exports
import { CompassIcon } from '@atlaskit/temp-nav-app-icons/compass';
import { ConfluenceIcon } from '@atlaskit/temp-nav-app-icons/confluence';
import { FocusIcon } from '@atlaskit/temp-nav-app-icons/focus';
import { JiraIcon } from '@atlaskit/temp-nav-app-icons/jira';
import { JiraProductDiscoveryIcon } from '@atlaskit/temp-nav-app-icons/jira-product-discovery';
import { JiraServiceManagementIcon } from '@atlaskit/temp-nav-app-icons/jira-service-management';
import { LoomIcon as NewLoomIcon} from '@atlaskit/temp-nav-app-icons/loom';
import { TrelloIcon } from '@atlaskit/temp-nav-app-icons/trello';

// This is the only place we want to import this
import {
	DO_NOT_IMPORT_ProductKeys3P,
	DO_NOT_IMPORT_ProductsOrder3P,
	ThirdPartyConfigs,
} from './3p-product-configs';
import { AdobeSignIcon } from './3p-product-configs/adobe-sign/icon';
import { AdobeSignSearchResultIcon } from './3p-product-configs/adobe-sign/search-result-icon';
import { AdobeXDIcon } from './3p-product-configs/adobe-xd/icon';
import { AdobeXDSearchResultIcon } from './3p-product-configs/adobe-xd/search-result-icon';
import { AhaIcon } from './3p-product-configs/aha/icon';
import { AhaSearchResultIcon } from './3p-product-configs/aha/search-result-icon';
import { AirtableIcon } from './3p-product-configs/airtable/icon';
import { AirtableSearchResultIcon } from './3p-product-configs/airtable/search-result-icon';
import { AmplitudeIcon } from './3p-product-configs/amplitude/icon';
import { AmplitudeSearchResultIcon } from './3p-product-configs/amplitude/search-result-icon';
import { AsanaIcon } from './3p-product-configs/asana/icon';
import { AsanaSearchResultIcon } from './3p-product-configs/asana/search-result-icon';
import { WebDomainSearchResultIcon } from './3p-product-configs/atlassian-web-crawler/search-result-icon';
import { AWSS3Icon } from './3p-product-configs/aws-s3/icon';
import { AWSS3SearchResultIcon } from './3p-product-configs/aws-s3/search-result-icon';
import { AzureDevopsIcon } from './3p-product-configs/azure-devops/icon';
import { AzureDevopsSearchResultIcon } from './3p-product-configs/azure-devops/search-result-icon';
import { BoxIcon } from './3p-product-configs/box/icon';
import { BoxSearchResultIcon } from './3p-product-configs/box/search-result-icon';
import { CanvaIcon } from './3p-product-configs/canva/icon';
import { CanvaSearchResultIcon } from './3p-product-configs/canva/search-result-icon';
import { ClickUpIcon } from './3p-product-configs/clickup/icon';
import { ClickUpSearchResultIcon } from './3p-product-configs/clickup/search-result-icon';
import { DocusignIcon } from './3p-product-configs/docusign/icon';
import { DocusignSearchResultIcon } from './3p-product-configs/docusign/search-result-icon';
import { DovetailIcon } from './3p-product-configs/dovetail/icon';
import { DovetailSearchResultIcon } from './3p-product-configs/dovetail/search-result-icon';
import { DropboxIcon } from './3p-product-configs/dropbox/icon';
import { DropboxSearchResultIcon } from './3p-product-configs/dropbox/search-result-icon';
import { FigmaIcon } from './3p-product-configs/figma/icon';
import { FigmaSearchResultIcon } from './3p-product-configs/figma/search-result-icon';
import { FreshserviceIcon } from './3p-product-configs/freshservice/icon';
import { FreshserviceSearchResultIcon } from './3p-product-configs/freshservice/search-result-icon';
import { GitbookIcon } from './3p-product-configs/gitbook/icon';
import { GitbookSearchResultIcon } from './3p-product-configs/gitbook/search-result-icon';
import { GithubIcon } from './3p-product-configs/github/icon';
import { GithubSearchResultIcon } from './3p-product-configs/github/search-result-icon';
import { GitlabIcon } from './3p-product-configs/gitlab/icon';
import { GitlabSearchResultIcon } from './3p-product-configs/gitlab/search-result-icon';
import { GmailIcon } from './3p-product-configs/gmail/icon';
import { GmailSearchResultIcon } from './3p-product-configs/gmail/search-result-icon';
import { GoogleCalendarIcon } from './3p-product-configs/google-calendar/icon';
import { GoogleCalendarSearchResultIcon } from './3p-product-configs/google-calendar/search-result-icon';
import { GoogleDriveIcon } from './3p-product-configs/google/icon';
import { GoogleDriveSearchResultIcon } from './3p-product-configs/google/search-result-icon';
import { HubSpotFilterIcon } from './3p-product-configs/hubspot/icon';
import { HubSpotIcon } from './3p-product-configs/hubspot/search-result-icon';
import { LaunchDarklyIcon } from './3p-product-configs/launchdarkly/icon';
import { LaunchDarklySearchResultIcon } from './3p-product-configs/launchdarkly/search-result-icon';
import { LoomIcon } from './3p-product-configs/loom/icon';
import { LoomSearchResultIcon } from './3p-product-configs/loom/search-result-icon';
import { LucidIcon } from './3p-product-configs/lucid/icon';
import { LucidSearchResultIcon } from './3p-product-configs/lucid/search-result-icon';
import { MiroIcon } from './3p-product-configs/miro/icon';
import { MiroSearchResultIcon } from './3p-product-configs/miro/search-result-icon';
import { MondayIcon } from './3p-product-configs/monday/icon';
import { MondaySearchResultIcon } from './3p-product-configs/monday/search-result-icon';
import { MuralIcon } from './3p-product-configs/mural/icon';
import { MuralSearchResultIcon } from './3p-product-configs/mural/search-result-icon';
import { NotionIcon } from './3p-product-configs/notion/icon';
import { NotionSearchResultIcon } from './3p-product-configs/notion/search-result-icon';
import { OneDriveIcon } from './3p-product-configs/onedrive/icon';
import { OneDriveSearchResultIcon } from './3p-product-configs/onedrive/search-result-icon';
import { OutlookCalendarIcon } from './3p-product-configs/outlook-calendar/icon';
import { OutlookCalendarSearchResultIcon } from './3p-product-configs/outlook-calendar/search-result-icon';
import { OutlookMailIcon } from './3p-product-configs/outlook-mail/icon';
import { OutlookMailSearchResultIcon } from './3p-product-configs/outlook-mail/search-result-icon';
import { PagerDutyIcon } from './3p-product-configs/pagerduty/icon';
import { PagerDutySearchResultIcon } from './3p-product-configs/pagerduty/search-result-icon';
import { PipedriveIcon } from './3p-product-configs/pipedrive/icon';
import { PipedriveSearchResultIcon } from './3p-product-configs/pipedrive/search-result-icon';
import { PowerBIIcon } from './3p-product-configs/powerBI/icon';
import { PowerBISearchResultIcon } from './3p-product-configs/powerBI/search-result-icon';
import { SalesforceIcon } from './3p-product-configs/salesforce/icon';
import { SalesforceSearchResultIcon } from './3p-product-configs/salesforce/search-result-icon';
import { SentryIcon } from './3p-product-configs/sentry/icon';
import { SentrySearchResultIcon } from './3p-product-configs/sentry/search-result-icon';
import { ServiceNowIcon } from './3p-product-configs/servicenow/icon';
import { ServiceNowSearchResultIcon } from './3p-product-configs/servicenow/search-result-icon';
import { SharePointIcon } from './3p-product-configs/sharepoint/icon';
import { SharePointSearchResultIcon } from './3p-product-configs/sharepoint/search-result-icon';
import { SlackIcon } from './3p-product-configs/slack/icon';
import { SlackSearchResultIcon } from './3p-product-configs/slack/search-result-icon';
import { SmartsheetIcon } from './3p-product-configs/smartsheet/icon';
import { SmartsheetSearchResultIcon } from './3p-product-configs/smartsheet/search-result-icon';
import { StripeIcon } from './3p-product-configs/stripe/icon';
import { StripeSearchResultIcon } from './3p-product-configs/stripe/search-result-icon';
import { TeamsIcon } from './3p-product-configs/teams/icon';
import { TeamsSearchResultIcon } from './3p-product-configs/teams/search-result-icon';
import { TodoistIcon } from './3p-product-configs/todoist/icon';
import { TodoistSearchResultIcon } from './3p-product-configs/todoist/search-result-icon';
import { WebexIcon } from './3p-product-configs/webex/icon';
import { WebexSearchResultIcon } from './3p-product-configs/webex/search-result-icon';
import { ZendeskIcon } from './3p-product-configs/zendesk/icon';
import { ZendeskSearchResultIcon } from './3p-product-configs/zendesk/search-result-icon';
import { ZeplinIcon } from './3p-product-configs/zeplin/icon';
import { ZeplinSearchResultIcon } from './3p-product-configs/zeplin/search-result-icon';
import {
	get3PLogo,
	getIconVersion,
	getLogoVersion,
	type RovoLogo,
	RovoLogoFromGlyph,
	RovoLogoFromLogo,
} from './icons';
import { AtlasSearchResultIcon } from './icons/atlas-search';
import { BitbucketSearchResultIcon } from './icons/bitbucket';
import { CompassSearchResultIcon } from './icons/compass';
import { ConfluenceSearchResultIcon } from './icons/confluence-search';
import { FocusSearchResultIcon } from './icons/focus';
import { JiraProductDiscoverySearchResultIcon } from './icons/jira-product-discovery';
import { JiraSearchResultIcon } from './icons/jira-search';
import { JiraServiceManagementSearchResultIcon } from './icons/jira-service-management';
import { TrelloSearchResultIcon } from './icons/trello';
import { messages } from './messages';

export interface ProductConfig {
	displayName: React.ReactNode;
	Icon: RovoLogo;
	is3P?: boolean;
	SearchResultIcon?: RovoLogo;
}

const ProductKeys1P = {
	Atlas: 'townsquare',
	Bitbucket: 'bitbucket',
	Compass: 'compass',
	Confluence: 'confluence',
	Jira: 'jira',
	JiraProductDiscovery: 'jira-product-discovery',
	JiraServiceManagement: 'jira-servicedesk',
	Trello: 'trello',
	Focus: 'mercury',
	// Can't add these until they're ready as we use this type to enforce that product configs are available for all products
	// Opsgenie: 'opsgenie',
} as const;

export const ProductKeys3P = DO_NOT_IMPORT_ProductKeys3P;

export const ProductKeys = {
	...ProductKeys1P,
	...DO_NOT_IMPORT_ProductKeys3P,
} as const;

export type ProductKeys3P =
	(typeof DO_NOT_IMPORT_ProductKeys3P)[keyof typeof DO_NOT_IMPORT_ProductKeys3P];
export type ProductKeys1P = (typeof ProductKeys1P)[keyof typeof ProductKeys1P];
export type ProductKeys = (typeof ProductKeys)[keyof typeof ProductKeys];
export const allProductKeys: ProductKeys[] = Object.values(ProductKeys);
export const all3pProductKeys: ProductKeys3P[] = Object.values(DO_NOT_IMPORT_ProductKeys3P);
export const all1pProductKeys: ProductKeys1P[] = Object.values(ProductKeys1P);
export const is1pProductKey = (key: string): key is ProductKeys1P =>
	all1pProductKeys.includes(key as ProductKeys1P);
export const is3pProductKey = (key: string): key is ProductKeys3P =>
	all3pProductKeys.includes(key as ProductKeys3P);

export const PRODUCTS_ORDER: ProductKeys[] = [
	ProductKeys.Confluence,
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
	ProductKeys.Atlas,
	ProductKeys.Trello,
	ProductKeys.Bitbucket,
	ProductKeys.Compass,
	ProductKeys.Focus,
	// ProductKeys.Opsgenie,

	...DO_NOT_IMPORT_ProductsOrder3P,
];

// This is different to ProductKeys, it represents products we have support for mounting Search Page in
export const PrimaryProductKeys = {
	// Fully supported as GA
	Atlas: 'townsquare',
	Confluence: 'confluence',
	// In development
	Assets: 'assets',
	Focus: 'mercury',
	Jira: 'jira',
	Elevate: 'elevate',
} as const;
export type PrimaryProductKey = (typeof PrimaryProductKeys)[keyof typeof PrimaryProductKeys];

export const originalProducts3P: Record<string, ProductConfig> = {
	[ProductKeys.GoogleDrive]: {
		displayName: 'Google Drive',
		Icon: get3PLogo(GoogleDriveIcon, 'Google Drive', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(GoogleDriveSearchResultIcon, 'Google Drive', 'large'),
	},
	[ProductKeys.Sharepoint]: {
		displayName: 'Microsoft SharePoint',
		Icon: get3PLogo(SharePointIcon, 'Sharepoint', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(SharePointSearchResultIcon, 'Sharepoint', 'large'),
	},
	[ProductKeys.AtlassianWebCrawler]: {
		displayName: <FormattedMessage {...messages.webPage} />,
		Icon: RovoLogoFromLogo(GlobeIcon, 'Atlassian Web Crawler'),
		is3P: true,
		SearchResultIcon: get3PLogo(WebDomainSearchResultIcon, 'Atlassian Web Crawler', 'large'),
	},
	[ProductKeys.Teams]: {
		displayName: 'Teams',
		Icon: get3PLogo(TeamsIcon, 'Teams', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(TeamsSearchResultIcon, 'Teams', 'large'),
	},
	[ProductKeys.Loom]: {
		displayName: 'Loom',
		Icon: getIconVersion(NewLoomIcon, LoomIcon, 'Loom', 'medium'),
		is3P: true,
		SearchResultIcon: getIconVersion(NewLoomIcon, LoomSearchResultIcon, 'Loom', 'large'),
	},

	[ProductKeys.OneDrive]: {
		displayName: 'OneDrive',
		Icon: get3PLogo(OneDriveIcon, 'OneDrive', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(OneDriveSearchResultIcon, 'OneDrive', 'large'),
	},
	[ProductKeys.Slack]: {
		displayName: 'Slack',
		Icon: get3PLogo(SlackIcon, 'Slack', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(SlackSearchResultIcon, 'Slack', 'large'),
	},
	[ProductKeys.Github]: {
		displayName: 'GitHub',
		Icon: get3PLogo(GithubIcon, 'Github', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(GithubSearchResultIcon, 'Github', 'large'),
	},
	[ProductKeys.Figma]: {
		displayName: 'Figma',
		Icon: get3PLogo(FigmaIcon, 'Figma', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(FigmaSearchResultIcon, 'Figma', 'large'),
	},
	[ProductKeys.Dropbox]: {
		displayName: 'Dropbox',
		Icon: get3PLogo(DropboxIcon, 'Dropbox', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(DropboxSearchResultIcon, 'Dropbox', 'large'),
	},
	[ProductKeys.Box]: {
		displayName: 'Box',
		Icon: get3PLogo(BoxIcon, 'Box', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(BoxSearchResultIcon, 'Box', 'large'),
	},
	[ProductKeys.GoogleCalendar]: {
		displayName: 'Google Calendar',
		Icon: get3PLogo(GoogleCalendarIcon, 'Google Calendar', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(GoogleCalendarSearchResultIcon, 'Google Calendar', 'large'),
	},
	[ProductKeys.Gmail]: {
		displayName: 'Gmail',
		Icon: get3PLogo(GmailIcon, 'Gmail', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(GmailSearchResultIcon, 'Gmail', 'large'),
	},
	[ProductKeys.OutlookCalendar]: {
		displayName: 'Outlook Calendar',
		Icon: get3PLogo(OutlookCalendarIcon, 'Outlook Calendar', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(OutlookCalendarSearchResultIcon, 'Outlook Calendar', 'large'),
	},
	[ProductKeys.OutlookMail]: {
		displayName: 'Outlook Mail',
		Icon: get3PLogo(OutlookMailIcon, 'Outlook Mail', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(OutlookMailSearchResultIcon, 'Outlook Mail', 'large'),
	},
	[ProductKeys.Airtable]: {
		displayName: 'Airtable',
		Icon: get3PLogo(AirtableIcon, 'Airtable', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(AirtableSearchResultIcon, 'Airtable', 'large'),
	},
	[ProductKeys.Smartsheet]: {
		displayName: 'Smartsheet',
		Icon: get3PLogo(SmartsheetIcon, 'Smartsheet', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(SmartsheetSearchResultIcon, 'Smartsheet', 'large'),
	},
	[ProductKeys.Asana]: {
		displayName: 'Asana',
		Icon: get3PLogo(AsanaIcon, 'Asana', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(AsanaSearchResultIcon, 'Asana', 'large'),
	},
	[ProductKeys.Zendesk]: {
		displayName: 'Zendesk',
		Icon: get3PLogo(ZendeskIcon, 'Zendesk', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(ZendeskSearchResultIcon, 'Zendesk', 'large'),
	},
	[ProductKeys.Notion]: {
		displayName: 'Notion',
		Icon: get3PLogo(NotionIcon, 'Notion', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(NotionSearchResultIcon, 'Notion', 'large'),
	},
	[ProductKeys.Miro]: {
		displayName: 'Miro',
		Icon: get3PLogo(MiroIcon, 'Miro', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(MiroSearchResultIcon, 'Miro', 'large'),
	},
	[ProductKeys.Docusign]: {
		displayName: 'Docusign',
		Icon: get3PLogo(DocusignIcon, 'Docusign', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(DocusignSearchResultIcon, 'Docusign', 'large'),
	},
	[ProductKeys.AzureDevops]: {
		displayName: 'Azure DevOps',
		Icon: get3PLogo(AzureDevopsIcon, 'AzureDevops', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(AzureDevopsSearchResultIcon, 'AzureDevops', 'large'),
	},
	[ProductKeys.Lucid]: {
		displayName: 'Lucid',
		Icon: get3PLogo(LucidIcon, 'Lucid', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(LucidSearchResultIcon, 'Lucid', 'large'),
	},
	[ProductKeys.Monday]: {
		displayName: 'Monday',
		Icon: get3PLogo(MondayIcon, 'Monday', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(MondaySearchResultIcon, 'Monday', 'large'),
	},
	[ProductKeys.Canva]: {
		displayName: 'Canva',
		Icon: get3PLogo(CanvaIcon, 'Canva', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(CanvaSearchResultIcon, 'Canva', 'large'),
	},
	[ProductKeys.Gitlab]: {
		displayName: 'Gitlab',
		Icon: get3PLogo(GitlabIcon, 'Gitlab', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(GitlabSearchResultIcon, 'Gitlab', 'large'),
	},
	[ProductKeys.ConfluenceDC]: {
		displayName: 'Confluence Data Center',
		Icon: RovoLogoFromLogo(
			getLogoVersion(ConfluenceIcon, oldConfluenceIcon),
			'Confluence Data Center',
		),
		is3P: true,
		SearchResultIcon: get3PLogo(ConfluenceSearchResultIcon, 'Confluence Data Center', 'large'),
	},
	[ProductKeys.Zeplin]: {
		displayName: 'Zeplin',
		Icon: get3PLogo(ZeplinIcon, 'Zeplin', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(ZeplinSearchResultIcon, 'Zeplin', 'large'),
	},
	[ProductKeys.ServiceNow]: {
		displayName: 'ServiceNow',
		Icon: get3PLogo(ServiceNowIcon, 'ServiceNow', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(ServiceNowSearchResultIcon, 'ServiceNow', 'large'),
	},
	[ProductKeys.Freshservice]: {
		displayName: 'Freshservice',
		Icon: get3PLogo(FreshserviceIcon, 'Freshservice', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(FreshserviceSearchResultIcon, 'Freshservice', 'large'),
	},
	[ProductKeys.Stripe]: {
		displayName: 'Stripe',
		Icon: get3PLogo(StripeIcon, 'Stripe', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(StripeSearchResultIcon, 'Stripe', 'large'),
	},
	[ProductKeys.PowerBI]: {
		displayName: 'PowerBI',
		Icon: get3PLogo(PowerBIIcon, 'PowerBI', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(PowerBISearchResultIcon, 'PowerBI', 'large'),
	},
	[ProductKeys.ClickUp]: {
		displayName: 'ClickUp',
		Icon: get3PLogo(ClickUpIcon, 'ClickUp', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(ClickUpSearchResultIcon, 'ClickUp', 'large'),
	},
	[ProductKeys.Amplitude]: {
		displayName: 'Amplitude',
		Icon: get3PLogo(AmplitudeIcon, 'Amplitude', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(AmplitudeSearchResultIcon, 'Amplitude', 'large'),
	},
	[ProductKeys.AdobeXD]: {
		displayName: 'AdobeXD',
		Icon: get3PLogo(AdobeXDIcon, 'AdobeXD', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(AdobeXDSearchResultIcon, 'AdobeXD', 'large'),
	},
	[ProductKeys.Aha]: {
		displayName: 'Aha',
		Icon: get3PLogo(AhaIcon, 'Aha', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(AhaSearchResultIcon, 'Aha', 'large'),
	},
	[ProductKeys.AdobeSign]: {
		displayName: 'Adobe Sign',
		Icon: get3PLogo(AdobeSignIcon, 'AdobeSign', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(AdobeSignSearchResultIcon, 'AdobeSign', 'large'),
	},
	[ProductKeys.Mural]: {
		displayName: 'Mural',
		Icon: get3PLogo(MuralIcon, 'Mural', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(MuralSearchResultIcon, 'Mural', 'large'),
	},
	[ProductKeys.Webex]: {
		displayName: 'Webex',
		Icon: get3PLogo(WebexIcon, 'Webex', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(WebexSearchResultIcon, 'Webex', 'large'),
	},
	[ProductKeys.Todoist]: {
		displayName: 'Todoist',
		Icon: RovoLogoFromGlyph(TodoistIcon, 'Todoist', 'medium'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(TodoistSearchResultIcon, 'Todoist', 'large'),
	},
	[ProductKeys.PagerDuty]: {
		displayName: 'PagerDuty',
		Icon: get3PLogo(PagerDutyIcon, 'PagerDuty', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(PagerDutySearchResultIcon, 'PagerDuty', 'large'),
	},
	[ProductKeys.Pipedrive]: {
		displayName: 'Pipedrive',
		Icon: get3PLogo(PipedriveIcon, 'Pipedrive', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(PipedriveSearchResultIcon, 'Pipedrive', 'large'),
	},
	[ProductKeys.Hubspot]: {
		displayName: 'HubSpot',
		Icon: RovoLogoFromGlyph(HubSpotFilterIcon, 'HubSpot'),
		is3P: true,
		SearchResultIcon: RovoLogoFromGlyph(HubSpotIcon, 'HubSpot'),
	},
	[ProductKeys.Dovetail]: {
		displayName: 'Dovetail',
		Icon: get3PLogo(DovetailIcon, 'Dovetail', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(DovetailSearchResultIcon, 'Dovetail', 'large'),
	},
	[ProductKeys.LaunchDarkly]: {
		displayName: 'LaunchDarkly',
		Icon: get3PLogo(LaunchDarklyIcon, 'LaunchDarkly', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(LaunchDarklySearchResultIcon, 'LaunchDarkly', 'large'),
	},
	[ProductKeys.Salesforce]: {
		displayName: 'Salesforce',
		Icon: get3PLogo(SalesforceIcon, 'Salesforce', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(SalesforceSearchResultIcon, 'Salesforce', 'large'),
	},
	[ProductKeys.Sentry]: {
		displayName: 'Sentry',
		Icon: get3PLogo(SentryIcon, 'Sentry', 'medium'),
		is3P: true,
		SearchResultIcon: get3PLogo(SentrySearchResultIcon, 'Sentry', 'large'),
	},
	[ProductKeys.JiraDC]: {
		displayName: 'Jira Data Center',
		Icon: RovoLogoFromLogo(getLogoVersion(JiraIcon, oldJiraIcon), 'Jira Data Center'),
		is3P: true,
		SearchResultIcon: get3PLogo(JiraSearchResultIcon, 'Jira Data Center', 'large'),
	},
	[ProductKeys.Gitbook]: {
		displayName: 'GitBook',
		Icon: RovoLogoFromLogo(GitbookIcon, 'GitBook'),
		is3P: true,
		SearchResultIcon: get3PLogo(GitbookSearchResultIcon, 'GitBook', 'large'),
	},
	[ProductKeys.AWSS3]: {
		displayName: 'Amazon Web Services S3',
		Icon: RovoLogoFromLogo(AWSS3Icon, 'Amazon Web Services S3'),
		is3P: true,
		SearchResultIcon: get3PLogo(AWSS3SearchResultIcon, 'Amazon Web Services S3', 'large'),
	},
};

/**
 * This is the source of truth for the products that are supported in Full Page Search.
 * Note: there is additional logic in controllers/store/bootstrap/utils.ts for products being rolled out.
 *
 * QS-5444 3P references in this object are being deprecated. Please add to ./3p-product-configs
 */
const products1P: Record<ProductKeys1P, ProductConfig> = {
	[ProductKeys.Confluence]: {
		displayName: 'Confluence',
		Icon: RovoLogoFromLogo(getLogoVersion(ConfluenceIcon, oldConfluenceIcon), 'Confluence'),
		SearchResultIcon: get3PLogo(ConfluenceSearchResultIcon, 'Confluence', 'large'),
	},
	[ProductKeys.Atlas]: {
		displayName: 'Home',
		Icon: RovoLogoFromLogo(AtlassianIcon, 'Home'),
		SearchResultIcon: get3PLogo(AtlasSearchResultIcon, 'Home', 'large'),
	},
	[ProductKeys.Jira]: {
		displayName: 'Jira',
		Icon: RovoLogoFromLogo(getLogoVersion(JiraIcon, oldJiraIcon), 'Jira'),
		SearchResultIcon: get3PLogo(JiraSearchResultIcon, 'Jira', 'large'),
	},
	[ProductKeys.JiraProductDiscovery]: {
		displayName: 'Jira Product Discovery',
		Icon: RovoLogoFromLogo(
			getLogoVersion(JiraProductDiscoveryIcon, oldJiraProductDiscoveryIcon),
			'Jira Product Discovery',
		),
		SearchResultIcon: get3PLogo(
			JiraProductDiscoverySearchResultIcon,
			'Jira Product Discovery',
			'large',
		),
	},
	[ProductKeys.JiraServiceManagement]: {
		displayName: 'Jira Service Management',
		Icon: RovoLogoFromLogo(
			getLogoVersion(JiraServiceManagementIcon, oldJiraServiceManagementIcon),
			'Jira Service Management',
		),
		SearchResultIcon: get3PLogo(
			JiraServiceManagementSearchResultIcon,
			'Jira Service Management',
			'large',
		),
	},
	[ProductKeys.Bitbucket]: {
		displayName: 'Bitbucket',
		Icon: RovoLogoFromLogo(getLogoVersion(BitbucketIcon, oldBitbucketIcon), 'Bitbucket'),
		SearchResultIcon: getIconVersion(
			BitbucketIcon,
			BitbucketSearchResultIcon,
			'Bitbucket',
			'large',
		),
	},
	[ProductKeys.Trello]: {
		displayName: 'Trello',
		Icon: RovoLogoFromLogo(getLogoVersion(TrelloIcon, oldTrelloIcon), 'Trello'),
		SearchResultIcon: get3PLogo(TrelloSearchResultIcon, 'Trello', 'large'),
	},
	[ProductKeys.Compass]: {
		displayName: 'Compass',
		Icon: RovoLogoFromLogo(getLogoVersion(CompassIcon, oldCompassIcon), 'Compass'),
		SearchResultIcon: get3PLogo(CompassSearchResultIcon, 'Compass', 'large'),
	},
	[ProductKeys.Focus]: {
		displayName: 'Focus',
		Icon: RovoLogoFromLogo(getLogoVersion(FocusIcon, oldFocusIcon), 'Focus'),
		SearchResultIcon: get3PLogo(FocusSearchResultIcon, 'Focus', 'large'),
	},
};

const getProducts3PFromConfig = (): Record<ProductKeys3P, ProductConfig> => {
	// The use of Partial, the if (config) and the cast here are intentional TS gymnastics
	// We KNOW that this returns a config for all the ProductKeys3P, so we cast that
	// The TS compiler can't know that, and we'd be forced to write UI code like:
	//
	// const Icon = configs[productKey].Icon
	// return Icon && <Icon />
	//
	// Because TS can't prove productKey can be guaranteed to index into configs. But we know it can!
	const thirdPartyProducts = ThirdPartyConfigs;
	const configs: Partial<Record<ProductKeys3P, ProductConfig>> = {};

	all3pProductKeys.map((productKey3P) => {
		const config = thirdPartyProducts.get(productKey3P);
		if (config) {
			configs[productKey3P] = {
				displayName: config.displayName,
				Icon: config.icon,
				is3P: true,
				...(config.searchResultIcon && { SearchResultIcon: config.searchResultIcon }),
			};
		}
	});

	return configs as Record<ProductKeys3P, ProductConfig>;
};

/**
 * This is the source of truth for the products that are supported in Full Page Search for use by the UI
 * Note: there is additional logic in controllers/store/bootstrap/utils.ts for products being rolled out.
 *
 * If you just need the set of productKeys, please import { allProductKeys } or other similar helpers
 *
 * To get the list of products available to a user (a state concern) please see the exports in store/search
 *
 * QS-5444 3P references in this object are being deprecated. Please add to ./3p-product-configs
 */
export const useProductConfigs = (): Record<ProductKeys, ProductConfig> => {
	const staticConfigFg = fg('rovo-full-page-search-3p-static-config');

	return useMemo(() => {
		const thirdPartyProducts = staticConfigFg ? getProducts3PFromConfig() : originalProducts3P;

		return {
			...products1P,
			...thirdPartyProducts,
		};
	}, [staticConfigFg]);
};

export const useProductConfig = (productKey: ProductKeys): ProductConfig => {
	return useProductConfigs()[productKey];
};

/**
 * Deprecated! Kept for testing
 * Remove when cleaning up rovo-full-page-search-3p-static-config
 *
 * Please use import useProductConfig(s) hooks instead
 */
export const originalProducts: Partial<Record<ProductKeys, ProductConfig>> = {
	...products1P,
	...originalProducts3P,
};
