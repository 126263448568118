import gql from 'graphql-tag';

export const UserContextQuery = gql`
	query UserContextQuery {
		user(current: true) {
			id
			displayName
			emails {
				value
				isPrimary
			}
		}
	}
`;
