import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ClickUpIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-587.5"
				y="-268.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.375"
				y="0.375"
				width="23.25"
				height="23.25"
				rx="5.625"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.75"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.48145 15.6485L7.88428 13.6388C9.16896 15.4424 10.525 16.2926 12.0238 16.2926C13.5226 16.2926 14.8549 15.4681 16.0682 13.6646L18.5186 15.6227C16.7581 18.1992 14.5694 19.5648 12.0238 19.5648C9.50203 19.5648 7.28952 18.1992 5.48145 15.6485Z"
				fill="url(#paint0_linear_719_13847)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 7.58303L7.53864 11.2685L5.48145 8.9621L12.0248 3.5647L18.5186 8.9621L16.4366 11.2447L12 7.58303Z"
				fill="url(#paint1_linear_719_13847)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_719_13847"
					x1="5.48145"
					y1="16.6047"
					x2="18.5292"
					y2="16.6047"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8930FD" />
					<stop offset="1" stopColor="#49CCF9" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_719_13847"
					x1="5.48028"
					y1="7.41526"
					x2="18.5049"
					y2="7.41526"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF02F0" />
					<stop offset="1" stopColor="#FFC800" />
				</linearGradient>
			</defs>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 16.0105L7.58713 13.8467C8.97035 15.7886 10.4304 16.704 12.0442 16.704C13.6579 16.704 15.0924 15.8163 16.3987 13.8744L19.0371 15.9828C17.1416 18.7569 14.785 20.2272 12.0442 20.2272C9.32896 20.2272 6.94675 18.7569 5 16.0105Z"
				fill="url(#paint0_linear_384_122)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0186 7.32653L7.21498 11.2947L5 8.81138L12.0452 3L19.0371 8.81138L16.7954 11.2691L12.0186 7.32653Z"
				fill="url(#paint1_linear_384_122)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_384_122"
					x1="5"
					y1="17.04"
					x2="19.0485"
					y2="17.04"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8930FD" />
					<stop offset="1" stopColor="#49CCF9" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_384_122"
					x1="4.99875"
					y1="7.1459"
					x2="19.0224"
					y2="7.1459"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF02F0" />
					<stop offset="1" stopColor="#FFC800" />
				</linearGradient>
			</defs>
		</svg>
	);
};
