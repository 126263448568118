export type SearchPageJiraHydrationQueryVariables = {
	issueKeys: string[];
	cloudId: string;
};

export const jiraFieldsQueryGenerator = () => {
	return `
	query SearchPageJiraFieldHydration (
		$issueKeys: [String!]!
		$cloudId: ID!
	) {
		jira {
			issuesByKey(cloudId: $cloudId, keys: $issueKeys) {
				id
				statusField {
					status {
						name
						statusCategory {
							colorName
						}
					}
				}
			}
		}
	}
	`;
};
