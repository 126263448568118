import React, { Fragment, useCallback, useState } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';

import {
	is3pProductKey,
	type ProductKeys,
	ProductKeys3P,
	useProductConfigs,
} from '../../constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../constants/schemas/3p-config';
import { useBulkConnectBanner } from '../../utils/bulk-connect-banner';
import { BannerType } from '../../utils/bulk-connect-banner/types';
import { BulkConnectDialog } from '../bulk-connect-dialog';
import { OAuthMessage } from '../oauth-message';

import { getBannerContent } from './utils';

const bulkConnectBannerContainerStyles = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

interface OAuthHandlersProps {
	product: ProductKeys;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	onFailAction?: () => void;
	isOnSearchDialog?: boolean;
}
interface OauthHandlerFunctions {
	onAuthorize: () => void;
	onDismiss: () => void;
	onFail: () => void;
}

type useOAuthHandlers = (props: OAuthHandlersProps) => OauthHandlerFunctions;

type BulkConnectBannerProps = {
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	availableProducts: ProductKeys[];
	useNewlyConnectedProducts: () => ProductKeys3P[];
	isOnSearchDialog?: boolean;
	useFeatureFlaggedLocalStorageCallback: () => () => any[]; // this hook does not have strict typing
	useIsBulkConnectModalOpenQuickFind?: () => [
		boolean,
		{ setIsBulkConnectModalOpen: (value: boolean) => void },
	]; // this pertains to bulk connect modal instance on quickfind. It was necessary to prevent QF (and hence, the modal) from closing when clicking while the modal is open
	useOAuthHandlers: useOAuthHandlers;
	useInitializeBulkConnect: (
		availableProducts: ProductKeys3P[],
		recentlyConnectedProducts: ProductKeys3P[],
	) => void;
	useSetProductStatus: (productKey: ProductKeys3P, status: boolean) => void;
	useSetProductConnectedInSession: (status: boolean) => void;
	useCurrentProductStatuses: () => Map<ProductKeys3P, boolean>;
	useCurrentProductOrder: () => ProductKeys3P[];
	useProductConnectedInSessionStatus: () => boolean;
};

/**
 * BulkConnectBanner component
 * This component displays a banner prompting users to connect their 3p products, and is the entrypoint to the modal dialog for connecting the products.
 */
export const BulkConnectBanner = ({
	thirdPartyConfigs,
	availableProducts,
	useNewlyConnectedProducts,
	isOnSearchDialog,
	useFeatureFlaggedLocalStorageCallback,
	useIsBulkConnectModalOpenQuickFind,
	useOAuthHandlers,
	useInitializeBulkConnect,
	useSetProductStatus,
	useSetProductConnectedInSession,
	useCurrentProductStatuses,
	useCurrentProductOrder,
	useProductConnectedInSessionStatus,
}: BulkConnectBannerProps) => {
	const allProducts = useProductConfigs();
	const newlyConnectedProducts = useNewlyConnectedProducts();
	const useLocalStorage = useFeatureFlaggedLocalStorageCallback();
	const [storedState, setState] = useLocalStorage();

	// For QuickFind, use store state. For Full Page Search, use local state
	const [localModalOpen, setLocalModalOpen] = useState(false);
	const modalState = useIsBulkConnectModalOpenQuickFind?.() ?? [
		localModalOpen,
		{ setIsBulkConnectModalOpen: setLocalModalOpen },
	];
	const [bulkConnectModalOpen, { setIsBulkConnectModalOpen }] = modalState;

	const onCloseModal = useCallback(
		(wasConnected: boolean, closedByButton: boolean) => {
			if (wasConnected && closedByButton) {
				setState({
					...storedState,
					bulkConnectBanner: {
						initialBannerDismissed: true,
						secondaryBannerDismissed: true,
					},
				});
			}
			setIsBulkConnectModalOpen(false);
		},
		[setIsBulkConnectModalOpen, setState, storedState],
	);

	const { bannerType, dismissBanner } = useBulkConnectBanner({
		thirdPartyConfigs,
		availableProducts,
		newlyConnectedProducts,
		useFeatureFlaggedLocalStorageCallback,
	});

	if (bannerType === BannerType.NONE) {
		return null;
	}

	const handleAuthorize = () => {
		setIsBulkConnectModalOpen(true);
	};

	const messageContent = getBannerContent(newlyConnectedProducts, allProducts, bannerType);

	if (!messageContent) {
		return null;
	}

	const messageProps = {
		content: messageContent,
		isBulkConnect: true,
		onAuthorize: handleAuthorize,
		onDismiss: dismissBanner,
	};

	const availableProductsAs3P = availableProducts.filter(
		(productKey): productKey is ProductKeys3P => is3pProductKey(productKey),
	);

	const modalContent = (
		<Fragment>
			<ModalTransition>
				{bulkConnectModalOpen && (
					<BulkConnectDialog
						availableProducts={availableProductsAs3P}
						newlyConnected={newlyConnectedProducts}
						onClose={onCloseModal}
						thirdPartyConfigs={thirdPartyConfigs}
						useOAuthHandlers={useOAuthHandlers}
						useInitializeBulkConnect={useInitializeBulkConnect}
						useSetProductStatus={useSetProductStatus}
						useSetProductConnectedInSession={useSetProductConnectedInSession}
						useCurrentProductStatuses={useCurrentProductStatuses}
						useCurrentProductOrder={useCurrentProductOrder}
						useProductConnectedInSessionStatus={useProductConnectedInSessionStatus}
					/>
				)}
			</ModalTransition>
		</Fragment>
	);

	return isOnSearchDialog ? (
		<Box xcss={bulkConnectBannerContainerStyles}>
			<OAuthMessage {...messageProps} isOnSearchDialog />
			{modalContent}
		</Box>
	) : (
		<>
			<OAuthMessage {...messageProps} />
			{modalContent}
		</>
	);
};
