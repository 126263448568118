import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { SentryIcon } from './icon';
import { SentrySearchResultIcon } from './search-result-icon';

export const sentryConfig: ThirdPartyConfig = {
	displayName: 'Sentry',
	id: 'sentry',
	resourceType: 'smartlinks-connector-sentry',
	icon: get3PLogo(SentryIcon, 'Sentry', 'medium'),
	searchResultIcon: get3PLogo(SentrySearchResultIcon, 'Sentry', 'large'),
	integrationAri: 'ari:cloud:platform::integration/sentry',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.WorkItem,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
