import React from 'react';

import { useIntl } from 'react-intl-next';

import PersonAvatarIcon from '@atlaskit/icon/core/person-avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Avatar } from '@atlassian/search-ui/avatar';

import { messages } from './messages';

const personAvatarColor = token('color.icon');

const defaultAvatarCSS = xcss({
	padding: 'space.025',
	alignItems: 'center',
	display: 'flex',
});

const avatarCSS = xcss({
	alignItems: 'center',
	display: 'flex',
	marginInlineEnd: 'space.025',
});

interface ProfileAvatarProps {
	name?: string | null;
	picture?: string | null;
}

/**
 * ProfileAvatar is used in search results with fallback to default avatar icon.
 */
export const ProfileAvatar = ({ name, picture }: ProfileAvatarProps) => {
	const { formatMessage } = useIntl();

	if (name) {
		return (
			<Box testId="3p-message-avatar" xcss={avatarCSS}>
				<Avatar
					variant={
						picture ? { type: 'profile-pic', src: picture, name } : { type: 'initials', name }
					}
					size="smaller"
				/>
			</Box>
		);
	}

	return (
		<Box xcss={defaultAvatarCSS} testId="3p-message-avatar">
			<PersonAvatarIcon
				label={formatMessage(messages.personAvatarLabel)}
				color={personAvatarColor}
			/>
		</Box>
	);
};
