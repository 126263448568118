import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ZendeskSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-214.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#03363D" />
			<path
				d="M15.4789 12.465V23.1153H6.65747L15.4789 12.465ZM15.4789 8.64319C15.4789 9.81298 15.0142 10.9349 14.187 11.762C13.3598 12.5892 12.238 13.0539 11.0682 13.0539C9.89838 13.0539 8.7765 12.5892 7.94933 11.762C7.12217 10.9349 6.65747 9.81298 6.65747 8.64319H15.4789ZM16.9321 23.1162C16.9321 21.9464 17.3968 20.8245 18.224 19.9974C19.0511 19.1702 20.173 18.7055 21.3428 18.7055C22.5126 18.7055 23.6345 19.1702 24.4616 19.9974C25.2888 20.8245 25.7535 21.9464 25.7535 23.1162H16.9321ZM16.9321 19.2943V8.64319H25.7554L16.9321 19.2934V19.2943Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.8571 14.7536V31.4835H5L18.8571 14.7536ZM18.8571 8.75C18.8571 10.5876 18.1271 12.3499 16.8278 13.6492C15.5284 14.9486 13.7661 15.6785 11.9286 15.6785C10.091 15.6785 8.32868 14.9486 7.02933 13.6492C5.72997 12.3499 5 10.5876 5 8.75H18.8571ZM21.1399 31.485C21.1399 29.6474 21.8699 27.8851 23.1693 26.5857C24.4686 25.2864 26.2309 24.5564 28.0685 24.5564C29.9061 24.5564 31.6684 25.2864 32.9677 26.5857C34.2671 27.8851 34.997 29.6474 34.997 31.485H21.1399ZM21.1399 25.4814V8.75H35L21.1399 25.4799V25.4814Z"
				fill="#03363D"
			/>
		</svg>
	);
};
