import { defineMessages, type MessageDescriptor } from 'react-intl-next';

import { TypeFilterValueKey } from '../../../common/constants/filters/universal-type';

export const SYSTEM_INCIDENT_SEARCH_STRING = '[System] Incident';

export const messages: Partial<Record<TypeFilterValueKey, MessageDescriptor>> = defineMessages({
	incident: {
		id: 'search-client.jira.issue.incident.label',
		defaultMessage: SYSTEM_INCIDENT_SEARCH_STRING,
		description: 'label for the incident type in JSM',
	},
});
