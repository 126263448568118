import React, { forwardRef, useRef } from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import SummarizeIcon from '@atlaskit/icon/core/summarize';
import { Popup, type PopupComponentProps, type TriggerProps } from '@atlaskit/popup';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { AIStreamingSummaryContent } from '@atlassian/ai-summary';

import { ProductKeys } from '../../constants/products';

import { messages } from './messages';

interface SearchResultContextMenuProps {
	url?: string;
	isContextMenuOpen: boolean;
	setIsContextMenuOpen: (open: boolean) => void;
	setIsFocused: (open: boolean) => void;
	setIsHovered: (open: boolean) => void;
	id: string;
	cloudId?: string;
	aiSummaryEnabled?: boolean;
}

const popupWrapperStyle = xcss({
	borderRadius: 'border.radius.100',
	boxShadow: 'elevation.shadow.overlay',
	':focus': {
		outline: 'none',
	},
});

export const AIStreamingPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => {
		return (
			<div {...props} ref={ref} role="dialog">
				<Box xcss={popupWrapperStyle}>
					<Stack space="space.150">{children}</Stack>
				</Box>
			</div>
		);
	},
);

const SearchResultContextMenu = ({
	url,
	isContextMenuOpen,
	setIsContextMenuOpen,
	setIsFocused,
	setIsHovered,
	id,
	cloudId,
	aiSummaryEnabled = false,
}: SearchResultContextMenuProps) => {
	const { formatMessage } = useIntl();
	const [isAISummaryOpen, setIsAISummaryOpen] = React.useState(false);
	const divRef = useRef<HTMLDivElement>(null);

	const copyToClipboard = async () => {
		setIsHovered(false);
		setIsFocused(false);
		if (!url) {
			return;
		}
		await navigator.clipboard.writeText(url);
	};

	return (
		<Popup
			placement="right"
			isOpen={aiSummaryEnabled && isAISummaryOpen}
			onClose={() => {
				setIsAISummaryOpen(false);
				setIsHovered(false);
				setIsFocused(false);
			}}
			popupComponent={AIStreamingPopupContainer}
			content={() => (
				<AIStreamingSummaryContent
					url={url}
					cloudId={cloudId}
					ari={id}
					agentNamedId={'summary_agent'}
					product={ProductKeys.Confluence}
					experienceId="page-summaries"
				/>
			)}
			trigger={({ 'aria-expanded': boolean, ...triggerProps }: TriggerProps) => (
				<Box {...triggerProps}>
					<DropdownMenu<HTMLButtonElement>
						trigger={({ triggerRef, ...props }) => (
							<IconButton
								{...props}
								appearance="subtle"
								icon={MoreIcon}
								label={formatMessage(messages.moreActionsLabel)}
								isTooltipDisabled={false}
								spacing="compact"
								ref={triggerRef}
							/>
						)}
						placement="right-start"
						testId="search-result-context-menu"
						onOpenChange={() => setIsContextMenuOpen(!isContextMenuOpen)}
						isOpen={isContextMenuOpen}
						returnFocusRef={divRef}
						zIndex={2}
					>
						<DropdownItemGroup testId="search-result-context-menu-dropdown">
							<Box onMouseDown={copyToClipboard}>
								<DropdownItem
									elemBefore={
										<LinkIcon
											color="currentColor"
											label="copy-link-icon"
											LEGACY_size="medium"
											spacing="spacious"
										/>
									}
									testId="copy-link-button"
								>
									{formatMessage(messages.copyLinkLabel)}
								</DropdownItem>
							</Box>
							{aiSummaryEnabled && (
								<Box
									onMouseDown={() => {
										setIsAISummaryOpen(true);
										setIsFocused(true);
										setIsHovered(true);
										setIsContextMenuOpen(false);
									}}
								>
									<DropdownItem
										elemBefore={
											<SummarizeIcon
												color="currentColor"
												label="ai-summary-button"
												LEGACY_size="medium"
												spacing="spacious"
											/>
										}
										testId="ai-summary-button"
									>
										{formatMessage(messages.aiSummaryLabel)}
									</DropdownItem>
								</Box>
							)}
						</DropdownItemGroup>
					</DropdownMenu>
				</Box>
			)}
		/>
	);
};

export default SearchResultContextMenu;
