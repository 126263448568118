import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { MuralIcon } from './icon';
import { MuralSearchResultIcon } from './search-result-icon';

export const muralConfig: ThirdPartyConfig = {
	displayName: 'Mural',
	id: 'mural',
	resourceType: 'smartlinks-connector-mural',
	icon: get3PLogo(MuralIcon, 'Mural', 'medium'),
	searchResultIcon: get3PLogo(MuralSearchResultIcon, 'Mural', 'large'),
	integrationAri: 'ari:cloud:platform::integration/mural',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Design,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
