import { type IntlShape } from 'react-intl-next';

import { getUserRecommendations } from '@atlaskit/smart-user-picker';

type ProductKeyType = 'confluence';

type GetOwnersProps = {
	query: string;
	userId: string;
	cloudId: string;
	productKey: ProductKeyType;
	maxNumberOfResults: number;
	intl: IntlShape;
};

export const getOwners = ({
	query,
	userId,
	cloudId,
	productKey,
	maxNumberOfResults,
	intl,
}: GetOwnersProps) => {
	const requestParams = {
		context: {
			contextType: 'owners',
			principalId: userId,
			productAttributes: {
				isEntitledConfluenceExternalCollaborator: true,
			},
			productKey,
			siteId: cloudId,
		},
		includeGroups: false,
		includeTeams: false,
		includeUsers: true,
		maxNumberOfResults,
		performSearchQueryOnly: false,
		query,
	};

	return getUserRecommendations(requestParams, intl);
};
