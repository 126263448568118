import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const TodoistSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-259.5"
				y="-263.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#DE483A"
			/>
			<path
				d="M22.3534 6.68774H9.68868C8.04291 6.68774 6.70874 8.02192 6.70874 9.6677V22.3325C6.70874 23.9783 8.04291 25.3125 9.68868 25.3125H22.3534C23.9992 25.3125 25.3334 23.9783 25.3334 22.3325V9.6677C25.3334 8.02192 23.9992 6.68774 22.3534 6.68774Z"
				fill="white"
			/>
			<path
				d="M10.6452 15.4139L14.355 13.2791L14.3596 13.2762L18.1824 11.0761C18.3436 10.983 18.3511 10.6978 18.1707 10.5942L18.0444 10.522C17.8599 10.4173 17.6213 10.2811 17.5194 10.2217C17.4302 10.1731 17.33 10.1482 17.2284 10.1495C17.1268 10.1507 17.0272 10.178 16.9392 10.2287C16.849 10.2811 10.8274 13.7429 10.626 13.8576C10.5073 13.9254 10.373 13.9611 10.2363 13.9611C10.0996 13.9611 9.96533 13.9254 9.84666 13.8576L6.6665 12.0097V13.5806L6.6991 13.5992C7.49297 14.0619 9.37173 15.1561 9.83153 15.4151C10.1103 15.5722 10.3769 15.5687 10.6452 15.4139Z"
				fill="#DE483A"
			/>
			<path
				d="M10.6452 18.5522L14.3503 16.4197L14.3643 16.4116L18.1824 14.2144C18.3436 14.1213 18.3511 13.8355 18.1707 13.7325L18.0444 13.6603C17.8605 13.5556 17.6213 13.4194 17.5194 13.36C17.4302 13.3114 17.33 13.2865 17.2284 13.2878C17.1268 13.289 17.0272 13.3163 16.9392 13.367C16.849 13.4188 10.8274 16.8812 10.626 16.9953C10.5073 17.0632 10.373 17.0988 10.2363 17.0988C10.0996 17.0988 9.96533 17.0632 9.84666 16.9953C9.65692 16.8859 6.6665 15.148 6.6665 15.148V16.7183L6.6991 16.7375C7.49355 17.2002 9.37173 18.2938 9.83153 18.5534C10.1103 18.7105 10.3769 18.7071 10.6452 18.5522Z"
				fill="#DE483A"
			/>
			<path
				d="M14.3556 19.5552L10.6452 21.6906C10.3769 21.8455 10.1097 21.8489 9.83153 21.6918C9.37174 21.4322 7.49356 20.3386 6.6991 19.8759L6.6665 19.8567V18.2864L9.84666 20.1337C10.087 20.2728 10.385 20.2711 10.626 20.1337C10.8274 20.0196 16.849 16.5572 16.9392 16.5048C17.0272 16.4541 17.1268 16.4268 17.2284 16.4256C17.33 16.4244 17.4302 16.4492 17.5194 16.4978C17.7361 16.6231 17.9534 16.7473 18.1713 16.8703C18.3517 16.9739 18.3436 17.2597 18.1824 17.3528L14.3556 19.5552Z"
				fill="#DE483A"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M30.2108 5H9.8567C7.2117 5 5.0675 7.14421 5.0675 9.78922V30.1434C5.0675 32.7884 7.2117 34.9326 9.8567 34.9326H30.2108C32.8558 34.9326 35 32.7884 35 30.1434V9.78922C35 7.14421 32.8558 5 30.2108 5Z"
				fill="#DE483A"
			/>
			<path
				d="M11.3943 19.0246L17.3565 15.5935L17.364 15.5888L23.5076 12.0531C23.7667 11.9034 23.7789 11.445 23.4889 11.2785L23.2859 11.1626C22.9894 10.9942 22.6059 10.7753 22.4422 10.6799C22.2988 10.6018 22.1377 10.5618 21.9745 10.5638C21.8112 10.5658 21.6511 10.6096 21.5096 10.6911C21.3646 10.7753 11.6871 16.339 11.3635 16.5233C11.1727 16.6323 10.9569 16.6897 10.7372 16.6897C10.5175 16.6897 10.3017 16.6323 10.111 16.5233L5 13.5534V16.0781L5.05238 16.108C6.32825 16.8516 9.34769 18.6102 10.0867 19.0264C10.5347 19.279 10.9631 19.2734 11.3943 19.0246Z"
				fill="white"
			/>
			<path
				d="M11.3943 24.0681L17.349 20.6408L17.3715 20.6277L23.5076 17.0966C23.7667 16.947 23.7789 16.4877 23.4889 16.3221L23.2859 16.2061C22.9904 16.0378 22.6059 15.8189 22.4422 15.7235C22.2988 15.6454 22.1377 15.6054 21.9745 15.6074C21.8112 15.6093 21.6511 15.6532 21.5096 15.7347C21.3646 15.8179 11.6871 21.3826 11.3635 21.5659C11.1727 21.675 10.9569 21.7323 10.7372 21.7323C10.5175 21.7323 10.3017 21.675 10.111 21.5659C9.80603 21.3901 5 18.597 5 18.597V21.1207L5.05238 21.1516C6.32919 21.8952 9.34769 23.6528 10.0867 24.07C10.5347 24.3226 10.9631 24.3169 11.3943 24.0681Z"
				fill="white"
			/>
			<path
				d="M17.3574 25.6798L11.3943 29.1118C10.9631 29.3606 10.5338 29.3662 10.0867 29.1137C9.34769 28.6965 6.32919 26.9389 5.05238 26.1952L5 26.1644V23.6407L10.111 26.6096C10.4973 26.8332 10.9762 26.8304 11.3635 26.6096C11.6871 26.4263 21.3646 20.8616 21.5096 20.7774C21.6511 20.6959 21.8112 20.6521 21.9745 20.6501C22.1377 20.6481 22.2988 20.6881 22.4422 20.7662C22.7904 20.9676 23.1396 21.1671 23.4899 21.3649C23.7798 21.5314 23.7667 21.9906 23.5076 22.1403L17.3574 25.6798Z"
				fill="white"
			/>
		</svg>
	);
};
