import { type FrontendExperiments, type SearchExperimentLayer } from '@atlassian/search-experiment';

/**
 * Mappings are typecasted with `as const` rather than using enums to
 * prevent reverse mappings generated by enums.
 */

export const AnalyticsKeys = {
	source: 'source',
} as const;

export type AnalyticsKeys = (typeof AnalyticsKeys)[keyof typeof AnalyticsKeys];

export type ExperimentLayer = {
	name: string;
	layerId: string | null;
	shadowId: string | null;
};

export const FeedbackKeys = {
	release: 'release',
	product: 'product',
	cloudId: 'cloudId',
	userId: 'userId',
	primaryProduct: 'primaryProduct',
	experimentId: 'experimentId',
	experimentLayers: 'experimentLayers',
	frontendExperiments: 'frontendExperiments',
	abTestId: 'abTestId',
	isHello: 'isHello',
	version: 'version',
	mode: 'mode',
} as const;

export type FeedbackKeys = (typeof FeedbackKeys)[keyof typeof FeedbackKeys];

export type FeedbackContext = {
	[AnalyticsKeys.source]?: string;
	[FeedbackKeys.release]?: string;
	[FeedbackKeys.product]?: string;
	[FeedbackKeys.cloudId]?: string;
	[FeedbackKeys.userId]?: string;
	[FeedbackKeys.primaryProduct]?: string;
	[FeedbackKeys.experimentId]?: string;
	[FeedbackKeys.experimentLayers]?: SearchExperimentLayer[];
	[FeedbackKeys.frontendExperiments]?: FrontendExperiments;
	[FeedbackKeys.abTestId]?: string;
	[FeedbackKeys.isHello]?: boolean;
	[FeedbackKeys.version]?: string;
	[FeedbackKeys.mode]?: string;
};

export const DEFAULT_GAS_CHANNEL = 'fabric-elements';
export const EVENT_SOURCE = 'advancedSearchScreen';
export const ACTION_SUBJECT_PREFIX = 'searchPage';

export enum OptionIds {
	TITLE = 'title',
	ARCHIVED = 'archived',
	ANCESTOR = 'ancestor',
	TO = 'to',
	FROM = 'from',
	LAST_MODIFIED = 'lastModified',
}

export const CURRENT = 'CURRENT';
export const ARCHIVED = 'ARCHIVED';
export const CROSSPRODUCT_SEARCH_API = 'crossProductSearch';
export const ALL_PRODUCTS_STRING = 'all';
export const ERROR_SCREEN_NAME = 'fullPageSearchErrorScreen';

export enum QueryState {
	POSTQUERY = 'PostQuery',
	PREQUERY = 'PreQuery',
}
