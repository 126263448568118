const SEARCH_PAGE_VISITED_KEY = 'atlassian.search-page.visited';

const setLocalStorageItem = (key: string, value: string) => {
	window.localStorage.setItem(key, value);
};

const getLocalStorageItem = (key: string): string | null => {
	return window.localStorage.getItem(key);
};

export const addVisitedLink = (value: string) => {
	setLocalStorageItem(SEARCH_PAGE_VISITED_KEY, JSON.stringify([...getVisitedLinks(), value]));
};

export const getVisitedLinks = (): Set<string> => {
	const visitedLinksRaw = getLocalStorageItem(SEARCH_PAGE_VISITED_KEY);
	return new Set(visitedLinksRaw ? JSON.parse(visitedLinksRaw) : []);
};

export const isVisited = (visitedLinks: Set<string>, id: string) => {
	return visitedLinks.has(id);
};
