import { FEDERATED_GOOGLE_EMAIL_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GmailIcon } from './icon';
import { GmailSearchResultIcon } from './search-result-icon';

export const gmailConfig: ThirdPartyConfig = {
	displayName: 'Gmail',
	id: 'gmail',
	resourceType: 'gmail-connector',
	icon: get3PLogo(GmailIcon, 'Gmail', 'medium'),
	searchResultIcon: get3PLogo(GmailSearchResultIcon, 'Gmail', 'large'),
	integrationAri: 'ari:cloud:platform::integration/google',
	requiresOauth: true,
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Message,
			{
				entities3P: [FEDERATED_GOOGLE_EMAIL_ENTITY_ATI],
				subTypes3P: [],
			},
		],
	]),
	filters: ['lastUpdated'],
	isEnabled: () => true,
	isFederated: true,
};
