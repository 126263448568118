import type { SearchResult } from '../../../../common/types';

const SearchResultCompassComponent = `
... on SearchResultCompassComponent {
	lastModifiedDate
	ownerId
	tier
	component {
		description
		state
	}
}
`;

export const getCompassPartials = () => {
	return SearchResultCompassComponent;
};

export interface SearchResultCompassComponent extends SearchResult {
	lastModifiedDate?: string;
	ownerId?: string;
	tier?: string;
	// sourced separately from bulk teams endpoint
	ownerName?: string;
	component?: {
		description?: string;
		state?: string;
	};
	__typename: 'SearchResultCompassComponent';
}

export type SearchCompassPartial = SearchResultCompassComponent;

export const isSearchCompassPartial = (
	partial: SearchResult,
): partial is SearchResultCompassComponent => {
	return partial.__typename === 'SearchResultCompassComponent';
};
