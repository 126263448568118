import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

export type ConfluenceSpacesQueryVariables = { spaceIds: string[] };

export const spacesCache = inMemoryCache<AggResponse<ConfluenceSpacesResponse>>();

const GQL_CF_SPACES_OPERATION_NAME = 'SearchPageConfluenceSpacesQuery';
const getConfluenceSpacesQuery = () => `
query ${GQL_CF_SPACES_OPERATION_NAME}($spaceIds: [ID]!) {
  confluence {
    spaces(ids: $spaceIds) {
      id
      spaceId
      name
      key
      links {
        base
      }
      icon {
        path
      }
    }
  }
}
`;

export type ConfluenceSpace = {
	id: string;
	spaceId: string;
	name: string;
	key: string;
	links?: {
		base?: string;
	};
	icon?: {
		path?: string;
	};
};

export type ConfluenceSpacesResponse = {
	confluence: {
		spaces: ConfluenceSpace[];
	};
};

export const confluenceSpacesQuery = (spaceIds: string[]) => {
	return spacesCache.inMemoryDecorator(
		// TODO: >>>> Limit this to a max string length
		// TODO: Refactor so that it can cache per page id
		spaceIds.join(','),
		async () => {
			if (spaceIds.length === 0) {
				return {
					data: {
						confluence: {
							spaces: [],
						},
					},
				};
			}

			const response = await fetchAgg<ConfluenceSpacesQueryVariables, ConfluenceSpacesResponse>({
				graphQuery: getConfluenceSpacesQuery(),
				variables: { spaceIds },
				operationName: GQL_CF_SPACES_OPERATION_NAME,
			});

			const hasErrors = response.errors?.some((error) => error);

			if (hasErrors) {
				return Promise.reject(response);
			}
			return response;
		},
	);
};
