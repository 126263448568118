import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ZendeskIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-219.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#03363D" />
			<path
				d="M11.6092 9.34881V17.3365H4.99316L11.6092 9.34881ZM11.6092 6.48242C11.6092 7.35976 11.2607 8.20117 10.6403 8.82155C10.0199 9.44192 9.17853 9.79044 8.30119 9.79044C7.42384 9.79044 6.58244 9.44192 5.96206 8.82155C5.34169 8.20117 4.99316 7.35976 4.99316 6.48242H11.6092ZM12.6991 17.3372C12.6991 16.4598 13.0477 15.6184 13.668 14.9981C14.2884 14.3777 15.1298 14.0292 16.0072 14.0292C16.8845 14.0292 17.7259 14.3777 18.3463 14.9981C18.9667 15.6184 19.3152 16.4598 19.3152 17.3372H12.6991ZM12.6991 14.4708V6.48242H19.3166L12.6991 14.4701V14.4708Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.4666 9.80166V17.609H5L11.4666 9.80166ZM11.4666 7C11.4666 7.85753 11.126 8.67994 10.5196 9.2863C9.91326 9.89267 9.09085 10.2333 8.23332 10.2333C7.37579 10.2333 6.55338 9.89267 5.94702 9.2863C5.34065 8.67994 5 7.85753 5 7H11.4666ZM12.532 17.6096C12.532 16.7521 12.8726 15.9297 13.479 15.3233C14.0854 14.717 14.9078 14.3763 15.7653 14.3763C16.6228 14.3763 17.4452 14.717 18.0516 15.3233C18.658 15.9297 18.9986 16.7521 18.9986 17.6096H12.532ZM12.532 14.808V7H19L12.532 14.8073V14.808Z"
				fill="#03363D"
			/>
		</svg>
	);
};
