import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { CanvaIcon } from './icon';
import { CanvaSearchResultIcon } from './search-result-icon';

export const canvaConfig: ThirdPartyConfig = {
	displayName: 'Canva',
	id: 'canva',
	resourceType: 'canva-connector',
	icon: get3PLogo(CanvaIcon, 'Canva', 'medium'),
	searchResultIcon: get3PLogo(CanvaSearchResultIcon, 'Canva', 'large'),
	integrationAri: 'ari:cloud:platform::integration/canva',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Design,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
