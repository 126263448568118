import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AsanaIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-268.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#F06A6A" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.9553 12.3807C14.2737 12.3807 12.9105 13.7439 12.9105 15.4256C12.9105 17.1072 14.2737 18.4704 15.9553 18.4704C17.6369 18.4704 19 17.1072 19 15.4256C19 13.7439 17.6369 12.3807 15.9553 12.3807ZM8.04475 12.381C6.3632 12.381 5 13.7439 5 15.4256C5 17.1072 6.3632 18.4704 8.04475 18.4704C9.72637 18.4704 11.0896 17.1072 11.0896 15.4256C11.0896 13.7439 9.72637 12.381 8.04475 12.381ZM15.0447 8.57471C15.0447 10.2564 13.6816 11.6197 12 11.6197C10.3184 11.6197 8.95526 10.2564 8.95526 8.57471C8.95526 6.89336 10.3184 5.53003 12 5.53003C13.6816 5.53003 15.0447 6.89336 15.0447 8.57471Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0853 12.8081C14.9233 12.8081 13.1707 14.5607 13.1707 16.7229C13.1707 18.8849 14.9233 20.6376 17.0853 20.6376C19.2474 20.6376 21 18.8849 21 16.7229C21 14.5607 19.2474 12.8081 17.0853 12.8081ZM6.91468 12.8084C4.75268 12.8084 3 14.5607 3 16.7229C3 18.8849 4.75268 20.6376 6.91468 20.6376C9.07677 20.6376 10.8295 18.8849 10.8295 16.7229C10.8295 14.5607 9.07677 12.8084 6.91468 12.8084ZM15.9146 7.91459C15.9146 10.0768 14.162 11.8296 12 11.8296C9.83793 11.8296 8.08533 10.0768 8.08533 7.91459C8.08533 5.75285 9.83793 4 12 4C14.162 4 15.9146 5.75285 15.9146 7.91459Z"
				fill="#F06A6A"
			/>
		</svg>
	);
};
