import React, { useState } from 'react';

import { IconButton } from '@atlaskit/button/new';
import EditorMoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--editor-more';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';

import { filterSpacer } from '../../utils/search-result-utils';

const popupContentContainerStyles = xcss({
	display: 'flex',
	padding: 'space.200',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start',
	gap: 'space.100',

	borderRadius: 'border.radius.100',
	background: 'elevation.surface.overlay',
	boxShadow: 'elevation.shadow.overlay',
});

const subheadingStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body',
});

const iconButtonContainerStyles = xcss({
	margin: 'space.025',
	marginLeft: 'space.075',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const iconButtonContainerIsFirstElementStyles = xcss({
	marginLeft: 'space.025',
});

export const MetadataPopupButton = ({
	metadata,
	isFirstElement,
}: {
	metadata: React.ReactNode[];
	isFirstElement: boolean;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			content={() => (
				<Box xcss={[popupContentContainerStyles, subheadingStyles]}>
					{metadata.map((item, index) => (
						<Box key={index}>{filterSpacer(item)}</Box>
					))}
				</Box>
			)}
			trigger={(triggerProps) => (
				<Box
					xcss={[
						iconButtonContainerStyles,
						isFirstElement && iconButtonContainerIsFirstElementStyles,
					]}
				>
					<IconButton
						{...triggerProps}
						appearance="subtle"
						spacing="compact"
						isSelected={isOpen}
						onClick={() => setIsOpen(!isOpen)}
						icon={EditorMoreIcon}
						label="More"
					/>
				</Box>
			)}
		/>
	);
};
