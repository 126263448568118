import { parse } from '@atlassian/cs-ari';

import { type EntityType, type ProductType } from '../../common/types';

/**
 * Site ARI syntaxes:
 * ari:cloud:avp::site/DUMMY-158c8204-ff3b-47c2-adbb-a0906ccc722b
 *
 * Result ARI syntaxes:
 * ari:cloud:confluence:DUMMY-7c8a2b74-595a-41c7-960c-fd32f8572cea:page/399835137
 */

export const getProductAndIDFromARI = (ari: string) => {
	const { resourceId, resourceOwner, resourceType, cloudId } = isThirdPartyARI(ari)
		? parseThirdPartyARI(ari)
		: parse(ari);

	return [resourceOwner, cloudId, resourceType, resourceId] as [
		ProductType,
		string, // productId
		EntityType,
		string, // id
	];
};

const thirdPartyRegex = new RegExp('^ari:third-party:(\\S+)::(\\S+)\\/(\\S+)$');
/**
 * Parse an ARI for third party resources.
 * **NOTE:** this function was made because the parse utility from @atlassian/cs-ari
 *  did not support third party results and we haven't been able to find the owning
 *  team for it in the past. If the issue is fixed we could remove this utility.
 **/
export const parseThirdPartyARI = (ari: string) => {
	const result = thirdPartyRegex.exec(ari);
	if (!result) {
		throw new Error(
			`Invalid third party ARI. Use ${isThirdPartyARI.name} to check before calling this function.`,
		);
	}
	const [_, resourceOwner, resourceType, resourceId] = result;
	return { resourceId, resourceOwner, resourceType, cloudId: resourceId };
};

/** @returns whether the given string is a third party ARI */
export const isThirdPartyARI = (ari: string) => thirdPartyRegex.test(ari);
