import {
	DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GitlabIcon } from './icon';
import { GitlabSearchResultIcon } from './search-result-icon';
export const gitlabConfig: ThirdPartyConfig = {
	displayName: 'Gitlab',
	id: 'gitlab',
	resourceType: 'gitlab-connector',
	icon: get3PLogo(GitlabIcon, 'Gitlab', 'medium'),
	searchResultIcon: get3PLogo(GitlabSearchResultIcon, 'Gitlab', 'large'),
	integrationAri: 'ari:cloud:platform::integration/gitlab',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Repository,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Repository],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
