// Modified from platform/packages/search/product-search-dialog/src/extensible/product-router/product/result-provider/faster-search-helpers.ts
import { highlightMatchedText } from '@atlassian/search-common';

type FilterItems = {
	title: string;
	highlightedTitle?: string;
};

/**
 * TODO: Remove this function because it does too much, making it hard to reuse
 * This will get removed when we no longer use the janky dropdown in the search page
 */
export function filterSearchResultsWithHighlighting<T extends FilterItems>(
	searchResults: T[],
	query: string,
	numberOfResultItems = 3,
	negateTitleHighlight?: boolean,
): T[] {
	const wordsInQuery = query.split(' ');
	const itemScoreMap: {
		[key: string]: number;
	} = {};

	const filteredResults: T[] = searchResults
		.flatMap((result) => {
			// 1st pass
			const doAllWordsMatch = wordsInQuery.reduce((acc, word) => {
				if (!acc) {
					return acc;
				}
				return result.title.toLowerCase().includes(word.toLowerCase());
			}, true);

			if (doAllWordsMatch) {
				// 2nd and 3rd Pass
				const {
					highlightedTitle,
					negatedHighlightedTitle,
					score = 0,
				} = highlightMatchedText(result.title, wordsInQuery);
				itemScoreMap[result.title] = score;

				return [
					{
						...result,
						highlightedTitle: negateTitleHighlight ? negatedHighlightedTitle : highlightedTitle,
					},
				];
			}

			return [];
		})
		.sort((item, secondItem) =>
			item && secondItem ? itemScoreMap[secondItem.title] - itemScoreMap[item.title] : 0,
		)
		.slice(0, numberOfResultItems);

	return filteredResults;
}

/**
 * Copy of the above function, but it doesn't do highlighting or limit the
 * number of results to make it more reusable.
 */
export function filterSearchResults<T extends FilterItems>(searchResults: T[], query: string): T[] {
	const wordsInQuery = query.split(' ');
	const itemScoreMap: {
		[key: string]: number;
	} = {};

	const filteredResults: T[] = searchResults
		.flatMap((result) => {
			// 1st pass
			const doAllWordsMatch = wordsInQuery.reduce((acc, word) => {
				if (!acc) {
					return acc;
				}
				return result.title.toLowerCase().includes(word.toLowerCase());
			}, true);

			if (doAllWordsMatch) {
				// 2nd and 3rd Pass
				const { score = 0 } = highlightMatchedText(result.title, wordsInQuery);
				itemScoreMap[result.title] = score;

				return [
					{
						...result,
					},
				];
			}

			return [];
		})
		.sort((item, secondItem) =>
			item && secondItem ? itemScoreMap[secondItem.title] - itemScoreMap[item.title] : 0,
		);

	return filteredResults;
}
