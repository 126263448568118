import { type ProductKeys3P } from '../3p-product-configs';
import type { FilterId } from '../filters';
import type { TypeFilterConfig3P } from '../filters/universal-type';
import { type RovoLogo } from '../icons';

type PlatformIntegrationAri = `ari:cloud:platform::integration/${string}`;

/**
 * Configuration for a third-party connector.
 */
export interface ThirdPartyConfig {
	/**
	 * The displayed name of the connector.
	 */
	displayName: React.ReactNode;

	/**
	 * The unique identifier for the connector.
	 */
	id: string;

	/**
	 * The resource type for the 3P config endpoint.
	 * Optional: Some connectors like Loom do not have this.
	 */
	resourceType?: string;

	/**
	 * The icon for the connector.
	 */
	icon: RovoLogo;

	/**
	 * The search result icon for the connector.
	 */
	searchResultIcon?: RovoLogo;

	/**
	 * The integration ARI (Atlassian Resource Identifier) for the connector.
	 */
	integrationAri: PlatformIntegrationAri;

	/**
	 * The list of types keys that can be used to search for this 3P.
	 *
	 * This powers both the type filter AND the entities that are requested from Aggregator during a search
	 *
	 * For how values map to nouns and subtypes please see ../nouns-and-types
	 */
	typeFilterConfig?: TypeFilterConfig3P;

	/**
	 * The filters available for the connector. The Universal Type Filter is included by default, so it is not necessary to include it here.
	 */
	filters?: FilterId[];

	/**
	 * The OAuth outbound URL for the connector. It is populated at runtime.
	 */
	oAuthOutboundUrl?: string;

	/**
	 * Indicates whether a product requires Oauth.
	 */
	requiresOauth: boolean;

	/**
	 * Indicates whether the user is authenticated via OAuth. It is populated at runtime.
	 */
	userNeedsOAuth?: boolean;

	/**
	 * Indicates whether the connector is enabled
	 */
	isEnabled: () => boolean;

	/**
	 * Indicates whether the connector is federated
	 * and therefore doesnt need a thirdPartyProduct filter
	 * in the QGL query
	 */
	isFederated?: boolean;
}

export const SMARTLINKS_RESOURCE_TYPE_PREFIX = 'smartlinks-connector';

export const FEDERATED_CONNECTOR_SOURCE = 'federated';
export const SMARTLINKS_CONNECTOR_SOURCE = 'smartlinks';
export const FULL_CONNECTOR_SOURCE = 'full-connector';
export type ConnectorSource =
	| typeof FEDERATED_CONNECTOR_SOURCE
	| typeof SMARTLINKS_CONNECTOR_SOURCE
	| typeof FULL_CONNECTOR_SOURCE;

// certain properties from ThirdPartyConfig are redefined as they are guaranteed
export type ThirdPartyConfigsWithAPIResponse = ThirdPartyConfig & {
	providerId: string;
	oAuthOutboundUrl: string;
	userNeedsOAuth: boolean;
	workspaceName?: string;
	connectorSources?: ConnectorSource[];
};

export type ThirdPartyConfigsBootstrap = Partial<
	Record<ProductKeys3P, ThirdPartyConfigsWithAPIResponse>
>;
