// these are values for the query params
export const PageStatusFilterValueKey = {
	RoughDraft: 'roughDraft',
	InProgress: 'inProgress',
	ReadyForReview: 'readyForReview',
	Verified: 'verified',
	CustomStatus: 'customStatus',
} as const;
export type PageStatusFilterValueKey =
	(typeof PageStatusFilterValueKey)[keyof typeof PageStatusFilterValueKey];
export const allPageStatusFilterValueKeys = Object.values(PageStatusFilterValueKey);

// these are the values that will be passed into the GQL query under ConfluenceFilters' pageStatus
export const PageStatusFilterValue: Record<PageStatusFilterValueKey, string> = {
	[PageStatusFilterValueKey.RoughDraft]: 'Rough draft',
	[PageStatusFilterValueKey.InProgress]: 'In progress',
	[PageStatusFilterValueKey.ReadyForReview]: 'Ready for review',
	[PageStatusFilterValueKey.Verified]: 'Verified',
	[PageStatusFilterValueKey.CustomStatus]: 'Custom status',
} as const;
