import {
	DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
} from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { SlackIcon } from './icon';
import { SlackSearchResultIcon } from './search-result-icon';

export const slackConfig: ThirdPartyConfig = {
	displayName: 'Slack',
	id: 'slack',
	resourceType: 'ai-3p-connector:slack-workspace',
	icon: get3PLogo(SlackIcon, 'Slack', 'medium'),
	searchResultIcon: get3PLogo(SlackSearchResultIcon, 'Slack', 'large'),
	integrationAri: 'ari:cloud:platform::integration/slack',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Conversation,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
				subTypes3P: [],
			},
		],
		[
			TypeFilterValueKey.Message,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
