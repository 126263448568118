import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { components } from '@atlaskit/select';

const boxStyles = xcss({
	borderRadius: 'border.radius',
	boxSizing: 'border-box',
});

export const Control = (props: any) => (
	<>
		{props.selectProps.isSearchable && (
			<Box paddingInline="space.100" paddingBlockStart="space.100" xcss={boxStyles}>
				<components.Control {...props} />
			</Box>
		)}
	</>
);
