import { FEDERATED_MICROSOFT_EMAIL_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { OutlookMailIcon } from './icon';
import { OutlookMailSearchResultIcon } from './search-result-icon';

export const outlookMail: ThirdPartyConfig = {
	displayName: 'Outlook Mail',
	id: 'outlook-mail',
	resourceType: 'outlook-mail-connector',
	icon: get3PLogo(OutlookMailIcon, 'Outlook Mail', 'medium'),
	searchResultIcon: get3PLogo(OutlookMailSearchResultIcon, 'Outlook Mail', 'large'),
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	requiresOauth: true,
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Message,
			{
				entities3P: [FEDERATED_MICROSOFT_EMAIL_ENTITY_ATI],
				subTypes3P: [],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch'],
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	isEnabled: () => true,
	isFederated: true,
};
