import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GitlabIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-587.5"
				y="-363.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.381243"
				y="0.381243"
				width="23.2375"
				height="23.2375"
				rx="5.61876"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.762486"
			/>
			<path
				d="M19.7331 10.3948L19.7115 10.3373L17.5339 4.65705C17.4898 4.54552 17.4114 4.4509 17.31 4.3869C17.2343 4.33846 17.1483 4.30842 17.059 4.29919C16.9696 4.28996 16.8793 4.30179 16.7953 4.33375C16.7113 4.3657 16.636 4.41688 16.5754 4.4832C16.5147 4.54951 16.4705 4.6291 16.4461 4.7156L14.9763 9.21564H9.02381L7.55392 4.7156C7.5294 4.62923 7.48504 4.54979 7.42438 4.4836C7.36371 4.41742 7.28842 4.36632 7.20451 4.3344C7.1206 4.30247 7.03039 4.29059 6.94107 4.2997C6.85175 4.30882 6.7658 4.33868 6.69007 4.3869C6.58865 4.4509 6.51022 4.54552 6.46615 4.65705L4.28957 10.3383L4.26697 10.3948C3.95361 11.2138 3.91501 12.1125 4.15698 12.9553C4.39895 13.7981 4.90838 14.5394 5.60846 15.0674L5.61668 15.0736L5.63517 15.088L8.94779 17.5706L10.5913 18.8125L11.5897 19.5675C11.7068 19.656 11.8496 19.7038 11.9964 19.7038C12.1432 19.7038 12.2861 19.656 12.4032 19.5675L13.4016 18.8125L15.0451 17.5706L18.3813 15.0736L18.3906 15.0664C19.0906 14.5386 19.6001 13.7975 19.8423 12.9549C20.0844 12.1123 20.0461 11.2138 19.7331 10.3948Z"
				fill="#E24329"
			/>
			<path
				d="M19.7329 10.3948L19.7114 10.3373C18.6504 10.555 17.6507 11.0047 16.7839 11.6541L12.0024 15.2698L15.047 17.5706L18.3832 15.0736L18.3925 15.0664C19.0922 14.5383 19.6012 13.7971 19.843 12.9545C20.0848 12.1119 20.0462 11.2135 19.7329 10.3948Z"
				fill="#FC6D26"
			/>
			<path
				d="M8.94775 17.5708L10.5912 18.8126L11.5896 19.5676C11.7068 19.6561 11.8496 19.7039 11.9964 19.7039C12.1432 19.7039 12.286 19.6561 12.4032 19.5676L13.4016 18.8126L15.045 17.5708L12.0005 15.2699L8.94775 17.5708Z"
				fill="#FCA326"
			/>
			<path
				d="M7.21598 11.6541C6.34952 11.005 5.35016 10.5556 4.28957 10.3383L4.26697 10.3948C3.95361 11.2137 3.91501 12.1124 4.15698 12.9552C4.39895 13.798 4.90838 14.5393 5.60846 15.0674L5.61668 15.0735L5.63517 15.0879L8.94779 17.5706L11.9944 15.2697L7.21598 11.6541Z"
				fill="#FC6D26"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.7331 11.0987L19.7115 11.0412L17.5339 5.36091C17.4898 5.24937 17.4114 5.15476 17.31 5.09076C17.2343 5.04232 17.1483 5.01228 17.059 5.00304C16.9696 4.99381 16.8793 5.00565 16.7953 5.0376C16.7113 5.06956 16.636 5.12074 16.5754 5.18705C16.5147 5.25337 16.4705 5.33296 16.4461 5.41946L14.9763 9.91949H9.02381L7.55392 5.41946C7.5294 5.33309 7.48504 5.25364 7.42438 5.18746C7.36371 5.12127 7.28842 5.07018 7.20451 5.03825C7.1206 5.00632 7.03039 4.99444 6.94107 5.00356C6.85175 5.01268 6.7658 5.04254 6.69007 5.09076C6.58865 5.15476 6.51023 5.24937 6.46615 5.36091L4.28957 11.0422L4.26697 11.0987C3.95361 11.9177 3.91501 12.8163 4.15698 13.6591C4.39895 14.502 4.90838 15.2433 5.60846 15.7713L5.61668 15.7775L5.63517 15.7918L8.9478 18.2745L10.5913 19.5164L11.5897 20.2713C11.7068 20.3598 11.8496 20.4077 11.9964 20.4077C12.1432 20.4077 12.2861 20.3598 12.4032 20.2713L13.4016 19.5164L15.0451 18.2745L18.3813 15.7775L18.3906 15.7703C19.0906 15.2424 19.6001 14.5014 19.8423 13.6588C20.0844 12.8161 20.0461 11.9176 19.7331 11.0987Z"
				fill="#E24329"
			/>
			<path
				d="M19.7331 11.0987L19.7115 11.0411C18.6505 11.2589 17.6508 11.7085 16.784 12.358L12.0026 15.9736L15.0471 18.2745L18.3833 15.7774L18.3926 15.7702C19.0923 15.2421 19.6014 14.501 19.8431 13.6583C20.0849 12.8157 20.0463 11.9174 19.7331 11.0987Z"
				fill="#FC6D26"
			/>
			<path
				d="M8.94781 18.2745L10.5913 19.5163L11.5897 20.2713C11.7068 20.3598 11.8496 20.4077 11.9965 20.4077C12.1433 20.4077 12.2861 20.3598 12.4032 20.2713L13.4016 19.5163L15.0451 18.2745L12.0006 15.9736L8.94781 18.2745Z"
				fill="#FCA326"
			/>
			<path
				d="M7.21598 12.358C6.34952 11.7089 5.35016 11.2596 4.28957 11.0422L4.26697 11.0987C3.95361 11.9177 3.91501 12.8164 4.15698 13.6592C4.39895 14.502 4.90838 15.2433 5.60846 15.7713L5.61668 15.7775L5.63517 15.7919L8.9478 18.2745L11.9944 15.9737L7.21598 12.358Z"
				fill="#FC6D26"
			/>
		</svg>
	);
};
