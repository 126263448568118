import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const TodoistIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-731.5"
				y="-268.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#DE483A"
			/>
			<path
				d="M16.7653 5.01575H7.2667C6.03236 5.01575 5.03174 6.01638 5.03174 7.25072V16.7493C5.03174 17.9837 6.03236 18.9843 7.2667 18.9843H16.7653C17.9996 18.9843 19.0002 17.9837 19.0002 16.7493V7.25072C19.0002 6.01638 17.9996 5.01575 16.7653 5.01575Z"
				fill="white"
			/>
			<path
				d="M7.98402 11.5604L10.7664 9.9593L10.7699 9.95712L13.6369 8.30708C13.7578 8.23724 13.7635 8.02335 13.6282 7.94565L13.5334 7.89152C13.3951 7.81295 13.2161 7.7108 13.1397 7.66628C13.0728 7.62983 12.9976 7.61118 12.9214 7.61209C12.8452 7.61301 12.7705 7.63347 12.7045 7.67151C12.6368 7.7108 8.12065 10.3072 7.96961 10.3932C7.88062 10.4441 7.77988 10.4708 7.67737 10.4708C7.57485 10.4708 7.47412 10.4441 7.38512 10.3932L5 9.00726V10.1854L5.02444 10.1994C5.61985 10.5464 7.02892 11.3671 7.37377 11.5613C7.58286 11.6792 7.78278 11.6766 7.98402 11.5604Z"
				fill="#DE483A"
			/>
			<path
				d="M7.98402 13.9142L10.7629 12.3148L10.7734 12.3087L13.6369 10.6608C13.7578 10.591 13.7635 10.3767 13.6282 10.2994L13.5334 10.2453C13.3955 10.1667 13.2161 10.0646 13.1397 10.02C13.0728 9.98359 12.9976 9.96494 12.9214 9.96585C12.8452 9.96677 12.7705 9.98723 12.7045 10.0253C12.6368 10.0641 8.12065 12.661 7.96961 12.7465C7.88062 12.7974 7.77988 12.8242 7.67737 12.8242C7.57485 12.8242 7.47412 12.7974 7.38512 12.7465C7.24282 12.6645 5 11.361 5 11.361V12.5387L5.02444 12.5531C5.62029 12.9002 7.02892 13.7204 7.37377 13.9151C7.58286 14.0329 7.78278 14.0303 7.98402 13.9142Z"
				fill="#DE483A"
			/>
			<path
				d="M10.7668 14.6663L7.98402 16.2679C7.78279 16.384 7.58242 16.3866 7.37377 16.2688C7.02892 16.0741 5.62029 15.2539 5.02444 14.9069L5 14.8924V13.7147L7.38512 15.1002C7.5654 15.2046 7.7889 15.2032 7.96961 15.1002C8.12065 15.0147 12.6368 12.4178 12.7045 12.3785C12.7705 12.3405 12.8452 12.32 12.9214 12.3191C12.9976 12.3182 13.0728 12.3369 13.1397 12.3733C13.3022 12.4673 13.4652 12.5604 13.6286 12.6527C13.7639 12.7304 13.7578 12.9447 13.6369 13.0146L10.7668 14.6663Z"
				fill="#DE483A"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.4458 4H6.59025C5.17958 4 4.03601 5.14358 4.03601 6.55425V17.4098C4.03601 18.8205 5.17958 19.9641 6.59025 19.9641H17.4458C18.8564 19.9641 20 18.8205 20 17.4098V6.55425C20 5.14358 18.8564 4 17.4458 4Z"
				fill="#DE483A"
			/>
			<path
				d="M7.41031 11.4797L10.5901 9.64985L10.5941 9.64735L13.8707 7.7616C14.0089 7.68178 14.0154 7.43733 13.8608 7.34853L13.7525 7.28667C13.5944 7.19687 13.3898 7.08013 13.3025 7.02924C13.226 6.9876 13.1401 6.96627 13.053 6.96732C12.966 6.96837 12.8806 6.99175 12.8051 7.03523C12.7278 7.08013 7.56645 10.0475 7.39384 10.1457C7.29213 10.2039 7.17701 10.2345 7.05985 10.2345C6.94269 10.2345 6.82756 10.2039 6.72585 10.1457L4 8.5618V9.90827L4.02794 9.92423C4.7084 10.3208 6.31877 11.2587 6.71288 11.4807C6.95184 11.6154 7.18033 11.6124 7.41031 11.4797Z"
				fill="white"
			/>
			<path
				d="M7.41031 14.1697L10.5861 12.3418L10.5981 12.3348L13.8707 10.4515C14.0089 10.3717 14.0154 10.1268 13.8608 10.0385L13.7525 9.97661C13.5949 9.88681 13.3898 9.77007 13.3025 9.71919C13.226 9.67754 13.1401 9.65622 13.053 9.65726C12.966 9.65831 12.8806 9.68169 12.8051 9.72517C12.7278 9.76957 7.56645 12.7374 7.39384 12.8352C7.29213 12.8933 7.17701 12.9239 7.05985 12.9239C6.94269 12.9239 6.82756 12.8933 6.72585 12.8352C6.56322 12.7414 4 11.2517 4 11.2517V12.5977L4.02794 12.6142C4.7089 13.0108 6.31877 13.9482 6.71288 14.1707C6.95184 14.3054 7.18033 14.3024 7.41031 14.1697Z"
				fill="white"
			/>
			<path
				d="M10.5906 15.0292L7.41031 16.8596C7.18033 16.9923 6.95134 16.9953 6.71288 16.8606C6.31877 16.6381 4.7089 15.7007 4.02794 15.3041L4 15.2877V13.9417L6.72585 15.5251C6.93189 15.6444 7.18731 15.6429 7.39384 15.5251C7.56645 15.4273 12.7278 12.4595 12.8051 12.4146C12.8806 12.3711 12.966 12.3478 13.053 12.3467C13.1401 12.3457 13.226 12.367 13.3025 12.4086C13.4882 12.516 13.6745 12.6225 13.8613 12.7279C14.0159 12.8167 14.0089 13.0617 13.8707 13.1415L10.5906 15.0292Z"
				fill="white"
			/>
		</svg>
	);
};
