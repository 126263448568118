import { DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AhaIcon } from './icon';
import { AhaSearchResultIcon } from './search-result-icon';

export const ahaConfig: ThirdPartyConfig = {
	displayName: 'Aha',
	id: 'aha',
	resourceType: 'aha-connector',
	icon: get3PLogo(AhaIcon, 'Aha', 'medium'),
	searchResultIcon: get3PLogo(AhaSearchResultIcon, 'Aha', 'large'),
	integrationAri: 'ari:cloud:platform::integration/aha',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.WorkItem,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
