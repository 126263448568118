import { fg } from '@atlaskit/platform-feature-flags';

import { DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo, RovoLogoFromLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GitbookIcon } from './icon';
import { GitbookSearchResultIcon } from './search-result-icon';

// This config file will be used for demo purposes only, and will be removed after Team 25
export const gitbookConfig: ThirdPartyConfig = {
	displayName: 'GitBook',
	id: 'gitbook',
	icon: RovoLogoFromLogo(GitbookIcon, 'GitBook'),
	resourceType: 'gitbook-connector',
	searchResultIcon: get3PLogo(GitbookSearchResultIcon, 'GitBook', 'large'),
	integrationAri: 'ari:cloud:platform::integration/graph-test-app', // as provided here: https://atlassian.slack.com/archives/C0882LN4A56/p1741316020130509
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Document,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: false,
	userNeedsOAuth: false,
	// This will only be enabled on the demo instance
	isEnabled: () => fg('forge-connector-demo'),
};
