import type { SearchResult } from '../../../../common/types';

const SearchResultTrelloBoard = `
... on SearchResultTrelloBoard {
	type
	description
	lastModifiedDate
}
`;

export interface SearchResultTrelloBoard extends SearchResult {
	lastModifiedDate?: string;
	__typename: 'SearchResultTrelloBoard';
}

export const isSearchResultTrelloBoard = (
	partial: SearchResult,
): partial is SearchResultTrelloBoard => {
	return partial.__typename === 'SearchResultTrelloBoard';
};

const SearchResultTrelloCard = `
... on SearchResultTrelloCard {
	type
	description
	lastModifiedDate
	boardName
	commentsCount
}
`;

export interface SearchResultTrelloCard extends SearchResult {
	lastModifiedDate?: string;
	boardName?: string;
	commentsCount?: number;
	__typename: 'SearchResultTrelloCard';
}

export const isSearchResultTrelloCard = (
	partial: SearchResult,
): partial is SearchResultTrelloCard => {
	return partial.__typename === 'SearchResultTrelloCard';
};

export type SearchTrelloPartial = SearchResultTrelloBoard | SearchResultTrelloCard;

export const isSearchTrelloPartial = (partial: SearchResult): partial is SearchTrelloPartial => {
	return isSearchResultTrelloBoard(partial) || isSearchResultTrelloCard(partial);
};

export const getTrelloPartials = () => {
	return `
		${SearchResultTrelloBoard}
		${SearchResultTrelloCard}
	`;
};
