import { getContributors, type User, usersQuery } from '@atlassian/search-client';

import {
	all1pProductKeys,
	all3pProductKeys,
	ProductKeys,
	type ProductKeys1P,
	ProductKeys3P,
} from '../../../../../common/constants/products';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';
import { loadRecommendedUsers } from '../utils/recommendedUser';

const MAX_SEARCHED_OPTIONS_RESULTS = 5;
export const CONTRIBUTOR_FILTER_QUERY_KEYWORDS = ['@', 'contributor:'];

// Filter out Trello for milestone 1 (QS-4733)
const productKeys1P: ProductKeys1P[] = all1pProductKeys.filter((key) => key !== ProductKeys.Trello);

// Figma doesn't support contributor
// https://atlassian.slack.com/archives/C07P3MD2U9Z/p1733868616441159?thread_ts=1733866616.102409&cid=C07P3MD2U9Z
const all3pProductKeysExceptFigma = all3pProductKeys.filter((key) => key !== ProductKeys3P.Figma);

export const filter = createMultiSelectFilter({
	id: 'contributor',
	// 1P only for now (excluding Trello)
	products: [...productKeys1P, ...all3pProductKeysExceptFigma],
	alwaysVisible: true,
	universal: true,
	queryParams: {
		key: 'contributors',
		fetcher: async (queryParams, _) => {
			const initialUsersReponse = await usersQuery([...queryParams]);

			const allRecommendedUsers = initialUsersReponse.data?.users ?? ([] satisfies User[]);

			const recommendedUserOptions = allRecommendedUsers.map((user) => ({
				trackingKey: user.accountId,
				value: user.accountId,
				label: user.name,
				avatarUrl: user.picture,
				queryParamValue: user.accountId,
			}));

			return recommendedUserOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return loadRecommendedUsers(config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			if (!config.intl) {
				return []; // base on existing interface, can only query contributors with intl
			}

			const response = await getContributors({
				query,
				cloudId: config.cloudId || '',
				userId: config.userId || '',
				productKey: ProductKeys.Confluence,
				maxNumberOfResults: MAX_SEARCHED_OPTIONS_RESULTS,
				intl: config.intl,
			});

			if (!response) {
				// TODO: log not found somewhere
				return [];
			}

			return response.map((contributor) => ({
				trackingKey: contributor.id,
				value: contributor.id,
				label: contributor.name,
				avatarUrl: contributor.avatarUrl,
				queryParamValue: contributor.id,
			}));
		},
	},
});
