import FeatureGates from '@atlaskit/feature-gate-js-client';

import { type SideBySideExperiment } from '../../types';
import { DEFAULT_STATSIG_EXPERIMENT_CONFIG } from '../constants';

import { getBackendExperimentConfig } from './backend';
import { getFrontendExperiment } from './frontend';

/**
 * This function fetches the experiment configuration within a given search experience layer
 * and will return the data for either the frontend experiment(s) or the backend experiment.
 *
 * If frontend experiment(s) exist in the layer,
 * * The frontend experiment exposure IS fired
 * * The backend experiment exposure IS NOT fired
 * * Backend experiment data IS NOT passed in requests
 *
 * If there is no frontend experiment(s) in the layer,
 * * The frontend experiment exposure IS NOT fired
 * * The backend experiment exposure IS fired
 * * Backend experiment data IS passed in requests
 */
export const getMutuallyExclusiveExperiment = (
	layerName?: string,
	onErrorFn?: (error: any) => any,
	shouldNotGetBackendExperiment: boolean = false,
) => {
	try {
		if (!layerName) {
			return {
				frontendExperiment: undefined,
				backendExperiment: getBackendExperimentConfig(onErrorFn, shouldNotGetBackendExperiment),
				isMutuallyExclusive: false,
			};
		}

		const layer = FeatureGates.getLayer(layerName, { fireLayerExposure: false });
		const frontendExperimentName = layer.get('frontendExperimentName', '');

		if (frontendExperimentName) {
			return {
				frontendExperiment: getFrontendExperiment({
					abTestId: frontendExperimentName,
				}),
				backendExperiment: DEFAULT_STATSIG_EXPERIMENT_CONFIG,
				isMutuallyExclusive: true,
			};
		}

		return {
			frontendExperiment: undefined,
			backendExperiment: getBackendExperimentConfig(onErrorFn, shouldNotGetBackendExperiment),
			isMutuallyExclusive: false,
		};
	} catch (error) {
		onErrorFn?.(error);
	}

	return {
		frontendExperiment: undefined,
		backendExperiment: DEFAULT_STATSIG_EXPERIMENT_CONFIG,
		isMutuallyExclusive: false,
	};
};

/**
 * Handles any backend experiment overrides for Rovo vs Rovo in SBS
 * This function only allows overrides for backend experiments; gates, layers
 * will not work currently.
 *
 * If an override is passed in for an experiment for particular layer, L1, L2 etc,
 * this will override that specific layer's experiment only, leaving all other experiments
 * as is. E.g. if an experiment for L1 is passed in only search_platform_layer_l1 will be overriden
 *
 *  Falls back to a standard statsig call if:
 * - search-experiment-overrides-backend is off
 * - errors
 * - no overrides provided
 * - overrides of non backend, experiment type
 * - experiments with no testId
 */
export const getBackendExperimentsWithOverrides = (
	overrides: SideBySideExperiment[] | undefined,
	layerName?: string,
	onErrorFn?: (error: any) => any,
	shouldNotGetBackendExperiment: boolean = false,
) => {
	// We should only evaluate the backend experiment here if we are using search experiment overrides functionality
	// backend experiments outside of rovo v rovo are evaluated at request time instead
	const shouldUseSearchExperimentOverrides = FeatureGates.checkGate(
		'search-experiment-overrides-backend',
	);
	const shouldNotGetBackendExperimentsForOverrides = shouldUseSearchExperimentOverrides
		? false
		: shouldNotGetBackendExperiment;

	// If the feature gate is enabled or no overrides are provided, return the default experiment.
	if (!shouldUseSearchExperimentOverrides || !overrides || overrides.length === 0) {
		return getMutuallyExclusiveExperiment(
			layerName,
			onErrorFn,
			shouldNotGetBackendExperimentsForOverrides,
		);
	}
	// Filter for backend experiment from the overrides. only allowing them for now
	const backendOverrideExperiments = overrides.filter(
		(override) => override.location === 'backend' && override.type === 'experiment',
	);

	if (!backendOverrideExperiments.length) {
		return getMutuallyExclusiveExperiment(
			layerName,
			onErrorFn,
			shouldNotGetBackendExperimentsForOverrides,
		);
	}

	// Get the base user's experiments from layers.
	const { backendExperiment, frontendExperiment, isMutuallyExclusive } =
		getMutuallyExclusiveExperiment(
			layerName,
			onErrorFn,
			shouldNotGetBackendExperimentsForOverrides,
		);

	if (backendExperiment && 'experimentLayers' in backendExperiment) {
		backendExperiment.experimentLayers = backendExperiment?.experimentLayers.map((layer) => {
			const layerToOverride = backendOverrideExperiments.find((exp) => {
				return (
					exp.layer && layer.name?.includes(exp.layer === 'qr' ? 'query_expansion' : exp.layer)
				);
			});

			if (!layerToOverride) {
				const { testId: _, ...rest } = layer;
				return rest;
			}
			const statsigOverrideExperiment = FeatureGates.getExperiment(layerToOverride?.name);
			// handle case where experiment didnt add testId - for older experiments should be on new ones
			if (!statsigOverrideExperiment.get<string | null>('testId', null)) {
				const { testId: _, ...rest } = layer;
				return rest;
			}
			return {
				name: layer.name,
				layerId: statsigOverrideExperiment.get<string | null>('testId', null),
				shadowId: statsigOverrideExperiment.get<string | null>('shadowId', null),
				// The below values might not be sent to the backend, but we can still track them in the frontend.
				controlId: statsigOverrideExperiment.get<string | null>('controlId', null),
				abTestId: statsigOverrideExperiment.get<string | null>('abTestId', null),
			};
		});
	}
	return {
		backendExperiment,
		frontendExperiment,
		isMutuallyExclusive,
	};
};
