import { type Action, createActionsHook, createHook, createStore } from 'react-sweet-state';

import { BulkConnectProductOrder3P } from '../../../common/constants/3p-product-configs';
import { ProductKeys3P } from '../../../common/constants/products';
import type { ThirdPartyConfigsBootstrap } from '../../../common/constants/schemas/3p-config';

export type BulkConnectDialogState = {
	productOrder: ProductKeys3P[];
	productStatuses: Map<ProductKeys3P, boolean>;
	connectedProducts: ProductKeys3P[];
	productConnectedInSession: boolean;
};

export const initialState: BulkConnectDialogState = {
	productOrder: [],
	productStatuses: new Map(),
	connectedProducts: [],
	productConnectedInSession: false,
};

const actions = {
	setProductStatus:
		(productKey: ProductKeys3P, status: boolean): Action<BulkConnectDialogState> =>
		({ setState, getState }) => {
			const { productStatuses } = getState();
			const updatedProductStatuses = new Map(productStatuses);
			updatedProductStatuses.set(productKey, status);
			setState({
				...getState(),
				productStatuses: updatedProductStatuses,
			});
		},

	initializeProductState:
		(
			availableProducts: ProductKeys3P[],
			recentlyConnectedProducts: ProductKeys3P[],
			thirdPartyConfigs: ThirdPartyConfigsBootstrap,
		): Action<BulkConnectDialogState> =>
		({ setState, getState }) => {
			// First, set product statuses
			const productStatuses = new Map();
			availableProducts.forEach((productKey) => {
				const connectStatus = !thirdPartyConfigs[productKey]?.userNeedsOAuth;
				productStatuses.set(productKey, connectStatus);
			});
			// Then, determine connected products
			const connectedProducts: ProductKeys3P[] = [];
			productStatuses.forEach((status, productKey) => {
				if (status) {
					connectedProducts.push(productKey);
				}
			});
			// Finally, set product order
			const productOrder: ProductKeys3P[] = [];
			recentlyConnectedProducts.forEach((productKey) => productOrder.push(productKey));
			BulkConnectProductOrder3P.filter(
				(productKey) =>
					availableProducts.includes(productKey) &&
					!connectedProducts.includes(productKey) &&
					!recentlyConnectedProducts.includes(productKey),
			).forEach((productKey) => productOrder.push(productKey));
			connectedProducts
				.filter((productKey) => !recentlyConnectedProducts.includes(productKey))
				.forEach((productKey) => productOrder.push(productKey));
			// Set all state at once
			setState({
				...getState(),
				productStatuses: productStatuses,
				connectedProducts: connectedProducts,
				productOrder: productOrder,
			});
		},

	setProductConnectedInSession:
		(productConnectedInSession: boolean): Action<BulkConnectDialogState> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				productConnectedInSession: productConnectedInSession,
			});
		},
};

type BulkConnectDialogActions = typeof actions;

const Store = createStore<BulkConnectDialogState, BulkConnectDialogActions>({
	initialState,
	actions,
	name: 'bulk-connect-dialog',
});

export const useBulkConnectActions = createActionsHook(Store);

export const useProductOrder = createHook(Store, {
	selector: (state) => state.productOrder,
});

export const useProductStatuses = createHook(Store, {
	selector: (state) => state.productStatuses,
});

export const useConnectedProducts = createHook(Store, {
	selector: (state) => state.connectedProducts,
});

export const useProductConnectedInSession = createHook(Store, {
	selector: (state) => state.productConnectedInSession,
});
