import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const StripeIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-652.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#635BFF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.0639 9.32966C11.0639 8.76566 11.5259 8.54366 12.2939 8.54366C13.3979 8.54366 14.7899 8.87966 15.8939 9.47366V6.06566C14.6879 5.58566 13.4999 5.39966 12.2999 5.39966C9.35395 5.39966 7.39795 6.93566 7.39795 9.50366C7.39795 13.5057 12.9119 12.8697 12.9119 14.5977C12.9119 15.2637 12.3299 15.4797 11.5199 15.4797C10.3139 15.4797 8.77795 14.9877 7.55995 14.3217V17.7717C8.90995 18.3537 10.2719 18.5997 11.5199 18.5997C14.5379 18.5997 16.6139 17.1057 16.6139 14.5077C16.5899 10.1877 11.0639 10.9557 11.0639 9.32966Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M5 5H19V19H5V5Z" fill="#635BFF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.4541 10.4422C11.4541 10.1132 11.7236 9.98366 12.1716 9.98366C12.8156 9.98366 13.6276 10.1797 14.2716 10.5262V8.53816C13.5681 8.25816 12.8751 8.14966 12.1751 8.14966C10.4566 8.14966 9.31555 9.04566 9.31555 10.5437C9.31555 12.8782 12.5321 12.5072 12.5321 13.5152C12.5321 13.9037 12.1926 14.0297 11.7201 14.0297C11.0166 14.0297 10.1206 13.7427 9.41005 13.3542V15.3667C10.1976 15.7062 10.9921 15.8497 11.7201 15.8497C13.4806 15.8497 14.6916 14.9782 14.6916 13.4627C14.6776 10.9427 11.4541 11.3907 11.4541 10.4422Z"
				fill="white"
			/>
		</svg>
	);
};
