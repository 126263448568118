import { DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { OutlookCalendarIcon } from './icon';
import { OutlookCalendarSearchResultIcon } from './search-result-icon';

export const outlookCalendarConfig: ThirdPartyConfig = {
	displayName: 'Outlook Calendar',
	id: 'outlook-calendar',
	resourceType: 'outlook-calendar-connector',
	icon: get3PLogo(OutlookCalendarIcon, 'Outlook Calendar', 'medium'),
	searchResultIcon: get3PLogo(OutlookCalendarSearchResultIcon, 'Outlook Calendar', 'large'),
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Calendar,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
