import React from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Grid, Inline, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import Text from '@atlaskit/primitives/text';
import { RemoveIcon } from '@atlassian/search-dialog';

import type { RovoLogo } from '../../constants/icons';

import { messages } from './messages';

export interface MessageContent {
	title: React.ReactNode;
	description: React.ReactNode;
	connectText: React.ReactNode;
	MessageIcon: RovoLogo;
	iconNeedsPadding?: boolean; // product logos and default logos have different padding, so we might need to compensate
}

export interface OAuthMessageProps {
	content: MessageContent;
	onAuthorize: (e: React.MouseEvent<HTMLElement>) => void;
	onDismiss: (e: React.MouseEvent<HTMLElement>) => void;
	isOnSearchDialog?: boolean;
	isBulkConnect?: boolean;
	testId?: string;
}

const containerBaseStyles = xcss({
	wordBreak: 'break-word',
	borderRadius: 'border.radius',
	backgroundColor: 'color.background.neutral',
});

const searchResultsContainerStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

const searchDialogContainerStyles = xcss({
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const searchDialogContainerWithLeftPaddingStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const connectPressableStyles = xcss({
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	paddingInline: 'space.150',
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	color: 'color.link',

	':hover': {
		textDecoration: 'underline',
		color: 'color.link.pressed',
	},
	':active': {
		textDecoration: 'underline',
		color: 'color.link.pressed',
	},
	whiteSpace: 'nowrap', // so that "Connect" doesn't get broken up needlessly
});

const actionsContainer = xcss({
	flexBasis: 'fit-content',
});

export const OAuthMessage = (props: OAuthMessageProps) => {
	const { content, onAuthorize, onDismiss, isOnSearchDialog, isBulkConnect, testId } = props;
	const { formatMessage } = useIntl();

	const ctaButton = (
		<Pressable
			onClick={onAuthorize}
			backgroundColor="color.background.neutral.subtle"
			xcss={connectPressableStyles}
		>
			<Text weight="medium" size="medium" color="color.link">
				{content.connectText}
			</Text>
		</Pressable>
	);

	const DismissIcon = isBulkConnect && isOnSearchDialog ? RemoveIcon : CrossIcon;

	return (
		<Grid
			alignItems="center"
			templateColumns={isBulkConnect ? 'min-content auto 1fr auto' : 'min-content 1fr auto'}
			columnGap={isBulkConnect && isOnSearchDialog ? 'space.075' : 'space.150'}
			testId={testId}
			xcss={[
				containerBaseStyles,
				isOnSearchDialog
					? content.iconNeedsPadding
						? searchDialogContainerWithLeftPaddingStyles
						: searchDialogContainerStyles
					: searchResultsContainerStyles,
			]}
		>
			<content.MessageIcon size={'small'} />
			<Inline
				space={!isBulkConnect && isOnSearchDialog ? 'space.025' : 'space.050'}
				alignBlock="center"
				shouldWrap
			>
				{isOnSearchDialog || isBulkConnect ? (
					<Text
						size="medium"
						as={isBulkConnect ? undefined : 'strong'}
						weight={isBulkConnect ? 'medium' : undefined}
					>
						{content.title}
					</Text>
				) : (
					<Heading size="small">{content.title}</Heading>
				)}
				<Text size="medium" color={isOnSearchDialog ? 'color.text.subtle' : undefined}>
					{content.description}
				</Text>
			</Inline>
			{isBulkConnect && (
				<Inline testId={testId && `${testId}--actions-cta`} alignBlock="center">
					{ctaButton}
				</Inline>
			)}
			<Inline
				testId={testId && `${testId}--actions`}
				space="space.150"
				rowSpace="space.0"
				alignBlock="center"
				xcss={actionsContainer}
			>
				{!isBulkConnect && ctaButton}
				<IconButton
					icon={(iconProps) => (
						<DismissIcon {...iconProps} label="" LEGACY_size="medium" color="currentColor" />
					)}
					onClick={onDismiss}
					label={formatMessage(messages.dismiss)}
					appearance={'subtle'}
					spacing={'compact'}
				/>
			</Inline>
		</Grid>
	);
};
