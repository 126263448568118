import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const DocusignSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />
			<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#0B1228" strokeOpacity="0.14" />
			<path
				d="M20.2568 20.2378V25.1619C20.2568 25.4206 20.0472 25.6295 19.7877 25.6295H6.80236C6.54285 25.6295 6.33325 25.4206 6.33325 25.1619V12.2299C6.33325 11.9712 6.54285 11.7623 6.80236 11.7623H11.733V19.7703C11.733 20.0289 11.9426 20.2378 12.2021 20.2378H20.2568Z"
				fill="#4C00FF"
			/>
			<path
				d="M25.6668 13.2943C25.6668 17.4325 23.2414 20.2278 20.257 20.2378V15.0252C20.257 14.876 20.1971 14.7267 20.0873 14.6273L17.3725 11.9215C17.2627 11.8121 17.123 11.7524 16.9732 11.7524H11.7432V6.83818C11.7432 6.57953 11.9528 6.37063 12.2123 6.37063H19.5284C23.0018 6.36068 25.6668 9.14605 25.6668 13.2943Z"
				fill="#FF5252"
			/>
			<path
				d="M20.0873 14.6171C20.1971 14.7266 20.257 14.8658 20.257 15.0151V20.2277H12.2123C11.9528 20.2277 11.7432 20.0188 11.7432 19.7601V11.7522H16.9732C17.123 11.7522 17.2727 11.8119 17.3725 11.9213L20.0873 14.6171Z"
				fill="black"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M26.6891 26.6013V34.2717C26.6891 34.6746 26.3626 35 25.9584 35H5.73074C5.3265 35 5 34.6746 5 34.2717V14.1271C5 13.7242 5.3265 13.3988 5.73074 13.3988H13.4113V25.873C13.4113 26.2759 13.7378 26.6013 14.1421 26.6013H26.6891Z"
				fill="#4C00FF"
			/>
			<path
				d="M35.116 15.7851C35.116 22.2314 31.3379 26.5858 26.6891 26.6012V18.4814C26.6891 18.249 26.5958 18.0166 26.4248 17.8616L22.1958 13.6467C22.0248 13.4763 21.8071 13.3833 21.5739 13.3833H13.4269V5.72835C13.4269 5.32545 13.7534 5.00004 14.1576 5.00004H25.5541C30.9647 4.98455 35.116 9.32338 35.116 15.7851Z"
				fill="#FF5252"
			/>
			<path
				d="M26.4248 17.846C26.5958 18.0165 26.6891 18.2334 26.6891 18.4659V26.5857H14.1576C13.7534 26.5857 13.4269 26.2603 13.4269 25.8574V13.3832H21.5739C21.8071 13.3832 22.0403 13.4762 22.1958 13.6467L26.4248 17.846Z"
				fill="black"
			/>
		</svg>
	);
};
