import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { TodoistIcon } from './icon';
import { TodoistSearchResultIcon } from './search-result-icon';

export const todoistConfig: ThirdPartyConfig = {
	displayName: 'Todoist',
	id: 'todoist',
	resourceType: 'smartlinks-connector-todoist',
	icon: get3PLogo(TodoistIcon, 'Todoist', 'medium'),
	searchResultIcon: get3PLogo(TodoistSearchResultIcon, 'Todoist', 'large'),
	integrationAri: 'ari:cloud:platform::integration/todoist',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.WorkItem,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
