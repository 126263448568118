import {
	DEFAULT_CLOUD_GRAPH_DEVINFO_BRANCH_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DEVINFO_COMMIT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DEVINFO_PULL_REQUEST_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
} from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GithubIcon } from './icon';
import { GithubSearchResultIcon } from './search-result-icon';

export const githubConfig: ThirdPartyConfig = {
	displayName: 'GitHub',
	id: 'github',
	resourceType: 'github-connector',
	icon: get3PLogo(GithubIcon, 'Github', 'medium'),
	searchResultIcon: get3PLogo(GithubSearchResultIcon, 'Github', 'large'),
	integrationAri: 'ari:cloud:platform::integration/github',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Repository,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DEVINFO_REPOSITORY_ENTITY_ATI,
				subTypes3P: [],
			},
		],
		[
			TypeFilterValueKey.PullRequest,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DEVINFO_PULL_REQUEST_ENTITY_ATI,
				subTypes3P: [],
			},
		],
		[
			TypeFilterValueKey.Branch,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DEVINFO_BRANCH_ENTITY_ATI,
				subTypes3P: [],
			},
		],
		[
			TypeFilterValueKey.Commit,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DEVINFO_COMMIT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
