import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { Box, Inline, xcss } from '@atlaskit/primitives';

import { GeneratingStreamAnimation } from '../../../../common/assets/GeneratingStreamAnimation';

import { i18n } from './messages';

const streamingSummaryLoadingStyles = xcss({ color: 'color.text.subtle' });

export const StreamingSummaryLoading = () => {
	const { formatMessage } = useIntl();

	return (
		<Box padding="space.300" xcss={streamingSummaryLoadingStyles}>
			<Inline alignBlock="center" space="space.025">
				<GeneratingStreamAnimation alt={formatMessage(i18n.generatingSummaryAnimationAlt)} />
				<FormattedMessage {...i18n.generatingSummaryText} />
			</Inline>
		</Box>
	);
};
