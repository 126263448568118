import type { captureException, captureMessage, Event, Scope } from '@sentry/browser';

import { PRODUCT_BUILD } from '../../common/constants/build';

import { SENTRY_DSN, UNKNOWN_TAG } from './constants';

type SentryClient = {
	withScope(callback: (scope: Scope) => void): void;
	captureException: typeof captureException;
	captureMessage: typeof captureMessage;
};

let Sentry: Promise<SentryClient | void> | undefined;

const getEnvironment = () => {
	if (window.location.hostname.includes('localhost')) {
		return 'development';
	} else if (
		window.location.hostname.includes('jira-dev.com') ||
		window.location.hostname.includes('stg.atlassian.com')
	) {
		return 'staging';
	} else {
		return 'production';
	}
};

const beforeSend = (event: Event) => {
	if (event.environment === 'development') {
		return null;
	}

	// Ignore errors that do not come from search projects
	if (!event.tags?.search) {
		return null;
	}

	// Delete the query string as it contains UGC
	if (event.request?.url) {
		event.request.url = event.request.url.split('?')[0];
	}
	if (event.request?.headers?.Referer) {
		event.request.headers.Referer = event.request.headers.Referer.split('?')[0];
	}

	return event;
};

export const getSentryClient = async () => {
	if (!Sentry) {
		Sentry = import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/search-common/sentry" */ '@sentry/browser'
		)
			.then((SentryModule) => {
				SentryModule.init({
					dsn: SENTRY_DSN,
					attachStacktrace: true,
					release: PRODUCT_BUILD || UNKNOWN_TAG,
					environment: getEnvironment(),
					beforeSend,
				});

				return SentryModule;
			})
			.catch(() => {
				// TODO: Retry mechanism for chunk loading failures?
			});
	}

	return Sentry;
};
