import { useCallback } from 'react';

import { bind } from 'bind-event-listener';

import type { ExternalStore, StorageProvider } from '../types';
import useExternalStore from '../use-external-store';

class LocalStorageAdapter implements StorageProvider {
	subscribers = [];
	setItem(key: string, value: any) {
		try {
			const rawValue = JSON.stringify(value);
			localStorage.setItem(key, rawValue);
			window.dispatchEvent(new StorageEvent('storage', { key, newValue: rawValue }));
		} catch {
			// noop
		}
	}

	getItem(key: string) {
		const rawValue = localStorage.getItem(key);
		if (!rawValue) {
			return null;
		}
		try {
			return JSON.parse(rawValue);
		} catch {
			return null;
		}
	}

	subscribe(listener: () => void) {
		const unbind = bind(window, { type: 'storage', listener });

		return () => unbind();
	}
}

function makeStore(storage: StorageProvider): ExternalStore {
	return {
		getSnapshot() {
			return storage.getItem('search-page-persisted-state');
		},

		subscribe(listener: () => void) {
			return storage.subscribe(listener);
		},
	};
}

const adapter = new LocalStorageAdapter();
const store = makeStore(adapter);

const useLocalStorage = () => {
	const state = useExternalStore(store.subscribe, store.getSnapshot);
	const setState = useCallback((value: any) => {
		adapter.setItem('search-page-persisted-state', value);
	}, []);
	return [state, setState];
};

export default useLocalStorage;
