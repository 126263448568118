import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ServiceNowSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-406.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#292A2E" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.0629 5.33574C13.9175 5.33126 11.8203 5.97137 10.043 7.17308C8.2658 8.37478 6.89053 10.0827 6.09553 12.0753C5.30054 14.068 5.12247 16.2535 5.58441 18.3486C6.04636 20.4436 7.12701 22.3516 8.68629 23.8252C9.05202 24.1734 9.53035 24.3793 10.0347 24.4054C10.539 24.4316 11.0361 24.2764 11.4359 23.9679C12.7551 22.9862 14.3557 22.456 16.0001 22.456C17.6445 22.456 19.2451 22.9862 20.5643 23.9679C20.968 24.2771 21.4693 24.431 21.9769 24.4013C22.4846 24.3717 22.9647 24.1606 23.3296 23.8066C24.8767 22.3385 25.9501 20.4419 26.4122 18.3598C26.8743 16.2777 26.7041 14.105 25.9234 12.1203C25.1427 10.1355 23.7871 8.42916 22.0303 7.21988C20.2735 6.01061 18.1956 5.35353 16.0629 5.33289M16.0001 21.3839C15.295 21.4029 14.5934 21.2781 13.9381 21.0171C13.2828 20.756 12.6876 20.3642 12.1887 19.8655C11.6899 19.3668 11.2979 18.7716 11.0366 18.1164C10.7754 17.4612 10.6504 16.7596 10.6693 16.0545C10.6693 14.6407 11.2309 13.2848 12.2306 12.285C13.2304 11.2853 14.5863 10.7237 16.0001 10.7237C17.4139 10.7237 18.7698 11.2853 19.7696 12.285C20.7693 13.2848 21.3309 14.6407 21.3309 16.0545C21.3498 16.7596 21.2248 17.4612 20.9636 18.1164C20.7023 18.7716 20.3103 19.3668 19.8115 19.8655C19.3127 20.3642 18.7174 20.756 18.0621 21.0171C17.4068 21.2781 16.7052 21.4029 16.0001 21.3839Z"
				fill="#62D84E"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.0886 7.00401C17.0717 6.99772 14.1224 7.89787 11.6231 9.58777C9.1239 11.2777 7.18993 13.6794 6.07196 16.4815C4.95399 19.2837 4.70358 22.3571 5.3532 25.3033C6.00281 28.2495 7.52248 30.9326 9.7152 33.0048C10.2295 33.4945 10.9022 33.784 11.6114 33.8208C12.3206 33.8576 13.0196 33.6394 13.5819 33.2055C15.437 31.825 17.6878 31.0793 20.0003 31.0793C22.3127 31.0793 24.5635 31.825 26.4186 33.2055C26.9863 33.6403 27.6913 33.8567 28.4052 33.815C29.119 33.7734 29.7942 33.4765 30.3074 32.9787C32.483 30.9142 33.9924 28.247 34.6423 25.319C35.2921 22.3911 35.0528 19.3358 33.9549 16.5448C32.8571 13.7537 30.9507 11.3541 28.4802 9.65359C26.0097 7.95305 23.0877 7.02904 20.0886 7M20.0003 29.5717C19.0087 29.5985 18.0221 29.423 17.1006 29.0559C16.179 28.6888 15.342 28.1378 14.6405 27.4364C13.939 26.7351 13.3878 25.8982 13.0204 24.9768C12.653 24.0554 12.4772 23.0688 12.5038 22.0773C12.5038 20.0891 13.2936 18.1823 14.6995 16.7765C16.1053 15.3706 18.0121 14.5808 20.0003 14.5808C21.9885 14.5808 23.8952 15.3706 25.3011 16.7765C26.7069 18.1823 27.4967 20.0891 27.4967 22.0773C27.5233 23.0688 27.3475 24.0554 26.9801 24.9768C26.6128 25.8982 26.0615 26.7351 25.36 27.4364C24.6585 28.1378 23.8215 28.6888 22.9 29.0559C21.9785 29.423 20.9918 29.5985 20.0003 29.5717Z"
				fill="#62D84E"
			/>
		</svg>
	);
};
