import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const SharePointSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-163.5"
				y="-23.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<g clipPath="url(#clip0_719_13847)">
				<path
					d="M16.2482 17.5237C19.5362 17.5237 22.2017 14.7948 22.2017 11.4285C22.2017 8.06218 19.5362 5.33325 16.2482 5.33325C12.9601 5.33325 10.2947 8.06218 10.2947 11.4285C10.2947 14.7948 12.9601 17.5237 16.2482 17.5237Z"
					fill="#036C70"
				/>
				<path
					d="M21.2091 22.6031C24.2231 22.6031 26.6664 20.1016 26.6664 17.0158C26.6664 13.93 24.2231 11.4285 21.2091 11.4285C18.1951 11.4285 15.7517 13.93 15.7517 17.0158C15.7517 20.1016 18.1951 22.6031 21.2091 22.6031Z"
					fill="#1A9BA1"
				/>
				<path
					d="M16.9922 26.6666C19.3212 26.6666 21.2093 24.7336 21.2093 22.3491C21.2093 19.9647 19.3212 18.0317 16.9922 18.0317C14.6632 18.0317 12.7751 19.9647 12.7751 22.3491C12.7751 24.7336 14.6632 26.6666 16.9922 26.6666Z"
					fill="#37C6D0"
				/>
				<path
					opacity="0.1"
					d="M17.2403 10.8343V21.6737C17.2379 22.0503 17.0149 22.3887 16.6747 22.5321C16.5664 22.579 16.45 22.6032 16.3324 22.6032H12.7802C12.7752 22.5168 12.7752 22.4356 12.7752 22.3492C12.7736 22.2644 12.7769 22.1796 12.7851 22.0953C12.8759 20.4716 13.8529 19.0391 15.3104 18.3924V17.4476C12.0667 16.9214 9.85383 13.8026 10.3679 10.4817C10.3714 10.4587 10.3751 10.4357 10.3789 10.4127C10.4036 10.2414 10.4384 10.0718 10.4831 9.90479H16.3324C16.833 9.90674 17.2384 10.3218 17.2403 10.8343Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M15.8362 10.4127H10.3788C9.82749 13.7276 12.0054 16.8725 15.2432 17.4369C15.3413 17.454 15.4397 17.4686 15.5385 17.4806C14.0005 18.2273 12.8797 20.3479 12.7845 22.0952C12.7762 22.1796 12.7729 22.2644 12.7746 22.3492C12.7746 22.4355 12.7746 22.5168 12.7795 22.6031C12.7885 22.7739 12.81 22.9437 12.844 23.1111H15.8356C16.2035 23.1085 16.534 22.8803 16.6741 22.532C16.7199 22.4211 16.7435 22.3019 16.7435 22.1815V11.3422C16.7417 10.8298 16.3366 10.4149 15.8362 10.4127Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M15.8364 10.4127H10.379C9.8278 13.7279 12.006 16.8729 15.2442 17.4372C15.3104 17.4488 15.3768 17.4592 15.4434 17.4684C13.9551 18.2689 12.8785 20.3845 12.7852 22.0952H15.8364C16.3362 22.0913 16.7405 21.6774 16.7443 21.1657V11.3422C16.7424 10.8296 16.337 10.4146 15.8364 10.4127Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M15.3401 10.4127H10.3788C9.85836 13.5423 11.7738 16.5562 14.7794 17.3368C13.6414 18.6681 12.9433 20.3335 12.785 22.0952H15.3401C15.8407 22.0933 16.2461 21.6782 16.248 21.1657V11.3422C16.2477 10.8289 15.8414 10.4129 15.3401 10.4127Z"
					fill="black"
				/>
				<path
					d="M6.24265 10.4127H15.3386C15.8408 10.4127 16.248 10.8295 16.248 11.3437V20.6562C16.248 21.1704 15.8408 21.5873 15.3386 21.5873H6.24265C5.7404 21.5873 5.33325 21.1704 5.33325 20.6562V11.3437C5.33325 10.8295 5.7404 10.4127 6.24265 10.4127Z"
					fill="url(#paint0_linear_719_13847)"
				/>
				<path
					d="M9.39487 15.8861C9.18175 15.7414 9.00374 15.5487 8.87443 15.3228C8.74914 15.0866 8.68669 14.8209 8.69334 14.5523C8.68218 14.1885 8.80208 13.8333 9.03021 13.5542C9.26993 13.2747 9.57979 13.0678 9.92621 12.9558C10.321 12.8228 10.7345 12.7572 11.1501 12.7618C11.6967 12.7413 12.2425 12.8196 12.7625 12.9929V14.1612C12.5366 14.021 12.2905 13.9182 12.0332 13.8564C11.7541 13.7863 11.4678 13.7512 11.1804 13.7518C10.8774 13.7404 10.5763 13.8056 10.3037 13.9417C10.0933 14.0346 9.95691 14.2466 9.95647 14.4812C9.95561 14.6235 10.009 14.7605 10.1053 14.8631C10.219 14.9841 10.3536 15.0826 10.5022 15.1537C10.6676 15.238 10.9156 15.3501 11.2464 15.4899C11.2828 15.5017 11.3183 15.5163 11.3526 15.5336C11.6781 15.6639 11.9921 15.8224 12.2912 16.0075C12.5177 16.1505 12.7078 16.3465 12.8459 16.5795C12.9874 16.8435 13.056 17.142 13.0443 17.443C13.0605 17.8164 12.9489 18.1839 12.7288 18.4822C12.5094 18.7564 12.2161 18.9585 11.8854 19.0633C11.4964 19.1881 11.0904 19.2484 10.6828 19.2421C10.3171 19.2438 9.9519 19.2132 9.59132 19.1506C9.28686 19.0996 8.98974 19.0102 8.70674 18.8845V17.6527C8.97726 17.8505 9.27948 17.9984 9.59976 18.0896C9.91896 18.1914 10.2508 18.2458 10.5851 18.2511C10.8945 18.2711 11.2033 18.204 11.4781 18.057C11.6706 17.9458 11.7876 17.7352 11.7827 17.509C11.784 17.3516 11.7232 17.2003 11.614 17.0894C11.4783 16.953 11.3212 16.8409 11.1492 16.7577C10.9507 16.6562 10.6585 16.5222 10.2725 16.356C9.96545 16.2296 9.67145 16.0722 9.39487 15.8861Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_719_13847"
					x1="7.22936"
					y1="9.68516"
					x2="14.6062"
					y2="22.1644"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#058F92" />
					<stop offset="0.5" stopColor="#038489" />
					<stop offset="1" stopColor="#026D71" />
				</linearGradient>
				<clipPath id="clip0_719_13847">
					<rect
						width="21.3333"
						height="21.3333"
						fill="white"
						transform="translate(5.33325 5.33325)"
					/>
				</clipPath>
			</defs>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2230_18933)">
				<path
					d="M20.349 22.1428C24.9728 22.1428 28.7211 18.3053 28.7211 13.5714C28.7211 8.83755 24.9728 4.99999 20.349 4.99999C15.7252 4.99999 11.9769 8.83755 11.9769 13.5714C11.9769 18.3053 15.7252 22.1428 20.349 22.1428Z"
					fill="#036C70"
				/>
				<path
					d="M27.3253 29.2857C31.5638 29.2857 34.9997 25.768 34.9997 21.4286C34.9997 17.0892 31.5638 13.5714 27.3253 13.5714C23.0868 13.5714 19.6509 17.0892 19.6509 21.4286C19.6509 25.768 23.0868 29.2857 27.3253 29.2857Z"
					fill="#1A9BA1"
				/>
				<path
					d="M21.3953 35C24.6705 35 27.3255 32.2817 27.3255 28.9286C27.3255 25.5754 24.6705 22.8571 21.3953 22.8571C18.1201 22.8571 15.4651 25.5754 15.4651 28.9286C15.4651 32.2817 18.1201 35 21.3953 35Z"
					fill="#37C6D0"
				/>
				<path
					opacity="0.1"
					d="M21.7444 12.7357V27.9786C21.7409 28.5082 21.4274 28.984 20.949 29.1857C20.7967 29.2517 20.633 29.2857 20.4676 29.2857H15.4723C15.4653 29.1643 15.4653 29.05 15.4653 28.9285C15.463 28.8093 15.4676 28.6901 15.4793 28.5714C15.607 26.2882 16.9808 24.2736 19.0304 23.3643V22.0357C14.469 21.2956 11.3571 16.9099 12.08 12.2398C12.085 12.2074 12.0902 12.1751 12.0956 12.1428C12.1303 11.9019 12.1792 11.6634 12.2421 11.4286H20.4677C21.1717 11.4313 21.7417 12.0149 21.7444 12.7357Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M19.7695 12.1429H12.0951C11.3198 16.8045 14.3825 21.227 18.9357 22.0207C19.0736 22.0447 19.212 22.0652 19.3509 22.0822C17.1881 23.1322 15.6121 26.1143 15.4781 28.5714C15.4665 28.6901 15.4618 28.8094 15.4641 28.9286C15.4641 29.05 15.4641 29.1643 15.4711 29.2857C15.4837 29.5258 15.514 29.7646 15.5618 30H19.7688C20.2861 29.9965 20.7509 29.6755 20.9479 29.1857C21.0123 29.0298 21.0455 28.8622 21.0455 28.6929V13.45C21.0429 12.7295 20.4733 12.146 19.7695 12.1429Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M19.7701 12.1429H12.0956C11.3205 16.805 14.3837 21.2276 18.9373 22.0212C19.0305 22.0374 19.1239 22.052 19.2175 22.065C17.1245 23.1907 15.6105 26.1657 15.4794 28.5715H19.7701C20.473 28.566 21.0415 27.984 21.0468 27.2643V13.45C21.0441 12.7292 20.4741 12.1456 19.7701 12.1429Z"
					fill="black"
				/>
				<path
					opacity="0.2"
					d="M19.0721 12.1429H12.0954C11.3635 16.5439 14.0571 20.7822 18.2838 21.88C16.6834 23.7521 15.7017 26.0941 15.4791 28.5715H19.0722C19.7762 28.5687 20.3462 27.9851 20.3489 27.2643V13.45C20.3485 12.7283 19.7771 12.1433 19.0721 12.1429Z"
					fill="black"
				/>
				<path
					d="M6.27884 12.1429H19.07C19.7763 12.1429 20.3488 12.7291 20.3488 13.4522V26.5479C20.3488 27.271 19.7763 27.8572 19.07 27.8572H6.27884C5.57255 27.8572 5 27.271 5 26.5479V13.4522C5 12.7291 5.57255 12.1429 6.27884 12.1429Z"
					fill="url(#paint0_linear_sharepoint)"
				/>
				<path
					d="M10.7116 19.84C10.412 19.6365 10.1616 19.3655 9.97978 19.0479C9.80359 18.7158 9.71577 18.3421 9.72513 17.9643C9.70944 17.4528 9.87804 16.9532 10.1988 16.5607C10.536 16.1678 10.9717 15.8768 11.4588 15.7193C12.014 15.5322 12.5955 15.44 13.18 15.4464C13.9486 15.4177 14.7161 15.5277 15.4474 15.7714V17.4143C15.1297 17.2172 14.7836 17.0726 14.4219 16.9857C14.0294 16.8872 13.6267 16.8378 13.2226 16.8386C12.7964 16.8226 12.373 16.9143 11.9898 17.1057C11.6939 17.2364 11.502 17.5344 11.5014 17.8643C11.5002 18.0644 11.5753 18.2571 11.7107 18.4014C11.8706 18.5716 12.0598 18.7101 12.2688 18.81C12.5014 18.9286 12.8502 19.0862 13.3154 19.2829C13.3666 19.2995 13.4165 19.32 13.4647 19.3443C13.9224 19.5275 14.364 19.7504 14.7847 20.0107C15.1032 20.2118 15.3705 20.4875 15.5647 20.815C15.7637 21.1863 15.8602 21.6061 15.8437 22.0293C15.8664 22.5545 15.7095 23.0713 15.4 23.4907C15.0915 23.8763 14.679 24.1605 14.214 24.3079C13.6669 24.4834 13.096 24.5683 12.5228 24.5593C12.0085 24.5617 11.495 24.5187 10.9879 24.4307C10.5598 24.359 10.1419 24.2333 9.74396 24.0564V22.3243C10.1244 22.6025 10.5494 22.8104 10.9998 22.9386C11.4486 23.0818 11.9153 23.1583 12.3854 23.1657C12.8205 23.194 13.2548 23.0996 13.6412 22.8929C13.9118 22.7365 14.0765 22.4403 14.0696 22.1222C14.0714 21.9008 13.9858 21.6881 13.8323 21.5322C13.6415 21.3403 13.4205 21.1827 13.1786 21.0657C12.8996 20.9229 12.4886 20.7346 11.9458 20.5007C11.514 20.323 11.1006 20.1017 10.7116 19.84Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_sharepoint"
					x1="7.6664"
					y1="11.1198"
					x2="18.0401"
					y2="28.6688"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#058F92" />
					<stop offset="0.5" stopColor="#038489" />
					<stop offset="1" stopColor="#026D71" />
				</linearGradient>
				<clipPath id="clip0_2230_18933">
					<rect width="30" height="30" fill="white" transform="translate(5 5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
