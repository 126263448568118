import { defineMessages } from 'react-intl-next';

import { TypeFilterValueKey } from '../../../../../common/constants/filters/universal-type';

export const typeLabels = defineMessages<TypeFilterValueKey | 'all' | 'pageAndLiveDoc'>({
	all: {
		id: 'search-page.filter.type.all',
		defaultMessage: 'All',
		description: 'All filter type',
	},
	document: {
		id: 'search-page.filter.type.documents',
		defaultMessage: 'Documents',
		description: 'Document filter type',
	},
	spreadsheet: {
		id: 'search-page.filter.type.spreadsheet',
		defaultMessage: 'Spreadsheets',
		description: 'Spreadsheets filter type',
	},
	presentation: {
		id: 'search-page.filter.type.presentation',
		defaultMessage: 'Presentations',
		description: 'Presentations filter type',
	},
	repository: {
		id: 'search-page.filter.type.repository',
		defaultMessage: 'Repositories',
		description: 'Repository filter type',
	},
	'pull-request': {
		id: 'search-page.filter.type.pull-request',
		defaultMessage: 'Pull requests',
		description: 'Pull request filter type',
	},
	branch: {
		id: 'search-page.filter.type.branch',
		defaultMessage: 'Branches',
		description: 'Branch filter type',
	},
	commit: {
		id: 'search-page.filter.type.commit',
		defaultMessage: 'Commits',
		description: 'Commit filter type',
	},
	conversation: {
		id: 'search-page.filter.type.conversation',
		defaultMessage: 'Conversations',
		description: 'Conversation filter type',
	},
	message: {
		id: 'search-page.filter.type.message',
		defaultMessage: 'Messages',
		description: 'Message filter type',
	},
	video: {
		id: 'search-page.filter.type.video',
		defaultMessage: 'Videos',
		description: 'Video filter type',
	},
	design: {
		id: 'search-page.filter.type.design',
		defaultMessage: 'Designs',
		description: 'Design filter type',
	},
	pageAndLiveDoc: {
		id: 'search-page.filter.type.page',
		defaultMessage: 'Pages and Live Docs',
		description: 'Page and Live Docs filter type',
	},
	page: {
		id: 'search-page.filter.type.page',
		defaultMessage: 'Pages',
		description: 'Page filter type',
	},
	blogpost: {
		id: 'search-page.filter.type.blogpost',
		defaultMessage: 'Blog posts',
		description: 'Blog posts filter type',
	},
	attachment: {
		id: 'search-page.filter.type.attachment',
		defaultMessage: 'Attachments',
		description: 'Attachment filter type',
	},
	space: {
		id: 'search-page.filter.type.space',
		defaultMessage: 'Spaces',
		description: 'Space filter type',
	},
	whiteboard: {
		id: 'search-page.filter.type.whiteboard',
		defaultMessage: 'Whiteboards',
		description: 'Whiteboard filter type',
	},
	issue: {
		id: 'search-page.filter.type.issue',
		defaultMessage: 'Issues',
		description: 'Issue filter type',
	},
	project: {
		id: 'search-page.filter.type.project',
		defaultMessage: 'Projects',
		description: 'Project filter type',
	},
	goal: {
		id: 'search-page.filter.type.goal',
		defaultMessage: 'Goals',
		description: 'Goal filter type',
	},
	tag: {
		id: 'search-page.filter.type.tag',
		defaultMessage: 'Tags',
		description: 'tag filter type',
	},
	'web-page': {
		id: 'search-page.filter.type.web-page',
		defaultMessage: 'Web pages',
		description: 'Webpage filter type',
	},
	comment: {
		id: 'search-page.filter.type.comment',
		defaultMessage: 'Comments',
		description: 'Comment filter type',
	},
	database: {
		id: 'search-page.filter.type.database',
		defaultMessage: 'Databases',
		description: 'Database filter type',
	},
	embed: {
		id: 'search-page.filter.type.embed',
		defaultMessage: 'Smart Links',
		description: 'Smart Link filter type',
	},
	folder: {
		id: 'search-page.filter.type.folder',
		defaultMessage: 'Folders',
		description: 'Folder filter type',
	},
	calendar: {
		id: 'search-page.filter.type.calendar',
		defaultMessage: 'Calendars',
		description: 'Calendars filter type',
	},
	'space-calendar': {
		id: 'search-page.filter.type.space-calendar',
		defaultMessage: 'Space calendars',
		description: 'Space calendars filter type',
	},
	answer: {
		id: 'search-page.filter.type.answer',
		defaultMessage: 'Answers',
		description: 'Answers filter type',
	},
	question: {
		id: 'search-page.filter.type.question',
		defaultMessage: 'Questions',
		description: 'Questions filter type',
	},
	component: {
		id: 'search-page.filter.type.component',
		defaultMessage: 'Components',
		description: 'Component filter type',
	},
	'work-item': {
		id: 'search-page.filter.type.work-item',
		defaultMessage: 'Tasks',
		description: 'Work item filter type',
	},
	card: {
		id: 'search-page.filter.type.card',
		defaultMessage: 'Card',
		description: 'Card filter type',
	},
	incident: {
		id: 'search-page.filter.type.incident',
		defaultMessage: 'Incident',
		description: 'Incident filter type',
	},
	'focus-area': {
		id: 'search-page.filter.type.focus-area',
		defaultMessage: 'Focus areas',
		description: 'Focus areas filter type',
	},
	dashboard: {
		id: 'search-page.filter.type.dashboard',
		defaultMessage: 'Dashboards',
		description: 'Dashboard filter type',
	},
	invoice: {
		id: 'search-page.filter.type.invoice',
		defaultMessage: 'Invoices',
		description: 'Invoice filter type',
	},
	deal: {
		id: 'search-page.filter.type.deal',
		defaultMessage: 'Deals',
		description: 'Deal filter type',
	},
	gliffy: {
		id: 'search-page.filter.type.gliffy',
		defaultMessage: 'Gliffy Diagrams',
		description: 'Gliffy filter type',
	},
});
