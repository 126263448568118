import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const BoxSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-211.5"
				y="-23.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#0071F7" />
			<path
				d="M26.4785 20.4687C26.7448 20.8416 26.6915 21.3209 26.372 21.5872C25.9991 21.8535 25.4665 21.8003 25.2002 21.4807L23.336 19.0839L21.5251 21.4275C21.2588 21.8003 20.7261 21.8003 20.3533 21.534C19.9805 21.2677 19.9272 20.7883 20.1935 20.4155L22.324 17.6458L20.1935 14.8762C19.9272 14.5033 20.0337 13.9707 20.3533 13.7044C20.7261 13.4381 21.2588 13.5446 21.5251 13.8642L23.336 16.261L25.2002 13.9707C25.4665 13.5979 25.9459 13.5446 26.372 13.8109C26.7448 14.0772 26.7448 14.6098 26.4785 14.9827L24.4013 17.6991L26.4785 20.4687ZM16.7847 20.1491C15.3999 20.1491 14.2814 19.0839 14.2814 17.6991C14.2814 16.3675 15.3999 15.249 16.7847 15.249C18.1695 15.249 19.288 16.3675 19.288 17.6991C19.2348 19.0839 18.1163 20.1491 16.7847 20.1491ZM9.43447 20.1491C8.04964 20.1491 6.93113 19.0839 6.93113 17.6991C6.93113 16.3675 8.04964 15.249 9.43447 15.249C10.8193 15.249 11.9378 16.3675 11.9378 17.6991C11.9378 19.0839 10.8193 20.1491 9.43447 20.1491ZM16.7847 13.6511C15.2401 13.6511 13.8553 14.5033 13.1629 15.7816C12.4704 14.5033 11.0856 13.6511 9.48773 13.6511C8.52901 13.6511 7.67681 13.9707 6.98439 14.4501V11.0412C6.98439 10.6151 6.61155 10.2423 6.18545 10.2423C5.70609 10.2423 5.33325 10.6151 5.33325 11.0412V17.7523C5.38651 19.9894 7.19744 21.747 9.43447 21.747C11.0323 21.747 12.4172 20.8416 13.1096 19.5633C13.802 20.8416 15.1868 21.747 16.7314 21.747C19.0217 21.747 20.8859 19.9361 20.8859 17.6458C20.9392 15.462 19.075 13.6511 16.7847 13.6511Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2230_19065)">
				<path
					d="M34.0488 26.5488C34.4146 27.061 34.3415 27.7195 33.9024 28.0854C33.3902 28.4512 32.6585 28.3781 32.2927 27.939L29.7317 24.6463L27.2439 27.8659C26.878 28.3781 26.1463 28.3781 25.6341 28.0122C25.122 27.6463 25.0488 26.9878 25.4146 26.4756L28.3415 22.6707L25.4146 18.8659C25.0488 18.3537 25.1951 17.622 25.6341 17.2561C26.1463 16.8902 26.878 17.0366 27.2439 17.4756L29.7317 20.7683L32.2927 17.622C32.6585 17.1098 33.3171 17.0366 33.9024 17.4024C34.4146 17.7683 34.4146 18.5 34.0488 19.0122L31.1951 22.7439L34.0488 26.5488ZM20.7317 26.1098C18.8293 26.1098 17.2927 24.6463 17.2927 22.7439C17.2927 20.9146 18.8293 19.378 20.7317 19.378C22.6341 19.378 24.1707 20.9146 24.1707 22.7439C24.0976 24.6463 22.561 26.1098 20.7317 26.1098ZM10.6341 26.1098C8.73171 26.1098 7.19512 24.6463 7.19512 22.7439C7.19512 20.9146 8.73171 19.378 10.6341 19.378C12.5366 19.378 14.0732 20.9146 14.0732 22.7439C14.0732 24.6463 12.5366 26.1098 10.6341 26.1098ZM20.7317 17.1829C18.6098 17.1829 16.7073 18.3537 15.7561 20.1098C14.8049 18.3537 12.9024 17.1829 10.7073 17.1829C9.39024 17.1829 8.21951 17.622 7.26829 18.2805V13.5976C7.26829 13.0122 6.7561 12.5 6.17073 12.5C5.5122 12.5 5 13.0122 5 13.5976V22.8171C5.07317 25.8902 7.56098 28.3049 10.6341 28.3049C12.8293 28.3049 14.7317 27.061 15.6829 25.3049C16.6341 27.061 18.5366 28.3049 20.6585 28.3049C23.8049 28.3049 26.3659 25.8171 26.3659 22.6707C26.439 19.6707 23.878 17.1829 20.7317 17.1829Z"
					fill="#0071F7"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2230_19065">
					<rect width="30" height="16.0976" fill="white" transform="translate(5 12.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
