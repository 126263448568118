import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AdobeXDIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-635.5"
				y="-555.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#470137"
			/>
			<path
				d="M12.5802 7.40457L9.75898 12.1789L12.7683 17.2427C12.7871 17.2812 12.7965 17.3198 12.7871 17.3584C12.7777 17.397 12.7401 17.368 12.6836 17.3777H10.5301C10.3796 17.3777 10.2762 17.368 10.2104 17.2716C10.0129 16.8665 9.806 16.471 9.60851 16.0659C9.41103 15.6705 9.19473 15.2654 8.96903 14.8507C8.74334 14.4359 8.51764 14.0212 8.29194 13.5968H8.27313C8.07565 14.0115 7.85935 14.4263 7.64306 14.841C7.42677 15.2558 7.21047 15.6705 7.00359 16.0756C6.78729 16.4807 6.571 16.8954 6.3547 17.2909C6.31709 17.3873 6.24186 17.397 6.13841 17.397H4.06952C4.0319 17.397 4.00369 17.4163 4.00369 17.368C3.99429 17.3295 4.00369 17.2909 4.0225 17.262L6.94716 12.3429L4.09773 7.39492C4.06952 7.35634 4.06011 7.31776 4.07892 7.29847C4.09773 7.26953 4.13535 7.25989 4.17296 7.25989H6.30768C6.3547 7.25989 6.40173 7.26953 6.43934 7.27918C6.47696 7.29847 6.50517 7.3274 6.53338 7.36598C6.71206 7.78073 6.91895 8.19547 7.13524 8.61021C7.36094 9.02496 7.57723 9.43006 7.81233 9.83515C8.03803 10.2403 8.24492 10.6453 8.44241 11.0601H8.46122C8.6587 10.6357 8.86559 10.221 9.07248 9.81586C9.27937 9.41077 9.49566 9.00567 9.71196 8.60057C9.92825 8.19547 10.1351 7.78073 10.342 7.38528C10.3514 7.34669 10.3702 7.30811 10.3985 7.28882C10.4361 7.26953 10.4737 7.25989 10.5207 7.26953H12.505C12.552 7.25989 12.599 7.28882 12.6084 7.33705C12.6178 7.34669 12.599 7.38528 12.5802 7.40457Z"
				fill="#FF61F6"
			/>
			<path
				d="M16.9248 17.5803C16.2289 17.5899 15.533 17.4453 14.9029 17.1463C14.3105 16.8666 13.8215 16.4036 13.4829 15.8345C13.135 15.2462 12.9657 14.5131 12.9657 13.6354C12.9563 12.9217 13.135 12.2176 13.4829 11.6003C13.8403 10.9733 14.3575 10.4525 14.9782 10.1053C15.6364 9.72911 16.4264 9.54585 17.3574 9.54585C17.4044 9.54585 17.4702 9.54585 17.5549 9.5555C17.711 9.5733 17.8464 9.43978 17.8464 9.28259V6.52691C17.8464 6.45939 17.8746 6.42081 17.9404 6.42081H19.791C19.8299 6.42081 19.873 6.41051 19.9052 6.43245C19.9189 6.44186 19.9299 6.45544 19.937 6.4702C19.9425 6.48177 19.9435 6.50762 19.9435 6.50762C19.9435 6.50762 19.9435 6.50762 19.9435 6.50762V15.6898C19.9435 15.8635 19.9529 16.0564 19.9623 16.2686C19.9811 16.4711 19.9905 16.664 19.9999 16.828C19.9999 16.8955 19.9717 16.9534 19.9059 16.9823C19.4169 17.1945 18.8996 17.3488 18.373 17.4453C17.8934 17.5321 17.4138 17.5803 16.9248 17.5803ZM17.5438 15.7015C17.7293 15.7077 17.8464 15.5265 17.8464 15.3409V11.666C17.8464 11.5147 17.7428 11.3763 17.5925 11.3591C17.489 11.3495 17.3856 11.3399 17.2821 11.3399C16.9154 11.3399 16.5486 11.417 16.2195 11.5906C15.8998 11.7546 15.627 11.9957 15.4201 12.3044C15.2133 12.613 15.1098 13.0278 15.1098 13.5293C15.1004 13.8669 15.1568 14.2045 15.2697 14.5228C15.3637 14.7832 15.5048 15.0147 15.6929 15.2076C15.8715 15.3812 16.0878 15.5162 16.3323 15.5934C16.5862 15.6802 16.8496 15.7188 17.1129 15.7188C17.2409 15.7188 17.3612 15.7108 17.4737 15.7022C17.4964 15.7004 17.521 15.7007 17.5438 15.7015Z"
				fill="#FF61F6"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.90598 4H17.5043C19.1111 4 20.4103 5.29915 20.4103 6.90598V17.094C20.4103 18.7009 19.1111 20 17.5043 20H6.90598C5.29915 20 4 18.7009 4 17.094V6.90598C4 5.29915 5.29915 4 6.90598 4Z"
				fill="#470137"
			/>
			<path
				d="M12.629 8.2051L10.5777 11.5897L12.7658 15.1795C12.7794 15.2068 12.7863 15.2342 12.7794 15.2615C12.7726 15.2889 12.7453 15.2684 12.7042 15.2752H11.1384C11.029 15.2752 10.9538 15.2684 10.9059 15.2C10.7624 14.9128 10.6119 14.6325 10.4683 14.3453C10.3247 14.0649 10.1675 13.7778 10.0034 13.4837C9.83928 13.1897 9.67518 12.8957 9.51107 12.5948H9.4974C9.35381 12.8889 9.19654 13.1829 9.03928 13.4769C8.88201 13.7709 8.72475 14.0649 8.57432 14.3521C8.41706 14.6393 8.25979 14.9333 8.10253 15.2136C8.07518 15.282 8.02047 15.2889 7.94526 15.2889H6.44099C6.41364 15.2889 6.39312 15.3025 6.39312 15.2684C6.38629 15.241 6.39312 15.2136 6.4068 15.1931L8.5333 11.706L6.4615 8.19827C6.44099 8.17092 6.43415 8.14356 6.44783 8.12989C6.4615 8.10938 6.48885 8.10254 6.5162 8.10254H8.06834C8.10253 8.10254 8.13671 8.10938 8.16406 8.11621C8.19142 8.12989 8.21193 8.1504 8.23244 8.17775C8.36236 8.47177 8.51278 8.76579 8.67005 9.0598C8.83415 9.35382 8.99142 9.641 9.16236 9.92818C9.32646 10.2154 9.47688 10.5025 9.62047 10.7966H9.63415C9.77774 10.4957 9.92817 10.2017 10.0786 9.9145C10.229 9.62733 10.3863 9.34015 10.5436 9.05297C10.7008 8.76579 10.8512 8.47177 11.0017 8.19143C11.0085 8.16408 11.0222 8.13673 11.0427 8.12305C11.07 8.10938 11.0974 8.10254 11.1316 8.10938H12.5743C12.6085 8.10254 12.6427 8.12305 12.6495 8.15724C12.6564 8.16408 12.6427 8.19143 12.629 8.2051Z"
				fill="#FF61F6"
			/>
			<path
				d="M15.788 15.4188C15.282 15.4256 14.776 15.323 14.3179 15.1111C13.8872 14.9128 13.5316 14.5846 13.2854 14.1812C13.0325 13.7641 12.9094 13.2444 12.9094 12.6222C12.9025 12.1162 13.0325 11.6171 13.2854 11.1794C13.5453 10.735 13.9213 10.3658 14.3726 10.1196C14.8513 9.85295 15.4256 9.72304 16.1025 9.72304C16.1367 9.72304 16.1846 9.72304 16.2461 9.72988C16.3077 9.73671 16.376 9.73671 16.4581 9.74355V7.58287C16.4581 7.53501 16.4786 7.50765 16.5265 7.50765H17.9145C17.9487 7.50082 17.976 7.52817 17.9829 7.55552C17.9829 7.56236 17.9829 7.56919 17.9829 7.56919V14.0786C17.9829 14.2017 17.9897 14.3384 17.9966 14.4889C18.0102 14.6324 18.0171 14.7692 18.0239 14.8854C18.0239 14.9333 18.0034 14.9743 17.9555 14.9948C17.6 15.1453 17.2239 15.2547 16.841 15.323C16.4923 15.3846 16.1436 15.4188 15.788 15.4188ZM16.4581 14.0512V11.0427C16.3966 11.029 16.335 11.0153 16.2735 11.0085C16.1983 11.0017 16.1231 10.9948 16.0478 10.9948C15.7812 10.9948 15.5145 11.0495 15.2752 11.1726C15.0427 11.2889 14.8444 11.4598 14.694 11.6786C14.5436 11.8974 14.4683 12.1914 14.4683 12.547C14.4615 12.7863 14.5025 13.0256 14.5846 13.2512C14.653 13.4359 14.7555 13.6 14.8923 13.7367C15.0222 13.8598 15.1795 13.9555 15.3572 14.0102C15.5419 14.0718 15.7333 14.0991 15.9248 14.0991C16.0273 14.0991 16.1231 14.0923 16.2119 14.0854C16.3008 14.0923 16.376 14.0786 16.4581 14.0512Z"
				fill="#FF61F6"
			/>
		</svg>
	);
};
