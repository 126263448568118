import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const MiroIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-459.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#FFDD33" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.0122 5.5H13.1097L14.6951 8.28547L11.2073 5.5H9.30491L11.0488 8.90456L7.40244 5.5H5.5L7.40244 9.83362L5.5 18.5H7.40244L11.0488 9.21453L9.30491 18.5H11.2073L14.6951 8.59544L13.1097 18.5H15.0122L18.5 7.6664L15.0122 5.5Z"
				fill="black"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4 8C4 5.79086 5.79086 4 8 4H16C18.2092 4 20 5.79086 20 8V16C20 18.2091 18.2092 20 16 20H8C5.79086 20 4 18.2091 4 16V8Z"
				fill="#FFDD33"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6372 7.05002H13.1848L14.3951 9.17662L11.7323 7.05002H10.2799L11.6113 9.64927L8.82744 7.05002H7.375L8.82744 10.3586L7.375 16.975H8.82744L11.6113 9.88592L10.2799 16.975H11.7323L14.3951 9.41327L13.1848 16.975H14.6372L17.3 8.70398L14.6372 7.05002Z"
				fill="black"
			/>
		</svg>
	);
};
