import React, { type PropsWithChildren, useCallback, useMemo } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	dynamicExperimentsSchema,
	getBackendExperimentsWithOverrides,
	getFrontendExperiment,
} from '@atlassian/search-experiment';

import type { BootstrapState, Features } from '../../../../common/constants/boostrap';
import { type NounKeys } from '../../../../common/constants/nouns';
import {
	allProductKeys,
	type PrimaryProductKey,
	ProductKeys,
} from '../../../../common/constants/products';
import { type SearchQueryStringParamsRaw } from '../../../../common/constants/schemas/query-params';
import { type UserDetails } from '../../../../common/constants/types';
import { getAccessibleProducts } from '../../../../common/utils/bootstrap';
import { useFetchConfig, type UseFetchConfigProps } from '../../../config';
import { useLogException } from '../../../error-boundary';
import { BootstrapStoreContainer } from '../index';

export type BootstrapContainerProps = PropsWithChildren<
	{
		/**
		 * Passing this will override the behaviour of bootstrapping in all product mode.
		 * The UI will only show the products which have been passed PROVIDED the user has access to them.
		 *
		 * NOTE: QS-4325: The `products` parameter is deprecated as we are removing it as a
		 * prop from the root <SearchPage/>.
		 */
		products?: ProductKeys[];

		nouns?: NounKeys[];

		/**
		 * The product the search page will be rendered in.
		 */
		primaryProduct: PrimaryProductKey;

		/**
		 * This restricts the searches only to the cloudId that was passedin.
		 * This is a Jira/Confluence term and unfortunately most of the Atlassian products continue to be tied to a site.
		 *
		 * This propertly is useless for Trello or Bitbucket which are Workspace scoped.
		 *
		 * Not passing this property would bootstrap the UI in the site on which the user is most active
		 */
		cloudId?: string;

		/**
		 * The absolute url to the Atlassian Graphql Gateway. If not passed in the relative URL would be used.
		 */
		aggAbsoluteUrl?: string;

		/**
		 * Details of the current user.
		 */
		user?: UserDetails;

		/**
		 * Extra attributes to be attached to analytics events that are fired from within Full Page Search
		 */
		analyticsAttributes?: Record<string, any>;

		SAINHostName?: string;

		orgId?: string;

		queryParams?: Record<string, any>;

		/**
		 * Features that are enabled/disabled for the search page
		 */
		features?: Features;

		/**
		 * A unique identifier for the search session. If not passed, a random UUID will be generated.
		 */
		searchSessionId?: string;

		callbacks?: {
			/**
			 * A callback that is invoked when the query parameters in the URL need to be updated.
			 * The consuming product can implement the specific steps to be done when this callback is invoked.
			 *
			 * @param queryParamObject An object which is key:value map of things that should be set as the query parameter.
			 */
			updateQueryParameters?: (queryParamObject: Partial<SearchQueryStringParamsRaw>) => void;

			/**
			 * @returns the space IDs for the given space keys. NOTE: these should be the space IDs, not ARIs.
			 * @example getSpaceIdsFromSpaceKeys(["STATUS"]); // Promise<["123454321"]>
			 **/
			getSpaceIdsFromSpaceKeys?: (spaceKeys: string[]) => Promise<string[]>;
		};

		/**
		 * The Statsig layer used to run mutually exclusive frontend and backend experiments
		 */
		statsigLayer?: string;
		/**
		 * Each product is responsible for providing the list of backend search experiment layers.
		 * Each string in the list is a stringified JSON object that represents a layer and
		 * the set of criteria for a search request to match on and be enrolled in a given backend experiment.
		 * This is defined via Statsig dynamic config: https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/search_platform_layer_functions
		 */
		experimentLayers?: string[];
	} & Pick<
		UseFetchConfigProps,
		'configFetchAbsoluteUrl' | 'thirdPartyAbsoluteUrl' | 'thirdPartyDisabled' | 'aggregatorHostName'
	>
>;

export const BootstrapStoreProvider = ({
	children,
	...props
}: PropsWithChildren<BootstrapContainerProps>) => {
	const {
		cloudId,
		configFetchAbsoluteUrl,
		user,
		thirdPartyAbsoluteUrl,
		primaryProduct,
		aggAbsoluteUrl,
		SAINHostName,
		aggregatorHostName,
		orgId,
		analyticsAttributes,
		products,
		queryParams,
		features,
		searchSessionId,
		callbacks,
		statsigLayer,
		experimentLayers,
	} = props;

	const {
		accessibleProducts,
		accessibleNouns,
		thirdPartyConfigs,
		firstCloudId,
		intercomHmac,
		isNounsAvailable,
		isRovoEnabled,
		productEdition,
		productDataRegions,
		rovoFeatures,
		isConfigLoaded,
	} = useFetchConfig({
		cloudId,
		configFetchAbsoluteUrl,
		aggregatorHostName,
		userId: user?.id,
		thirdPartyAbsoluteUrl,
		primaryProduct,
	});

	const onError = useLogException();

	const experimentConfig = useMemo(() => {
		const shouldNotGetStaticBackendExperiment = fg('platform_search-scaleable-statsig-layers');
		const { frontendExperiment, backendExperiment, isMutuallyExclusive } =
			getBackendExperimentsWithOverrides(
				features?.sideBySide?.experiments,
				statsigLayer,
				onError,
				shouldNotGetStaticBackendExperiment,
			);

		const exampleFrontendExperiment = getFrontendExperiment({ abTestId: 'fe_search_experiment' });

		return {
			isMutuallyExclusive,
			backendExperiment,
			frontendExperiments: {
				// the frontend experiment that's mutually exclusive to other BE and FE experiments
				...(frontendExperiment?.abTestId && {
					[frontendExperiment.abTestId]: frontendExperiment,
				}),
				// add other frontend experiments that can be run alongside backend experiments here
				// NOTE: these aren't mutually exclusive to other BE or FE experiments
				[exampleFrontendExperiment.abTestId]: exampleFrontendExperiment,
			},
			dynamicExperiments: dynamicExperimentsSchema.safeParse(experimentLayers).data,
		};
	}, [experimentLayers, features?.sideBySide?.experiments, onError, statsigLayer]);

	const allFilterExcludedProducts = useMemo(
		() => [ProductKeys.Trello, ProductKeys.Gmail, ProductKeys.OutlookMail],
		[],
	);

	const getInitialProps = useCallback(() => {
		let storeContainerInitialProps: Partial<BootstrapState> = {
			cloudId: cloudId ?? firstCloudId,
			aggAbsoluteUrl,
			SAINHostName,
			aggregatorHostName,
			user,
			primaryProduct,
			orgId,
			analyticsAttributes,
			features,
			experimentConfig,
			intercomHmac,
			isNounsAvailable,
			isRovoEnabled,
			isConfigLoaded,
			products: getAccessibleProducts({
				supportedProducts: allProductKeys,
				products,
				accessibleProducts,
			}),
			nouns: accessibleNouns,
			allFilterExcludedProducts,
			productEdition,
			productDataRegions,
			rovoFeatures,
		};

		if (searchSessionId) {
			storeContainerInitialProps.searchSessionId = searchSessionId;
		}
		if (accessibleProducts) {
			storeContainerInitialProps.thirdPartyConfigs = thirdPartyConfigs;
		}

		return storeContainerInitialProps;
	}, [
		cloudId,
		firstCloudId,
		aggAbsoluteUrl,
		SAINHostName,
		aggregatorHostName,
		user,
		primaryProduct,
		orgId,
		analyticsAttributes,
		features,
		experimentConfig,
		intercomHmac,
		isNounsAvailable,
		isRovoEnabled,
		isConfigLoaded,
		products,
		accessibleProducts,
		accessibleNouns,
		allFilterExcludedProducts,
		productEdition,
		productDataRegions,
		searchSessionId,
		thirdPartyConfigs,
		rovoFeatures,
	]);

	return (
		<BootstrapStoreContainer
			initialProps={getInitialProps()}
			queryParams={queryParams}
			queryParamsCallback={callbacks?.updateQueryParameters}
			getSpaceIdsFromSpaceKeys={callbacks?.getSpaceIdsFromSpaceKeys}
		>
			{children}
		</BootstrapStoreContainer>
	);
};
