import {
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AsanaIcon } from './icon';
import { AsanaSearchResultIcon } from './search-result-icon';

export const asanaConfig: ThirdPartyConfig = {
	displayName: 'Asana',
	id: 'asana',
	icon: get3PLogo(AsanaIcon, 'Asana', 'medium'),
	resourceType: 'asana-connector',
	searchResultIcon: get3PLogo(AsanaSearchResultIcon, 'Asana', 'large'),
	integrationAri: 'ari:cloud:platform::integration/asana',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.WorkItem,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Task],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
