import { smartsRecommendedUserQuery, type User, usersQuery } from '@atlassian/search-client';

import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';

const MAX_SMART_DEFAULT_OPTIONS = 5;

export const loadRecommendedUsers = async (config: CloudConfig) => {
	const initialUsersQuery = usersQuery([
		...(config.userId ? [config.userId] : []), // The current user should always be the first recommended user
	]);

	const recommendedUserQuery = smartsRecommendedUserQuery({
		aggAbsoluteUrl: config.aggAbsoluteUrl,
		variables: {
			userId: config.userId || '',
			tenantId: config.cloudId || '',
			maxNumberOfResults: MAX_SMART_DEFAULT_OPTIONS,
		},
	});

	const [initialUsersReponse, smartsRecommendedUserResponse] = await Promise.all([
		initialUsersQuery,
		recommendedUserQuery,
	]);

	const recommendedUsersResults = smartsRecommendedUserResponse.data?.smarts.results ?? [];

	const allRecommendedUsers = [
		...(initialUsersReponse.data?.users ?? []),
		...recommendedUsersResults.flatMap((recommendedUsers) =>
			!!recommendedUsers && !!recommendedUsers.user
				? { id: recommendedUsers.id, ...recommendedUsers.user }
				: [],
		),
	] satisfies User[];

	const recommendedUserOptions = allRecommendedUsers.map((user) => ({
		trackingKey: user.accountId,
		value: user.accountId,
		label: user.name,
		avatarUrl: user.picture,
		queryParamValue: user.accountId,
	}));

	return recommendedUserOptions;
};
