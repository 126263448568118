import { type CloudConfig } from '../../../common/constants/filters/cloud-config/types';
import type {
	CreatedSelectFilter,
	CreateSelectFilter,
	SelectFilterDeps,
	SelectFilterOption,
	SelectFilterOptionChange,
	SelectFilterState,
	SelectFilterType,
} from '../../../common/constants/filters/select-filter/types';
import { CUSTOM_OPTION_VALUE } from '../../../common/constants/filters/types';
import {
	clearOptions,
	deselectOptions,
	getOptions,
	getState,
	getValue,
	loadInitialOptions,
	setLookupInput,
} from '../base-select-filter';

export const createSingleWithCustomOptionSelectFilter = <ID extends string = string>(
	initial: CreateSelectFilter<ID>,
): CreatedSelectFilter<ID> => {
	const initialState = Object.freeze({
		id: initial.id,
		type: 'single-select',
		didMount: false,
		hasSelection: false,
		alwaysVisible: initial.alwaysVisible,
		universal: initial.universal,
		queryParams: {
			isLoading: false,
			options: [],
		},
		lookup: {
			isLoading: false,
			isActive: false,
			isDefined: !!initial.lookup,
			options: [],
			lookupResultOptions: [],
			input: '',
		},
		initialOptions: {
			options: [],
			isLoading: false,
		},
		options: [],
		selectedOptions: [],
		value: [],
		products: initial.products,
	} satisfies SelectFilterState<ID>);

	return {
		id: initial.id,
		type: initialState.type,
		queryParams: { key: initial.queryParams.key },
		products: initial.products,
		universal: initial.universal,
		customValueFields: initial.customValueFields,
		withState: (
			state: unknown, // To support polymorphic function calls from the Store
			config: CloudConfig,
		) =>
			SingleSelectWithCustomOptionFilter({
				initial,
				state: state as SelectFilterState<ID>, // This has to be cast back to the correct state
				config,
			}),
		getInitialState: (config: CloudConfig) =>
			SingleSelectWithCustomOptionFilter({ initial, state: initialState, config }),
		isCompatible: (id) => id === initial.id,
		isSupportCustomValue: true,
	};
};

export const getQueryParams = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return () => {
		const options = SingleSelectWithCustomOptionFilter({ initial, state, config }).getOptions({
			isLookupActive: false,
		});

		const selectedOptions = options.filter((option) => option.isSelected);

		const queryParamValue = selectedOptions.map((option) => option.queryParamValue);

		const isCustomOptionSelected = selectedOptions.find(
			(option) => option.value === CUSTOM_OPTION_VALUE,
		);

		const customValueQueryParam =
			isCustomOptionSelected && state.customValue ? state.customValue : {};

		return { [initial.queryParams.key]: queryParamValue, ...customValueQueryParam };
	};
};

export const setQueryParams = <ID extends string>({
	initial,
	state,
	config,
}: SelectFilterDeps<ID>) => {
	return async (
		queryParams: Record<string, string[] | undefined>,
		onStateChange?: (state: SelectFilterState<string>) => void,
	) => {
		const queryParamValues = queryParams[initial.queryParams.key];
		const filter = SingleSelectWithCustomOptionFilter({ initial, state, config });

		if (!queryParamValues) {
			const clearedOptions = filter.clearOptions();
			onStateChange?.(clearedOptions.getState());
			return clearedOptions;
		}

		const allCurrentOptions = filter.getOptions({ isLookupActive: false });
		const allQueryParamValues = allCurrentOptions.map((option) => option.queryParamValue);
		const queryParamsToHydrate = queryParamValues.filter(
			(value) => !allQueryParamValues.includes(value),
		);

		if (queryParamsToHydrate.length === 0) {
			const newOptions = allCurrentOptions.map(
				(option) =>
					({
						isSelected: queryParamValues.includes(option.queryParamValue),
						value: option.value,
					}) satisfies SelectFilterOptionChange,
			);

			const isCustomOptionSelected = newOptions.find(
				(option) => option.value === CUSTOM_OPTION_VALUE,
			)?.isSelected;

			const customValueParser = initial.queryParams.customValueParser;

			let customValue;

			if (isCustomOptionSelected) {
				if (customValueParser) {
					customValue = customValueParser(queryParams);
				} else {
					customValue = initial.customValueFields
						? initial.customValueFields.reduce(
								(acc, field) => ({
									...acc,
									[field]: queryParams[field]?.at(0) ?? '',
								}),
								{},
							)
						: {};
				}
			}

			const filterWithNewOptions = filter.setOption(newOptions, customValue);
			onStateChange?.(filterWithNewOptions.getState());
			return filterWithNewOptions;
		}

		// At this point we need to hydrate the filters with new options from
		// the query params
		const newQueryParamOptions = [...state.queryParams.options];
		const fetcher = initial.queryParams.fetcher;
		if (fetcher) {
			onStateChange?.({
				...state,
				queryParams: {
					...state.queryParams,
					isLoading: true,
				},
			});

			const fetchedOptions = await fetcher(queryParamsToHydrate, config);
			fetchedOptions.forEach((option) => {
				const mappedOption = {
					isSelected: true,
					trackingKey: option.trackingKey,
					value: option.value,
					queryParamValue: option.queryParamValue,
					isSuggested: false,
					isDefault: option.isDefault ?? false,
					avatarUrl: option.avatarUrl,
					label: option.label,
				} satisfies SelectFilterOption;
				newQueryParamOptions.push(mappedOption);
			});
		} else {
			// The edge case here is where we have an unsupported query param
			// and we don't have a fetcher to hydrate the unsupported query param
			// TODO: >>>> Not sure what to do here
		}

		const selectedOptionsFromQueryParams = newQueryParamOptions.map((option) => ({
			...option,
			isSelected: queryParamValues.includes(option.queryParamValue),
		}));

		const selectedInitialOptionsFromQueryParams = [...state.initialOptions.options].map(
			(option) => ({
				...option,
				isSelected: queryParamValues.includes(option.queryParamValue),
			}),
		);

		const hydratedQueryParams = SingleSelectWithCustomOptionFilter({
			initial,
			state: {
				...state,
				queryParams: {
					...state.queryParams,
					options: selectedOptionsFromQueryParams,
					isLoading: false,
				},
				initialOptions: {
					...state.initialOptions,
					options: selectedInitialOptionsFromQueryParams,
				},
			},
			config,
		});

		onStateChange?.(hydratedQueryParams.getState());
		return hydratedQueryParams;
	};
};

export const SingleSelectWithCustomOptionFilter: SelectFilterType = ({
	initial,
	state,
	config,
}) => {
	const filterState = Object.freeze({
		...state,
	});

	return {
		id: initial.id,
		type: filterState.type,
		getState: getState({ initial, state: filterState, config }),
		queryParams: {
			key: initial.queryParams.key,
			get: getQueryParams({ initial, state: filterState, config }),
			set: setQueryParams({ initial, state: filterState, config }),
		},
		loadInitialOptions: loadInitialOptions({ initial, state: filterState, config }),
		setOption: setOption({ initial, state: filterState, config }),
		clearOptions: clearOptions({ initial, state: filterState, config }),
		setLookupInput: setLookupInput({ initial, state: filterState, config }),
		getOptions: getOptions({ initial, state: filterState, config }),
		getValue: getValue({ initial, state: filterState, config }),
		clear: deselectOptions({ initial, state: filterState, config }),
	};
};

const updateOptionState =
	(props: { selectedOption?: SelectFilterOptionChange }) => (option: SelectFilterOption) => {
		if (option.isDefault) {
			return {
				...option,
				isSelected: false,
			};
		}

		return {
			...option,
			isSelected: props.selectedOption
				? props.selectedOption.value === option.value
				: option.isSelected,
		};
	};

export const setOption = <ID extends string>({ initial, state, config }: SelectFilterDeps<ID>) => {
	return (
		option: SelectFilterOptionChange | SelectFilterOptionChange[],
		customValue?: Record<string, string>,
	) => {
		const optionsToChange = Array.isArray(option) ? option : [option];

		const selectedOption = optionsToChange.filter((o) => o.isSelected).at(0);

		const queryParamOptions = state.queryParams.options.map(
			updateOptionState({
				selectedOption,
			}),
		);

		const initialOptions = state.initialOptions.options.map(
			updateOptionState({
				selectedOption,
			}),
		);

		const lookupOptions = [
			...state.lookup.options,
			// 'interacted' lookup options are options that a user made a lookup for and has either
			// selected or deselected an option from a lookup query
			// These 'interacted' options need to be persisted if a user wants to select them again
			...state.lookup.lookupResultOptions.flatMap((option) => {
				if (!optionsToChange.find((x) => x.value === option.value)) {
					return [];
				}

				return [option];
			}),
		].map(
			updateOptionState({
				selectedOption,
			}),
		);

		return SingleSelectWithCustomOptionFilter({
			initial: initial satisfies CreateSelectFilter<ID>,
			state: {
				...state,
				initialOptions: {
					...state.initialOptions,
					options: initialOptions,
				},
				queryParams: {
					...state.queryParams,
					options: queryParamOptions,
				},
				lookup: {
					...state.lookup,
					options: lookupOptions,
				},
				customValue,
			},
			config,
		});
	};
};
