import React, { type ReactNode, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import CheckMarkIcon from '@atlaskit/icon/core/check-mark';
import ErrorIcon from '@atlaskit/icon/core/error';
import LinkExternalIcon from '@atlaskit/icon/utility/link-external';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Pressable, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { RovoLogo } from '../../constants/icons';
import { ProductKeys, ProductKeys3P } from '../../constants/products';

import { messages } from './messages';

const tileStyles = xcss({
	width: '174px',
	maxWidth: '174px',
	height: '134px',
	border: '1px solid',
	borderRadius: '4px',
	borderColor: 'color.border',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: 'space.075',
	overflow: 'hidden',
	boxSizing: 'border-box',
});

const contentStyles = xcss({
	boxSizing: 'border-box',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
});

const iconStyles = xcss({
	width: '32px',
	height: '32px',
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
	border: '1px solid',
	borderRadius: '4px',
	borderColor: 'color.border',
});

const productTitleStyles = xcss({
	color: 'color.text.subtle',
	font: 'font.heading.xxsmall',
	fontFamily: token('font.family.brand.heading'),
	alignItems: 'center',
	maxWidth: '100%',
	overflow: 'hidden',
	boxSizing: 'border-box',
});

const titleTextStyles = xcss({
	overflow: 'clip',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
});

const connectLinkStyles = xcss({
	color: 'color.link',
	font: 'font.body',
	fontStyle: 'normal',
	textDecoration: 'none',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.150',
});

const connectedLinkStyles = xcss({
	color: 'color.text.accent.green',
	font: 'font.body',
	fontStyle: 'normal',
	textDecoration: 'none',
	paddingInlineStart: 'space.100',
	paddingInlineEnd: 'space.150',
});

const errorLinkStyles = xcss({
	color: 'color.link',
	font: 'font.body',
	fontStyle: 'normal',
	textDecoration: 'none',
});

const errorMessageStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body',
	fontStyle: 'normal',
	paddingLeft: 'space.025',
	paddingRight: 'space.025',
});

const connectIconStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

const connectPressableStyles = xcss({
	':hover': {
		textDecoration: 'underline',
	},
});

type ConnectStatus = 'default' | 'connected' | 'error';

interface OAuthHandlersProps {
	product: ProductKeys;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	onFailAction?: () => void;
	isOnSearchDialog?: boolean;
}
interface OauthHandlerFunctions {
	onAuthorize: () => void;
	onDismiss: () => void;
	onFail: () => void;
}

type useOAuthHandlers = (props: OAuthHandlersProps) => OauthHandlerFunctions;

type ProductTileProps = {
	icon: RovoLogo;
	productName: ReactNode;
	productKey: ProductKeys3P;
	url: string;
	newlyConnected?: boolean;
	useOAuthHandlers: useOAuthHandlers;
	useCurrentProductStatuses: () => Map<ProductKeys3P, boolean>;
	setProductStatus: (productKey: ProductKeys3P, status: boolean) => void;
	setProductConnectedInSession: (status: boolean) => void;
};

export const ProductTile = ({
	icon,
	productName,
	productKey,
	url,
	newlyConnected,
	useOAuthHandlers,
	useCurrentProductStatuses,
	setProductStatus,
	setProductConnectedInSession,
}: ProductTileProps) => {
	const { formatMessage } = useIntl();
	let ProductIcon = icon;
	const [curConnectStatus, setCurConnectStatus] = useState<ConnectStatus>('default');
	const productStatuses = useCurrentProductStatuses();

	useEffect(() => {
		setCurConnectStatus(productStatuses.get(productKey) ? 'connected' : 'default');
	}, [productStatuses, productKey]);

	const { onAuthorize, onFail } = useOAuthHandlers({
		product: productKey,
		successFlagTitle: formatMessage(messages.authorizationSuccess, { product: productName }),
		failFlagTitle: formatMessage(messages.authorizationFail, { product: productName }),
		outboundAuthUrl: url,
		onCompletedAction: () => {
			setCurConnectStatus('connected');
			setProductStatus(productKey, true);
			setProductConnectedInSession(true);
		},
		onFailAction: () => {
			setCurConnectStatus('error');
		},
	});

	const onConnectClick = () => {
		onAuthorize();
		onFail();
	};

	let connectAction;
	switch (curConnectStatus) {
		case 'connected':
			connectAction = (
				<Inline space="space.050" alignBlock="center" xcss={connectedLinkStyles}>
					<CheckMarkIcon
						label={formatMessage(messages.checkIconLabel)}
						color={token('color.icon.accent.green')}
					/>
					<Box>
						<FormattedMessage {...messages.connected} />
					</Box>
				</Inline>
			);
			break;
		case 'error':
			connectAction = (
				<Pressable
					onClick={onConnectClick}
					xcss={connectPressableStyles}
					backgroundColor="color.background.neutral.subtle"
				>
					<Inline alignBlock="center">
						<Inline space="space.050" alignBlock="center">
							<ErrorIcon
								label={formatMessage(messages.errorIconLabel)}
								color={token('color.icon.danger')}
							/>
							<Box xcss={errorMessageStyles}>
								<FormattedMessage {...messages.failed} />
							</Box>
						</Inline>
						<Box xcss={errorLinkStyles}>
							<FormattedMessage {...messages.retry} />
						</Box>
					</Inline>
				</Pressable>
			);
			break;
		default:
			connectAction = (
				<Pressable
					onClick={onConnectClick}
					xcss={connectPressableStyles}
					backgroundColor="color.background.neutral.subtle"
				>
					<Box xcss={connectLinkStyles}>
						<Inline space="space.050" alignBlock="center">
							<Box xcss={connectIconStyles}>
								<LinkExternalIcon label={formatMessage(messages.externalLinkIconlabel)} />
							</Box>
							<Box>
								<FormattedMessage {...messages.connect} />
							</Box>
						</Inline>
					</Box>
				</Pressable>
			);
	}

	return (
		<Box xcss={tileStyles}>
			<Box xcss={contentStyles}>
				<Stack alignInline="center" space="space.025">
					<Stack space="space.100" alignInline="center">
						<Box xcss={iconStyles}>{<ProductIcon />}</Box>
						<Inline xcss={productTitleStyles} space="space.050" alignBlock="center">
							<Box xcss={titleTextStyles}>{productName}</Box>
							<Box>
								{newlyConnected && (
									<Lozenge appearance="new">
										<FormattedMessage {...messages.new} />
									</Lozenge>
								)}
							</Box>
						</Inline>
					</Stack>
					{connectAction}
				</Stack>
			</Box>
		</Box>
	);
};
