import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const GithubSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-646.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#292A2E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 5.33337C10.1097 5.33337 5.33325 10.2298 5.33325 16.2701C5.33325 21.1022 8.3896 25.2018 12.6278 26.6479C13.1609 26.7492 13.3566 26.4107 13.3566 26.1218C13.3566 25.861 13.3467 24.9995 13.3422 24.0856C10.3746 24.7472 9.74847 22.7952 9.74847 22.7952C9.26325 21.5311 8.56412 21.1949 8.56412 21.1949C7.59632 20.5162 8.63707 20.5301 8.63707 20.5301C9.70819 20.6073 10.2722 21.6571 10.2722 21.6571C11.2236 23.3291 12.7675 22.8457 13.3762 22.5663C13.472 21.8594 13.7484 21.377 14.0535 21.1039C11.6842 20.8273 9.19365 19.8895 9.19365 15.6989C9.19365 14.5048 9.61034 13.5292 10.2927 12.7633C10.1819 12.4878 9.81683 11.3755 10.396 9.86903C10.396 9.86903 11.2917 9.57509 13.3301 10.9901C14.181 10.7478 15.0935 10.6262 16 10.6221C16.9065 10.6262 17.8197 10.7478 18.6722 10.9901C20.7081 9.57509 21.6026 9.86903 21.6026 9.86903C22.1832 11.3755 21.8179 12.4878 21.7071 12.7633C22.3911 13.5292 22.8049 14.5048 22.8049 15.6989C22.8049 19.8995 20.3096 20.8244 17.9344 21.0952C18.317 21.4346 18.6579 22.1001 18.6579 23.1205C18.6579 24.5838 18.6455 25.7616 18.6455 26.1218C18.6455 26.4128 18.8375 26.7539 19.3782 26.6465C23.6141 25.1987 26.6666 21.1006 26.6666 16.2701C26.6666 10.2298 21.8909 5.33337 16 5.33337Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.0001 5C11.7169 5 5 11.8856 5 20.3798C5 27.1749 9.29799 32.9399 15.258 34.9736C16.0077 35.1159 16.2829 34.6399 16.2829 34.2337C16.2829 33.867 16.269 32.6554 16.2625 31.3703C12.0895 32.3007 11.2089 29.5557 11.2089 29.5557C10.5266 27.778 9.5434 27.3053 9.5434 27.3053C8.18244 26.3508 9.64599 26.3704 9.64599 26.3704C11.1523 26.4789 11.9454 27.9553 11.9454 27.9553C13.2833 30.3065 15.4545 29.6268 16.3105 29.2338C16.4451 28.2398 16.8338 27.5613 17.2628 27.1772C13.9311 26.7883 10.4287 25.4696 10.4287 19.5765C10.4287 17.8974 11.0147 16.5254 11.9742 15.4483C11.8185 15.061 11.305 13.4967 12.1195 11.3783C12.1195 11.3783 13.3791 10.9649 16.2456 12.9547C17.4421 12.614 18.7254 12.4431 20.0001 12.4372C21.2749 12.4431 22.5591 12.614 23.7579 12.9547C26.6209 10.9649 27.8787 11.3783 27.8787 11.3783C28.6952 13.4967 28.1815 15.061 28.0258 15.4483C28.9876 16.5254 29.5696 17.8974 29.5696 19.5765C29.5696 25.4836 26.0605 26.7843 22.7203 27.165C23.2583 27.6423 23.7377 28.5782 23.7377 30.0131C23.7377 32.071 23.7203 33.7272 23.7203 34.2337C23.7203 34.643 23.9904 35.1226 24.7507 34.9715C30.7075 32.9356 35 27.1726 35 20.3798C35 11.8856 28.2841 5 20.0001 5Z"
				fill={token('color.icon', N800)}
			/>
		</svg>
	);
};
