type SlackAuthSuccessRequestConfigProps = {
	absoluteUrl?: string;
	cloudId?: string;
};

export const sendSlackAuthSuccessRequest = ({
	absoluteUrl = '',
	cloudId,
}: SlackAuthSuccessRequestConfigProps) => {
	const relativeSlackUrl = `/gateway/api/third-party-user-auth/slack/user-authed`;
	const baseUrl = absoluteUrl || relativeSlackUrl;
	const url = `${baseUrl}/${cloudId}`;

	fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	});
};
