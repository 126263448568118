export type { ExternalStore, StorageProvider } from './types';

import { fg } from '@atlaskit/platform-feature-flags';

import useLocalStorageOld from './use-local-storage';
import { useLocalStorageSearchPagePersistedState as useLocalStorageNew } from './use-local-storage-new';

export const useFeatureFlaggedLocalStorageCallback = () => {
	return fg('search_page_local_storage_update') ? useLocalStorageNew : useLocalStorageOld;
};
