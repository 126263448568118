import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	footerQualityDescriptor: {
		id: 'ai-summary.ai-summary-footer.quality-descriptor',
		defaultMessage: 'Information quality may vary. <learnMoreLink>Learn why</learnMoreLink>',
		description: 'Text to represent the creator of the page in the feed card byline',
	},
	poweredBy: {
		id: 'ai-summary.ai-summary-footer.powered-by',
		defaultMessage: 'Powered by',
		description:
			'Text that precedes the Atlassian Intelligence logo to let users know feature is powered by atlassian intelligence',
	},
	atlassianIntelligenceIconAlt: {
		id: 'ai-summary.ai-summary-footer.atlassian-intelligence-icon',
		defaultMessage: 'Atlassian Intelligence',
		description: 'alt text for the Atlassian Intelligence icon',
	},
	betaLozenge: {
		id: 'ai-summary.ai-summary-footer.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'Lozenge in the footer to indicate that this feature is in a Beta state',
	},
});
