import type { CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	allPageStatusFilterValueKeys,
	PageStatusFilterValue,
} from '../../../../../common/constants/filters/page-status-filter';
import type { FetchedSelectFilterOption } from '../../../../../common/constants/filters/select-filter/types';

import { pageStatusLabels } from './messages';

export const getAllPageStatusOptions = (config: CloudConfig): FetchedSelectFilterOption[] => {
	const allOption = {
		value: 'all',
		trackingKey: 'all',
		queryParamValue: 'all',
		isDefault: true,
		label: config.intl?.formatMessage(pageStatusLabels['all']) ?? '',
	};

	const pageStatusOptions = allPageStatusFilterValueKeys.map((key) => ({
		value: PageStatusFilterValue[key],
		trackingKey: key,
		queryParamValue: key,
		isDefault: false,
		label: config.intl?.formatMessage(pageStatusLabels[key]) ?? '',
	}));

	return [allOption, ...pageStatusOptions];
};
