import { useEffect, useRef, useState } from 'react';

export const useOverflowObserver = ({
	containerRef,
	itemRefs,
	itemIds,
	alwaysVisibleRef,
}: {
	containerRef: React.MutableRefObject<HTMLDivElement | undefined | null>;
	itemRefs: React.MutableRefObject<React.MutableRefObject<HTMLDivElement>[]>;
	itemIds: string[];
	alwaysVisibleRef?: React.MutableRefObject<HTMLDivElement | undefined>;
}) => {
	const observerRef = useRef<IntersectionObserver | null>(null);
	const [visibleItems, setVisibleItems] = useState<string[]>(itemIds);
	const rightNavWidth = alwaysVisibleRef ? alwaysVisibleRef.current?.offsetWidth ?? 80 : 0;
	const rootMargin = `0px -${rightNavWidth}px 0px 0px`;

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const target = entry.target as HTMLElement;
					const targetId = target.getAttribute('id');

					const isReferenceElement = targetId?.startsWith('reference:');
					if (isReferenceElement && targetId) {
						const elementId = targetId.split('reference:')[1];
						// Set visibility for corresponding target element from reference element id
						if (entry.isIntersecting) {
							setVisibleItems((prev) => [...prev.filter((id) => id !== elementId), elementId]);
						} else {
							setVisibleItems((prev) => prev.filter((id) => id !== elementId));
						}
					}
				});
			},
			{
				root: containerRef.current,
				rootMargin,
				threshold: 0.99,
			},
		);
		observerRef.current = observer;
		return () => {
			observer.disconnect();
		};
	}, [containerRef, rootMargin]);

	useEffect(() => {
		const observer = observerRef.current;
		if (!observer) {
			return;
		}
		itemRefs.current.forEach((itemRef) => {
			if (itemRef?.current) {
				observer.observe(itemRef.current);
			}
		});
	}, [itemRefs, itemIds]);

	return visibleItems;
};
