import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const CanvaSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-115.5"
				y="-550.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<g clipPath="url(#clip0_719_13847)">
				<rect width="32" height="32" rx="8" fill="#7D2AE7" />
				<rect width="32" height="32" rx="8" fill="url(#paint0_radial_719_13847)" />
				<rect width="32" height="32" rx="8" fill="url(#paint1_radial_719_13847)" />
				<rect width="32" height="32" rx="8" fill="url(#paint2_radial_719_13847)" />
				<rect width="32" height="32" rx="8" fill="url(#paint3_radial_719_13847)" />
				<path
					d="M22.6962 18.8755C22.5778 18.8755 22.4735 18.9756 22.365 19.1941C21.1399 21.6782 19.024 23.4359 16.5754 23.4359C13.7441 23.4359 11.9908 20.8801 11.9908 17.3493C11.9908 11.3684 15.3232 7.91035 18.2502 7.91035C19.6179 7.91035 20.4532 8.76986 20.4532 10.1377C20.4532 11.761 19.5309 12.6205 19.5309 13.1931C19.5309 13.4501 19.6908 13.6057 20.0078 13.6057C21.2814 13.6057 22.7762 12.1423 22.7762 10.0748C22.7762 8.07025 21.0315 6.5968 18.1046 6.5968C13.2673 6.5968 8.96826 11.0814 8.96826 17.2865C8.96826 22.0895 11.711 25.2635 15.9429 25.2635C20.4346 25.2635 23.0318 20.7945 23.0318 19.3439C23.0318 19.0227 22.8676 18.8755 22.6962 18.8755Z"
					fill="white"
				/>
			</g>
			<defs>
				<radialGradient
					id="paint0_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(6.1812 28.3623) rotate(-49.416) scale(24.7493)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8.47152 3.63783) rotate(54.703) scale(27.9094)"
				>
					<stop stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint2_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(6.18105 28.3621) rotate(-45.1954) scale(24.4497 11.2447)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint3_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(13.0863 4.31158) rotate(66.5198) scale(25.1934 42.2046)"
				>
					<stop stopColor="#00C4CC" stopOpacity="0.725916" />
					<stop offset="0.0001" stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>
				<clipPath id="clip0_719_13847">
					<path
						d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
						fill="white"
					/>
				</clipPath>
			</defs>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.9766 35C28.2479 35 34.9532 28.2843 34.9532 20C34.9532 11.7157 28.2479 5 19.9766 5C11.7053 5 5 11.7157 5 20C5 28.2843 11.7053 35 19.9766 35Z"
				fill="#7D2AE7"
			/>
			<path
				d="M19.9766 35C28.2479 35 34.9532 28.2843 34.9532 20C34.9532 11.7157 28.2479 5 19.9766 5C11.7053 5 5 11.7157 5 20C5 28.2843 11.7053 35 19.9766 35Z"
				fill="url(#paint0_radial_2230_19011)"
			/>
			<path
				d="M19.9766 35C28.2479 35 34.9532 28.2843 34.9532 20C34.9532 11.7157 28.2479 5 19.9766 5C11.7053 5 5 11.7157 5 20C5 28.2843 11.7053 35 19.9766 35Z"
				fill="url(#paint1_radial_2230_19011)"
			/>
			<path
				d="M19.9766 35C28.2479 35 34.9532 28.2843 34.9532 20C34.9532 11.7157 28.2479 5 19.9766 5C11.7053 5 5 11.7157 5 20C5 28.2843 11.7053 35 19.9766 35Z"
				fill="url(#paint2_radial_2230_19011)"
			/>
			<path
				d="M19.9766 35C28.2479 35 34.9532 28.2843 34.9532 20C34.9532 11.7157 28.2479 5 19.9766 5C11.7053 5 5 11.7157 5 20C5 28.2843 11.7053 35 19.9766 35Z"
				fill="url(#paint3_radial_2230_19011)"
			/>
			<path
				d="M26.4424 23.0771C26.3188 23.0771 26.21 23.1816 26.0968 23.4099C24.8186 26.0059 22.6109 27.8427 20.0561 27.8427C17.1021 27.8427 15.2728 25.1719 15.2728 21.4822C15.2728 15.2322 18.7497 11.6186 21.8036 11.6186C23.2306 11.6186 24.1021 12.5167 24.1021 13.9461C24.1021 15.6425 23.1398 16.5407 23.1398 17.139C23.1398 17.4075 23.3066 17.5701 23.6373 17.5701C24.9661 17.5701 26.5258 16.0409 26.5258 13.8804C26.5258 11.7857 24.7054 10.2459 21.6516 10.2459C16.6046 10.2459 12.1191 14.9323 12.1191 21.4165C12.1191 26.4357 14.9808 29.7524 19.3962 29.7524C24.0827 29.7524 26.7925 25.0823 26.7925 23.5666C26.7925 23.2309 26.6211 23.0771 26.4424 23.0771Z"
				fill="white"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_2230_19011"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(10.7858 31.5897) rotate(-49.4602) scale(23.1872 23.1816)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_2230_19011"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.9297 8.41046) rotate(54.7452) scale(26.1514 26.1378)"
				>
					<stop stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint2_radial_2230_19011"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(10.7857 31.5895) rotate(-45.2401) scale(22.9038 10.5336)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint3_radial_2230_19011"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(17.2493 9.04211) rotate(66.5525) scale(23.613 39.5149)"
				>
					<stop stopColor="#00C4CC" stopOpacity="0.725916" />
					<stop offset="0.0001" stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	);
};
