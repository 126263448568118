import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { StripeIcon } from './icon';
import { StripeSearchResultIcon } from './search-result-icon';

export const stripeConfig: ThirdPartyConfig = {
	displayName: 'Stripe',
	id: 'stripe',
	resourceType: 'smartlinks-connector-stripe',
	icon: get3PLogo(StripeIcon, 'Stripe', 'medium'),
	searchResultIcon: get3PLogo(StripeSearchResultIcon, 'Stripe', 'large'),
	integrationAri: 'ari:cloud:platform::integration/stripe',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Invoice,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
