import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const OneDriveSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-23.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<path
				d="M13.3891 12.867L13.3894 12.8662L18.0077 15.6326L20.7598 14.4745C21.319 14.2327 21.922 14.1088 22.5312 14.1094C22.6327 14.1094 22.733 14.114 22.8329 14.1206C22.502 12.8305 21.8035 11.6642 20.8223 10.7635C19.8411 9.86286 18.6195 9.26656 17.3058 9.0471C15.9921 8.82765 14.6429 8.99448 13.4222 9.52732C12.2016 10.0602 11.1619 10.9361 10.4297 12.0486C10.4532 12.0484 10.4764 12.0469 10.5 12.0469C11.5207 12.0455 12.5214 12.3296 13.3891 12.867Z"
				fill="#0364B8"
			/>
			<path
				d="M13.3894 12.8664L13.3892 12.8671C12.5214 12.3297 11.5207 12.0456 10.5 12.047C10.4764 12.047 10.4532 12.0485 10.4297 12.0488C9.43071 12.0611 8.45396 12.3455 7.60443 12.8713C6.75489 13.3971 6.0647 14.1444 5.608 15.033C5.15131 15.9216 4.94539 16.9179 5.01237 17.9147C5.07935 18.9115 5.41669 19.8713 5.98816 20.6908L10.0609 18.9769L11.8714 18.215L15.9026 16.5186L18.0078 15.6327L13.3894 12.8664Z"
				fill="#0078D4"
			/>
			<path
				d="M22.8327 14.1208C22.7329 14.1141 22.6325 14.1095 22.531 14.1095C21.9218 14.1089 21.3189 14.2333 20.7597 14.4751L18.0076 15.6327L18.8056 16.1107L21.4215 17.6776L22.5628 18.3613L26.4653 20.6988C26.8199 20.0405 27.0036 19.3038 26.9997 18.5561C26.9958 17.8084 26.8044 17.0737 26.4429 16.4191C26.0815 15.7646 25.5616 15.2112 24.9308 14.8097C24.3001 14.4081 23.5787 14.1713 22.8327 14.1208Z"
				fill="#1490DF"
			/>
			<path
				d="M22.5626 18.3612L21.4213 17.6776L18.8054 16.1107L18.0074 15.6327L15.9022 16.5186L11.871 18.215L10.0605 18.9768L5.98779 20.6907C6.49391 21.4184 7.16861 22.0127 7.95428 22.4231C8.73996 22.8334 9.61327 23.0474 10.4996 23.0469H22.5309C23.3376 23.0472 24.1292 22.829 24.8219 22.4156C25.5146 22.0021 26.0824 21.4089 26.4651 20.6988L22.5626 18.3612Z"
				fill="#28A8EA"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.4398 15.3371L16.4401 15.3361L22.7379 19.1084L26.4906 17.5292C27.2532 17.1995 28.0755 17.0304 28.9063 17.0312C29.0447 17.0312 29.1815 17.0375 29.3176 17.0466C28.8664 15.2873 27.914 13.6969 26.576 12.4687C25.238 11.2405 23.5721 10.4274 21.7806 10.1282C19.9892 9.82889 18.1495 10.0564 16.4849 10.783C14.8204 11.5096 13.4027 12.704 12.4042 14.2212C12.4363 14.2208 12.4678 14.2187 12.5 14.2187C13.8919 14.2169 15.2565 14.6042 16.4398 15.3371Z"
				fill="#0364B8"
			/>
			<path
				d="M16.4401 15.3361L16.4398 15.3371C15.2565 14.6042 13.8919 14.2169 12.5 14.2188C12.4678 14.2188 12.4362 14.2208 12.4042 14.2212C11.0419 14.238 9.70995 14.6258 8.55149 15.3428C7.39304 16.0598 6.45186 17.0789 5.8291 18.2906C5.20634 19.5023 4.92553 20.8608 5.01687 22.2202C5.1082 23.5795 5.56822 24.8882 6.34749 26.0057L11.9012 23.6686L14.3701 22.6297L19.8671 20.3164L22.7379 19.1084L16.4401 15.3361Z"
				fill="#0078D4"
			/>
			<path
				d="M29.3176 17.0466C29.1815 17.0375 29.0447 17.0313 28.9063 17.0313C28.0755 17.0304 27.2534 17.2001 26.4908 17.5298L22.7379 19.1084L23.8261 19.7602L27.3933 21.8969L28.9496 22.8291L34.2712 26.0167C34.7547 25.119 35.0053 24.1144 34.9999 23.0948C34.9946 22.0752 34.7336 21.0733 34.2407 20.1807C33.7478 19.2882 33.0388 18.5336 32.1787 17.986C31.3186 17.4385 30.3349 17.1155 29.3176 17.0466Z"
				fill="#1490DF"
			/>
			<path
				d="M28.9496 22.8291L27.3933 21.8969L23.8261 19.7602L22.7379 19.1084L19.8672 20.3164L14.3701 22.6297L11.9013 23.6686L6.34753 26.0057C7.03769 26.998 7.95774 27.8085 9.02911 28.368C10.1005 28.9275 11.2914 29.2194 12.5 29.2188H28.9063C30.0063 29.2191 31.0859 28.9216 32.0305 28.3578C32.975 27.794 33.7493 26.985 34.2712 26.0167L28.9496 22.8291Z"
				fill="#28A8EA"
			/>
		</svg>
	);
};
