import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AirtableIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-635.5"
				y="-268.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.375"
				y="0.375"
				width="23.25"
				height="23.25"
				rx="5.625"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.75"
			/>
			<path
				d="M11.1447 5.16889L5.17945 7.63723C4.84772 7.77451 4.85115 8.24569 5.18496 8.37801L11.1751 10.7535C11.7015 10.9622 12.2876 10.9622 12.8139 10.7535L18.8042 8.37801C19.1379 8.24569 19.1414 7.77451 18.8096 7.63723L12.8445 5.16889C12.3002 4.9437 11.6888 4.9437 11.1447 5.16889Z"
				fill="#FFBF00"
			/>
			<path
				d="M12.5259 12.0506V17.9848C12.5259 18.2671 12.8105 18.4603 13.0728 18.3563L19.7477 15.7655C19.9001 15.705 20.0001 15.5578 20.0001 15.3939V9.45974C20.0001 9.17748 19.7155 8.98421 19.4531 9.08821L12.7782 11.6791C12.6259 11.7395 12.5259 11.8867 12.5259 12.0506Z"
				fill="#26B5F8"
			/>
			<path
				d="M10.9672 12.3568L8.98623 13.3133L8.78509 13.4105L4.6034 15.4141C4.33833 15.542 4 15.3488 4 15.0544V9.48465C4 9.37811 4.05462 9.28613 4.12788 9.21686C4.15845 9.1862 4.19309 9.16097 4.22908 9.14098C4.32901 9.08102 4.47155 9.06501 4.59273 9.11295L10.9339 11.6254C11.2562 11.7533 11.2815 12.2049 10.9672 12.3568Z"
				fill="#ED3049"
			/>
			<path
				d="M10.9672 12.3568L8.98628 13.3133L4.12793 9.21686C4.1585 9.1862 4.19314 9.16097 4.22913 9.14098C4.32906 9.08102 4.4716 9.06501 4.59278 9.11295L10.934 11.6254C11.2563 11.7533 11.2816 12.2049 10.9672 12.3568Z"
				fill="black"
				fillOpacity="0.25"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.2515 6.14778L6.03189 8.30757C5.74163 8.4277 5.74464 8.83998 6.03672 8.95576L11.2781 11.0343C11.7387 11.2169 12.2515 11.2169 12.712 11.0343L17.9535 8.95576C18.2455 8.83998 18.2486 8.4277 17.9583 8.30757L12.7388 6.14778C12.2625 5.95074 11.7276 5.95074 11.2515 6.14778Z"
				fill="#FFBF00"
			/>
			<path
				d="M12.4601 12.1693V17.3617C12.4601 17.6087 12.7091 17.7778 12.9387 17.6868L18.7792 15.4198C18.9126 15.3669 19 15.2381 19 15.0947V9.90228C19 9.65531 18.751 9.4862 18.5214 9.5772L12.6809 11.8442C12.5476 11.8971 12.4601 12.0259 12.4601 12.1693Z"
				fill="#26B5F8"
			/>
			<path
				d="M11.0963 12.4372L9.36295 13.2741L9.18695 13.3592L5.52798 15.1124C5.29604 15.2243 5 15.0552 5 14.7976V9.92405C5 9.83083 5.0478 9.75035 5.11189 9.68974C5.13864 9.66291 5.16895 9.64083 5.20045 9.62334C5.28789 9.57088 5.4126 9.55687 5.51864 9.59881L11.0672 11.7972C11.3492 11.9091 11.3714 12.3043 11.0963 12.4372Z"
				fill="#ED3049"
			/>
			<path
				d="M11.0963 12.4372L9.36293 13.2741L5.11188 9.68974C5.13862 9.66291 5.16893 9.64083 5.20043 9.62334C5.28787 9.57088 5.41258 9.55687 5.51862 9.59881L11.0671 11.7972C11.3492 11.9091 11.3713 12.3043 11.0963 12.4372Z"
				fill="black"
				fillOpacity="0.25"
			/>
		</svg>
	);
};
