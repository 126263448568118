import { DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GoogleCalendarIcon } from './icon';
import { GoogleCalendarSearchResultIcon } from './search-result-icon';

export const googleCalendarConfig: ThirdPartyConfig = {
	displayName: 'Google Calendar',
	id: 'google-calendar',
	resourceType: 'google-calendar-connector',
	icon: get3PLogo(GoogleCalendarIcon, 'Google Calendar', 'medium'),
	searchResultIcon: get3PLogo(GoogleCalendarSearchResultIcon, 'Google Calendar', 'large'),
	integrationAri: 'ari:cloud:platform::integration/google',
	requiresOauth: true,
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Calendar,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	isEnabled: () => true,
};
