import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const MondaySearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-263.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#0B1228" strokeOpacity="0.14" />
			<path
				d="M8.48792 22.364C8.0458 22.365 7.61135 22.2409 7.22895 22.0042C6.84656 21.7675 6.52992 21.4268 6.31138 21.0168C6.09619 20.6097 5.98887 20.1479 6.00091 19.6808C6.01296 19.2136 6.14391 18.7588 6.37975 18.3649L10.862 10.8571C11.0914 10.4536 11.4171 10.1222 11.8058 9.89695C12.1946 9.67165 12.6324 9.56045 13.0746 9.5747C13.5165 9.586 13.9476 9.72232 14.3238 9.96971C14.7001 10.2171 15.008 10.5667 15.216 10.9827C15.6338 11.8254 15.5816 12.8394 15.0811 13.6305L10.6018 21.1384C10.3746 21.5159 10.0618 21.8262 9.69227 22.0405C9.32275 22.2547 8.9085 22.366 8.48792 22.364Z"
				fill="#F62B54"
			/>
			<path
				d="M16.176 22.3638C15.272 22.3638 14.4401 21.8482 14.0033 21.0196C13.7886 20.6137 13.6815 20.153 13.6935 19.6871C13.7056 19.2212 13.8363 18.7676 14.0717 18.3749L18.5453 10.8842C18.7714 10.475 19.0959 10.1378 19.4853 9.90752C19.8747 9.67722 20.3148 9.56224 20.7598 9.5745C21.6715 9.59577 22.4977 10.1397 22.9155 10.9906C23.3305 11.8414 23.2697 12.8615 22.7541 13.6505L18.2813 21.1412C18.0549 21.5171 17.7433 21.8262 17.3754 22.0399C17.0074 22.2536 16.595 22.365 16.176 22.3638Z"
				fill="#FFCC00"
			/>
			<path
				d="M23.6923 22.4266C24.9668 22.4266 25.9999 21.3477 25.9999 20.0168C25.9999 18.6859 24.9668 17.6071 23.6923 17.6071C22.4179 17.6071 21.3848 18.6859 21.3848 20.0168C21.3848 21.3477 22.4179 22.4266 23.6923 22.4266Z"
				fill="#00CA72"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.73188 29.2367C8.06871 29.2381 7.41704 29.0635 6.84344 28.7306C6.26984 28.3978 5.79488 27.9187 5.46707 27.3422C5.14429 26.7697 4.9833 26.1203 5.00137 25.4634C5.01944 24.8064 5.21587 24.1668 5.56963 23.613L12.293 13.055C12.6371 12.4876 13.1256 12.0217 13.7088 11.7049C14.2919 11.388 14.9486 11.2317 15.6119 11.2517C16.2747 11.2676 16.9214 11.4593 17.4858 11.8072C18.0502 12.1551 18.512 12.6467 18.824 13.2317C19.4508 14.4168 19.3724 15.8427 18.6218 16.9552L11.9027 27.5131C11.562 28.044 11.0927 28.4804 10.5384 28.7817C9.98413 29.083 9.36276 29.2395 8.73188 29.2367Z"
				fill="#F62B54"
			/>
			<path
				d="M20.2641 29.2364C18.908 29.2364 17.6602 28.5113 17.005 27.3462C16.6829 26.7753 16.5223 26.1275 16.5404 25.4723C16.5584 24.8171 16.7545 24.1792 17.1075 23.6269L23.8181 13.0932C24.1571 12.5178 24.6439 12.0436 25.228 11.7197C25.8121 11.3959 26.4722 11.2342 27.1399 11.2514C28.5073 11.2813 29.7466 12.0463 30.3733 13.2428C30.9958 14.4393 30.9046 15.8737 30.1312 16.9834L23.4221 27.5171C23.0824 28.0458 22.615 28.4804 22.0631 28.7809C21.5112 29.0814 20.8925 29.238 20.2641 29.2364Z"
				fill="#FFCC00"
			/>
			<path
				d="M31.5387 29.3247C33.4503 29.3247 35 27.8076 35 25.936C35 24.0645 33.4503 22.5473 31.5387 22.5473C29.627 22.5473 28.0773 24.0645 28.0773 25.936C28.0773 27.8076 29.627 29.3247 31.5387 29.3247Z"
				fill="#00CA72"
			/>
		</svg>
	);
};
