import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const ZeplinIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-555.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="white"
			/>
			<path
				d="M0.385264 6C0.385264 2.89907 2.89907 0.385264 6 0.385264H18C21.1009 0.385264 23.6147 2.89907 23.6147 6V18C23.6147 21.1009 21.1009 23.6147 18 23.6147H6C2.89907 23.6147 0.385264 21.1009 0.385264 18V6Z"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.770528"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.77432 14.5822L4.83263 14.9145L4.78223 17.6201L8.0417 16.4703C6.92361 16.1075 6.10732 15.4691 5.77432 14.5822Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.26698 11.7203L3 12.873L4.83299 14.9145L5.77438 14.5821C5.44228 13.6977 5.64688 12.6983 6.26698 11.7203Z"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7655 7.81617C7.60483 9.28414 4.92255 12.3134 5.77454 14.5823L20.8407 9.26618C19.9887 6.99727 15.9259 6.34819 11.7655 7.81617Z"
				fill="#FECF33"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.8498 16.0323C19.0101 14.564 21.6972 11.5483 20.8407 9.26611L5.77393 14.5822C6.63072 16.8645 10.6891 17.5002 14.8498 16.0323Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.8407 9.26611L5.77393 14.5822C6.06882 15.3681 7.19412 15.7552 8.7724 15.7552C10.3114 15.7552 12.2815 15.3875 14.3353 14.6628C18.4956 13.1948 21.4227 10.8178 20.8401 9.26611"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.8418 8.09302C16.3028 8.09302 14.3327 8.46074 12.2789 9.18545C8.11857 10.6534 5.19149 13.0301 5.77408 14.5821L20.8403 9.26601C20.5454 8.48015 19.4204 8.09302 17.8418 8.09302Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0099 17.7638C16.5665 17.7638 15.9128 17.377 15.0659 16.6143C14.0648 15.712 12.9176 14.3903 11.8359 12.8925C10.7544 11.3953 9.86547 9.89801 9.33327 8.67692C8.75368 7.34629 8.67088 6.52827 9.08757 6.24516C9.17907 6.18315 9.28917 6.15186 9.41517 6.15186C9.75417 6.15186 10.4148 6.39555 11.6361 7.55724L11.6631 7.58245L11.6592 7.61867C11.6508 7.70213 11.6433 7.78645 11.6364 7.86904L11.6229 8.03305L11.5029 7.91656C10.1868 6.63577 9.60747 6.49755 9.41937 6.49755C9.36567 6.49755 9.32307 6.50798 9.29247 6.52885C9.11157 6.65142 9.09987 7.2524 9.66207 8.54217C10.185 9.74153 11.0607 11.2159 12.1281 12.694C13.196 14.1724 14.3255 15.4746 15.3092 16.3608C16.3697 17.3164 16.8476 17.4196 17.0042 17.4196C17.0582 17.4196 17.1017 17.4086 17.1344 17.3863C17.3249 17.257 17.3252 16.6132 16.6982 15.2246L16.6694 15.1611L16.7324 15.1278C16.7954 15.0945 16.8566 15.0617 16.9136 15.0304L16.9892 14.989L17.024 15.0658C17.4683 16.0496 17.8838 17.2999 17.339 17.6699C17.2472 17.7323 17.1365 17.7638 17.0099 17.7638Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.9505 15.9908L14.9445 16.0662C14.8392 17.3235 14.562 18.8346 13.8399 18.8961C13.8255 18.8972 13.8105 18.8978 13.7955 18.8978C13.3048 18.8978 12.8407 18.2189 12.4162 16.8801C12.0142 15.6133 11.7022 13.9129 11.5381 12.0911C11.3737 10.2702 11.3761 8.54257 11.545 7.2273C11.7289 5.7938 12.0793 5.04533 12.5863 5.00215C12.6013 5.00099 12.6169 5.00012 12.6322 5.00012C12.988 5.00012 13.5244 5.38957 14.0733 7.20238L13.7148 7.26903C13.5316 6.66341 13.3318 6.1708 13.1335 5.83176C12.9499 5.51881 12.7717 5.3464 12.6313 5.3464C12.6265 5.3464 12.622 5.3464 12.6172 5.34698C12.3976 5.36552 12.0775 5.88045 11.8993 7.2699C11.7334 8.56169 11.7313 10.2632 11.8933 12.061C12.0559 13.859 12.3625 15.5344 12.7573 16.7784C12.934 17.3354 13.1278 17.7947 13.318 18.1062C13.4932 18.3936 13.6627 18.5518 13.7952 18.5518C13.7997 18.5518 13.8042 18.5518 13.809 18.5512C14.019 18.5336 14.4153 17.9978 14.5821 16.1128L14.9505 15.9908Z"
				fill="#EE6723"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.385264 6C0.385264 2.89907 2.89907 0.385264 6 0.385264H18C21.1009 0.385264 23.6147 2.89907 23.6147 6V18C23.6147 21.1009 21.1009 23.6147 18 23.6147H6C2.89907 23.6147 0.385264 21.1009 0.385264 18V6Z"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.770528"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.77432 14.5822L4.83263 14.9145L4.78223 17.6201L8.0417 16.4703C6.92361 16.1075 6.10732 15.4691 5.77432 14.5822Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.26698 11.7203L3 12.873L4.83299 14.9145L5.77438 14.5821C5.44228 13.6977 5.64688 12.6983 6.26698 11.7203Z"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7655 7.81617C7.60483 9.28414 4.92255 12.3134 5.77454 14.5823L20.8407 9.26618C19.9887 6.99727 15.9259 6.34819 11.7655 7.81617Z"
				fill="#FECF33"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.8498 16.0323C19.0101 14.564 21.6972 11.5483 20.8407 9.26611L5.77393 14.5822C6.63072 16.8645 10.6891 17.5002 14.8498 16.0323Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.8407 9.26611L5.77393 14.5822C6.06882 15.3681 7.19412 15.7552 8.7724 15.7552C10.3114 15.7552 12.2815 15.3875 14.3353 14.6628C18.4956 13.1948 21.4227 10.8178 20.8401 9.26611"
				fill="#F69833"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.8418 8.09302C16.3028 8.09302 14.3327 8.46074 12.2789 9.18545C8.11857 10.6534 5.19149 13.0301 5.77408 14.5821L20.8403 9.26601C20.5454 8.48015 19.4204 8.09302 17.8418 8.09302Z"
				fill="#FDBD39"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0099 17.7638C16.5665 17.7638 15.9128 17.377 15.0659 16.6143C14.0648 15.712 12.9176 14.3903 11.8359 12.8925C10.7544 11.3953 9.86547 9.89801 9.33327 8.67692C8.75368 7.34629 8.67088 6.52827 9.08757 6.24516C9.17907 6.18315 9.28917 6.15186 9.41517 6.15186C9.75417 6.15186 10.4148 6.39555 11.6361 7.55724L11.6631 7.58245L11.6592 7.61867C11.6508 7.70213 11.6433 7.78645 11.6364 7.86904L11.6229 8.03305L11.5029 7.91656C10.1868 6.63577 9.60747 6.49755 9.41937 6.49755C9.36567 6.49755 9.32307 6.50798 9.29247 6.52885C9.11157 6.65142 9.09987 7.2524 9.66207 8.54217C10.185 9.74153 11.0607 11.2159 12.1281 12.694C13.196 14.1724 14.3255 15.4746 15.3092 16.3608C16.3697 17.3164 16.8476 17.4196 17.0042 17.4196C17.0582 17.4196 17.1017 17.4086 17.1344 17.3863C17.3249 17.257 17.3252 16.6132 16.6982 15.2246L16.6694 15.1611L16.7324 15.1278C16.7954 15.0945 16.8566 15.0617 16.9136 15.0304L16.9892 14.989L17.024 15.0658C17.4683 16.0496 17.8838 17.2999 17.339 17.6699C17.2472 17.7323 17.1365 17.7638 17.0099 17.7638Z"
				fill="#EE6723"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.9505 15.9908L14.9445 16.0662C14.8392 17.3235 14.562 18.8346 13.8399 18.8961C13.8255 18.8972 13.8105 18.8978 13.7955 18.8978C13.3048 18.8978 12.8407 18.2189 12.4162 16.8801C12.0142 15.6133 11.7022 13.9129 11.5381 12.0911C11.3737 10.2702 11.3761 8.54257 11.545 7.2273C11.7289 5.7938 12.0793 5.04533 12.5863 5.00215C12.6013 5.00099 12.6169 5.00012 12.6322 5.00012C12.988 5.00012 13.5244 5.38957 14.0733 7.20238L13.7148 7.26903C13.5316 6.66341 13.3318 6.1708 13.1335 5.83176C12.9499 5.51881 12.7717 5.3464 12.6313 5.3464C12.6265 5.3464 12.622 5.3464 12.6172 5.34698C12.3976 5.36552 12.0775 5.88045 11.8993 7.2699C11.7334 8.56169 11.7313 10.2632 11.8933 12.061C12.0559 13.859 12.3625 15.5344 12.7573 16.7784C12.934 17.3354 13.1278 17.7947 13.318 18.1062C13.4932 18.3936 13.6627 18.5518 13.7952 18.5518C13.7997 18.5518 13.8042 18.5518 13.809 18.5512C14.019 18.5336 14.4153 17.9978 14.5821 16.1128L14.9505 15.9908Z"
				fill="#EE6723"
			/>
		</svg>
	);
};
