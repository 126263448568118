import { DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo, RovoLogoFromLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AWSS3Icon } from './icon';
import { AWSS3SearchResultIcon } from './search-result-icon';

// This config file will be used for demo purposes only, and will be removed after Team 25
export const awss3Config: ThirdPartyConfig = {
	displayName: 'Amazon Web Services S3',
	id: 'aws-s3',
	icon: RovoLogoFromLogo(AWSS3Icon, 'Amazon Web Services S3'),
	resourceType: 'aws-s3-connector',
	searchResultIcon: get3PLogo(AWSS3SearchResultIcon, 'Amazon Web Services S3', 'large'),
	integrationAri: 'ari:cloud:platform::integration/graph-test-app', // as provided here: https://atlassian.slack.com/archives/C0882LN4A56/p1741316020130509
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Document,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	// Keeping this disabled for team demo. We only need GitBook enabled for demo.
	isEnabled: () => false,
};
