import { type ComponentType } from 'react';

import type {
	CommonFilters,
	ConfluenceFilters,
	JiraFilters,
	MercuryFilters,
	ThirdPartyFilters,
} from '@atlassian/search-client';
import {
	type BackendExperiment,
	type DynamicExperiments,
	type FrontendExperiments,
} from '@atlassian/search-experiment';

import { type ProductKeys } from './products';
import { type FilterKeysRaw } from './schemas/query-params';

export type OptionType<Value = FilterValue<ProductKeys[]>> = {
	[key: string]: any; // Used in OptionType from @atlaskit/select
	label: React.ReactNode;
	value: Value;
	isSelected: boolean;
};

export type FilterPrimitiveType = string | number | boolean;

export type FilterOption<
	Products extends ProductKeys[] = ProductKeys[],
	Value = FilterValue<Products>,
> = OptionType<Value> & {
	products: Products[number][];
	isSuggested?: boolean;
	isDefault?: boolean;
	avatarUrl?: string;
	type?: string;
	filterKey?: string | boolean; // Used to map the initialValue from the queryParams to a filter option
};

export type FilterState<Value, Products extends ProductKeys[]> = {
	readonly select: {
		readonly options: FilterOption<Products, Value>[];

		/* `isHydrated` is the state that we use whether the filter options are up to date.
		 * We need this as we are bootstrapping filter options through a useEffect
		 * which causes filters and its options to be rendered at different times.
		 * It is also needed for non-primitive filters to hydrate the filter options
		 * from different data sources.
		 * */
		readonly isHydrated: boolean;
	};
	readonly boolean: {
		readonly value: boolean;
	};
};

export type FilterMultiValue<Products extends ProductKeys[], Value> = Partial<
	Record<Products[number], Value>
>;

export type FilterValue<Products extends ProductKeys[]> =
	| FilterPrimitiveType
	| FilterMultiValue<Products, FilterPrimitiveType>;

export type FilterType<Value, Products extends ProductKeys[]> = keyof FilterState<Value, Products>;

export type Filter<
	ID extends string = string,
	Products extends ProductKeys[] = ProductKeys[],
	Value = FilterValue<Products>,
	Type extends FilterType<Value, Products> = FilterType<Value, Products>,
> = {
	readonly id: ID;
	readonly type: Type;
	readonly products: Products;
	readonly state: FilterState<Value, Products>[Type];
	// Set by a filter when it has a value, used to show/hide with the dropdown
	readonly hasSelectedValue?: boolean;
	// Set by the adding dropdown, to allow a filter to show without a selected value, should clear on blur
	readonly hasBeenAdded?: boolean;
	readonly initialValue?: FilterKeysRaw;
};

export type Filters<Products extends ProductKeys[] = ProductKeys[]> = Record<
	string,
	Filter<string, Products, FilterValue<Products>, FilterType<FilterValue<Products>, Products>>
>;

export type FixedSizeArray<N extends number> = N extends 0
	? never[]
	: {
			length: N;
		};

export type FilterProduct<F extends Filter> =
	F['products'] extends FixedSizeArray<1> ? F['products'][0] : F['products'][number] | undefined;

export type FilterComponentProps<F extends Filter> = {
	product: FilterProduct<F>;
	state: F['state'];
	initialValue: F['initialValue'];
	hasBeenAdded: F['hasBeenAdded'];
	filterIsVisible?: boolean;
	setState: (state: F['state'], isSelected?: boolean) => void | Promise<void>;
	bootstrapState: (
		state: F['state'],
		isSelected?: boolean,
		hasBeenAdded?: boolean,
	) => void | Promise<void>;
};

export type FilterComponent<F extends Filter> = ComponentType<FilterComponentProps<F>>;
export interface UserDetails {
	id: string | null;
	name: string | null;
	email?: string | null;
}

export enum UserStatus {
	Unauthenticated = 'unauthenticated',
	Anonymous = 'anonymous',
	Authenticated = 'authenticated',
}

export interface UserDetailsWithStatus extends UserDetailsWithPicture {
	status: UserStatus;
}

export interface UserDetailsWithPicture extends UserDetails {
	picture?: string | null;
}

export interface SearchResultAncestor {
	id: string;
	title: string;
	href: string;
}

export type SelectedFiltersType = {
	commonFilters: CommonFilters;
	jiraFilters: JiraFilters;
	confluenceFilters: ConfluenceFilters;
	mercuryFilters: MercuryFilters;
	thirdPartyFilters: ThirdPartyFilters;
};

type onInputDropdownResultSelectArgs = {
	title: string;
	url?: string;
};

export type onInputDropdownResultSelect = (args: onInputDropdownResultSelectArgs) => void;

type onInputSubmitArgs = {
	query: string;
};

export type onInputSubmit = (args: onInputSubmitArgs) => void;

// All state in the experiment config should be only be initialised on bootstrap and never mutated after
export type ExperimentConfig = {
	/**
	 * Search frontend experiments
	 */
	frontendExperiments: FrontendExperiments;
	/**
	 * The legacy backend experiments that are initialised on bootstrap.
	 */
	backendExperiment: BackendExperiment;
	/**
	 * The experiment layers that are sourced from remote dynamic configuration
	 * https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/search_platform_layer_functions
	 */
	dynamicExperiments: DynamicExperiments | undefined;
	/**
	 * Determines if frontend and backend experiments are run mutually exclusively i.e. in an orthogonal manner.
	 * When true, the user will only receive a frontend experiment
	 * When false, the user can receive both frontend and backend experiments
	 */
	isMutuallyExclusive: boolean;
};
