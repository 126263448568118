import {
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AirtableIcon } from './icon';
import { AirtableSearchResultIcon } from './search-result-icon';

export const airtableConfig: ThirdPartyConfig = {
	displayName: 'Airtable',
	id: 'airtable',
	resourceType: 'airtable-connector',
	icon: get3PLogo(AirtableIcon, 'Airtable', 'medium'),
	searchResultIcon: get3PLogo(AirtableSearchResultIcon, 'Airtable', 'large'),
	integrationAri: 'ari:cloud:platform::integration/airtable',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Document,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Document],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
