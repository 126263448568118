import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import AppsIcon from '@atlaskit/icon/core/apps';

import { RovoLogoFromLogo } from '../../constants/icons';
import type { ProductConfig, ProductKeys } from '../../constants/products';
import {
	type BannerType,
	BannerType as BannerTypeMap,
} from '../../utils/bulk-connect-banner/types';
import type { MessageContent } from '../oauth-message';

import { messages } from './messages';

const getProductDisplayName = (
	product: ProductKeys,
	products: Record<ProductKeys, ProductConfig>,
): React.ReactNode => {
	return product === 'sharepoint' ? 'SharePoint' : products[product]?.displayName || '';
};

// TODO AI3W-289: change icons after content is finalized
export const getBannerContent = (
	newlyAddedProducts: ProductKeys[],
	allProducts: Record<ProductKeys, ProductConfig>,
	bannerType: BannerType,
): MessageContent | null => {
	if (bannerType === BannerTypeMap.NONE) {
		return null;
	}
	if (bannerType === BannerTypeMap.INITIAL) {
		return {
			title: <FormattedMessage {...messages.initialBannerTitle} />,
			description: null,
			connectText: <FormattedMessage {...messages.initialBannerConnectText} />,
			MessageIcon: RovoLogoFromLogo(AppsIcon, 'App'),
			iconNeedsPadding: true,
		};
	}
	// it is a secondary banner
	const newlyAddedProductCount = newlyAddedProducts.length;
	if (newlyAddedProductCount === 1) {
		const product = newlyAddedProducts[0];
		const productString = getProductDisplayName(product, allProducts);
		const Icon = allProducts[product]?.Icon;

		return {
			title: (
				<FormattedMessage
					{...messages.singleProductBannerTitle}
					values={{ product: productString }}
				/>
			),
			description: (
				<FormattedMessage
					{...messages.singleProductBannerDescription}
					values={{ product: productString }}
				/>
			),
			connectText: <FormattedMessage {...messages.singleProductBannerConnectText} />,
			MessageIcon: Icon,
		};
	}
	// it is a secondary banner with multiple new products
	return {
		title: (
			<FormattedMessage
				{...messages.multipleProductsBannerTitle}
				values={{ count: newlyAddedProductCount }}
			/>
		),
		description: <FormattedMessage {...messages.multipleProductsBannerDescription} />,
		connectText: <FormattedMessage {...messages.multipleProductsBannerConnectText} />,
		MessageIcon: RovoLogoFromLogo(AppsIcon, 'App'),
		iconNeedsPadding: true,
	};
};
