import { MAX_CHARACTERS_IN_EXCERPT, truncateTextWithQuery } from './truncate-text-with-query';

interface AdfContent {
	type: string;
	content?: AdfContent[];
	text?: string;
}

const getContentString = (adf: AdfContent, level: number = 0) => {
	let aggregatedString = '';

	if (adf.content) {
		adf.content.forEach((node) => {
			if (node.type === 'text') {
				aggregatedString += node.text;
			} else {
				if (node.content && level < 15) {
					aggregatedString += ' ' + getContentString(node, ++level);
				}
			}
		});
	}
	return aggregatedString;
};

export const getSummarizedAdfContent = (description: string, query: string) => {
	/**
	 * This description can be either a complicated JSON which is actually an ADF (Atlassian Document Format) schema of the entire content of the jira issue.
	 * or it could be a simple excerpt. This uncertainity is due to the progressive development of the BE feature and rolling it out.
	 */

	try {
		const adf = JSON.parse(description);
		const text = getContentString(adf);

		return truncateTextWithQuery(text, query);
	} catch (e) {
		return description.length > MAX_CHARACTERS_IN_EXCERPT
			? description.substring(0, MAX_CHARACTERS_IN_EXCERPT) + '...'
			: description.substring(0, MAX_CHARACTERS_IN_EXCERPT);
	}
};
