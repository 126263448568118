import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

export const projectsCache = inMemoryCache<AggResponse<MercuryFocusAreaTypesResponse>>();

const GQL_MERCURY_FOCUS_AREA_TYPES_OPERATION_NAME = 'FocusAreaTypes';
const mercuryFocusAreaTypesQueryString = `query ${GQL_MERCURY_FOCUS_AREA_TYPES_OPERATION_NAME}($cloudId: ID!) {
  mercury {
    focusAreaTypes(cloudId: $cloudId) @optIn(to: "Mercury") {
      id
      name
      hierarchyLevel
    }
  }
}`;

export type MercuryFocusAreaType = {
	/** The id of the focus area type. */
	id: string;
	/** The name of the focus area type. */
	name: string;
	/** The hierarchy level of the focus area type. */
	hierarchyLevel: number;
};

export type MercuryFocusAreaTypesResponse = {
	mercury: {
		focusAreaTypes: MercuryFocusAreaType[];
	};
};

export type MercuryFocusAreaTypeQueryVariables = { cloudId: string };

export const mercuryFocusAreaTypesQuery = (cloudId: string) => {
	return projectsCache.inMemoryDecorator(cloudId, async () => {
		if (!cloudId) {
			return {
				data: {
					mercury: {
						focusAreaTypes: [],
					},
				},
			};
		}

		const response = await fetchAgg<
			MercuryFocusAreaTypeQueryVariables,
			MercuryFocusAreaTypesResponse
		>({
			graphQuery: mercuryFocusAreaTypesQueryString,
			variables: { cloudId },
			operationName: GQL_MERCURY_FOCUS_AREA_TYPES_OPERATION_NAME,
		});

		const hasErrors = response.errors?.some((error) => error);

		if (hasErrors) {
			return Promise.reject(response);
		}
		return response;
	});
};
