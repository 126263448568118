import { IdentityTeamAri } from '@atlassian/ari/identity/team';
import { TeamsTeamAri } from '@atlassian/ari/teams/team';

export const getTeamId = (ari?: string) => {
	let teamId: string | null = null;

	if (!ari) {
		return teamId;
	}

	const isTeamsAri = TeamsTeamAri.check(ari);
	const isIdentityTeamsAri = IdentityTeamAri.check(ari);

	if (isTeamsAri) {
		teamId = TeamsTeamAri.parse(ari).teamId;
	} else if (isIdentityTeamsAri) {
		teamId = IdentityTeamAri.parse(ari).teamId;
	}

	if (teamId === 'unidentified') {
		teamId = null;
	}

	return teamId;
};
