import type { SearchResult } from '../../../../common/types';

const SearchDefaultResultAtlas = ``;

export interface SearchDefaultResultAtlas extends SearchResult {
	__typename: 'SearchDefaultResult';
}

const SearchResultAtlasProject = `
... on SearchResultAtlasProject {
  project {
    key
    state {
      label
      value
    }
    dueDate {
      label
    }
    isPrivate
  }
}
`;

export interface SearchResultAtlasProject extends SearchResult {
	project?: {
		key: string;
		state?: {
			label?: string;
			value?: string;
		};
		dueDate?: {
			label?: string;
		};
		isPrivate: boolean;
	};
	__typename: 'SearchResultAtlasProject';
}

const SearchResultAtlasGoal = `
... on SearchResultAtlasGoal {
  goal {
    description
    key
    status {
      localizedLabel {
        messageId
      }
      value
      score
    }
    targetDate {
      label
    }
  }
}
`;

export interface SearchResultAtlasGoal extends SearchResult {
	goal?: {
		description?: string;
		key: string;
		status?: {
			localizedLabel?: {
				messageId?: string;
			};
			score?: number;
			value?: string;
		};
		targetDate?: {
			label?: string;
		};
	};
	__typename: 'SearchResultAtlasGoal';
}

export type SearchAtlasPartial =
	| SearchDefaultResultAtlas
	| SearchResultAtlasProject
	| SearchResultAtlasGoal;

export const isSearchResultAtlasProjectPartial = (
	partial: SearchResult,
): partial is SearchResultAtlasProject => {
	return partial?.id?.includes('townsquare') && partial.__typename === 'SearchResultAtlasProject';
};

export const isSearchResultAtlasGoalPartial = (
	partial: SearchResult,
): partial is SearchResultAtlasGoal => {
	return partial?.id?.includes('townsquare') && partial.__typename === 'SearchResultAtlasGoal';
};

export const isSearchResultAtlasTagPartial = (
	partial: SearchResult,
): partial is SearchResultAtlasGoal => {
	return partial?.id?.includes('townsquare') && partial.__typename === 'SearchResultAtlasTag';
};

export const isDefaultSearchResultAtlas = (
	partial: SearchResult,
): partial is SearchDefaultResultAtlas => {
	return partial?.id?.includes('townsquare') && partial.__typename === 'SearchDefaultResult';
};

export const isSearchAtlasPartial = (partial: SearchResult): partial is SearchAtlasPartial => {
	return (
		isSearchResultAtlasGoalPartial(partial) ||
		isSearchResultAtlasProjectPartial(partial) ||
		isSearchResultAtlasTagPartial(partial) ||
		isDefaultSearchResultAtlas(partial)
	);
};

export const getAtlasPartials = () => {
	return `
		${SearchResultAtlasGoal}
		${SearchResultAtlasProject}
		${SearchDefaultResultAtlas}
	`;
};
