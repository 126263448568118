import React from 'react';

export const JiraProductDiscoverySearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18700)">
			<g clipPath="url(#clip1_2230_18700)">
				<g clipPath="url(#clip2_2230_18700)">
					<path
						d="M22.0686 7.09728C25.7026 8.16332 29.3723 8.16332 33.0063 7.09728C33.3269 7.02621 33.5407 7.23942 33.4694 7.55923C32.4362 11.2904 32.4362 14.7372 33.4694 18.4683C33.5407 18.7526 33.3269 19.0014 33.0063 18.8948C29.3723 17.8998 25.7026 17.8998 22.0686 18.8948C21.7836 19.0014 21.5342 18.7526 21.6411 18.4683C22.603 14.7372 22.603 11.2904 21.6411 7.55923C21.5342 7.23942 21.7836 7.02621 22.0686 7.09728ZM23.5294 20.3161V20.9202C23.5294 23.7275 21.5698 25.7885 19.1472 25.7885C16.7245 25.7885 14.8006 23.8341 14.8006 21.4177C14.8006 19.0014 16.7601 17.0825 19.5034 17.0825H20.216V8.58973C19.8597 8.5542 19.5034 8.5542 19.1472 8.5542C12.0217 8.5542 6.25 14.3108 6.25 21.4532C6.25 28.5957 12.0217 34.3168 19.1472 34.3168C26.2727 34.3168 32.08 28.5602 32.08 21.4532V20.3161H23.5294Z"
						fill="#1868DB"
					/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18700">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18700">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip2_2230_18700">
				<rect width="27.2333" height="27.2333" fill="white" transform="translate(6.25 7.08334)" />
			</clipPath>
		</defs>
	</svg>
);
