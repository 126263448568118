import { type FireAnalyticsEvent } from '../../../common/types';
import { EVENT_SOURCE } from '../../constants/analytics';

import { type CommonAttributes, type NonPrivacySafeAttributes } from './types';

interface OAuthEventProps {
	cloudId: string;
	product: string;
	commonAttributes: CommonAttributes;
	nonPrivacySafeAttributes: NonPrivacySafeAttributes;
	isOnSearchDialog?: boolean;
}

const SEARCH_DIALOG_EVENT_SOURCE = 'searchDialog';

// https://data-portal.internal.atlassian.com/analytics/registry/71499
export const onOAuthStarted = ({
	cloudId,
	product,
	commonAttributes,
	nonPrivacySafeAttributes,
	isOnSearchDialog,
}: OAuthEventProps): FireAnalyticsEvent => {
	return {
		source: isOnSearchDialog ? SEARCH_DIALOG_EVENT_SOURCE : EVENT_SOURCE,
		action: 'clicked',
		actionSubject: 'thirdPartyOAuth',
		attributes: {
			additionalAttributes: {
				cloudId,
				product,
			},
			commonAttributes,
		},
		nonPrivacySafeAttributes,
		eventType: 'track',
	};
};

//https://data-portal.internal.atlassian.com/analytics/registry/71499
export const onOAuthCompleted = ({
	cloudId,
	product,
	commonAttributes,
	nonPrivacySafeAttributes,
	isOnSearchDialog,
}: OAuthEventProps): FireAnalyticsEvent => {
	return {
		source: isOnSearchDialog ? SEARCH_DIALOG_EVENT_SOURCE : EVENT_SOURCE,
		action: 'completed',
		actionSubject: 'thirdPartyOAuth',
		attributes: {
			additionalAttributes: {
				cloudId,
				product,
			},
			commonAttributes,
		},
		nonPrivacySafeAttributes,
		eventType: 'operational',
	};
};
