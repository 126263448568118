import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

export type ConfluencePagesQueryVariables = { pageIds: string[] };

export const pagesCache = inMemoryCache<AggResponse<ConfluencePagesResponse>>();

const GQL_CF_PAGES_OPERATION_NAME = 'SearchPageConfluencePagesQuery';
const getConfluencePagesQuery = () => `
query ${GQL_CF_PAGES_OPERATION_NAME}($pageIds: [ID]!) {
  confluence {
    pages(ids: $pageIds) {
      id
      title
      type
      links {
        base
      }
    }
  }
}
`;

export type ConfluencePage = {
	id: string;
	title: string;
	// On the GraphQL Schema:
	// type: ConfluenceContentType = Content type of the page. Will always be "PAGE".
	type: 'PAGE';
	links: {
		base: string;
	};
};

export type ConfluencePagesResponse = {
	confluence: {
		pages: ConfluencePage[];
	};
};

export const confluencePagesQuery = (pageIds: string[]) => {
	return pagesCache.inMemoryDecorator(
		// TODO: >>>> Limit this to a max string length
		// TODO: Refactor so that it can cache per page id
		pageIds.join(','),
		async () => {
			if (pageIds.length === 0) {
				return {
					data: {
						confluence: {
							pages: [],
						},
					},
				};
			}

			const response = await fetchAgg<ConfluencePagesQueryVariables, ConfluencePagesResponse>({
				graphQuery: getConfluencePagesQuery(),
				variables: { pageIds },
				operationName: GQL_CF_PAGES_OPERATION_NAME,
			});

			const hasErrors = response.errors?.some((error) => error);

			if (hasErrors) {
				return Promise.reject(response);
			}
			return response;
		},
	);
};
