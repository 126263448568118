import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const BoxIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-683.5"
				y="-28.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#0071F7" />
			<path
				d="M19.8589 15.3516C20.0587 15.6312 20.0187 15.9907 19.779 16.1905C19.4994 16.3902 19.0999 16.3502 18.9002 16.1106L17.5021 14.313L16.1439 16.0706C15.9441 16.3502 15.5447 16.3502 15.265 16.1505C14.9854 15.9508 14.9455 15.5913 15.1452 15.3116L16.7431 13.2344L15.1452 11.1571C14.9455 10.8775 15.0254 10.478 15.265 10.2783C15.5447 10.0786 15.9441 10.1585 16.1439 10.3982L17.5021 12.1958L18.9002 10.478C19.0999 10.1984 19.4595 10.1585 19.779 10.3582C20.0587 10.5579 20.0587 10.9574 19.8589 11.237L18.301 13.2743L19.8589 15.3516ZM12.5886 15.1119C11.55 15.1119 10.7111 14.313 10.7111 13.2743C10.7111 12.2757 11.55 11.4368 12.5886 11.4368C13.6272 11.4368 14.4661 12.2757 14.4661 13.2743C14.4261 14.313 13.5873 15.1119 12.5886 15.1119ZM7.07591 15.1119C6.03729 15.1119 5.19841 14.313 5.19841 13.2743C5.19841 12.2757 6.03729 11.4368 7.07591 11.4368C8.11453 11.4368 8.95342 12.2757 8.95342 13.2743C8.95342 14.313 8.11453 15.1119 7.07591 15.1119ZM12.5886 10.2384C11.4301 10.2384 10.3915 10.8775 9.8722 11.8362C9.35289 10.8775 8.31427 10.2384 7.11586 10.2384C6.39682 10.2384 5.75766 10.478 5.23835 10.8376V8.28097C5.23835 7.96139 4.95873 7.68176 4.63915 7.68176C4.27963 7.68176 4 7.96139 4 8.28097V13.3143C4.03995 14.9921 5.39814 16.3103 7.07591 16.3103C8.27432 16.3103 9.31294 15.6312 9.83225 14.6725C10.3516 15.6312 11.3902 16.3103 12.5486 16.3103C14.2664 16.3103 15.6645 14.9521 15.6645 13.2344C15.7045 11.5966 14.3063 10.2384 12.5886 10.2384Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.8413 15.6285C21.066 15.9431 21.0211 16.3476 20.7514 16.5723C20.4368 16.797 19.9874 16.752 19.7627 16.4824L18.1898 14.4601L16.6619 16.4375C16.4371 16.752 15.9877 16.752 15.6732 16.5273C15.3586 16.3026 15.3136 15.8982 15.5383 15.5836L17.336 13.2467L15.5383 10.9098C15.3136 10.5952 15.4035 10.1458 15.6732 9.92112C15.9877 9.69642 16.4371 9.7863 16.6619 10.0559L18.1898 12.0783L19.7627 10.1458C19.9874 9.83124 20.3919 9.7863 20.7514 10.011C21.066 10.2357 21.066 10.6851 20.8413 10.9997L19.0886 13.2916L20.8413 15.6285ZM12.6622 15.3589C11.4937 15.3589 10.55 14.4601 10.55 13.2916C10.55 12.1681 11.4937 11.2244 12.6622 11.2244C13.8306 11.2244 14.7744 12.1681 14.7744 13.2916C14.7294 14.4601 13.7857 15.3589 12.6622 15.3589ZM6.4604 15.3589C5.29196 15.3589 4.34821 14.4601 4.34821 13.2916C4.34821 12.1681 5.29196 11.2244 6.4604 11.2244C7.62885 11.2244 8.5726 12.1681 8.5726 13.2916C8.5726 14.4601 7.62885 15.3589 6.4604 15.3589ZM12.6622 9.87618C11.3589 9.87618 10.1904 10.5952 9.60622 11.6738C9.022 10.5952 7.85355 9.87618 6.50534 9.87618C5.69642 9.87618 4.97737 10.1458 4.39315 10.5503V7.6741C4.39315 7.31458 4.07857 7 3.71904 7C3.31458 7 3 7.31458 3 7.6741V13.3366C3.04494 15.2241 4.57291 16.7071 6.4604 16.7071C7.80861 16.7071 8.97706 15.9431 9.56128 14.8646C10.1455 15.9431 11.314 16.7071 12.6172 16.7071C14.5497 16.7071 16.1226 15.1791 16.1226 13.2467C16.1675 11.4041 14.5946 9.87618 12.6622 9.87618Z"
				fill="#0071F7"
			/>
		</svg>
	);
};
