import React, { type PropsWithChildren, useCallback, useEffect, useRef } from 'react';

import {
	COMMON_TAGS,
	ErrorBoundary as CommonErrorBoundary,
	getSentryClient,
	UNKNOWN_TAG,
} from '@atlassian/search-common';

import { type PrimaryProductKey } from '../../common/constants/products';
import { useBootstrap } from '../store';

export type onError = (error: any) => Promise<void>;

export const logException = async (
	error: any,
	userId?: string | null,
	primaryProduct?: PrimaryProductKey,
) => {
	try {
		const SentryClient = await getSentryClient();

		if (!SentryClient) {
			return;
		}

		SentryClient.withScope((scope) => {
			for (const [key, value] of Object.entries(COMMON_TAGS)) {
				scope.setTag(key, value);
			}
			scope.setUser({ id: userId || UNKNOWN_TAG });
			scope.setTag('product', primaryProduct || UNKNOWN_TAG);
			SentryClient.captureException(error, scope);
		});
	} catch (error) {
		// When there was an error with Sentry, just output to console
		// eslint-disable-next-line no-console
		console.error(error);
	}
};

/**
 * Returns a callback that logs exceptions to Sentry. The callback returned never mutates,
 * hence it is safe to use in dependency arrays.
 */
export const useLogException = () => {
	const [{ primaryProduct, user }] = useBootstrap();

	const primaryProductRef = useRef(primaryProduct);
	const userRef = useRef(user);

	useEffect(() => {
		primaryProductRef.current = primaryProduct;
	}, [primaryProduct]);

	useEffect(() => {
		userRef.current = user;
	}, [user]);

	// Store data required for logging exceptions in refs to prevent re-renders
	return useCallback(
		async (error) => await logException(error, user?.id, primaryProduct),
		[primaryProduct, user?.id],
	) satisfies onError;
};

type ErrorBoundaryProps = {
	fallback?: React.ReactNode;
};

export const ErrorBoundary = (props: PropsWithChildren<ErrorBoundaryProps>) => {
	const onException = useLogException();

	return (
		<CommonErrorBoundary {...props} onException={onException}>
			{props.children}
		</CommonErrorBoundary>
	);
};
