import { getInstance, type ICache } from '../common/cache';

/**
 * An in-memory cache implementation with cache eviction based on expiration by default.
 * Eviction happens when an expired result is retrieved.
 */
export const inMemoryCache = <T>(args?: ICache) => {
	const cacheInstance = getInstance<T>(args);

	const get = (key: string) => (key.length ? cacheInstance.get(key) : undefined);
	const set = (key: string, result: Promise<T>) => {
		if (key.length) {
			cacheInstance.set(key, result);
		}
	};
	const has = (key: string) => cacheInstance.has(key);
	const clear = (key?: string) => cacheInstance.clear(key);

	const inMemoryDecorator = (
		key: string,
		supplier: () => Promise<T>,
		expireWhen?: (result: T) => boolean,
	) => {
		// Check if the key is already in the cache
		const cached = get(key);
		// If the key is in the cache, return the cached value
		if (cached) {
			return cached;
		}
		// If the key is not in the cache, call the supplier function
		const result = supplier();
		set(key, result);
		// If the promise is rejected, clear the cache
		result.catch(() => {
			// If the promise is rejected, clear the cache
			cacheInstance.clear(key);
		});
		// Return the result
		// If the result should be expired, clear the cache
		return result.then((value) => {
			if (expireWhen && expireWhen(value)) {
				cacheInstance.clear(key);
			}
			return value;
		});
	};

	return {
		get,
		set,
		has,
		clear,
		inMemoryDecorator,
	};
};
