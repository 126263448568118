import React from 'react';

export const ConfluenceSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18702)">
			<g clipPath="url(#clip1_2230_18702)">
				<g clipPath="url(#clip2_2230_18702)">
					<path
						d="M32.2106 25.0334C23.981 21.0541 21.5768 20.4595 18.1093 20.4595C14.0407 20.4595 10.5731 22.1519 7.47546 26.9087L6.96689 27.6863C6.55078 28.3267 6.45831 28.5553 6.45831 28.8298C6.45831 29.1042 6.59701 29.3329 7.10559 29.6531L12.33 32.9006C12.6074 33.0835 12.8386 33.175 13.0698 33.175C13.3472 33.175 13.5321 33.0378 13.8095 32.6261L14.6417 31.3454C15.9363 29.3787 17.0921 28.7383 18.5716 28.7383C19.8662 28.7383 21.3919 29.1042 23.2875 30.019L28.7431 32.5804C29.2979 32.8548 29.8989 32.7176 30.1763 32.0773L32.7654 26.4056C33.0428 25.7653 32.8579 25.3536 32.2106 25.0334ZM8.2152 15.0166C16.4448 18.9959 18.849 19.5905 22.3166 19.5905C26.3851 19.5905 29.8527 17.8981 32.9504 13.1413L33.4589 12.3637C33.875 11.7233 33.9675 11.4947 33.9675 11.2202C33.9675 10.9458 33.8288 10.7171 33.3202 10.3969L28.0958 7.14943C27.8184 6.96648 27.5872 6.875 27.3561 6.875C27.0787 6.875 26.8937 7.01222 26.6163 7.42387L25.7841 8.70457C24.4896 10.6713 23.3337 11.3117 21.8542 11.3117C20.5597 11.3117 19.0339 10.9458 17.1384 10.031L11.6827 7.46961C11.1279 7.19517 10.5269 7.33239 10.2495 7.97274L7.6604 13.6444C7.38299 14.2847 7.56793 14.6964 8.2152 15.0166Z"
						fill="#1868DB"
					/>
				</g>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18702">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18702">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip2_2230_18702">
				<rect width="27.5092" height="26.3" fill="white" transform="translate(6.45831 6.875)" />
			</clipPath>
		</defs>
	</svg>
);
