import React from 'react';

// Define a type that covers both function refs and mutable object refs
type Ref<T> = React.Ref<T> | React.MutableRefObject<T | null> | undefined;

// Utility function to merge refs
export const mergeRefs =
	<T>(...refs: Ref<T>[]): React.RefCallback<T> =>
	(node: T | null) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(node);
			} else if (ref != null) {
				(ref as React.MutableRefObject<T | null>).current = node;
			}
		});
	};
