import { fg } from '@atlaskit/platform-feature-flags';
import {
	getOwners,
	smartsRecommendedUserQuery,
	type User,
	usersQuery,
} from '@atlassian/search-client';

import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';

const MAX_SMART_DEFAULT_OPTIONS = 5;
const MAX_SEARCHED_OPTIONS_RESULTS = 5;

const loadRecommendedOwner = async (config: CloudConfig) => {
	const initialUsersQuery = usersQuery([
		...(config.userId ? [config.userId] : []), // The current user should always be the first recommended user
	]);

	const recommendedUserQuery = smartsRecommendedUserQuery({
		aggAbsoluteUrl: config.aggAbsoluteUrl,
		variables: {
			userId: config.userId || '',
			tenantId: config.cloudId || '',
			maxNumberOfResults: MAX_SMART_DEFAULT_OPTIONS,
		},
	});

	const [initialUsersResponse, smartsRecommendedUserResponse] = await Promise.all([
		initialUsersQuery,
		recommendedUserQuery,
	]);

	const recommendedUsersResults = smartsRecommendedUserResponse.data?.smarts.results ?? [];

	const allRecommendedUsers = [
		...(initialUsersResponse.data?.users ?? []),
		...recommendedUsersResults.flatMap((recommendedUsers) =>
			!!recommendedUsers && !!recommendedUsers.user
				? { id: recommendedUsers.id, ...recommendedUsers.user }
				: [],
		),
	] satisfies User[];

	const recommendedUserOptions = allRecommendedUsers.map((user) => ({
		trackingKey: user.accountId,
		value: user.accountId,
		label: user.name,
		avatarUrl: user.picture,
		queryParamValue: user.accountId,
	}));

	return recommendedUserOptions;
};

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence, ProductKeys.Focus];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('owner');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createMultiSelectFilter({
	id: 'owner',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: true,
	universal: false,
	queryParams: {
		key: 'owners',
		fetcher: async (queryParams, _) => {
			const initialUsersReponse = await usersQuery([...queryParams]);

			const allRecommendedUsers = initialUsersReponse.data?.users ?? ([] satisfies User[]);

			const recommendedUserOptions = allRecommendedUsers.map((user) => ({
				trackingKey: user.accountId,
				value: user.accountId,
				label: user.name,
				avatarUrl: user.picture,
				queryParamValue: user.accountId,
			}));

			return recommendedUserOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return loadRecommendedOwner(config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			if (!config.intl) {
				return [];
			}

			const response = await getOwners({
				query,
				cloudId: config.cloudId || '',
				userId: config.userId || '',
				productKey: ProductKeys.Confluence,
				maxNumberOfResults: MAX_SEARCHED_OPTIONS_RESULTS,
				intl: config.intl,
			});

			if (!response) {
				// TODO: log not found somewhere
				return [];
			}

			return response.map((owner) => ({
				trackingKey: owner.id,
				value: owner.id,
				label: owner.name,
				avatarUrl: owner.avatarUrl,
				queryParamValue: owner.id,
			}));
		},
	},
});
