import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	authorizationFail: {
		id: 'oauth-banner.authorization-fail',
		defaultMessage: 'Failed to connect to {product}.',
		description: 'Message displayed when authorization fails.',
	},
	authorizationSuccess: {
		id: 'oauth-banner.authorization-success',
		defaultMessage: 'Account connected to {product}.',
		description: 'Message displayed when authorization is successful.',
	},
	connectThirdPartyProductTitle: {
		id: 'connect_third_party_product_title',
		defaultMessage: 'Your admin has added {product}!',
		description: 'Title shown to prompt users to connect third-party products',
	},
	connectThirdPartyProductTitleAlternate: {
		id: 'connect_third_party_product_title_alternate',
		defaultMessage: '{product} is now available!',
		description:
			'Alternate title shown to prompt users to connect third-party products (not added by admin)',
	},
	connectThirdPartyProductDescription: {
		id: 'connect_third_party_product_description',
		defaultMessage: 'Connect your account to see missing results',
		description: 'Description shown to prompt users to connect third-party products',
	},
	connect: {
		id: 'oauth-message-connect',
		defaultMessage: 'Connect',
		description: 'Message for button to connect the third-party product',
	},
});
