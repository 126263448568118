import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const DropboxSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-23.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#0061FF" />
			<path
				d="M16.0129 10.9983L10.9891 14.2031L16.0129 17.4079L10.9891 20.6128L5.96533 17.3906L10.9891 14.1858L5.96533 10.9983L10.9891 7.79346L16.0129 10.9983ZM10.9631 21.6435L15.9869 18.4387L21.0107 21.6435L15.9869 24.8483L10.9631 21.6435ZM16.0129 17.3906L21.0367 14.1858L16.0129 10.9983L21.0107 7.79346L26.0345 10.9983L21.0107 14.2031L26.0345 17.4079L21.0107 20.6128L16.0129 17.3906Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20.0194 12.2907L12.5097 17.0814L20.0194 21.872L12.5097 26.6627L5 21.8461L12.5097 17.0555L5 12.2907L12.5097 7.5L20.0194 12.2907ZM12.4709 28.2035L19.9806 23.4128L27.4903 28.2035L19.9806 32.9942L12.4709 28.2035ZM20.0194 21.8461L27.5291 17.0555L20.0194 12.2907L27.4903 7.5L35 12.2907L27.4903 17.0814L35 21.872L27.4903 26.6627L20.0194 21.8461Z"
				fill="#0061FF"
			/>
		</svg>
	);
};
