import {
	DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { MiroIcon } from './icon';
import { MiroSearchResultIcon } from './search-result-icon';

export const miroConfig: ThirdPartyConfig = {
	displayName: 'Miro',
	id: 'miro',
	resourceType: 'miro-connector',
	icon: get3PLogo(MiroIcon, 'Miro', 'medium'),
	searchResultIcon: get3PLogo(MiroSearchResultIcon, 'Miro', 'large'),
	integrationAri: 'ari:cloud:platform::integration/miro',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Design,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Design],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
