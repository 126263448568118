import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const MondayIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-268.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="white" />
			<rect
				x="0.375"
				y="0.375"
				width="23.25"
				height="23.25"
				rx="5.625"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="0.75"
			/>
			<path
				d="M6.36594 16.7731C6.03435 16.7738 5.70851 16.6807 5.42172 16.5032C5.13492 16.3257 4.89744 16.0701 4.73354 15.7627C4.57214 15.4574 4.49165 15.111 4.50068 14.7606C4.50972 14.4103 4.60793 14.0691 4.78482 13.7738L8.14648 8.14287C8.31854 7.84024 8.56281 7.59175 8.85436 7.42277C9.14592 7.2538 9.47429 7.1704 9.80595 7.18109C10.1373 7.18956 10.4607 7.2918 10.7429 7.47734C11.0251 7.66289 11.256 7.92507 11.412 8.23707C11.7254 8.86914 11.6862 9.6296 11.3109 10.2229L7.95133 15.8538C7.78097 16.137 7.54634 16.3697 7.2692 16.5304C6.99206 16.6911 6.68137 16.7746 6.36594 16.7731Z"
				fill="#F62B54"
			/>
			<path
				d="M12.1321 16.7728C11.454 16.7728 10.8301 16.3861 10.5025 15.7647C10.3415 15.4602 10.2612 15.1148 10.2702 14.7653C10.2793 14.4159 10.3773 14.0756 10.5538 13.7811L13.9091 8.16313C14.0786 7.85625 14.322 7.60333 14.614 7.43061C14.9061 7.25789 15.2361 7.17165 15.5699 7.18084C16.2537 7.19679 16.8733 7.60475 17.1867 8.2429C17.4979 8.88105 17.4523 9.64607 17.0656 10.2379L13.7111 15.8559C13.5412 16.1378 13.3075 16.3696 13.0316 16.5299C12.7556 16.6901 12.4463 16.7737 12.1321 16.7728Z"
				fill="#FFCC00"
			/>
			<path
				d="M17.7693 16.82C18.7251 16.82 19.4999 16.0108 19.4999 15.0126C19.4999 14.0145 18.7251 13.2053 17.7693 13.2053C16.8134 13.2053 16.0386 14.0145 16.0386 15.0126C16.0386 16.0108 16.8134 16.82 17.7693 16.82Z"
				fill="#00CA72"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.99034 16.9904C5.63664 16.9912 5.28909 16.8981 4.98317 16.7205C4.67725 16.543 4.42393 16.2875 4.24911 15.98C4.07695 15.6747 3.99109 15.3283 4.00073 14.978C4.01037 14.6276 4.11513 14.2865 4.3038 13.9911L7.88959 8.36021C8.07311 8.05759 8.33368 7.80909 8.64467 7.64012C8.95566 7.47115 9.30592 7.38774 9.65969 7.39843C10.0132 7.40691 10.3581 7.50914 10.6591 7.69469C10.9601 7.88023 11.2064 8.14242 11.3728 8.45442C11.7071 9.08649 11.6653 9.84695 11.2649 10.4403L7.68143 16.0712C7.49971 16.3543 7.24944 16.5871 6.95382 16.7478C6.6582 16.9084 6.3268 16.9919 5.99034 16.9904Z"
				fill="#F62B54"
			/>
			<path
				d="M12.1409 16.9902C11.4177 16.9902 10.7522 16.6035 10.4027 15.9821C10.2309 15.6776 10.1453 15.3322 10.1549 14.9827C10.1645 14.6333 10.2691 14.2931 10.4574 13.9985L14.0363 8.38054C14.2172 8.07366 14.4768 7.82074 14.7883 7.64802C15.0998 7.47529 15.4519 7.38906 15.808 7.39825C16.5373 7.4142 17.1982 7.82216 17.5325 8.46031C17.8645 9.09846 17.8159 9.86347 17.4033 10.4553L13.8251 16.0733C13.644 16.3552 13.3947 16.587 13.1004 16.7473C12.806 16.9075 12.476 16.9911 12.1409 16.9902Z"
				fill="#FFCC00"
			/>
			<path
				d="M18.154 17.0374C19.1736 17.0374 20.0001 16.2282 20.0001 15.2301C20.0001 14.2319 19.1736 13.4227 18.154 13.4227C17.1344 13.4227 16.3079 14.2319 16.3079 15.2301C16.3079 16.2282 17.1344 17.0374 18.154 17.0374Z"
				fill="#00CA72"
			/>
		</svg>
	);
};
