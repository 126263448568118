import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const PowerBISearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-115.5"
				y="-502.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="white"
			/>
			<path
				d="M0.513685 8C0.513685 3.86542 3.86542 0.513685 8 0.513685H24C28.1346 0.513685 31.4863 3.86542 31.4863 8V24C31.4863 28.1346 28.1346 31.4863 24 31.4863H8C3.86542 31.4863 0.513685 28.1346 0.513685 24V8Z"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.02737"
			/>
			<path
				d="M23.2042 5.33325H17.5491C17.0629 5.33325 16.6687 5.72743 16.6687 6.21368V25.7862C16.6687 26.2724 17.0629 26.6666 17.5491 26.6666H23.2042C23.6904 26.6666 24.0846 26.2724 24.0846 25.7862V6.21368C24.0846 5.72743 23.6904 5.33325 23.2042 5.33325Z"
				fill="url(#paint0_linear_719_13847)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.7162 25.786V26.6665H13.1807C12.6945 26.6665 12.3003 26.2723 12.3003 25.786V11.53C12.3003 11.0437 12.6945 10.6495 13.1807 10.6495H18.8357C19.322 10.6495 19.7162 11.0437 19.7162 11.53V25.786Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.7162 25.786V26.6665H13.1807C12.6945 26.6665 12.3003 26.2723 12.3003 25.786V11.53C12.3003 11.0437 12.6945 10.6495 13.1807 10.6495H18.8357C19.322 10.6495 19.7162 11.0437 19.7162 11.53V25.786Z"
				fill="url(#paint1_linear_719_13847)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.4159 25.786V26.6664H8.88042C8.39416 26.6664 8 26.2723 8 25.786V16.8802C8 16.3939 8.39416 15.9998 8.88042 15.9998H14.5354C15.0217 15.9998 15.4159 16.3939 15.4159 16.8802V25.786Z"
				fill="url(#paint2_linear_719_13847)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_719_13847"
					x1="20.3414"
					y1="5.30616"
					x2="20.3414"
					y2="26.6395"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBBB14" />
					<stop offset="1" stopColor="#B25400" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_719_13847"
					x1="15.973"
					y1="10.5835"
					x2="15.973"
					y2="26.6004"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9E583" />
					<stop offset="1" stopColor="#DE9800" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_719_13847"
					x1="11.6727"
					y1="15.9727"
					x2="11.6727"
					y2="26.6393"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9E68B" />
					<stop offset="1" stopColor="#F3CD32" />
				</linearGradient>
			</defs>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M30.381 5H22.4286C21.7448 5 21.1905 5.55431 21.1905 6.2381V33.7619C21.1905 34.4457 21.7448 35 22.4286 35H30.381C31.0647 35 31.6191 34.4457 31.6191 33.7619V6.2381C31.6191 5.55431 31.0647 5 30.381 5Z"
				fill="url(#paint0_linear_powerbi)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.4762 33.7619V35H16.2857C15.6019 35 15.0476 34.4457 15.0476 33.7619V13.7143C15.0476 13.0305 15.6019 12.4762 16.2857 12.4762H24.2381C24.9219 12.4762 25.4762 13.0305 25.4762 13.7143V33.7619Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.4762 33.7619V35H16.2857C15.6019 35 15.0476 34.4457 15.0476 33.7619V13.7143C15.0476 13.0305 15.6019 12.4762 16.2857 12.4762H24.2381C24.9219 12.4762 25.4762 13.0305 25.4762 13.7143V33.7619Z"
				fill="url(#paint1_linear_powerbi)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.4286 33.7619V35H10.2381C9.55429 35 9 34.4457 9 33.7619V21.2381C9 20.5543 9.55429 20 10.2381 20H18.1905C18.8743 20 19.4286 20.5543 19.4286 21.2381V33.7619Z"
				fill="url(#paint2_linear_powerbi)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_powerbi"
					x1="26.3553"
					y1="4.9619"
					x2="26.3553"
					y2="34.9619"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EBBB14" />
					<stop offset="1" stopColor="#B25400" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_powerbi"
					x1="20.2124"
					y1="12.3833"
					x2="20.2124"
					y2="34.9071"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9E583" />
					<stop offset="1" stopColor="#DE9800" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_powerbi"
					x1="14.1648"
					y1="19.9619"
					x2="14.1648"
					y2="34.9619"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9E68B" />
					<stop offset="1" stopColor="#F3CD32" />
				</linearGradient>
			</defs>
		</svg>
	);
};
