import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AirtableSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-163.5"
				y="-263.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#0B1228" strokeOpacity="0.14" />
			<path
				d="M14.8596 6.89182L6.90601 10.1829C6.46371 10.366 6.46829 10.9942 6.91336 11.1706L14.9003 14.3379C15.6021 14.6162 16.3836 14.6162 17.0853 14.3379L25.0723 11.1706C25.5172 10.9942 25.5219 10.366 25.0795 10.1829L17.126 6.89182C16.4003 6.59156 15.5852 6.59156 14.8596 6.89182Z"
				fill="#FFBF00"
			/>
			<path
				d="M16.7012 16.0675V23.9798C16.7012 24.3561 17.0807 24.6138 17.4305 24.4751L26.3303 21.0206C26.5335 20.9401 26.6667 20.7437 26.6667 20.5252V12.613C26.6667 12.2366 26.2873 11.9789 25.9375 12.1176L17.0376 15.5721C16.8345 15.6527 16.7012 15.849 16.7012 16.0675Z"
				fill="#26B5F8"
			/>
			<path
				d="M14.6231 16.4758L11.9818 17.7511L11.7136 17.8807L6.13804 20.5522C5.7846 20.7227 5.3335 20.4652 5.3335 20.0725V12.6462C5.3335 12.5042 5.40633 12.3816 5.504 12.2892C5.54476 12.2483 5.59094 12.2147 5.63894 12.188C5.77218 12.1081 5.96222 12.0867 6.12381 12.1506L14.5787 15.5006C15.0085 15.6711 15.0422 16.2733 14.6231 16.4758Z"
				fill="#ED3049"
			/>
			<path
				d="M14.623 16.4758L11.9817 17.7511L5.50391 12.2892C5.54466 12.2483 5.59085 12.2147 5.63884 12.188C5.77209 12.1081 5.96213 12.0867 6.12371 12.1506L14.5786 15.5006C15.0084 15.6711 15.0421 16.2733 14.623 16.4758Z"
				fill="black"
				fillOpacity="0.25"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.3959 7.81668L7.21116 12.4448C6.58917 12.7022 6.59561 13.5857 7.2215 13.8338L18.4531 18.2877C19.44 18.6791 20.539 18.6791 21.5257 18.2877L32.7575 13.8338C33.3832 13.5857 33.3898 12.7022 32.7677 12.4448L21.583 7.81668C20.5626 7.39444 19.4163 7.39444 18.3959 7.81668Z"
				fill="#FFBF00"
			/>
			<path
				d="M20.9858 20.7199V31.8465C20.9858 32.3758 21.5195 32.7381 22.0114 32.5431L34.5268 27.6852C34.8126 27.572 34.9999 27.2959 34.9999 26.9886V15.862C34.9999 15.3328 34.4663 14.9704 33.9744 15.1654L21.4589 20.0233C21.1734 20.1366 20.9858 20.4126 20.9858 20.7199Z"
				fill="#26B5F8"
			/>
			<path
				d="M18.0635 21.2941L14.3492 23.0875L13.972 23.2698L6.13138 27.0266C5.63437 27.2664 5 26.9042 5 26.3521V15.9088C5 15.7091 5.10242 15.5366 5.23977 15.4067C5.29709 15.3492 5.36204 15.3019 5.42953 15.2644C5.6169 15.152 5.88415 15.122 6.11137 15.2119L18.0011 19.9228C18.6054 20.1625 18.6529 21.0094 18.0635 21.2941Z"
				fill="#ED3049"
			/>
			<path
				d="M18.0635 21.2941L14.3492 23.0875L5.23981 15.4067C5.29712 15.3492 5.36207 15.3019 5.42956 15.2644C5.61693 15.152 5.88418 15.122 6.11141 15.2119L18.0011 19.9228C18.6055 20.1625 18.6529 21.0094 18.0635 21.2941Z"
				fill="black"
				fillOpacity="0.25"
			/>
		</svg>
	);
};
