import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const MuralSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-454.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.513685"
				y="0.513685"
				width="30.9726"
				height="30.9726"
				rx="7.48631"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.02737"
			/>
			<path
				d="M16.9007 21.5684C16.9012 18.6846 16.9126 15.8006 16.8987 12.9168C16.8875 10.5931 18.5241 8.58721 20.7437 8.10982C21.0845 8.03652 21.4282 7.98912 21.7782 8.00559C21.8341 8.08874 21.8268 8.18373 21.827 8.27652C21.828 9.11621 21.8282 9.9561 21.8266 10.7958C21.8264 10.8942 21.8407 10.9976 21.7704 11.0832C21.1799 11.1033 20.6993 11.3411 20.3423 11.8138C20.1189 12.1097 19.9992 12.4452 19.9906 12.8178C19.9888 12.8979 19.988 12.9781 19.988 13.0584C19.988 15.8185 19.988 18.5785 19.9876 21.3386C19.9876 21.4186 19.982 21.4985 19.9791 21.5786C19.9191 21.6471 19.8361 21.6453 19.757 21.6457C18.8836 21.6491 18.01 21.6491 17.1365 21.6457C17.052 21.6453 16.9598 21.6535 16.9009 21.5684H16.9007Z"
				fill="#00843F"
			/>
			<path
				d="M26.661 12.8934C26.6626 12.9669 26.6654 13.0404 26.6654 13.1139C26.6656 16.8812 26.6658 20.6482 26.665 24.4155C26.665 24.7003 26.7015 24.6617 26.4232 24.6621C25.5533 24.6637 24.6835 24.6627 23.8135 24.6627C23.7667 24.6627 23.7197 24.6607 23.6729 24.6627C23.602 24.6657 23.5785 24.628 23.5816 24.5635C23.5828 24.5368 23.5816 24.5101 23.5816 24.4832C23.582 20.6289 23.5822 16.7749 23.5826 12.9207C23.5826 12.9141 23.5832 12.9072 23.5834 12.9006C23.6314 12.8058 23.7223 12.8044 23.8083 12.804C24.6813 12.7994 25.5544 12.7996 26.4274 12.8034C26.5157 12.8038 26.6011 12.8163 26.6608 12.8932L26.661 12.8934Z"
				fill="black"
			/>
			<path
				d="M8.4211 12.8779C8.42412 12.9512 8.42954 13.0245 8.42954 13.0978C8.42994 16.8758 8.43014 20.6537 8.42954 24.4316C8.42954 24.7055 8.46007 24.6675 8.19898 24.6679C7.31631 24.6694 6.43364 24.6694 5.55117 24.6669C5.48269 24.6669 5.40235 24.7001 5.34692 24.6256C5.37002 24.5535 5.35375 24.4798 5.35375 24.4071C5.35496 20.6348 5.35476 16.8625 5.35476 13.0904C5.35476 12.8225 5.36902 12.8028 5.63372 12.8016C6.47642 12.798 7.31912 12.7992 8.16183 12.8014C8.25421 12.8016 8.35202 12.7944 8.42131 12.8779H8.4211Z"
				fill="black"
			/>
			<path
				d="M15.1058 24.6466C15.0423 24.6773 14.9742 24.6653 14.9081 24.6655C14.0319 24.6675 13.1555 24.6685 12.2792 24.6695C12.2457 24.6695 12.2123 24.6705 12.1788 24.6693C12.0869 24.666 12.0395 24.6154 12.0364 24.5175C12.0358 24.4974 12.0364 24.4773 12.0364 24.4572C12.0364 20.6576 12.0364 16.8578 12.0366 13.0582C12.0366 12.9982 12.041 12.9381 12.0434 12.8779C12.1125 12.7953 12.2095 12.8014 12.3019 12.801C13.1482 12.7988 13.9945 12.7988 14.8409 12.801C14.9334 12.8012 15.03 12.7962 15.0993 12.8781C15.1019 12.9649 15.107 13.0516 15.107 13.1384C15.1074 15.8653 15.1072 18.5922 15.1078 21.319C15.1078 21.4055 15.0975 21.4929 15.1208 21.5783C15.1602 21.6592 15.16 21.7462 15.16 21.8331C15.1602 22.6812 15.161 23.5294 15.1592 24.3773C15.1592 24.4695 15.1706 24.5661 15.1058 24.6462V24.6466Z"
				fill="#5887FF"
			/>
			<path
				d="M15.0995 12.8785C14.0809 12.8785 13.0623 12.8785 12.0434 12.8783C12.0109 12.5987 11.9645 12.324 11.8329 12.0689C11.4781 11.3811 10.7207 10.989 9.96157 11.1053C9.19438 11.2228 8.58504 11.8289 8.46012 12.6001C8.44526 12.6923 8.43402 12.7851 8.42116 12.8777C7.47061 12.8777 6.52006 12.8773 5.56951 12.8771C5.5428 12.8771 5.51568 12.8793 5.48917 12.8769C5.39719 12.8686 5.34959 12.9034 5.35662 13.0018C5.36044 13.055 5.35702 13.1088 5.35702 13.1625C5.35702 16.9177 5.35702 20.6731 5.35662 24.4283C5.35662 24.4946 5.3504 24.5609 5.34678 24.6263C5.32348 24.5743 5.33734 24.5203 5.33734 24.4681C5.33634 20.6681 5.33594 16.8679 5.33694 13.0679C5.33694 12.3802 5.42491 11.7052 5.69844 11.0686C6.42286 9.38336 7.68892 8.34805 9.49864 8.06086C12.0402 7.65738 14.4117 9.29559 14.9959 11.8052C15.0778 12.1573 15.1282 12.5148 15.0993 12.8785H15.0995Z"
				fill="#FF4B4B"
			/>
			<path
				d="M26.6612 12.8944C26.4214 12.8918 26.1816 12.8872 25.9418 12.8868C25.2284 12.8858 24.5153 12.8862 23.8019 12.8868C23.729 12.8868 23.6551 12.8791 23.5838 12.902C23.5714 12.7026 23.5517 12.506 23.4896 12.313C23.2697 11.6295 22.6039 11.108 21.8693 11.0951C21.8361 11.0945 21.8032 11.0881 21.7703 11.0843C21.7739 11.0445 21.7805 11.0047 21.7805 10.965C21.7801 9.97867 21.7791 8.99257 21.7783 8.00647C24.4687 7.97293 26.701 10.2255 26.6612 12.8946V12.8944Z"
				fill="#FC83FF"
			/>
			<path
				d="M15.1058 24.6473C15.1058 23.6975 15.1058 22.7478 15.1064 21.7982C15.1064 21.7251 15.0953 21.651 15.1208 21.5793C15.7141 21.5759 16.3074 21.5725 16.9006 21.5691C16.9669 21.5727 17.0334 21.5793 17.0997 21.5793C18.0595 21.5799 19.019 21.5795 19.9788 21.5793C19.9819 21.6458 19.9877 21.7125 19.9877 21.779C19.9883 22.6606 19.9881 23.5425 19.9881 24.4242C19.9881 24.471 19.9865 24.5177 19.9877 24.5643C19.9893 24.6298 19.9606 24.6642 19.8933 24.6634C19.8465 24.663 19.7997 24.6644 19.7531 24.6644C18.2768 24.6644 16.8006 24.6644 15.3243 24.6642C15.2512 24.6642 15.1771 24.6736 15.106 24.6471L15.1058 24.6473Z"
				fill="#FFAA00"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21.2668 27.0807C21.2674 23.0254 21.2835 18.9697 21.264 14.9144C21.2482 11.6468 23.5497 8.82592 26.671 8.1546C27.1503 8.05151 27.6335 7.98486 28.1258 8.00802C28.2043 8.12494 28.1941 8.25853 28.1944 8.38901C28.1958 9.56982 28.1961 10.7509 28.1938 11.9317C28.1936 12.0701 28.2136 12.2156 28.1148 12.3359C27.2844 12.3641 26.6086 12.6985 26.1064 13.3633C25.7924 13.7794 25.6241 14.2513 25.6119 14.7752C25.6094 14.8879 25.6082 15.0006 25.6082 15.1135C25.6082 18.9949 25.6082 22.8762 25.6077 26.7576C25.6077 26.87 25.5998 26.9824 25.5958 27.0951C25.5114 27.1914 25.3947 27.1889 25.2835 27.1894C24.0552 27.1942 22.8266 27.1942 21.5984 27.1894C21.4795 27.1889 21.3499 27.2004 21.2671 27.0807H21.2668Z"
				fill="#00843F"
			/>
			<path
				d="M34.9921 14.8817C34.9943 14.985 34.9983 15.0884 34.9983 15.1918C34.9986 20.4895 34.9989 25.7869 34.9977 31.0846C34.9977 31.4851 35.0491 31.4309 34.6577 31.4314C33.4345 31.4337 32.2113 31.4323 30.9879 31.4323C30.9221 31.4323 30.856 31.4294 30.7902 31.4323C30.6905 31.4365 30.6574 31.3834 30.6617 31.2927C30.6634 31.2552 30.6617 31.2176 30.6617 31.1798C30.6622 25.7598 30.6625 20.3401 30.6631 14.9201C30.6631 14.9107 30.6639 14.9011 30.6642 14.8918C30.7317 14.7585 30.8597 14.7565 30.9805 14.756C32.2082 14.7495 33.4359 14.7498 34.6636 14.7551C34.7879 14.7557 34.9079 14.7732 34.9918 14.8814L34.9921 14.8817Z"
				fill="black"
			/>
			<path
				d="M9.34229 14.8601C9.34653 14.9632 9.35415 15.0662 9.35415 15.1693C9.35472 20.482 9.355 25.7947 9.35415 31.1074C9.35415 31.4926 9.39708 31.4392 9.02993 31.4398C7.78868 31.4417 6.54742 31.4417 5.30645 31.4384C5.21014 31.4384 5.09718 31.485 5.01923 31.3802C5.0517 31.2788 5.02883 31.1751 5.02883 31.0729C5.03052 25.7681 5.03024 20.4634 5.03024 15.1589C5.03024 14.7821 5.05029 14.7544 5.42253 14.7527C6.60758 14.7477 7.79263 14.7494 8.97768 14.7525C9.1076 14.7527 9.24514 14.7426 9.34258 14.8601H9.34229Z"
				fill="black"
			/>
			<path
				d="M18.7424 31.4098C18.6532 31.453 18.5575 31.436 18.4645 31.4363C17.2323 31.4392 15.9998 31.4406 14.7676 31.442C14.7204 31.442 14.6736 31.4434 14.6264 31.4417C14.4972 31.437 14.4305 31.3658 14.4262 31.2282C14.4253 31.1999 14.4262 31.1717 14.4262 31.1435C14.4262 25.8003 14.4262 20.4568 14.4264 15.1136C14.4264 15.0292 14.4327 14.9447 14.436 14.86C14.5332 14.7439 14.6696 14.7524 14.7995 14.7518C15.9897 14.7487 17.1798 14.7487 18.3699 14.7518C18.5001 14.7521 18.636 14.7451 18.7334 14.8603C18.7371 14.9823 18.7441 15.1043 18.7441 15.2263C18.7447 19.0611 18.7444 22.8958 18.7453 26.7303C18.7453 26.852 18.7309 26.9749 18.7636 27.0949C18.819 27.2087 18.8187 27.331 18.8187 27.4533C18.819 28.646 18.8201 29.8387 18.8176 31.031C18.8176 31.1607 18.8337 31.2965 18.7424 31.4092V31.4098Z"
				fill="#5887FF"
			/>
			<path
				d="M18.7338 14.8605C17.3014 14.8605 15.8689 14.8605 14.4362 14.8603C14.3904 14.4671 14.3252 14.0808 14.1402 13.7221C13.6412 12.7548 12.5761 12.2035 11.5086 12.367C10.4297 12.5322 9.57283 13.3846 9.39717 14.4691C9.37627 14.5987 9.36045 14.7292 9.34237 14.8594C8.00566 14.8594 6.66895 14.8588 5.33223 14.8586C5.29467 14.8586 5.25654 14.8617 5.21926 14.8583C5.08991 14.8467 5.02298 14.8956 5.03287 15.034C5.03823 15.1088 5.03343 15.1845 5.03343 15.2599C5.03343 20.5407 5.03343 25.8217 5.03287 31.1025C5.03287 31.1957 5.02411 31.2889 5.01903 31.3809C4.98627 31.3078 5.00575 31.2318 5.00575 31.1584C5.00434 25.8146 5.00378 20.4706 5.00519 15.1269C5.00519 14.1598 5.12889 13.2106 5.51355 12.3153C6.53225 9.94552 8.31266 8.48962 10.8576 8.08575C14.4317 7.51836 17.7665 9.8221 18.5881 13.3513C18.7033 13.8464 18.7742 14.3491 18.7335 14.8605H18.7338Z"
				fill="#FF4B4B"
			/>
			<path
				d="M34.9922 14.8828C34.655 14.8792 34.3178 14.8727 33.9805 14.8721C32.9774 14.8707 31.9745 14.8712 30.9713 14.8721C30.8688 14.8721 30.7649 14.8614 30.6646 14.8936C30.6471 14.6131 30.6194 14.3366 30.5322 14.0652C30.2229 13.1041 29.2867 12.3707 28.2536 12.3526C28.207 12.3517 28.1606 12.3427 28.1143 12.3373C28.1194 12.2814 28.1287 12.2255 28.1287 12.1696C28.1282 10.7826 28.1267 9.39589 28.1256 8.00919C31.909 7.96202 35.0481 11.1297 34.9922 14.8831V14.8828Z"
				fill="#FC83FF"
			/>
			<path
				d="M18.7425 31.4104C18.7425 30.0748 18.7425 28.7392 18.7433 27.4039C18.7433 27.3011 18.7278 27.1969 18.7637 27.0961C19.598 27.0913 20.4322 27.0865 21.2665 27.0817C21.3597 27.0867 21.4532 27.0961 21.5464 27.0961C22.8961 27.0969 24.2455 27.0964 25.5952 27.0961C25.5995 27.1896 25.6077 27.2833 25.6077 27.3768C25.6085 28.6166 25.6082 29.8568 25.6082 31.0966C25.6082 31.1624 25.606 31.2282 25.6077 31.2937C25.6099 31.3858 25.5695 31.4341 25.4749 31.433C25.4091 31.4324 25.3433 31.4344 25.2778 31.4344C23.2017 31.4344 21.1259 31.4344 19.0498 31.4341C18.947 31.4341 18.8428 31.4474 18.7428 31.4101L18.7425 31.4104Z"
				fill="#FFAA00"
			/>
		</svg>
	);
};
