import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const OutlookMailSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-71.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<path
				d="M22.9285 7.74731H12.8944C12.4449 7.74731 12.075 8.11336 12.075 8.55822V9.47993L17.7305 11.2127L23.7479 9.47993V8.55822C23.7479 8.11336 23.378 7.74731 22.9285 7.74731Z"
				fill="#0364B8"
			/>
			<path
				d="M24.7109 16.6444C24.7964 16.3785 24.8647 16.1075 24.9152 15.8329C24.9153 15.6955 24.8411 15.5683 24.7208 15.4996L24.7133 15.4956L24.7109 15.4945L18.3853 11.9286C18.358 11.9111 18.3297 11.8951 18.3007 11.8806C18.0558 11.7605 17.7676 11.7605 17.5227 11.8806C17.4936 11.8951 17.4654 11.911 17.438 11.9286L11.1125 15.4945L11.1101 15.4956L11.1025 15.4996C10.9823 15.5683 10.9081 15.6955 10.9082 15.8329C10.9587 16.1075 11.027 16.3785 11.1125 16.6444L17.8197 21.4987L24.7109 16.6444Z"
				fill="#0A2767"
			/>
			<path
				d="M20.2461 9.47986H16.1606L14.981 11.2126L16.1606 12.9453L20.2461 16.4106H23.748V12.9453L20.2461 9.47986Z"
				fill="#28A8EA"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.075 9.47992H16.1605V12.9452H12.075V9.47992Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.2461 9.47992H23.748V12.9452H20.2461V9.47992Z"
				fill="#50D9FF"
			/>
			<path
				d="M20.2461 16.4106L16.1605 12.9453H12.075V16.4106L16.1605 19.876L22.4826 20.8972L20.2461 16.4106Z"
				fill="#0364B8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.1606 12.9453H20.2462V16.4106H16.1606V12.9453Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.075 16.4109H16.1605V19.8762H12.075V16.4109Z"
				fill="#064A8C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.2461 16.4109H23.748V19.8762H20.2461V16.4109Z"
				fill="#0078D4"
			/>
			<path
				d="M18.0228 21.1571L11.1392 16.1901L11.4275 15.6882C11.4275 15.6882 17.6988 19.2228 17.7945 19.276C17.8738 19.3075 17.9629 19.305 18.0403 19.269C18.1295 19.2194 24.4207 15.6709 24.4207 15.6709L24.7102 16.1728L18.0228 21.1571Z"
				fill="#0A2767"
				fillOpacity="0.498039"
			/>
			<path
				d="M24.7207 16.1663L24.7132 16.1709L24.7114 16.1721L18.3858 19.738C18.1306 19.9008 17.8073 19.9207 17.5337 19.7905L19.7369 22.7135L24.5544 23.7514V23.7538C24.7815 23.5912 24.9163 23.33 24.9163 23.0526V15.8331C24.9163 15.9704 24.8421 16.0978 24.7219 16.1663H24.7207Z"
				fill="#1490DF"
			/>
			<path
				d="M24.9149 23.0525V22.6263L19.0883 19.3411L18.385 19.7373C18.1299 19.9002 17.8066 19.9202 17.533 19.7898L19.7362 22.7129L24.5537 23.7508V23.7531C24.7808 23.5905 24.9155 23.3294 24.9155 23.0519L24.9149 23.0525Z"
				fill="black"
				fillOpacity="0.047059"
			/>
			<path
				d="M24.8858 23.274L18.4989 19.6735L18.385 19.7376C18.1299 19.9004 17.8066 19.9204 17.533 19.7901L19.7362 22.7132L24.5537 23.7511V23.7534C24.7171 23.6361 24.8347 23.4665 24.8863 23.2734L24.8858 23.274Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M11.1118 16.1739V16.1681H11.106L11.0884 16.1566C10.9751 16.0876 10.9064 15.9647 10.9075 15.8331V23.0538C10.9075 23.5284 11.3022 23.919 11.7819 23.919H24.0397C24.1126 23.9182 24.1852 23.9086 24.2556 23.8901C24.2922 23.8838 24.3276 23.8721 24.3607 23.8555C24.3731 23.8542 24.385 23.8502 24.3957 23.8439C24.4434 23.8245 24.4886 23.7993 24.5299 23.7688L24.5533 23.7514L11.1118 16.1739Z"
				fill="#28A8EA"
			/>
			<path
				d="M16.744 20.8389V11.4056C16.7427 10.9837 16.3924 10.637 15.966 10.6357H12.0929V14.942L11.1117 15.4953L11.1094 15.4965L11.1019 15.5005C10.9817 15.5692 10.9075 15.6965 10.9075 15.8337V21.6111V21.6087H15.966C16.3924 21.6075 16.7427 21.2607 16.744 20.8389Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M16.1604 21.4162V11.9829C16.1591 11.5611 15.8087 11.2144 15.3824 11.213H12.0929V14.9417L11.1117 15.495L11.1094 15.4962L11.1019 15.5002C10.9817 15.569 10.9075 15.6963 10.9075 15.8334V22.1884V22.1861H15.3824C15.8087 22.1848 16.1591 21.8381 16.1604 21.4162ZM16.1604 20.2611V11.9829C16.1591 11.5611 15.8087 11.2144 15.3824 11.213H12.0929V14.9417L11.1117 15.495L11.1094 15.4962L11.1019 15.5002C10.9817 15.569 10.9075 15.6963 10.9075 15.8334V21.0332V21.031H15.3824C15.8087 21.0297 16.1591 20.683 16.1604 20.2611ZM15.5767 20.2611V11.9829C15.5754 11.5611 15.2251 11.2144 14.7988 11.213H12.0929V14.9417L11.1117 15.495L11.1094 15.4962L11.1019 15.5002C10.9817 15.569 10.9075 15.6963 10.9075 15.8334V21.0332V21.031H14.7988C15.2251 21.0297 15.5754 20.683 15.5767 20.2611Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M7.0163 11.2128H14.7981C15.2249 11.2128 15.5761 11.5603 15.5761 11.9826V19.6832C15.5761 20.1056 15.2249 20.4531 14.7981 20.4531H7.0163C6.58951 20.4531 6.23828 20.1056 6.23828 19.6832V11.9826C6.23828 11.5603 6.58951 11.2128 7.0163 11.2128Z"
				fill="#0078D4"
			/>
			<path
				d="M8.49517 14.3708C8.7019 13.9348 9.03573 13.57 9.45352 13.3231C9.91648 13.0608 10.4436 12.9302 10.9769 12.9454C11.4707 12.9347 11.9582 13.0586 12.3858 13.3035C12.7881 13.5406 13.1122 13.8884 13.3184 14.3044C13.5431 14.7628 13.6551 15.2672 13.6453 15.7766C13.6562 16.309 13.5409 16.8366 13.3086 17.317C13.0978 17.7477 12.7639 18.1081 12.3484 18.3531C11.9042 18.6058 11.3986 18.733 10.8864 18.721C10.3818 18.7329 9.88354 18.6076 9.44597 18.3588C9.04048 18.1213 8.71252 17.7732 8.50162 17.3562C8.27553 16.9044 8.16219 16.4054 8.17128 15.9014C8.16126 15.3735 8.27222 14.8503 8.49577 14.3708H8.49517ZM9.51652 16.83C9.6268 17.1057 9.81384 17.3449 10.0559 17.5196C10.3022 17.6903 10.5975 17.7784 10.898 17.7709C11.2183 17.7834 11.534 17.6926 11.7974 17.5122C12.0365 17.3374 12.2186 17.097 12.321 16.8208C12.4361 16.513 12.4929 16.1867 12.4885 15.8586C12.4921 15.5276 12.4388 15.1982 12.331 14.8849C12.2359 14.6027 12.0603 14.3537 11.8255 14.1681C11.569 13.9786 11.2541 13.8823 10.9343 13.8955C10.6272 13.8876 10.3253 13.9762 10.0722 14.1485C9.82564 14.3238 9.63444 14.565 9.52119 14.8439C9.27073 15.4823 9.26929 16.1907 9.51711 16.83H9.51652Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M31.807 6.875H15.6906C14.9685 6.875 14.3744 7.46914 14.3744 8.19118V9.68723L23.4581 12.4997L33.1232 9.68723V8.19118C33.1232 7.46914 32.529 6.875 31.807 6.875Z"
				fill="#0364B8"
			/>
			<path
				d="M34.6699 21.3163C34.8073 20.8847 34.9169 20.4448 34.998 19.9992C34.9982 19.7762 34.879 19.5696 34.6859 19.4582L34.6738 19.4517L34.6699 19.4499L24.5099 13.6621C24.4661 13.6337 24.4207 13.6077 24.3741 13.5842C23.9807 13.3892 23.5179 13.3892 23.1244 13.5842C23.0778 13.6077 23.0324 13.6336 22.9885 13.6621L12.8286 19.4499L12.8248 19.4517L12.8125 19.4582C12.6195 19.5696 12.5004 19.7762 12.5005 19.9992C12.5816 20.4448 12.6913 20.8847 12.8286 21.3163L23.6016 29.1954L34.6699 21.3163Z"
				fill="#0A2767"
			/>
			<path
				d="M27.4985 9.68726H20.9364L19.0418 12.4998L20.9364 15.312L27.4985 20.9366H33.1231V15.312L27.4985 9.68726Z"
				fill="#28A8EA"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.3745 9.68726H20.9365V15.3118H14.3745V9.68726Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.4987 9.68726H33.1233V15.3118H27.4987V9.68726Z"
				fill="#50D9FF"
			/>
			<path
				d="M27.4986 20.9365L20.9365 15.312H14.3745V20.9365L20.9365 26.5613L31.0909 28.2186L27.4986 20.9365Z"
				fill="#0364B8"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.9366 15.312H27.4987V20.9365H20.9366V15.312Z"
				fill="#0078D4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.3745 20.9366H20.9365V26.5612H14.3745V20.9366Z"
				fill="#064A8C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.4987 20.9366H33.1233V26.5612H27.4987V20.9366Z"
				fill="#0078D4"
			/>
			<path
				d="M23.9279 28.6405L12.8717 20.5785L13.3348 19.7639C13.3348 19.7639 23.4076 25.501 23.5612 25.5873C23.6886 25.6384 23.8318 25.6343 23.956 25.576C24.0993 25.4954 34.204 19.7358 34.204 19.7358L34.669 20.5504L23.9279 28.6405Z"
				fill="#0A2767"
				fillOpacity="0.498039"
			/>
			<path
				d="M34.6859 20.5401L34.6738 20.5476L34.6709 20.5496L24.511 26.3374C24.1011 26.6016 23.5818 26.634 23.1423 26.4226L26.6811 31.167L34.4188 32.8516V32.8554C34.7836 32.5916 35.0001 32.1676 35 31.7173V19.9993C35.0001 20.2221 34.8808 20.4289 34.6878 20.5401H34.6859Z"
				fill="#1490DF"
			/>
			<path
				d="M34.9981 31.7173V31.0255L25.6396 25.6933L24.51 26.3364C24.1002 26.6007 23.5809 26.6331 23.1414 26.4216L26.6802 31.1661L34.4179 32.8506V32.8544C34.7826 32.5904 34.9991 32.1666 34.9991 31.7163L34.9981 31.7173Z"
				fill="black"
				fillOpacity="0.047059"
			/>
			<path
				d="M34.9513 32.0763L24.6928 26.2323L24.51 26.3363C24.1002 26.6006 23.5809 26.6331 23.1414 26.4216L26.6802 31.1661L34.4179 32.8506V32.8544C34.6804 32.664 34.8692 32.3887 34.9521 32.0753L34.9513 32.0763Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M12.8276 20.5524V20.543H12.8183L12.7901 20.5243C12.6082 20.4124 12.4977 20.2129 12.4995 19.9993V31.7192C12.4995 32.4896 13.1334 33.1235 13.9039 33.1235H33.5921C33.7091 33.1223 33.8257 33.1066 33.9388 33.0766C33.9976 33.0664 34.0545 33.0474 34.1077 33.0204C34.1276 33.0184 34.1467 33.012 34.1638 33.0016C34.2405 32.9702 34.313 32.9293 34.3794 32.8797L34.4169 32.8516L12.8276 20.5524Z"
				fill="#28A8EA"
			/>
			<path
				d="M21.8739 28.1241V12.8129C21.8719 12.1281 21.3091 11.5653 20.6243 11.5632H14.4035V18.5527L12.8275 19.4508L12.8239 19.4527L12.8117 19.4592C12.6186 19.5708 12.4995 19.7774 12.4995 20.0001V29.3774V29.3736H20.6243C21.3091 29.3716 21.8719 28.8087 21.8739 28.1241Z"
				fill="black"
				fillOpacity="0.098039"
			/>
			<path
				d="M20.9365 29.0614V13.7502C20.9345 13.0656 20.3716 12.5028 19.6869 12.5006H14.4035V18.5527L12.8275 19.4508L12.8239 19.4527L12.8117 19.4592C12.6186 19.5708 12.4995 19.7774 12.4995 20.0001V30.3149V30.3111H19.6869C20.3716 30.309 20.9345 29.7462 20.9365 29.0614ZM20.9365 27.1866V13.7502C20.9345 13.0656 20.3716 12.5028 19.6869 12.5006H14.4035V18.5527L12.8275 19.4508L12.8239 19.4527L12.8117 19.4592C12.6186 19.5708 12.4995 19.7774 12.4995 20.0001V28.4399V28.4362H19.6869C20.3716 28.4342 20.9345 27.8714 20.9365 27.1866ZM19.999 27.1866V13.7502C19.997 13.0656 19.4342 12.5028 18.7495 12.5006H14.4035V18.5527L12.8275 19.4508L12.8239 19.4527L12.8117 19.4592C12.6186 19.5708 12.4995 19.7774 12.4995 20.0001V28.4399V28.4362H18.7495C19.4342 28.4342 19.997 27.8714 19.999 27.1866Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M6.24964 12.4999H18.7485C19.434 12.4999 19.9981 13.0639 19.9981 13.7494V26.2483C19.9981 26.9338 19.434 27.498 18.7485 27.498H6.24964C5.56414 27.498 5 26.9338 5 26.2483V13.7494C5 13.0639 5.56414 12.4999 6.24964 12.4999Z"
				fill="#0078D4"
			/>
			<path
				d="M8.62511 17.6256C8.95716 16.918 9.49334 16.3258 10.1644 15.9251C10.908 15.4994 11.7547 15.2874 12.6112 15.3121C13.4044 15.2946 14.1874 15.4957 14.8741 15.8932C15.5203 16.2781 16.0408 16.8426 16.3721 17.5179C16.7329 18.2619 16.9129 19.0806 16.8971 19.9074C16.9147 20.7715 16.7293 21.6279 16.3562 22.4076C16.0177 23.1067 15.4815 23.6916 14.8141 24.0894C14.1007 24.4994 13.2885 24.706 12.4658 24.6865C11.6554 24.7057 10.8551 24.5024 10.1522 24.0986C9.50097 23.7131 8.9742 23.1481 8.63547 22.4713C8.27234 21.7379 8.09029 20.9281 8.10488 20.1099C8.08879 19.2531 8.26702 18.4038 8.62606 17.6256H8.62511ZM10.2656 21.6172C10.4427 22.0646 10.7431 22.4529 11.1319 22.7365C11.5275 23.0136 12.0017 23.1565 12.4845 23.1444C12.9988 23.1647 13.506 23.0173 13.9291 22.7244C14.3131 22.4407 14.6056 22.0505 14.7701 21.6022C14.955 21.1026 15.0461 20.5731 15.0391 20.0405C15.0448 19.5032 14.9592 18.9686 14.786 18.46C14.6333 18.0021 14.3513 17.5979 13.9741 17.2967C13.5622 16.9891 13.0564 16.8328 12.5427 16.8542C12.0495 16.8414 11.5646 16.9851 11.1581 17.2648C10.7621 17.5494 10.455 17.9409 10.2731 18.3935C9.87079 19.4297 9.86847 20.5795 10.2665 21.6172H10.2656Z"
				fill="white"
			/>
		</svg>
	);
};
