import type {
	Maybe,
	SearchResult,
	SearchResultSlackMessage,
	ThirdPartyRestrictionLevel,
} from '@atlassian/search-client';

export interface SearchResultGraphDocument extends SearchResult {
	excerpt: string;
	permissionLevel: Maybe<ThirdPartyRestrictionLevel>;
	subtype: string;
	integrationId: string;
	lastModifiedDate: string;
	initialContributors: {
		name: string;
	}[];
}

const GOOGLE_INTEGRATION_ARI = 'ari:cloud:platform::integration/google';
export const isGoogleDocument = (partial: SearchResult) =>
	partial.__typename === 'SearchResultGoogleDocument' ||
	(isGraphDocument(partial) &&
		partial.integrationId === GOOGLE_INTEGRATION_ARI &&
		partial.subtype === 'document');

export const isGraphDocument = (partial: SearchResult): partial is SearchResultGraphDocument =>
	partial.__typename === 'SearchResultGraphDocument';

export const isSlackMessage = (partial: SearchResult): partial is SearchResultSlackMessage =>
	partial.__typename === 'SearchResultSlackMessage';
