import React, { useState } from 'react';

import { useIntl } from 'react-intl-next';

import LinkIcon from '@atlaskit/icon/core/link';
import SuccessIcon from '@atlaskit/icon/core/success';
import { Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { i18n } from './messages';

const TIMEOUT_DELAY = 2500;

export type CopyLinkComponentProps = {
	onClick: () => void;
	isCopied: boolean;
	testId?: string;
};

const cursorDisabledStyles = xcss({
	cursor: 'default',
});

const buttonStyles = xcss({
	borderRadius: 'border.radius.100',
	height: `${24 / 14}em`,
	width: `${24 / 14}em`,
	paddingInlineEnd: 'space.0',
	paddingInlineStart: 'space.0',
	':hover': {
		background: token('color.background.neutral'),
	},
	':active': {
		background: token('color.background.neutral.hovered'),
	},
	':disabled': {
		background: 'transparent',
	},
});

export type CopyLinkProps = {
	showSuccess?: boolean;
	fullResultUrl?: string | (() => string);
	isButton?: boolean;
	testId?: string;
	onClick?: () => void;
};

export const CopyLinkButton = ({
	showSuccess,
	fullResultUrl,
	isButton,
	testId,
	onClick,
}: CopyLinkProps) => {
	const { formatMessage } = useIntl();
	const [isCopied, setIsCopied] = useState<boolean>(false);
	const [tooltipLabel, setTooltipLabel] = useState(formatMessage(i18n.copyLinkTooltipLabel));
	const updateTooltip = React.useRef<() => void>();

	React.useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [tooltipLabel]);

	const copyToClipboard = async () => {
		// Call the onClick callback if it exists
		onClick?.();

		if (!fullResultUrl) {
			return;
		}
		await navigator.clipboard.writeText(
			typeof fullResultUrl === 'function' ? fullResultUrl() : fullResultUrl,
		);

		if (showSuccess) {
			setIsCopied(true);
			setTooltipLabel(formatMessage(i18n.copiedLinkTooltipLabel));
			setTimeout(() => {
				setIsCopied(false);
				setTooltipLabel(formatMessage(i18n.copyLinkTooltipLabel));
			}, TIMEOUT_DELAY);
		}
	};

	return (
		<Tooltip
			content={({ update }) => {
				updateTooltip.current = update;
				return tooltipLabel;
			}}
			position="top"
		>
			<Pressable
				isDisabled={isCopied}
				onClick={copyToClipboard}
				testId={testId}
				aria-label={formatMessage(i18n.copyLinkButtonLabel)}
				backgroundColor="color.background.neutral.subtle"
				xcss={[isButton && buttonStyles, isCopied && cursorDisabledStyles]}
			>
				{isCopied ? (
					<SuccessIcon
						label={formatMessage(i18n.successIconLabel)}
						color={token('color.icon.accent.green')}
					/>
				) : (
					<LinkIcon label={formatMessage(i18n.linkIconLabel)} color={token('color.icon.subtle')} />
				)}
			</Pressable>
		</Tooltip>
	);
};
