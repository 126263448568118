import {
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
} from '../../entities';
import { NounSubtypeKey, TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GoogleDriveIcon } from './icon';
import { GoogleDriveSearchResultIcon } from './search-result-icon';

// static 3P config object for google drive
export const googleDriveConfig: ThirdPartyConfig = {
	displayName: 'Google Drive',
	id: 'drive',
	resourceType: 'google-drive-connector',
	icon: get3PLogo(GoogleDriveIcon, 'Google Drive', 'medium'),
	searchResultIcon: get3PLogo(GoogleDriveSearchResultIcon, 'Google Drive', 'large'),
	integrationAri: 'ari:cloud:platform::integration/google',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Document,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Document],
			},
		],
		[
			TypeFilterValueKey.Spreadsheet,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Spreadsheet],
			},
		],
		[
			TypeFilterValueKey.Presentation,
			{
				entities3P: [
					...DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
					...DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				],
				subTypes3P: [NounSubtypeKey.Presentation],
			},
		],
	]),
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => true,
};
