import { type Maybe } from '../../../../../common/types';
import { type AggRequest, type AggResponse, fetchAgg } from '../../../../../common/utils/fetch-agg';
import { generateHashFromVariables } from '../../../../../utils/caching/common/key-generator';
import { inMemoryCache } from '../../../../../utils/caching/in-memory';

const operationName = 'FPSConfluenceSmartUsersQuery';
const SmartsRecommendedUserQuery = `
query ${operationName}($userId: String!, $tenantId: String!, $maxNumberOfResults: Int!) {
  smarts {
    results: recommendedUser(
      recommendationsQuery: {
        context: {
          userId: $userId
          tenantId: $tenantId
          product: "confluence"
        },
        modelRequestParams: {
          caller: "full-page-search"
          experience: "CgUserNearbyUser"
        }
        requestingUserId: $userId
        maxNumberOfResults: $maxNumberOfResults
      }
    ) {
      id
      user {
        accountId
        name
        picture
      }
    }
  }
}
`;

export type SmartsRecommendedUser = {
	id: string;
	user: Maybe<{
		accountId: string;
		name: string;
		picture: string;
	}>;
};

export type SmartsRecommendedUserQueryVariables = {
	userId: string;
	tenantId: string;
	maxNumberOfResults: number;
};

export type SmartsRecommendedUserQueryData = {
	smarts: {
		results: Maybe<Array<SmartsRecommendedUser>>;
	};
};

export const cache = inMemoryCache<AggResponse<SmartsRecommendedUserQueryData>>();

// Expire the cache if there are errors or edges are empty
const expireWhen = (result: AggResponse<SmartsRecommendedUserQueryData>) => {
	const { data, errors } = result;
	if (!data || (errors && errors.length > 0)) {
		return true;
	}
	const { results } = data.smarts;
	return !results || results.length === 0;
};

export type SmartsRecommendedUserQueryProps = Pick<
	AggRequest<SmartsRecommendedUserQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
>;

export const smartsRecommendedUserQuery = ({
	variables,
	aggAbsoluteUrl,
}: SmartsRecommendedUserQueryProps) =>
	cache.inMemoryDecorator(
		generateHashFromVariables(variables),
		() =>
			fetchAgg<SmartsRecommendedUserQueryVariables, SmartsRecommendedUserQueryData>({
				variables,
				aggAbsoluteUrl,
				graphQuery: SmartsRecommendedUserQuery,
				operationName,
			}),
		expireWhen,
	);
