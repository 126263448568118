import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const GitlabSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" fill="#F5F5F5" />
			<rect
				x="-115.5"
				y="-358.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect
				x="0.508324"
				y="0.508324"
				width="30.9834"
				height="30.9834"
				rx="7.49168"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.01665"
			/>
			<path
				d="M26.3107 13.8597L26.2819 13.783L23.3785 6.20936C23.3197 6.06065 23.2151 5.9345 23.0799 5.84916C22.979 5.78457 22.8644 5.74452 22.7452 5.73221C22.626 5.7199 22.5056 5.73568 22.3937 5.77829C22.2817 5.82089 22.1813 5.88914 22.1004 5.97755C22.0196 6.06597 21.9606 6.1721 21.9281 6.28743L19.9683 12.2875H12.0317L10.0718 6.28743C10.0391 6.17227 9.97998 6.06634 9.89909 5.9781C9.8182 5.88985 9.71782 5.82172 9.60593 5.77915C9.49405 5.73658 9.37377 5.72074 9.25468 5.7329C9.13559 5.74505 9.02099 5.78487 8.92002 5.84916C8.78479 5.9345 8.68022 6.06065 8.62145 6.20936L5.71935 13.7844L5.68922 13.8597C5.2714 14.9517 5.21993 16.1499 5.54256 17.2737C5.86519 18.3974 6.54443 19.3858 7.47787 20.0899L7.48882 20.0981L7.51348 20.1173L11.9303 23.4275L14.1216 25.0833L15.4528 26.0899C15.609 26.2079 15.7994 26.2717 15.9952 26.2717C16.1909 26.2717 16.3813 26.2079 16.5375 26.0899L17.8687 25.0833L20.06 23.4275L24.5084 20.0981L24.5207 20.0885C25.4541 19.3847 26.1334 18.3966 26.4563 17.2732C26.7791 16.1497 26.728 14.9517 26.3107 13.8597Z"
				fill="#E24329"
			/>
			<path
				d="M26.3105 13.8598L26.2817 13.7831C24.8671 14.0734 23.5342 14.6729 22.3785 15.5389L16.0032 20.3597L20.0626 23.4275L24.5109 20.0981L24.5232 20.0885C25.4561 19.3844 26.1349 18.3962 26.4573 17.2727C26.7796 16.1492 26.7281 14.9514 26.3105 13.8598Z"
				fill="#FC6D26"
			/>
			<path
				d="M11.9302 23.4277L14.1215 25.0835L15.4527 26.0901C15.6089 26.2081 15.7993 26.2719 15.995 26.2719C16.1908 26.2719 16.3812 26.2081 16.5374 26.0901L17.8686 25.0835L20.0599 23.4277L16.0005 20.3599L11.9302 23.4277Z"
				fill="#FCA326"
			/>
			<path
				d="M9.62123 15.5387C8.46595 14.6732 7.13347 14.0741 5.71935 13.7843L5.68922 13.8596C5.2714 14.9516 5.21993 16.1498 5.54256 17.2736C5.86519 18.3973 6.54443 19.3857 7.47787 20.0898L7.48882 20.098L7.51348 20.1172L11.9303 23.4274L15.9924 20.3596L9.62123 15.5387Z"
				fill="#FC6D26"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M34.4996 16.435L34.4591 16.3272L30.3761 5.6767C30.2935 5.46758 30.1464 5.29018 29.9562 5.17018C29.8144 5.07934 29.6532 5.02302 29.4856 5.00571C29.318 4.9884 29.1487 5.01059 28.9912 5.07051C28.8337 5.13042 28.6925 5.22639 28.5788 5.35073C28.4651 5.47506 28.3821 5.6243 28.3365 5.78648L25.5805 14.2241H14.4196L11.6636 5.78648C11.6176 5.62454 11.5345 5.47558 11.4207 5.35149C11.307 5.22739 11.1658 5.13159 11.0085 5.07172C10.8511 5.01186 10.682 4.98958 10.5145 5.00668C10.347 5.02377 10.1859 5.07976 10.0439 5.17018C9.85372 5.29018 9.70667 5.46758 9.62403 5.6767L5.54295 16.3291L5.50058 16.435C4.91302 17.9706 4.84064 19.6556 5.29434 21.2359C5.74803 22.8162 6.70322 24.2061 8.01587 25.1962L8.03127 25.2077L8.06594 25.2347L14.2771 29.8897L17.3586 32.2182L19.2307 33.6337C19.4503 33.7997 19.7181 33.8894 19.9933 33.8894C20.2686 33.8894 20.5364 33.7997 20.756 33.6337L22.628 32.2182L25.7095 29.8897L31.965 25.2077L31.9823 25.1942C33.2949 24.2046 34.2502 22.8151 34.7042 21.2352C35.1583 19.6553 35.0864 17.9706 34.4996 16.435Z"
				fill="#E24329"
			/>
			<path
				d="M34.4996 16.4351L34.4591 16.3272C32.4698 16.7354 30.5954 17.5786 28.9702 18.7963L20.0049 25.5756L25.7134 29.8897L31.9689 25.2077L31.9862 25.1943C33.2981 24.2041 34.2526 22.8144 34.706 21.2345C35.1593 19.6546 35.0868 17.9702 34.4996 16.4351Z"
				fill="#FC6D26"
			/>
			<path
				d="M14.2771 29.8897L17.3586 32.2182L19.2306 33.6337C19.4503 33.7996 19.718 33.8894 19.9933 33.8894C20.2686 33.8894 20.5363 33.7996 20.756 33.6337L22.628 32.2182L25.7095 29.8897L20.001 25.5756L14.2771 29.8897Z"
				fill="#FCA326"
			/>
			<path
				d="M11.03 18.7963C9.40535 17.5792 7.53156 16.7367 5.54295 16.3291L5.50058 16.4351C4.91302 17.9706 4.84064 19.6556 5.29434 21.2359C5.74803 22.8162 6.70322 24.2062 8.01587 25.1962L8.03127 25.2077L8.06594 25.2347L14.2771 29.8897L19.9895 25.5756L11.03 18.7963Z"
				fill="#FC6D26"
			/>
		</svg>
	);
};
