import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const SmartsheetIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-539.5"
				y="-171.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="24" height="24" rx="6" fill="#292A2E" />
			<path
				d="M12.2708 12.9524C12.0781 12.5707 11.8938 12.2011 11.6762 11.8499C11.4003 11.4042 11.1009 10.9773 10.6919 10.6414C10.3847 10.3891 10.0444 10.2293 9.63382 10.2899C9.27961 10.3422 8.99393 10.5287 8.73676 10.7652C8.60099 10.8901 8.47697 11.0271 8.36318 11.1923C8.42475 11.2006 8.47588 11.2054 8.52636 11.2146C8.8921 11.2813 9.17343 11.4943 9.4269 11.7487C9.85292 12.1767 10.1699 12.6838 10.4539 13.2121C11.0905 14.3964 11.5574 15.6505 11.9569 16.9316C11.9608 16.944 11.9643 16.9564 11.9732 16.9859C13.5754 13.1534 15.383 9.45358 17.8734 6.12915L17.9021 6.13874C17.9021 6.22486 17.9021 6.31098 17.9021 6.39688C17.9021 9.19064 17.9052 11.9844 17.9002 14.7784C17.8986 15.6202 17.8858 16.4624 17.8233 17.3027C17.8027 17.5811 17.7742 17.8593 17.6754 18.1242C17.5601 18.4333 17.3395 18.5127 17.0594 18.338C16.8218 18.1898 16.6373 17.9825 16.4822 17.754C16.1504 17.2654 15.831 16.7687 15.6093 16.2167C15.5952 16.1814 15.5917 16.1009 15.5142 16.1761C15.009 16.6673 14.4222 17.0471 13.8187 17.4003C12.0839 18.4152 10.2293 19.1317 8.28355 19.6187C7.58535 19.7934 6.88193 19.9416 6.1735 20.0672C6.10497 20.0794 6.08734 20.0753 6.10192 19.997C6.19656 19.4857 6.23986 18.9697 6.23986 18.449C6.23856 14.0349 6.23964 9.6208 6.23638 5.20669C6.23638 5.09615 6.26314 5.07239 6.37128 5.07239C10.1708 5.07544 13.9701 5.075 17.7696 5.075H17.9017C15.5708 7.38279 13.6026 9.93061 12.2715 12.9526L12.2708 12.9524Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.2752 13.0096C12.0792 12.6216 11.8919 12.246 11.6708 11.8889C11.3903 11.436 11.086 11.0021 10.6703 10.6606C10.358 10.4042 10.0121 10.2417 9.59481 10.3033C9.23477 10.3565 8.9444 10.546 8.68299 10.7864C8.54499 10.9134 8.41894 11.0526 8.30327 11.2206C8.36586 11.229 8.41783 11.2339 8.46914 11.2432C8.8409 11.311 9.12685 11.5275 9.38449 11.7861C9.81751 12.2211 10.1397 12.7366 10.4283 13.2736C11.0754 14.4773 11.55 15.752 11.9561 17.0542C11.96 17.0668 11.9636 17.0795 11.9726 17.1094C13.6012 13.2139 15.4386 9.45323 17.9699 6.07414L17.9991 6.08389C17.9991 6.17142 17.9991 6.25896 17.9991 6.34627C17.9991 9.18597 18.0022 12.0257 17.9971 14.8656C17.9955 15.7212 17.9825 16.5773 17.919 17.4314C17.898 17.7144 17.869 17.9971 17.7686 18.2664C17.6514 18.5806 17.4272 18.6613 17.1425 18.4838C16.901 18.3331 16.7135 18.1224 16.5558 17.8901C16.2186 17.3935 15.8939 16.8887 15.6686 16.3275C15.6542 16.2916 15.6506 16.2099 15.5719 16.2863C15.0584 16.7856 14.4619 17.1717 13.8485 17.5307C12.0852 18.5623 10.2001 19.2905 8.22233 19.7855C7.51265 19.963 6.79766 20.1137 6.07759 20.2414C6.00793 20.2538 5.99001 20.2496 6.00483 20.17C6.10103 19.6504 6.14504 19.1258 6.14504 18.5966C6.14371 14.1099 6.14482 9.62321 6.1415 5.13651C6.1415 5.02415 6.1687 5 6.27862 5C10.1406 5.0031 14.0024 5.00266 17.8644 5.00266H17.9986C15.6294 7.34839 13.6289 9.93811 12.2758 13.0098L12.2752 13.0096Z"
				fill="#143158"
			/>
		</svg>
	);
};
