import React from 'react';

export const LoomSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M35 18.43H25.8611L33.7754 13.86L32.2059 11.14L24.2906 15.71L28.859 7.795L26.14 6.225L21.5705 14.138V5H18.4295V14.139L13.86 6.225L11.1399 7.795L15.7084 15.708L7.79507 11.14L6.22459 13.86L14.139 18.43H5V21.571H14.138L6.22459 26.141L7.79407 28.861L15.7094 24.291L11.1399 32.206L13.859 33.776L18.4295 25.862V35H21.5705V25.666L26.2239 33.726L28.7741 32.254L24.1196 24.1921L32.2039 28.86L33.7744 26.14L25.8611 21.57H34.999V18.43H35ZM20 24.27C19.4393 24.2701 18.884 24.1597 18.3661 23.9451C17.8481 23.7305 17.3773 23.416 16.9808 23.0194C16.5843 22.6229 16.2697 22.1521 16.0551 21.6339C15.8405 21.1157 15.73 20.5604 15.7299 19.9996C15.7298 19.4386 15.8402 18.8832 16.0547 18.365C16.2693 17.8468 16.5836 17.3759 16.9801 16.9793C17.3765 16.5827 17.8471 16.268 18.3652 16.0533C18.8831 15.8386 19.4383 15.7281 19.999 15.728C21.1313 15.7279 22.2174 16.1777 23.0182 16.9786C23.819 17.7796 24.269 18.8658 24.2691 19.9985C24.2692 21.1312 23.8196 22.2176 23.0189 23.0187C22.2183 23.8199 21.1323 24.2699 20 24.27Z"
			fill="#5753F3"
		/>
	</svg>
);
