import { defineMessages } from 'react-intl-next';

import type { i18nType } from '../../../../../common/types';

type LastUpdatedLabelsType = Record<string, i18nType>;

export const lastUpdatedLabels: LastUpdatedLabelsType = defineMessages({
	anytime: {
		id: 'search-page.filter.last.updated.anytime',
		defaultMessage: 'Anytime',
		description: 'Last updated filter anytime option label',
	},
	today: {
		id: 'search-page.filter.last.updated.today',
		defaultMessage: 'Today',
		description: 'Last updated filter today option label',
	},
	yesterday: {
		id: 'search-page.filter.last.updated.yesterday',
		defaultMessage: 'Yesterday',
		description: 'Last updated filter yesterday option label',
	},
	past7Days: {
		id: 'search-page.filter.last.updated.past7Days',
		defaultMessage: 'Past 7 days',
		description: 'Last updated filter past 7 days option label',
	},
	past30Days: {
		id: 'search-page.filter.last.updated.past30Days',
		defaultMessage: 'Past 30 days',
		description: 'Last updated filter past 30 days option label',
	},
	pastYear: {
		id: 'search-page.filter.last.updated.pastYear',
		defaultMessage: 'Past year',
		description: 'Last updated filter past year option label',
	},
	custom: {
		id: 'search-page.filter.last.updated.costum',
		defaultMessage: 'Custom',
		description: 'Last updated filter custom option label',
	},
});
