import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const SlackSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-598.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="white"
			/>
			<path
				d="M0.513685 8C0.513685 3.86542 3.86542 0.513685 8 0.513685H24C28.1346 0.513685 31.4863 3.86542 31.4863 8V24C31.4863 28.1346 28.1346 31.4863 24 31.4863H8C3.86542 31.4863 0.513685 28.1346 0.513685 24V8Z"
				stroke="#0B1228"
				strokeOpacity="0.14"
				strokeWidth="1.02737"
			/>
			<g clipPath="url(#clip0_719_13847)">
				<path
					d="M10.6616 18.5983C10.6616 19.7479 9.7325 20.677 8.58289 20.677C7.43328 20.677 6.50415 19.7479 6.50415 18.5983C6.50415 17.4487 7.43328 16.5195 8.58289 16.5195H10.6616V18.5983ZM11.701 18.5983C11.701 17.4487 12.6301 16.5195 13.7797 16.5195C14.9293 16.5195 15.8585 17.4487 15.8585 18.5983V23.7951C15.8585 24.9447 14.9293 25.8739 13.7797 25.8739C12.6301 25.8739 11.701 24.9447 11.701 23.7951V18.5983Z"
					fill="#E01E5A"
				/>
				<path
					d="M13.7796 10.2517C12.63 10.2517 11.7009 9.32258 11.7009 8.17298C11.7009 7.02337 12.63 6.09424 13.7796 6.09424C14.9292 6.09424 15.8584 7.02337 15.8584 8.17298V10.2517H13.7796ZM13.7796 11.3068C14.9292 11.3068 15.8584 12.236 15.8584 13.3856C15.8584 14.5352 14.9292 15.4643 13.7796 15.4643H8.56702C7.41742 15.4643 6.48828 14.5352 6.48828 13.3856C6.48828 12.236 7.41742 11.3068 8.56702 11.3068H13.7796Z"
					fill="#36C5F0"
				/>
				<path
					d="M22.1104 13.3856C22.1104 12.236 23.0396 11.3068 24.1892 11.3068C25.3388 11.3068 26.2679 12.236 26.2679 13.3856C26.2679 14.5352 25.3388 15.4643 24.1892 15.4643H22.1104V13.3856ZM21.0711 13.3856C21.0711 14.5352 20.1419 15.4643 18.9923 15.4643C17.8427 15.4643 16.9136 14.5352 16.9136 13.3856V8.17298C16.9136 7.02337 17.8427 6.09424 18.9923 6.09424C20.1419 6.09424 21.0711 7.02337 21.0711 8.17298V13.3856Z"
					fill="#2EB67D"
				/>
				<path
					d="M18.9923 21.7164C20.1419 21.7164 21.0711 22.6455 21.0711 23.7951C21.0711 24.9447 20.1419 25.8739 18.9923 25.8739C17.8427 25.8739 16.9136 24.9447 16.9136 23.7951V21.7164H18.9923ZM18.9923 20.677C17.8427 20.677 16.9136 19.7479 16.9136 18.5983C16.9136 17.4487 17.8427 16.5195 18.9923 16.5195H24.2049C25.3545 16.5195 26.2837 17.4487 26.2837 18.5983C26.2837 19.7479 25.3545 20.677 24.2049 20.677H18.9923Z"
					fill="#ECB22E"
				/>
			</g>
			<defs>
				<clipPath id="clip0_719_13847">
					<rect width="20" height="20" fill="white" transform="translate(6.37817 6)" />
				</clipPath>
			</defs>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.9995 5C14.3411 5.00122 12.999 6.34436 13.0002 7.99942C12.999 9.65449 14.3423 10.9976 16.0007 10.9988H19.0012V8.00065C19.0025 6.34558 17.6591 5.00245 15.9995 5C16.0007 5 16.0007 5 15.9995 5V5ZM15.9995 13.0001H8.00053C6.34215 13.0013 4.99878 14.3444 5 15.9995C4.99755 17.6546 6.34092 18.9977 7.9993 19.0002H15.9995C17.6579 18.9989 19.0012 17.6558 19 16.0007C19.0012 14.3444 17.6579 13.0013 15.9995 13.0001Z"
				fill="#36C5F0"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M35.0002 15.9995C35.0014 14.3444 33.658 13.0013 31.9996 13.0001C30.3413 13.0013 28.9979 14.3444 28.9991 15.9995V19.0002H31.9996C33.658 18.9989 35.0014 17.6558 35.0002 15.9995ZM27 15.9995V7.99942C27.0012 6.34558 25.659 5.00245 24.0007 5C22.3423 5.00122 20.9989 6.34436 21.0001 7.99942V15.9995C20.9977 17.6546 22.3411 18.9977 23.9994 19.0002C25.6578 18.9989 27.0012 17.6558 27 15.9995Z"
				fill="#2EB67D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.9996 35.0001C25.658 34.9989 27.0014 33.6558 27.0002 32.0007C27.0014 30.3456 25.658 29.0025 23.9996 29.0013H20.9991V32.0007C20.9979 33.6545 22.3412 34.9977 23.9996 35.0001ZM23.9996 26.9988H31.9998C33.6582 26.9976 35.0016 25.6544 35.0004 23.9993C35.0028 22.3443 33.6594 21.0011 32.0011 20.9987H24.0008C22.3425 20.9999 20.9991 22.3431 21.0003 23.9981C20.9991 25.6544 22.3412 26.9976 23.9996 26.9988Z"
				fill="#ECB22E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.00031 24.0003C4.99908 25.6554 6.34246 26.9985 8.00084 26.9997C9.65922 26.9985 11.0026 25.6554 11.0014 24.0003V21.0009H8.00084C6.34246 21.0021 4.99908 22.3452 5.00031 24.0003ZM13.0005 24.0003V32.0004C12.998 33.6555 14.3414 34.9986 15.9998 35.0011C17.6582 34.9998 19.0016 33.6567 19.0003 32.0016V24.0027C19.0028 22.3477 17.6594 21.0045 16.001 21.0021C14.3414 21.0021 12.9993 22.3452 13.0005 24.0003C13.0005 24.0015 13.0005 24.0003 13.0005 24.0003Z"
				fill="#E01E5A"
			/>
		</svg>
	);
};
