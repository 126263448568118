import type { SearchState } from '../constants/search';

export const getFetchFromCursor = (state: SearchState) => {
	return state.paginationTokens[0]?.cursor;
};

export const getSelectedProduct = (state: Pick<SearchState, 'selectedProducts'>) =>
	state.selectedProducts[0];

export const getProducts = ({
	selectedProducts,
	availableProducts,
}: Pick<SearchState, 'selectedProducts' | 'availableProducts'>) => {
	const selectedProduct = selectedProducts?.[0];
	const filteredProducts = selectedProduct ? [selectedProduct] : availableProducts;
	return {
		selectedProduct,
		filteredProducts,
		availableProducts,
	};
};
