import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const PipedriveIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-635.5"
				y="-411.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#017737"
			/>
			<path
				d="M10.2336 10.1635C10.2336 11.5838 10.9541 13.1159 12.5396 13.1159C13.7154 13.1159 14.9043 12.198 14.9043 10.1427C14.9043 8.34075 13.9697 7.13106 12.5787 7.13106C11.4453 7.13106 10.2336 7.92728 10.2336 10.1635ZM13.1373 4.5C15.9802 4.5 17.8917 6.75159 17.8917 10.1032C17.8917 13.4022 15.878 15.7053 12.9993 15.7053C11.6268 15.7053 10.7476 15.1175 10.2879 14.6919C10.2912 14.7928 10.2933 14.9058 10.2933 15.0264V19.5H7.34834V7.58072C7.34834 7.40743 7.29292 7.3526 7.12122 7.3526H6.1084V4.74238H8.5796C9.71739 4.74238 10.0086 5.32145 10.0641 5.76782C10.5259 5.25017 11.4833 4.5 13.1373 4.5Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.4002 10.0411C10.4002 11.556 11.1687 13.1903 12.8599 13.1903C14.1142 13.1903 15.3823 12.2112 15.3823 10.0189C15.3823 8.0968 14.3854 6.80647 12.9017 6.80647C11.6927 6.80647 10.4002 7.65577 10.4002 10.0411ZM13.4975 4C16.5299 4 18.5688 6.4017 18.5688 9.97675C18.5688 13.4957 16.4209 15.9523 13.3503 15.9523C11.8862 15.9523 10.9485 15.3253 10.4582 14.8714C10.4616 14.979 10.4639 15.0995 10.4639 15.2282V20H7.32261V7.2861C7.32261 7.10126 7.26349 7.04278 7.08034 7.04278H6V4.25854H8.63594C9.84959 4.25854 10.1602 4.87621 10.2194 5.35234C10.712 4.80018 11.7332 4 13.4975 4Z"
				fill="#017737"
			/>
		</svg>
	);
};
