import React, { forwardRef, type PropsWithChildren, useCallback } from 'react';

import { type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Anchor, type AnchorProps } from '@atlaskit/primitives';

import { useAppContext } from '../app-context';

export const Link = forwardRef<HTMLAnchorElement, AnchorProps>(
	(props: PropsWithChildren<AnchorProps>, ref) => {
		const { onNavigate } = useAppContext();

		const { href, onClick: originalOnClick } = props;

		const onClick = useCallback(
			(
				event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
				analyticsEvent: UIAnalyticsEvent,
			) => {
				originalOnClick?.(event, analyticsEvent);

				// Use onNavigate if
				// - alwaysUseOnLinkClick is true
				// - navigating to the same site and not new tab/window
				const metaPressed = event.ctrlKey || event.metaKey || event.shiftKey;
				const sameOrigin = window.location.origin === new URL(href).origin;
				const onNavigateOverride =
					typeof onNavigate === 'object' ? onNavigate.options.alwaysUseOnLinkClick : false;

				const useOnNavigate = onNavigateOverride || (sameOrigin && !metaPressed);

				if (useOnNavigate) {
					event.preventDefault();

					// Handles SPA navigation if consumer provides their own onNavigate
					const onNavigateCallback =
						typeof onNavigate === 'function' ? onNavigate : onNavigate.callback;
					onNavigateCallback(href, 'push');
				}
			},
			[href, onNavigate, originalOnClick],
		);

		return <Anchor ref={ref} {...props} onClick={onClick} />;
	},
);
