import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const MiroSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-19.5"
				y="-454.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#FFDD33" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.0164 7.33325H17.4798L19.5936 11.0472L14.9432 7.33325H12.4067L14.7319 11.8727L9.87008 7.33325H7.3335L9.87008 13.1114L7.3335 24.6666H9.87008L14.7319 12.286L12.4067 24.6666H14.9432L19.5936 11.4605L17.4798 24.6666H20.0164L24.6668 10.2218L20.0164 7.33325Z"
				fill="black"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5 12.5C5 8.35786 8.35786 5 12.5 5H27.5C31.6422 5 35 8.35786 35 12.5V27.5C35 31.6422 31.6422 35 27.5 35H12.5C8.35786 35 5 31.6422 5 27.5V12.5Z"
				fill="#FFDD33"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.9447 10.7188H22.2214L24.4908 14.7061L19.4981 10.7188H16.7748L19.2712 15.5923L14.0515 10.7188H11.3281L14.0515 16.9223L11.3281 29.3281H14.0515L19.2712 16.0361L16.7748 29.3281H19.4981L24.4908 15.1498L22.2214 29.3281H24.9447L29.9375 13.8199L24.9447 10.7188Z"
				fill="black"
			/>
		</svg>
	);
};
