import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { HubSpotFilterIcon } from './icon';
import { HubSpotIcon } from './search-result-icon';
export const hubSpotConfig: ThirdPartyConfig = {
	displayName: 'HubSpot',
	id: 'hubspot',
	resourceType: 'smartlinks-connector-hubspot',
	icon: RovoLogoFromGlyph(HubSpotFilterIcon, 'HubSpot'),
	searchResultIcon: RovoLogoFromGlyph(HubSpotIcon, 'HubSpot'),
	integrationAri: 'ari:cloud:platform::integration/hubspot',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Deal,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
