import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	generatingSummaryText: {
		id: 'ai-summary.stream-loading.body',
		defaultMessage: 'Atlassian Intelligence is working...',
		description: 'Text for loading state of streaming',
	},
	generatingSummaryAnimationAlt: {
		id: 'ai-summary.stream-loading.generating-summary-animation',
		defaultMessage: 'Generating summary',
		description: 'Alt text of the generating icon',
	},
});
