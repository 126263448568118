import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const PipedriveSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-163.5"
				y="-406.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#017737"
			/>
			<path
				d="M13.7389 13.5678C13.7389 15.4746 14.7062 17.5315 16.8349 17.5315C18.4135 17.5315 20.0096 16.2991 20.0096 13.5398C20.0096 11.1206 18.7549 9.49657 16.8874 9.49657C15.3657 9.49657 13.7389 10.5655 13.7389 13.5678ZM17.6373 5.96423C21.454 5.96423 24.0203 8.98711 24.0203 13.4868C24.0203 17.9158 21.3169 21.0079 17.452 21.0079C15.6093 21.0079 14.429 20.2187 13.8119 19.6474C13.8163 19.7829 13.8192 19.9345 13.8192 20.0965V26.1025H9.86537V10.1003C9.86537 9.86761 9.79096 9.79399 9.56045 9.79399H8.20068V6.28964H11.5184C13.0459 6.28964 13.4369 7.06707 13.5113 7.66635C14.1314 6.97137 15.4168 5.96423 17.6373 5.96423Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.2504 16.3271C16.2504 19.1676 17.6914 22.2318 20.8624 22.2318C23.2141 22.2318 25.5918 20.3959 25.5918 16.2854C25.5918 12.6815 23.7226 10.2621 20.9406 10.2621C18.6737 10.2621 16.2504 11.8546 16.2504 16.3271ZM22.0578 5C27.7435 5 31.5666 9.50318 31.5666 16.2064C31.5666 22.8043 27.5392 27.4106 21.7818 27.4106C19.0367 27.4106 17.2784 26.2349 16.359 25.3839C16.3656 25.5857 16.3699 25.8116 16.3699 26.0529V35H10.4799V11.1614C10.4799 10.8149 10.369 10.7052 10.0256 10.7052H8V5.48476H12.9424C15.218 5.48476 15.8005 6.6429 15.9113 7.53564C16.835 6.50033 18.7498 5 22.0578 5Z"
				fill="#017737"
			/>
		</svg>
	);
};
