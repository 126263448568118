import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const LucidSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-115.5"
				y="-454.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="#292A2E" />
			<path
				d="M8.0143 18.1416C8.0143 15.4564 8.02145 12.772 8.00572 10.0868C8.00358 9.70682 8.11652 9.49577 8.45677 9.30326C10.6727 8.0491 12.8737 6.76856 15.0796 5.49657C15.5192 5.24274 15.5414 5.25415 15.5414 5.75039C15.5414 11.0487 15.5414 16.347 15.5414 21.646C15.5414 21.7529 15.5485 21.8606 15.5407 21.9668C15.5214 22.2363 15.6164 22.2813 15.8459 22.1465C16.5336 21.7437 17.2262 21.3487 17.9167 20.9508C19.4629 20.0596 21.0091 19.1676 22.5559 18.2771C22.9226 18.066 22.9641 18.0895 22.9641 18.5216C22.9655 21.0043 22.9655 23.4876 22.9641 25.9703C22.9641 26.4309 22.9369 26.4573 22.4802 26.458C20.2885 26.4616 18.0969 26.4566 15.9059 26.4723C15.5507 26.4751 15.4263 26.3739 15.4334 26.0088C15.4549 24.9636 15.442 23.9183 15.4406 22.8723C15.4406 22.4994 15.4334 22.4923 15.1053 22.6812C12.8872 23.9561 10.6713 25.2352 8.45248 26.5093C8.33525 26.5764 8.21588 26.7304 8.07291 26.6377C7.95568 26.5621 8.01501 26.3945 8.01501 26.2683C8.01287 23.5597 8.01287 20.851 8.01287 18.1423L8.0143 18.1416Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.0201 23.0117C10.0201 19.2357 10.0302 15.4607 10.008 11.6847C10.005 11.1503 10.1639 10.8535 10.6423 10.5828C13.7585 8.81916 16.8536 7.0184 19.9557 5.22966C20.5739 4.87272 20.6051 4.88876 20.6051 5.58661C20.6051 13.0373 20.6051 20.488 20.6051 27.9398C20.6051 28.0902 20.6151 28.2416 20.6041 28.391C20.5769 28.77 20.7106 28.8331 21.0333 28.6436C22.0003 28.0771 22.9744 27.5217 23.9454 26.9622C26.1197 25.7089 28.294 24.4545 30.4693 23.2022C30.985 22.9054 31.0433 22.9385 31.0433 23.5461C31.0453 27.0374 31.0453 30.5296 31.0433 34.0209C31.0433 34.6686 31.0051 34.7057 30.3627 34.7067C27.2807 34.7117 24.1987 34.7047 21.1177 34.7267C20.6181 34.7307 20.4432 34.5884 20.4533 34.075C20.4834 32.6051 20.4653 31.1352 20.4633 29.6643C20.4633 29.1399 20.4533 29.1299 19.9919 29.3956C16.8727 31.1884 13.7565 32.9871 10.6363 34.7789C10.4715 34.8731 10.3036 35.0897 10.1025 34.9593C9.93768 34.8531 10.0211 34.6174 10.0211 34.44C10.0181 30.6309 10.0181 26.8218 10.0181 23.0127L10.0201 23.0117Z"
				fill="#222C35"
			/>
		</svg>
	);
};
