import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { DEFAULT_GAS_CHANNEL, EVENT_SOURCE } from '../../common/constants/analytics';
import { type FireAnalyticsEvent, type FireAnalyticsScreenEvent } from '../../common/types';
import {
	useAnalyticsAttributes,
	useApplicationMode,
	useAvailableProducts,
	useExperimentConfig,
	useInputQuery,
	useQueryUpdatedTime,
	useResultsSort,
	useSearchQuery,
	useSearchSessionId,
	useSelectedProduct,
} from '../store';
import { useFilterAttributesForTracking, useResultsRequestId } from '../store/search';

import { getAttributes } from './utils';

export const useAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// analytics attributes passed in from the product
	const [analyticsAttributes] = useAnalyticsAttributes();

	// use this hook to fire UI, track, and operational event types
	const fireAnalyticsEvent = useCallback(
		(payload: FireAnalyticsEvent) => {
			createAnalyticsEvent({
				...payload,
				source: payload.source || EVENT_SOURCE,
				attributes: {
					...payload.attributes.commonAttributes,
					...payload.attributes.additionalAttributes,
					...analyticsAttributes,
					isFullPageSearch: true,
				},
			}).fire(DEFAULT_GAS_CHANNEL);
		},
		[analyticsAttributes, createAnalyticsEvent],
	);

	// use this hook to fire screen event types
	const fireAnalyticsScreenEvent = useCallback(
		(payload: FireAnalyticsScreenEvent) => {
			createAnalyticsEvent({
				...payload,
				eventType: 'screen',
				source: EVENT_SOURCE,
				attributes: {
					...payload.attributes.commonAttributes,
					...payload.attributes.additionalAttributes,
					...analyticsAttributes,
					isFullPageSearch: true,
				},
			}).fire(DEFAULT_GAS_CHANNEL);
		},
		[analyticsAttributes, createAnalyticsEvent],
	);

	return { fireAnalyticsEvent, fireAnalyticsScreenEvent };
};

export const useCommonAndPrivateAttributes = () => {
	const [searchSessionId] = useSearchSessionId();
	const [requestId] = useResultsRequestId();
	const [searchQuery] = useSearchQuery();
	const [inputQuery] = useInputQuery();
	const [queryUpdatedTime] = useQueryUpdatedTime();
	const [productSelected] = useSelectedProduct();
	const availableProducts = useAvailableProducts();
	const [sort] = useResultsSort();
	const [experimentConfig] = useExperimentConfig();
	const filters = useFilterAttributesForTracking();
	const applicationMode = useApplicationMode();

	return getAttributes({
		searchSessionId,
		requestId,
		searchQuery,
		inputQuery,
		filters,
		queryUpdatedTime,
		productSelected,
		availableProducts,
		sort,
		experimentConfig,
		applicationMode,
	});
};
