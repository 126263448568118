import { defineMessages } from 'react-intl-next';

import type { PageStatusFilterValueKey } from '../../../../../common/constants/filters/page-status-filter';

export const pageStatusLabels = defineMessages<PageStatusFilterValueKey | 'all'>({
	all: {
		id: 'search-page.filter.pageStatus.all',
		defaultMessage: 'All',
		description: 'All page status',
	},
	roughDraft: {
		id: 'search-page.filter.pageStatus.roughDraft',
		defaultMessage: 'Rough draft',
		description: 'Rough draft page status',
	},
	inProgress: {
		id: 'search-page.filter.pageStatus.inProgress',
		defaultMessage: 'In progress',
		description: 'In progress page status',
	},
	readyForReview: {
		id: 'search-page.filter.pageStatus.readyForReview',
		defaultMessage: 'Ready for review',
		description: 'Ready for review page status',
	},
	verified: {
		id: 'search-page.filter.pageStatus.verified',
		defaultMessage: 'Verified',
		description: 'Verified page status',
	},
	customStatus: {
		id: 'search-page.filter.pageStatus.customStatus',
		defaultMessage: 'Custom status',
		description: 'Custom status page status',
	},
});
