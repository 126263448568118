import React from 'react';

export const BitbucketSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18696)">
			<path
				d="M31.9828 19.5142L29.96 31.8711C29.8281 32.6187 29.3004 33.0584 28.5528 33.0584H11.4026C10.655 33.0584 10.1273 32.6187 9.99541 31.8711L6.43345 9.83969C6.30152 9.09212 6.6973 8.6084 7.40089 8.6084H32.5545C33.2581 8.6084 33.6539 9.09212 33.5219 9.83969L32.5545 15.6444C32.4226 16.4799 31.9388 16.8317 31.1473 16.8317H16.02C15.8001 16.8317 15.6682 16.9636 15.7121 17.2275L16.8995 24.5273C16.9434 24.7032 17.0754 24.8351 17.2513 24.8351H22.7041C22.88 24.8351 23.012 24.7032 23.0559 24.5273L23.8915 19.2503C23.9794 18.5907 24.4191 18.3268 25.0348 18.3268H30.9714C31.8509 18.3268 32.1147 18.7666 31.9828 19.5142Z"
				fill="#1868DB"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2230_18696">
				<rect width="40" height="40" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
