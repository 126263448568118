import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

export type ConfluenceLabelsQueryVariables = {
	cloudId: string;
	searchText: string;
	limit?: number;
};

export const labelsCache = inMemoryCache<AggResponse<ConfluenceLabelsResponse>>();

const GQL_CF_LABEL_OPERATION_NAME = 'SearchLabelsQuery';
const getConfluenceLabelsQuery = () => `
query ${GQL_CF_LABEL_OPERATION_NAME}($cloudId: ID! $searchText: String!, $limit: Int) {
  confluence {
    searchLabels(cloudId: $cloudId, searchText: $searchText, limit: $limit) @optIn(to: "ConfluenceExperimentalSearchLabels") {
	  otherLabels {
        label
      }
      suggestedLabels {
        label
      }
    }
  }
}
`;

export type ConfluenceLabel = {
	label?: string;
	id?: string;
};

export type ConfluenceLabelsResponse = {
	confluence: {
		searchLabels: {
			suggestedLabels?: ConfluenceLabel[];
			otherLabels?: ConfluenceLabel[];
		};
	};
};

export const confluenceLabelsQuery = (cloudId: string, searchText: string, limit?: number) => {
	return labelsCache.inMemoryDecorator(searchText, async () => {
		const response = await fetchAgg<ConfluenceLabelsQueryVariables, ConfluenceLabelsResponse>({
			graphQuery: getConfluenceLabelsQuery(),
			variables: { cloudId, searchText, limit },
			operationName: GQL_CF_LABEL_OPERATION_NAME,
		});

		const hasErrors = response.errors?.some((error) => error);

		if (hasErrors) {
			return Promise.reject(response);
		}
		return response;
	});
};
