import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	connected: {
		id: 'product-tile-connected',
		defaultMessage: 'Connected',
		description: 'Text displayed when a product is connected',
	},
	failed: {
		id: 'product-tile-failed',
		defaultMessage: 'Failed.',
		description: 'Error text displayed when a product does has failed to connect',
	},
	retry: {
		id: 'product-tile-try-again',
		defaultMessage: 'Try again',
		description: 'Text displayed when a user is prompted to retry connection',
	},
	connect: {
		id: 'product-tile-connect',
		defaultMessage: 'Connect',
		description: 'Text displayed when a user is prompted to connect',
	},
	new: {
		id: 'product-tile-new',
		defaultMessage: 'New',
		description: 'Text displayed when a product is new',
	},
	checkIconLabel: {
		id: 'product-tile-check-icon-label',
		defaultMessage: 'Check Icon',
		description: 'Label for check icon',
	},
	errorIconLabel: {
		id: 'product-tile-error-icon-label',
		defaultMessage: 'Error Icon',
		description: 'Label for error icon',
	},
	externalLinkIconlabel: {
		id: 'product-tile-external-link-icon-label',
		defaultMessage: 'External Link Icon',
		description: 'Label for external link icon',
	},
	authorizationSuccess: {
		id: 'product-tile-oauth-warning.authorization-success',
		defaultMessage: 'Successfully connected to {product}',
		description: 'Message displayed when authorization is successful.',
	},
	authorizationFail: {
		id: 'product-tile-oauth-warning.authorization-fail',
		defaultMessage: 'Failed to connect to {product}',
		description: 'Message displayed when authorization fails',
	},
});
