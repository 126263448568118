import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { PowerBIIcon } from './icon';
import { PowerBISearchResultIcon } from './search-result-icon';

export const powerBIConfig: ThirdPartyConfig = {
	displayName: 'PowerBI',
	id: 'power-bi',
	resourceType: 'smartlinks-connector-power-bi',
	icon: get3PLogo(PowerBIIcon, 'PowerBI', 'medium'),
	searchResultIcon: get3PLogo(PowerBISearchResultIcon, 'PowerBI', 'large'),
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Dashboard,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
