import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const CanvaIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-587.5"
				y="-555.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<g clipPath="url(#clip0_719_13847)">
				<rect width="24" height="24" rx="6" fill="#7D2AE7" />
				<rect width="24" height="24" rx="6" fill="url(#paint0_radial_719_13847)" />
				<rect width="24" height="24" rx="6" fill="url(#paint1_radial_719_13847)" />
				<rect width="24" height="24" rx="6" fill="url(#paint2_radial_719_13847)" />
				<rect width="24" height="24" rx="6" fill="url(#paint3_radial_719_13847)" />
				<path
					d="M17.0221 14.2091C16.9332 14.2091 16.855 14.2841 16.7737 14.4479C15.8548 16.3111 14.2679 17.6294 12.4314 17.6294C10.308 17.6294 8.993 15.7125 8.993 13.0644C8.993 8.57869 11.4923 5.98516 13.6875 5.98516C14.7133 5.98516 15.3398 6.62979 15.3398 7.65565C15.3398 8.87317 14.6481 9.51781 14.6481 9.94721C14.6481 10.14 14.768 10.2567 15.0057 10.2567C15.9609 10.2567 17.082 9.15909 17.082 7.60853C17.082 6.10509 15.7735 5 13.5783 5C9.95033 5 6.72607 8.36346 6.72607 13.0172C6.72607 16.6195 8.78313 19 11.9571 19C15.3258 19 17.2737 15.6483 17.2737 14.5604C17.2737 14.3194 17.1506 14.2091 17.0221 14.2091Z"
					fill="white"
				/>
			</g>
			<defs>
				<radialGradient
					id="paint0_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(4.6359 21.2717) rotate(-49.416) scale(18.562)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(6.35364 2.72837) rotate(54.703) scale(20.932)"
				>
					<stop stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint2_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(4.63579 21.2716) rotate(-45.1954) scale(18.3373 8.43355)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint3_radial_719_13847"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(9.81474 3.23368) rotate(66.5198) scale(18.8951 31.6535)"
				>
					<stop stopColor="#00C4CC" stopOpacity="0.725916" />
					<stop offset="0.0001" stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>
				<clipPath id="clip0_719_13847">
					<path
						d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
						fill="white"
					/>
				</clipPath>
			</defs>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 20.025C16.4183 20.025 20 16.4377 20 12.0125C20 7.58732 16.4183 4 12 4C7.58172 4 4 7.58732 4 12.0125C4 16.4377 7.58172 20.025 12 20.025Z"
				fill="#7D2AE7"
			/>

			<path
				d="M12 20.025C16.4183 20.025 20 16.4377 20 12.0125C20 7.58732 16.4183 4 12 4C7.58172 4 4 7.58732 4 12.0125C4 16.4377 7.58172 20.025 12 20.025Z"
				fill="url(#paint0_radial_24_1067)"
			/>

			<path
				d="M12 20.025C16.4183 20.025 20 16.4377 20 12.0125C20 7.58732 16.4183 4 12 4C7.58172 4 4 7.58732 4 12.0125C4 16.4377 7.58172 20.025 12 20.025Z"
				fill="url(#paint1_radial_24_1067)"
			/>

			<path
				d="M12 20.025C16.4183 20.025 20 16.4377 20 12.0125C20 7.58732 16.4183 4 12 4C7.58172 4 4 7.58732 4 12.0125C4 16.4377 7.58172 20.025 12 20.025Z"
				fill="url(#paint2_radial_24_1067)"
			/>

			<path
				d="M12 20.025C16.4183 20.025 20 16.4377 20 12.0125C20 7.58732 16.4183 4 12 4C7.58172 4 4 7.58732 4 12.0125C4 16.4377 7.58172 20.025 12 20.025Z"
				fill="url(#paint3_radial_24_1067)"
			/>
			<path
				d="M15.4538 13.656C15.3878 13.656 15.3297 13.7118 15.2692 13.8338C14.5864 15.2204 13.4071 16.2016 12.0425 16.2016C10.4645 16.2016 9.48736 14.775 9.48736 12.804C9.48736 9.4655 11.3446 7.53522 12.9759 7.53522C13.7382 7.53522 14.2037 8.015 14.2037 8.77851C14.2037 9.68467 13.6897 10.1645 13.6897 10.484C13.6897 10.6275 13.7788 10.7144 13.9554 10.7144C14.6652 10.7144 15.4983 9.89747 15.4983 8.74344C15.4983 7.62448 14.526 6.802 12.8947 6.802C10.1988 6.802 7.8028 9.30531 7.8028 12.769C7.8028 15.45 9.3314 17.2217 11.69 17.2217C14.1933 17.2217 15.6408 14.7271 15.6408 13.9174C15.6408 13.7381 15.5493 13.656 15.4538 13.656Z"
				fill="white"
			/>

			<defs>
				<radialGradient
					id="paint0_radial_24_1067"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.0906 18.2033) rotate(-49.4602) scale(12.3858 12.3828)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>

				<radialGradient
					id="paint1_radial_24_1067"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(8.23576 5.82176) rotate(54.7452) scale(13.9692 13.962)"
				>
					<stop stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>

				<radialGradient
					id="paint2_radial_24_1067"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.09053 18.2032) rotate(-45.2401) scale(12.2345 5.62672)"
				>
					<stop stopColor="#6420FF" />
					<stop offset="1" stopColor="#6420FF" stopOpacity="0" />
				</radialGradient>

				<radialGradient
					id="paint3_radial_24_1067"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(10.5432 6.15916) rotate(66.5525) scale(12.6133 21.1075)"
				>
					<stop stopColor="#00C4CC" stopOpacity="0.725916" />
					<stop offset="0.0001" stopColor="#00C4CC" />
					<stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	);
};
