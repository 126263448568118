import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

const spacesCache = inMemoryCache<AggResponse<ConfluenceSpaceKeysToIdsQueryResponse>>();

const GRAPHQL_OPERATION_NAME = 'ConfluenceSpaceKeysToIdsQuery';

const getConfluenceSpaceKeysToIdsQuery = () => `
query ${GRAPHQL_OPERATION_NAME}($spaceKeys: [String]) {
  spaces(spaceKeys: $spaceKeys) {
    nodes {
      id
 	}
  }
}
`;

export interface ConfluenceSpaceKeysToIdsQueryResponseNode {
	id: string | null;
}

export interface ConfluenceSpaceKeysToIdsQueryResponseNodes {
	nodes: (ConfluenceSpaceKeysToIdsQueryResponseNode | null)[] | null;
}

export interface ConfluenceSpaceKeysToIdsQueryResponse {
	spaces: ConfluenceSpaceKeysToIdsQueryResponseNodes | null;
}

export interface ConfluenceSpaceKeysToIdsQueryVariables {
	spaceKeys?: (string | null)[] | null;
}

export interface ConfluenceSpaceKeysToIdsQueryProps {
	spaceKeys: string[];
	cloudId: string;
}

export const confluenceSpaceKeysToIdsQuery = ({
	spaceKeys,
	cloudId,
}: ConfluenceSpaceKeysToIdsQueryProps): Promise<
	AggResponse<ConfluenceSpaceKeysToIdsQueryResponse>
> => {
	return spacesCache.inMemoryDecorator(`${spaceKeys.join(',')}-${cloudId}`, async () => {
		// Technically fetchAgg is a util to fetch from AGG, but since we're overriding the URL it works with cc-graphql.
		const response = await fetchAgg<
			ConfluenceSpaceKeysToIdsQueryVariables,
			ConfluenceSpaceKeysToIdsQueryResponse
		>({
			graphQuery: getConfluenceSpaceKeysToIdsQuery(),
			variables: { spaceKeys },
			operationName: GRAPHQL_OPERATION_NAME,
			aggAbsoluteUrl: `/gateway/api/ex/confluence/${cloudId}/graphql`,
		});

		const hasErrors = response.errors?.some((error) => error);
		if (hasErrors) {
			return Promise.reject(response);
		}
		return response;
	});
};
