import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

const GQL_JIRA_ISSUE_TYPE_OPERATION_NAME = 'SearchPageJiraIssueTypeQuery';
const jqlIssueTypeQueryString = `query ${GQL_JIRA_ISSUE_TYPE_OPERATION_NAME}(
	$cloudId: ID!,
	$first: Int = 10,
	$jqlTerm: String!,
	$jql: String!,
	$searchString: String!) {
		jira {
			jqlBuilder(cloudId: $cloudId) {
				fieldValues (
					first: $first
					jqlTerm: $jqlTerm
					jqlContext: $jql
					searchString: $searchString
				) {
					edges {
						node {
							displayName
							... on JiraJqlIssueTypeFieldValue {
								issueTypes {
									issueTypeId
								}
							}
						}
					}
          		}
			}
		}
	}`;

export const issueTypeCache = inMemoryCache<AggResponse<JiraIssueTypeJQLResponse>>();

export type JiraIssueTypeJQLQueryVariables = {
	cloudId: string;
	jqlTerm: string;
	jql: string;
	searchString: string;
};

export type JiraIssueTypeJQLResponse = {
	jira: {
		jqlBuilder: {
			fieldValues: {
				totalCount: string;
				edges?: {
					node?: {
						issueTypes: { issueTypeId?: string }[];
						displayName: string;
					};
				}[];
			};
		};
	};
};
export const jiraIssueTypeJQLQuery = async (cloudId: string, searchString: string) => {
	return issueTypeCache.inMemoryDecorator(`${cloudId}-${searchString}`, async () => {
		const response = await fetchAgg<JiraIssueTypeJQLQueryVariables, JiraIssueTypeJQLResponse>({
			graphQuery: jqlIssueTypeQueryString,
			variables: {
				cloudId,
				jqlTerm: 'type',
				searchString,
				jql: '',
			},
			operationName: GQL_JIRA_ISSUE_TYPE_OPERATION_NAME,
			headers: {
				'X-ExperimentalApi': 'JiraJqlBuilder',
			},
		});

		const hasErrors = response.errors?.some((error) => error);

		if (hasErrors) {
			return Promise.reject(response);
		}

		return response;
	});
};
