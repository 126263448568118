import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const DovetailSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			\{' '}
			<rect
				x="-19.5"
				y="-742.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#292A2E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.69929 18.4645C7.65493 18.4901 7.65493 18.5542 7.69929 18.5798L11.878 20.993C11.9191 21.0168 11.9445 21.0608 11.9445 21.1083V25.9335C11.9445 25.9847 12 26.0167 12.0444 25.9911L16.223 23.5779C16.2642 23.5541 16.2896 23.5102 16.2896 23.4626V18.599C16.2896 18.5514 16.2642 18.5075 16.223 18.4837L11.9445 16.0142L7.69929 18.4645Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.69929 8.43014C7.65493 8.45578 7.65493 8.51982 7.69929 8.54542L11.878 10.9587C11.9191 10.9825 11.9445 11.0264 11.9445 11.0739V16.0144L16.2896 13.5051V8.56466C16.2896 8.5171 16.2642 8.47314 16.223 8.44934L12.011 6.01822C11.9699 5.99442 11.9191 5.99442 11.878 6.01822L7.69929 8.43014Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.2896 13.5041L20.568 15.975C20.6092 15.9988 20.6346 16.0427 20.6346 16.0903V20.9155C20.6346 20.9667 20.6901 20.9987 20.7344 20.9731L24.9131 18.5599C24.9543 18.5361 24.9796 18.4921 24.9796 18.4446V13.581C24.9796 13.5334 24.9543 13.4895 24.9131 13.4657L20.7012 11.0345C20.66 11.0108 20.6092 11.0108 20.568 11.0345L16.2896 13.5041Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M32.6 5H7.4C6.07452 5 5 6.07452 5 7.4V32.6C5 33.9255 6.07452 35 7.4 35H32.6C33.9255 35 35 33.9255 35 32.6V7.4C35 6.07452 33.9255 5 32.6 5Z"
				fill="#0C0020"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.2181 22.3104C12.1766 22.3344 12.1766 22.3944 12.2181 22.4185L16.1357 24.6809C16.1742 24.7032 16.198 24.7444 16.198 24.789V29.3125C16.198 29.3606 16.25 29.3906 16.2917 29.3665L20.2091 27.1042C20.2477 27.0819 20.2715 27.0407 20.2715 26.9961V22.4365C20.2715 22.3919 20.2477 22.3507 20.2091 22.3284L16.198 20.0132L12.2181 22.3104Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.2181 12.9029C12.1766 12.9269 12.1766 12.987 12.2181 13.011L16.1357 15.2734C16.1742 15.2957 16.198 15.3369 16.198 15.3815V20.0132L20.2715 17.6607V13.029C20.2715 12.9844 20.2477 12.9432 20.2091 12.9209L16.2604 10.6417C16.2218 10.6194 16.1742 10.6194 16.1357 10.6417L12.2181 12.9029Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.2715 17.6607L24.2826 19.9771C24.3212 19.9994 24.345 20.0406 24.345 20.0852V24.6088C24.345 24.6568 24.397 24.6868 24.4386 24.6628L28.3561 22.4004C28.3947 22.3781 28.4185 22.3369 28.4185 22.2924V17.7327C28.4185 17.6881 28.3947 17.6469 28.3561 17.6247L24.4074 15.3454C24.3688 15.3232 24.3212 15.3232 24.2826 15.3454L20.2715 17.6607Z"
				fill="white"
			/>
		</svg>
	);
};
