import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const AdobeSignSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-67.5"
				y="-550.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
				fill="#292A2E"
			/>
			<g clipPath="url(#clip0_719_13847)">
				<path
					d="M15.9995 16C15.9995 15.1159 16.3382 14.2681 16.941 13.6429C17.5438 13.0178 18.3613 12.6666 19.2138 12.6666C20.0663 12.6666 20.8838 13.0178 21.4866 13.6429C22.0894 14.2681 22.4281 15.1159 22.4281 16C22.4281 16.884 22.0894 17.7319 21.4866 18.357C20.8838 18.9821 20.0663 19.3333 19.2138 19.3333C18.3613 19.3333 17.5438 18.9821 16.941 18.357C16.3382 17.7319 15.9995 16.884 15.9995 16Z"
					fill="#1ABCFE"
				/>
				<path
					d="M9.57104 22.6666C9.57104 21.7825 9.90969 20.9347 10.5125 20.3096C11.1153 19.6844 11.9328 19.3333 12.7853 19.3333H15.9996V22.6666C15.9996 23.5506 15.661 24.3985 15.0582 25.0236C14.4554 25.6487 13.6378 25.9999 12.7853 25.9999C11.9328 25.9999 11.1153 25.6487 10.5125 25.0236C9.90969 24.3985 9.57104 23.5506 9.57104 22.6666V22.6666Z"
					fill="#0ACF83"
				/>
				<path
					d="M15.9995 6V12.6667H19.2138C20.0663 12.6667 20.8838 12.3155 21.4866 11.6904C22.0894 11.0652 22.4281 10.2174 22.4281 9.33333C22.4281 8.44928 22.0894 7.60143 21.4866 6.97631C20.8838 6.35119 20.0663 6 19.2138 6H15.9995Z"
					fill="#FF7262"
				/>
				<path
					d="M9.57129 9.33333C9.57129 10.2174 9.90994 11.0652 10.5127 11.6904C11.1155 12.3155 11.9331 12.6667 12.7856 12.6667H15.9999V6H12.7856C11.9331 6 11.1155 6.35119 10.5127 6.97631C9.90994 7.60143 9.57129 8.44928 9.57129 9.33333V9.33333Z"
					fill="#F24E1E"
				/>
				<path
					d="M9.57104 16C9.57104 16.884 9.90969 17.7319 10.5125 18.357C11.1153 18.9821 11.9328 19.3333 12.7853 19.3333H15.9996V12.6666H12.7853C11.9328 12.6666 11.1153 13.0178 10.5125 13.6429C9.90969 14.2681 9.57104 15.1159 9.57104 16V16Z"
					fill="#A259FF"
				/>
			</g>
			<defs>
				<clipPath id="clip0_719_13847">
					<rect width="12.8571" height="20" fill="white" transform="translate(9.57129 6)" />
				</clipPath>
			</defs>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.3 5H29.7C32.6 5 35 7.4 35 10.3V28.9C35 31.85 32.6 34.2 29.7 34.2H10.3C7.4 34.25 5 31.85 5 28.95V10.3C5 7.4 7.4 5 10.3 5Z"
				fill="#584CCC"
			/>
			<path
				d="M29.1 21.9501C28.5 21.3001 27.35 21.0001 25.7 21.0001C24.8 21.0001 23.85 21.1001 22.95 21.2501C22.35 20.7001 21.85 20.0501 21.35 19.4001C21 18.9001 20.65 18.4001 20.35 17.8501C20.85 16.4501 21.15 14.9501 21.25 13.4501C21.25 12.1001 20.7 10.6501 19.2 10.6501C18.7 10.6501 18.2 10.9501 17.95 11.4001C17.3 12.5001 17.55 14.7501 18.6 17.1001C18.25 18.1501 17.85 19.2001 17.4 20.3501C17 21.3501 16.55 22.3001 16 23.2001C14.45 23.8001 11.2 25.3001 10.9 26.9501C10.8 27.4501 10.95 27.9501 11.35 28.2501C11.7 28.5501 12.2 28.7501 12.7 28.7001C14.65 28.7001 16.6 26.0001 17.95 23.6501C18.7 23.4001 19.45 23.1501 20.25 22.9501C21.1 22.7501 21.9 22.5501 22.65 22.4001C24.75 24.2001 26.6 24.5001 27.5 24.5001C28.75 24.5001 29.25 23.9501 29.4 23.5001C29.6 22.9501 29.5 22.3501 29.1 21.9501ZM27.75 22.9001C27.7 23.2501 27.3 23.5001 26.8 23.5001C26.65 23.5001 26.5 23.5001 26.35 23.4501C25.35 23.2001 24.4 22.7001 23.6 22.0501C24.25 21.9501 24.95 21.9001 25.6 21.9001C26.05 21.9001 26.5 21.9501 26.95 22.0001C27.4 22.1001 27.9 22.3001 27.75 22.9001ZM18.8 11.8501C18.9 11.7001 19.05 11.6001 19.25 11.5501C19.75 11.5501 19.85 12.1001 19.85 12.6001C19.8 13.7501 19.6 14.8501 19.25 15.9501C18.4 13.8001 18.5 12.3501 18.8 11.8501ZM21.6 21.4501C21.05 21.5501 20.5 21.7001 19.95 21.8501C19.55 21.9501 19.15 22.1001 18.7 22.2001C18.9 21.7501 19.1 21.3001 19.3 20.9001C19.55 20.3501 19.75 19.8001 19.95 19.2501C20.15 19.5501 20.3 19.8001 20.5 20.0501C20.85 20.5501 21.25 21.0001 21.6 21.4501ZM15.55 24.3501C14.3 26.3501 13.05 27.6501 12.35 27.6501C12.25 27.6501 12.1 27.6001 12.05 27.5501C11.9 27.4501 11.85 27.2501 11.9 27.1001C12 26.3501 13.45 25.3001 15.55 24.3501Z"
				fill="white"
			/>
		</svg>
	);
};
