import { type SearchResultType } from '../../../common/types';
import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';
import { type SearchPageData, type SearchPageEdge } from '../search-page/types';

export type ResultNode = {
	title: string;
	url: string;
	excerpt: string;
	lastModified: string;
	links: {
		base: string;
	};
	content: {
		id: string;
		type: SearchResultType;
		space: {
			id: string | null;
			name: string | null;
			links: {
				webui: string;
			} | null;
		};
	};
};

export type PageInfo = {
	endCursor: string | null;
};

export type CQLQueryResponse = {
	search: {
		nodes: ResultNode[];
		pageInfo: PageInfo;
	};
};

export type CQLQueryVariables = {
	cql: string;
	first: number;
	after?: string;
};

export const pagesCache = inMemoryCache<AggResponse<SearchPageData>>();

const OPERATION_NAME = 'SearchPageCQLQuery';
const getConfluenceCQLQuery = () => `
query ${OPERATION_NAME}($cql: String!, $first: Int, $after: String) {
  search(cql: $cql, first: $first, after: $after) {
    nodes {
      title
      excerpt
      url
      lastModified
      links {
        base
      }
      content {
        id
        type
        space {
          id
          name
          links {
            webui
          }
        }
      }
    }
    pageInfo {
      endCursor
    }
  }
}
`;

const mapResultNodeToSearchPageEdge = (node: ResultNode, cloudId: string): SearchPageEdge => ({
	node: {
		id: `ari:cloud:confluence:${cloudId}:${node.content.type}/${node.content.id}`,
		title: node.title,
		description: node.excerpt,
		type: node.content.type,
		url: `${node.links.base}${node.url}`,
		lastModified: node.lastModified,
		isVerified: null,
		space:
			node.content.space.id && node.content.space.name
				? {
						id: `ari:cloud:confluence:${cloudId}:space/${node.content.space.id}`,
						name: node.content.space.name,
						webUiLink: node.content.space.links?.webui,
					}
				: null,
		socialSignal: null,
		__typename: 'SearchConfluencePageBlogAttachment',
	},
});

const mapToSearchPageData = (
	response: AggResponse<CQLQueryResponse>,
	cloudId: string,
): AggResponse<SearchPageData> => {
	const { data, ...rest } = response;
	if (!data) {
		return {
			data: {
				search: {
					results: {
						edges: [],
						totalCount: 0,
						pageInfo: { endCursor: null },
						abTest: {},
						totalCounts: [],
					},
				},
			},
			...rest,
		};
	}

	const { nodes, pageInfo } = data.search;

	const edges = nodes.map((node) => mapResultNodeToSearchPageEdge(node, cloudId));
	const totalCount = nodes.length;
	return {
		data: {
			search: {
				results: {
					edges,
					// total count is a mandatory field, but for cql case, it's inaccurate as we can't get total count from cql and also it won't be used in the UI
					totalCount,
					pageInfo,
					abTest: {},
					totalCounts: [],
				},
			},
		},
		...rest,
	};
};

export const confluenceCQLQuery = (
	{ cql, first, after }: CQLQueryVariables,
	cloudId: string,
): Promise<AggResponse<SearchPageData>> => {
	return pagesCache.inMemoryDecorator(`${cql}-${first}-${after}`, async () => {
		const response = await fetchAgg<CQLQueryVariables, CQLQueryResponse>({
			graphQuery: getConfluenceCQLQuery(),
			variables: { cql, first, after },
			operationName: OPERATION_NAME,
			aggAbsoluteUrl: `/gateway/api/ex/confluence/${cloudId}/graphql`,
		});

		const mappedResponse: AggResponse<SearchPageData> = mapToSearchPageData(response, cloudId);

		const hasErrors = mappedResponse.errors?.some((error) => error);

		if (hasErrors) {
			return Promise.reject(mappedResponse);
		}
		return mappedResponse;
	});
};
