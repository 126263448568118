import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

export const NotionSearchResultIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-211.5"
				y="-263.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<rect width="32" height="32" rx="8" fill="white" />
			<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#0B1228" strokeOpacity="0.14" />
			<path
				d="M7.63209 6.86307L18.6985 6.04568C20.0578 5.92908 20.407 6.00768 21.2618 6.62907L24.7944 9.11762C25.3769 9.54561 25.5709 9.66221 25.5709 10.1282V23.7765C25.5709 24.6319 25.2604 25.1379 24.173 25.2151L11.322 25.9935C10.506 26.0321 10.1174 25.9155 9.69005 25.3709L7.0887 21.9882C6.62211 21.3656 6.42871 20.8996 6.42871 20.3548V8.22304C6.42871 7.52365 6.7393 6.94046 7.63209 6.86307Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.6985 6.04588L7.63209 6.86327C6.7393 6.94046 6.42871 7.52385 6.42871 8.22304V20.3548C6.42871 20.8994 6.62211 21.3654 7.0887 21.9882L9.69005 25.3707C10.1174 25.9153 10.506 26.0321 11.322 25.9933L24.1732 25.2153C25.2597 25.1379 25.5711 24.6319 25.5711 23.7767V10.1284C25.5711 9.68641 25.3965 9.55901 24.8826 9.18182L21.2618 6.62907C20.4072 6.00768 20.0578 5.92908 18.6985 6.04568V6.04588ZM11.6126 9.90501C10.5632 9.97561 10.3252 9.99161 9.72925 9.50701L8.21408 8.30184C8.06008 8.14584 8.13748 7.95125 8.52547 7.91245L19.1639 7.13506C20.0572 7.05706 20.5224 7.36846 20.8718 7.64045L22.6964 8.96243C22.7744 9.00182 22.9684 9.23442 22.735 9.23442L11.7486 9.89581L11.6126 9.90501ZM10.3892 23.6601V12.0738C10.3892 11.5678 10.5446 11.3344 11.0098 11.2952L23.6284 10.5564C24.0564 10.5178 24.2498 10.7898 24.2498 11.295V22.8042C24.2498 23.3101 24.1718 23.7381 23.4732 23.7767L11.398 24.4767C10.6994 24.5153 10.3894 24.2827 10.3894 23.6601H10.3892ZM22.309 12.695C22.3864 13.0449 22.309 13.3949 21.959 13.4349L21.377 13.5503V22.1048C20.8716 22.3768 20.4064 22.5322 20.0176 22.5322C19.3963 22.5322 19.2411 22.3376 18.7757 21.7548L14.9697 15.7669V21.5602L16.1737 21.8328C16.1737 21.8328 16.1737 22.5328 15.2023 22.5328L12.5244 22.6882C12.4464 22.5322 12.5244 22.1436 12.7958 22.0662L13.4952 21.8722V14.2123L12.5246 14.1337C12.4466 13.7837 12.6406 13.2783 13.1846 13.2391L16.0579 13.0457L20.0178 19.111V13.7451L19.0085 13.6291C18.9305 13.2005 19.2411 12.8892 19.6291 12.8512L22.309 12.695Z"
				fill="#292A2E"
			/>
		</svg>
	) : (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.05507 6.29423L24.6546 5.06816C26.6937 4.89326 27.2175 5.01116 28.4997 5.94324L33.7985 9.67607C34.6724 10.3181 34.9633 10.493 34.9633 11.1919V31.6644C34.9633 32.9475 34.4975 33.7065 32.8664 33.8223L13.59 34.9899C12.366 35.0478 11.7831 34.8729 11.142 34.056L7.23998 28.9819C6.54009 28.048 6.25 27.349 6.25 26.5318V8.33419C6.25 7.28511 6.71589 6.41033 8.05507 6.29423Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.6546 5.06846L8.05507 6.29453C6.71589 6.41033 6.25 7.28541 6.25 8.33419V26.5318C6.25 27.3487 6.54009 28.0477 7.23998 28.9819L11.142 34.0557C11.7831 34.8726 12.366 35.0478 13.59 34.9896L32.8667 33.8226C34.4966 33.7065 34.9636 32.9475 34.9636 31.6647V11.1922C34.9636 10.5293 34.7018 10.3382 33.9308 9.77237L28.4997 5.94324C27.2178 5.01116 26.6937 4.89326 24.6546 5.06816V5.06846ZM14.0259 10.8571C12.4518 10.963 12.0948 10.987 11.2008 10.2602L8.92805 8.45239C8.69705 8.2184 8.81315 7.9265 9.39514 7.8683L25.3527 6.70222C26.6928 6.58523 27.3906 7.05232 27.9147 7.46031L30.6515 9.44327C30.7685 9.50237 31.0595 9.85126 30.7094 9.85126L14.2298 10.8433L14.0259 10.8571ZM12.1908 31.4898V14.1103C12.1908 13.3513 12.4239 13.0012 13.1217 12.9424L32.0495 11.8342C32.6915 11.7763 32.9816 12.1843 32.9816 12.9421V30.2059C32.9816 30.9649 32.8646 31.6068 31.8167 31.6647L13.704 32.7147C12.6561 32.7726 12.1911 32.4237 12.1911 31.4898H12.1908ZM30.0704 15.0421C30.1865 15.5671 30.0704 16.092 29.5455 16.152L28.6725 16.3251V29.1568C27.9144 29.5648 27.2166 29.7979 26.6334 29.7979C25.7013 29.7979 25.4685 29.506 24.7704 28.6318L19.0616 19.65V28.3399L20.8675 28.7488C20.8675 28.7488 20.8675 29.7988 19.4104 29.7988L15.3935 30.0319C15.2765 29.7979 15.3935 29.215 15.8006 29.0989L16.8497 28.8079V17.3181L15.3938 17.2002C15.2768 16.6752 15.5678 15.9171 16.3838 15.8583L20.6938 15.5683L26.6337 24.6662V16.6173L25.1196 16.4433C25.0026 15.8004 25.4685 15.3334 26.0505 15.2764L30.0704 15.0421Z"
				fill="black"
			/>
		</svg>
	);
};
