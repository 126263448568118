import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	modalTitle: {
		id: 'bulk-connect-dialog-title',
		defaultMessage: 'Connect your apps',
		description: 'Title for the bulk connect dialog',
	},
	modalSubheading: {
		id: 'bulk-connect-dialog-subheading',
		defaultMessage: 'Find your work more efficiently by individually connecting the apps you use',
		description: 'Subheading for the bulk connect dialog',
	},
	cancel: {
		id: 'bulk-connect-cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button for the bulk connect dialog',
	},
	done: {
		id: 'bulk-connect-done',
		defaultMessage: 'Done',
		description: 'Done button for the bulk connect dialog',
	},
	searchIconLabel: {
		id: 'search-icon-label',
		defaultMessage: 'Search',
		description: 'Label text for search icon',
	},
	searchPlaceholder: {
		id: 'search-icon-placeholder',
		defaultMessage: 'Search',
		description: 'Placeholder text for search input',
	},
	noResults: {
		id: 'bulk-connect-no-results',
		defaultMessage: "We couldn't find apps matching your search",
		description: 'Text displayed when no results found',
	},
});
