import { useCallback } from 'react';

import { onOAuthCompleted, onOAuthStarted } from '../../../common/utils/events';
import { useAnalytics, useCommonAndPrivateAttributes } from '../../analytics';

type OAuthEventProps = {
	cloudId: string;
	product: string;
	isOnSearchDialog?: boolean;
};

export const useFireOAuthStarted = ({ cloudId, product, isOnSearchDialog }: OAuthEventProps) => {
	const { fireAnalyticsEvent } = useAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useCommonAndPrivateAttributes();

	const callback = useCallback(() => {
		fireAnalyticsEvent(
			onOAuthStarted({
				cloudId,
				product,
				commonAttributes,
				nonPrivacySafeAttributes,
				isOnSearchDialog,
			}),
		);
	}, [
		fireAnalyticsEvent,
		cloudId,
		product,
		commonAttributes,
		nonPrivacySafeAttributes,
		isOnSearchDialog,
	]);
	return callback;
};
export const useFireOAuthCompleted = ({ cloudId, product, isOnSearchDialog }: OAuthEventProps) => {
	const { fireAnalyticsEvent } = useAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useCommonAndPrivateAttributes();

	const callback = useCallback(() => {
		fireAnalyticsEvent(
			onOAuthCompleted({
				cloudId,
				product,
				commonAttributes,
				nonPrivacySafeAttributes,
				isOnSearchDialog,
			}),
		);
	}, [
		fireAnalyticsEvent,
		cloudId,
		product,
		commonAttributes,
		nonPrivacySafeAttributes,
		isOnSearchDialog,
	]);
	return callback;
};
