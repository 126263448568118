import React from 'react';

export const FocusSearchResultIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2230_18694)">
			<g clipPath="url(#clip1_2230_18694)">
				<path
					d="M37.8564 20.4181C34.509 17.0708 29.0684 17.1063 25.7566 20.4181L22.9696 23.2051C21.4309 24.7439 18.9314 24.7439 17.3927 23.2051L15.0771 20.8895L9.02573 26.9409L11.3413 29.2565C16.2215 34.1367 24.1378 34.1367 29.018 29.2565L37.8564 20.4181ZM2.5 19.5848C5.84737 22.9322 11.2198 22.9648 14.5671 19.6174L17.3897 16.7948C18.9285 15.256 21.4279 15.256 22.9667 16.7948L25.2823 19.1104L31.3336 13.0591L29.018 10.7435C24.1378 5.86326 16.2215 5.86326 11.3413 10.7435L2.50296 19.5818L2.5 19.5848Z"
					fill="#1868DB"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_2230_18694">
				<rect width="40" height="40" fill="white" />
			</clipPath>
			<clipPath id="clip1_2230_18694">
				<rect width="40" height="40" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
