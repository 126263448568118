import React, { Suspense, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { FlagGroup } from '@atlaskit/flag';
import { getProductReleaseVersion } from '@atlassian/search-common';

import { type ApplicationModes } from '../../constants/application-modes';
import { type ExperimentConfig } from '../../constants/types';

import { BUILD_VERSION, ENTRYPOINT_ID, FEEDBACK_CONTEXT_CF } from './constants';
import { getFeedbackCollectorContext } from './utils';

export const AkFeedbackCollector = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/search-page/feedback-collector" */ '@atlaskit/feedback-collector'
	).then((module) => {
		return {
			default: module.default,
		};
	}),
);

const AkFeedbackFlag = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/search-page/feedback-flag" */ '@atlaskit/feedback-collector'
	).then(({ FeedbackFlag }) => {
		return {
			default: FeedbackFlag,
		};
	}),
);

export type FeedbackCollectorProps = {
	cloudId: string;
	experimentConfig: ExperimentConfig;
	isHello: boolean;
	isOpen: boolean;
	primaryProduct: string;
	product: string;
	setIsOpen: (isOpen: boolean) => void;
	source: string;
	userId: string;
	userName: string;
	mode: ApplicationModes;
};

const FeedbackCollector = ({
	cloudId,
	experimentConfig,
	isHello,
	isOpen,
	primaryProduct,
	product,
	setIsOpen,
	source,
	userId,
	userName,
	mode,
}: FeedbackCollectorProps) => {
	const { locale } = useIntl();

	const [displayFlag, setDisplayFlag] = useState(false);

	const feedbackContext = getFeedbackCollectorContext({
		experimentLayers: experimentConfig.backendExperiment?.experimentLayers,
		frontendExperiments: experimentConfig.frontendExperiments,
		version: BUILD_VERSION,
		release: getProductReleaseVersion(primaryProduct),
		source,
		product: product,
		primaryProduct,
		cloudId,
		userId,
		isHello,
		mode,
	});

	return (
		<>
			{isOpen && (
				<Suspense fallback={<></>}>
					<AkFeedbackCollector
						locale={locale}
						onClose={() => setIsOpen(false)}
						onSubmit={() => setDisplayFlag(true)}
						atlassianAccountId={userId}
						name={userName}
						entrypointId={ENTRYPOINT_ID}
						additionalFields={[
							{
								id: FEEDBACK_CONTEXT_CF,
								value: feedbackContext,
							},
						]}
					/>
				</Suspense>
			)}
			{displayFlag && (
				<FlagGroup onDismissed={() => setDisplayFlag(false)}>
					<Suspense fallback={<></>}>
						<AkFeedbackFlag locale={locale} />
					</Suspense>
				</FlagGroup>
			)}
		</>
	);
};

export default FeedbackCollector;
