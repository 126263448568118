import React from 'react';
import { Query } from 'react-apollo';

import { UserContextQuery } from './UserContextQuery.graphql';
import type { UserContextQuery as UserContextQueryResults } from './__types__/UserContextQuery';

export interface UserContextData {
	isLoading: boolean;
	data?: UserContextDataPayload;
}

export interface UserContextDataPayload {
	name: string | undefined;
	email: string | undefined;
	id: string | undefined;
}

export const UserContext = ({ children }: { children: (data: UserContextData) => JSX.Element }) => (
	<Query<UserContextQueryResults> query={UserContextQuery}>
		{({ data: rawData, loading }) => {
			const displayName = (rawData && rawData.user && rawData.user.displayName) ?? undefined;
			const id = (rawData && rawData.user && rawData.user.id) ?? undefined;
			const emails = (rawData && rawData.user && rawData.user.emails) || [];
			const email =
				emails
					.filter((email) => email?.isPrimary)
					.map((email) => email?.value)
					.find(Boolean) ?? undefined;

			return children({
				isLoading: loading,
				data: {
					email,
					id,
					name: displayName,
				},
			});
		}}
	</Query>
);
