import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AdobeXDIcon } from './icon';
import { AdobeXDSearchResultIcon } from './search-result-icon';

export const adobeXDConfig: ThirdPartyConfig = {
	displayName: 'AdobeXD',
	id: 'adobexd',
	resourceType: 'smartlinks-connector-adobexd',
	icon: get3PLogo(AdobeXDIcon, 'AdobeXD', 'medium'),
	searchResultIcon: get3PLogo(AdobeXDSearchResultIcon, 'AdobeXD', 'large'),
	integrationAri: 'ari:cloud:platform::integration/adobe',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Design,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
