import { DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI } from '../../entities';
import { TypeFilterValueKey } from '../../filters/universal-type';
import { get3PLogo } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { PipedriveIcon } from './icon';
import { PipedriveSearchResultIcon } from './search-result-icon';

export const pipedriveConfig: ThirdPartyConfig = {
	displayName: 'Pipedrive',
	id: 'pipedrive',
	resourceType: 'smartlinks-connector-pipedrive',
	icon: get3PLogo(PipedriveIcon, 'Pipedrive', 'medium'),
	searchResultIcon: get3PLogo(PipedriveSearchResultIcon, 'Pipedrive', 'large'),
	integrationAri: 'ari:cloud:platform::integration/pipedrive',
	typeFilterConfig: new Map([
		[
			TypeFilterValueKey.Deal,
			{
				entities3P: DEFAULT_CLOUD_GRAPH_REMOTE_LINK_ENTITY_ATI,
				subTypes3P: [],
			},
		],
	]),
	requiresOauth: true,
	isEnabled: () => true,
};
