import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const GithubIcon = () => {
	return fg('platform-team25-app-icon-tiles') ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="-491.5"
				y="-652.5"
				width="1941"
				height="891"
				rx="4.5"
				stroke="#9747FF"
				strokeDasharray="10 5"
			/>
			<path
				d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
				fill="#292A2E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0001 4C7.58233 4 4 7.67232 4 12.2025C4 15.8266 6.29226 18.9013 9.47093 19.9859C9.87075 20.0618 10.0175 19.808 10.0175 19.5913C10.0175 19.3957 10.0101 18.7496 10.0067 18.0642C7.78105 18.5604 7.31141 17.0964 7.31141 17.0964C6.9475 16.1483 6.42315 15.8962 6.42315 15.8962C5.6973 15.3871 6.47786 15.3975 6.47786 15.3975C7.28121 15.4554 7.70421 16.2428 7.70421 16.2428C8.41774 17.4968 9.57572 17.1343 10.0322 16.9247C10.104 16.3945 10.3114 16.0327 10.5402 15.8279C8.76324 15.6204 6.8953 14.9171 6.8953 11.7741C6.8953 10.8786 7.20782 10.1469 7.71958 9.57245C7.63651 9.36585 7.36268 8.53158 7.79708 7.40174C7.79708 7.40174 8.46887 7.18129 9.99766 8.24253C10.6358 8.06079 11.3202 7.96965 12.0001 7.96653C12.6799 7.96965 13.3649 8.06079 14.0042 8.24253C15.5311 7.18129 16.202 7.40174 16.202 7.40174C16.6375 8.53158 16.3635 9.36585 16.2804 9.57245C16.7934 10.1469 17.1038 10.8786 17.1038 11.7741C17.1038 14.9246 15.2323 15.6183 13.4508 15.8213C13.7378 16.0759 13.9935 16.5751 13.9935 17.3403C13.9935 18.4378 13.9842 19.3211 13.9842 19.5913C13.9842 19.8096 14.1282 20.0654 14.5337 19.9848C17.7107 18.899 20 15.8254 20 12.2025C20 7.67232 16.4182 4 12.0001 4Z"
				fill="white"
			/>
		</svg>
	) : (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_6309_146339)">
				<rect width="20" height="20" fill="white" fillOpacity="0.01" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.0001 0C4.47791 0 0 4.59039 0 10.2532C0 14.7833 2.86533 18.6266 6.83867 19.9824C7.33844 20.0773 7.52192 19.76 7.52192 19.4891C7.52192 19.2446 7.51265 18.437 7.50834 17.5802C4.72631 18.2005 4.13927 16.3705 4.13927 16.3705C3.68437 15.1854 3.02894 14.8702 3.02894 14.8702C2.12163 14.2339 3.09733 14.2469 3.09733 14.2469C4.10151 14.3193 4.63026 15.3035 4.63026 15.3035C5.52217 16.871 6.96965 16.4178 7.5403 16.1559C7.63006 15.4932 7.88921 15.0409 8.1752 14.7848C5.95404 14.5256 3.61912 13.6464 3.61912 9.71766C3.61912 8.59825 4.00977 7.6836 4.64947 6.96556C4.54564 6.70731 4.20335 5.66448 4.74635 4.25218C4.74635 4.25218 5.58609 3.97661 7.49708 5.30317C8.29476 5.07599 9.15024 4.96206 10.0001 4.95816C10.8499 4.96206 11.7061 5.07599 12.5052 5.30317C14.4139 3.97661 15.2525 4.25218 15.2525 4.25218C15.7968 5.66448 15.4544 6.70731 15.3505 6.96556C15.9917 7.6836 16.3797 8.59825 16.3797 9.71766C16.3797 13.6557 14.0403 14.5228 11.8135 14.7767C12.1722 15.0949 12.4918 15.7188 12.4918 16.6754C12.4918 18.0473 12.4802 19.1514 12.4802 19.4891C12.4802 19.762 12.6602 20.0817 13.1671 19.981C17.1383 18.6237 20 14.7818 20 10.2532C20 4.59039 15.5227 0 10.0001 0Z"
					fill={token('color.icon', N800)}
				/>
			</g>
			<defs>
				<clipPath id="clip0_6309_146339">
					<rect width="20" height="20" fill={token('color.icon', N800)} />
				</clipPath>
			</defs>
		</svg>
	);
};
