import { fg } from '@atlaskit/platform-feature-flags';

import { type AggRequest, fetchAgg } from '../../../../common/utils/fetch-agg';

import { operationName, quickFindRecentActivitiesQuery } from './query';
import {
	type QuickFindRecentActivitiesQuery,
	type QuickFindRecentActivitiesQueryVariables,
} from './types';

export type QuickFindRecentActivitiesQueryProps = Pick<
	AggRequest<QuickFindRecentActivitiesQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
>;

export const recentActivitiesQuery = ({
	variables,
	aggAbsoluteUrl,
}: QuickFindRecentActivitiesQueryProps) => {
	const is3PEnabled = fg('quick_find_enable_3p_activity');

	return fetchAgg<QuickFindRecentActivitiesQueryVariables, QuickFindRecentActivitiesQuery>({
		variables,
		aggAbsoluteUrl,
		graphQuery: quickFindRecentActivitiesQuery(is3PEnabled),
		operationName,
	});
};
