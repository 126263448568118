import { type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { inMemoryCache } from '../../../utils/caching/in-memory';

export type UserVariables = { accountId: string };

export type User = {
	picture: string;
	name: string;
	id: string;
	accountId: string;
};

export type UserResponse = {
	user: User;
};

export const cache = inMemoryCache<AggResponse<UserResponse>>();

const GQL_OPERATION_NAME = 'SearchPageUserQuery';
const getUserQuery = () => `
query ${GQL_OPERATION_NAME}($accountId: ID!) {
  user(accountId: $accountId) {
    id
    accountId
    name
    picture
  }
}
`;

export const userQuery = (accountId: string) => {
	return cache.inMemoryDecorator(accountId, async () => {
		const response = await fetchAgg<UserVariables, UserResponse>({
			graphQuery: getUserQuery(),
			variables: { accountId },
			operationName: GQL_OPERATION_NAME,
		});

		const hasErrors = response.errors?.some((error) => error);

		if (hasErrors) {
			return Promise.reject(response);
		}
		return response;
	});
};

export type UsersQueryVariables = { accountIds: string[] };

export const usersQueryCache = inMemoryCache<AggResponse<UsersResponse>>();

const GQL_USERS_OPERATION_NAME = 'SearchPageUsersQuery';
const getUsersQuery = () => `
query ${GQL_USERS_OPERATION_NAME}($accountIds: [ID!]!) {
  users(accountIds: $accountIds) {
    id
    name
    picture
    accountId
  }
}
`;

export type UsersResponse = {
	users: User[];
};

export const usersQuery = (accountIds: string[]) => {
	return usersQueryCache.inMemoryDecorator(
		// TODO: >>>> Limit this to a max string length
		// TODO: Refactor so that it can cache per user id
		accountIds.join(','),
		async () => {
			const response = await fetchAgg<UsersQueryVariables, UsersResponse>({
				graphQuery: getUsersQuery(),
				variables: { accountIds },
				operationName: GQL_USERS_OPERATION_NAME,
			});

			const hasErrors = response.errors?.some((error) => error);

			if (hasErrors) {
				return Promise.reject(response);
			}
			return response;
		},
	);
};
